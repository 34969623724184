import { Trans } from '@lingui/macro';

import Select from '../../generic/form/Select';

export const CLIENT_SELECTOR_FIELDNAME = 'client';

const CheckoutClientSelect = ({ fieldChoices, isLoading, handleSearchChange, willSearchStaffs = false, ...props }) => {
  return (
    <Select
      fieldName={CLIENT_SELECTOR_FIELDNAME}
      fieldPlaceholder={willSearchStaffs ? <Trans>Select staff</Trans> : <Trans>Select client</Trans>}
      fieldChoices={fieldChoices}
      isSearchable
      isClearable
      selectFirstChoice={false}
      isLoading={isLoading}
      onInputChange={handleSearchChange}
      {...props}
      isRequired={willSearchStaffs ? true : false}
    />
  );
};

export default CheckoutClientSelect;
