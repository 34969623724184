import { t } from '@lingui/macro';

import useWriteSeo from '../../hooks/useWriteSeo';
import Checkout from '../business/checkout/Checkout';

// ==============================================================================================
// Page infos :
// This is the final process, we fill the payment infos and validate
// This page CANNOT be accessed directly from an url.
// Route infos :
// - standard navigation : no param, we reuse data from cart page
// ==============================================================================================

const CheckoutPage = () => {
  useWriteSeo({ title: t`Bill and contact` });

  return <Checkout />;
};

export default CheckoutPage;
