import { useLocation, useNavigate } from 'react-router-dom';

import { getFullFrontUrl } from '../utils/navigationUtil';

// This hook is to be used in place of the "useNavigate"
// Because this hook handles our /bookingEngineId/page thingy
const useNavigateBookingEngine = () => {
  const originalNav = useNavigate();
  const location = useLocation();
  const navigate = (relativeRoutePath, params) => {
    // We still support the navigate(-1)
    if (typeof relativeRoutePath === 'number') {
      // Default means we just arrived on the page so no back but a redirect to the home page
      originalNav(location && location.key === 'default' ? getFullFrontUrl('') : relativeRoutePath);
    } else {
      originalNav(getFullFrontUrl(relativeRoutePath), params);
    }
  };

  return navigate;
};

export default useNavigateBookingEngine;
