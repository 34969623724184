import { HStack, Icon } from '@chakra-ui/react';

const IconWithText = ({ icon, iconProps, ...props }) => {
  return (
    <HStack>
      <Icon as={icon} color="brand.color_main" {...iconProps} />
      {props.children}
    </HStack>
  );
};

export default IconWithText;
