import { useMemo } from 'react';

import { t } from '@lingui/macro';

import useIsMobile from '../../../../hooks/useIsMobile';
import { getDynamicInputMaxWidth } from '../../../generic/form/InputText';
import Select from '../../../generic/form/Select';
import SelectOptionWithPrice from '../../../generic/form/SelectOptionWithPrice';
import SelectedOptionDetail from './SelectedOptionDetail';

const OptionCustom = ({ data, index, onOptionUpdate, defaultOption, variantParticipants }) => {
  const isMobile = useIsMobile();

  const hasOneOptionRequired = data.required && defaultOption.resources && defaultOption.resources.length === 1;
  const choices = useMemo(
    () => getSelectChoices(defaultOption, variantParticipants, hasOneOptionRequired),
    [JSON.stringify(defaultOption), variantParticipants]
  );

  return (
    <Select
      fieldLabel={data.label}
      fieldLabelLines={3}
      fieldValue={data.selectedResource}
      fieldChoices={choices}
      onChange={(fieldName, newValue) => onOptionUpdate(data.id, newValue, index)}
      isRequired={data.required}
      maxWidth={() => getDynamicInputMaxWidth(data.label, isMobile)}
      formatOptionLabel={SelectOptionWithPrice}
      isOptionInvalid={option => option.unavailable}
      additionalComponentHRight={<SelectedOptionDetail selectedId={data.selectedResource} />}
      triggerChangeOnUpdate={hasOneOptionRequired ? true : undefined} // this is to trigger the onChange when we have one mandatory option
    />
  );
};

export default OptionCustom;

const getSelectChoices = (defaultOption, variantParticipants, hasOneOptionRequired) => {
  const choices = hasOneOptionRequired ? [] : [{ label: t`None`, value: '' }];
  for (const element of defaultOption.resources) {
    const stockMax = element.current_stock == null ? null : Math.max(element.current_stock, 0); // null = infinite stock
    const stock = element.calculatedStock ?? stockMax; // no calculatedStock when infinite

    choices.push({
      label: element.label,
      value: element.id,
      price: element.price,
      stock: stock,
      stockMax: stockMax,
      unavailable: stock !== null && (stock < 0 || variantParticipants > stockMax),
    });
  }
  return choices;
};
