import { Box, Checkbox, HStack, Icon, Spacer } from '@chakra-ui/react';
import { BsArrowRightCircle } from 'react-icons/bs';
import { useBoolean, useUpdateEffect } from 'usehooks-ts';

import { ROUTE_P3_SESSION_VARIANTS } from '../../../constants';
import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import useReadAvailability from '../../../hooks/useReadAvailability';
import useWritePreCart from '../../../hooks/useWritePreCart';
import { ITEM_LIST_HOVER, ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import ButtonIcon from '../../generic/button/ButtonIcon';
import AvailabilityDateTime from './AvailabilityDateTime';
import AvailabilityMoreDates from './AvailabilityMoreDates';
import AvailabilityPriceInfo from './AvailabilityPriceInfo';

const Availability = ({ availability: ava, onAvailabilitySelect }) => {
  const { preCartData, setPreCartAvailabilities } = useWritePreCart();
  const { product } = preCartData;
  const { maxAvailablePlaces, maxBookableSessions } = useReadAvailability(ava, product);

  const { value: isChecked, toggle: toggleChecked } = useBoolean(false);

  const navigate = useNavigateBookingEngine();
  const onAvailabilityClick = e => {
    e.preventDefault();

    if (onAvailabilitySelect) {
      toggleChecked();
      return;
    }

    ava.variants = [];
    setPreCartAvailabilities([ava]);
    navigate(ROUTE_P3_SESSION_VARIANTS);
  };

  useUpdateEffect(() => {
    if (onAvailabilitySelect) onAvailabilitySelect(ava, isChecked);
  }, [isChecked]);

  return (
    <Box bg="brand.background_main" onClick={onAvailabilityClick} {...ITEM_LIST_HOVER} {...ROUNDED_ELEMENT_PROPS}>
      <HStack spacing=".5rem">
        {onAvailabilitySelect && <Checkbox size="lg" isChecked={isChecked} />}

        <Box>
          <AvailabilityDateTime sessions={[ava]} placesAvailable={maxAvailablePlaces} />

          <AvailabilityMoreDates sessionGroupIds={[ava.session_group_id]} nbSessions={ava.nb_sessions} />
        </Box>

        <Spacer />

        {product && <AvailabilityPriceInfo product={product} price={ava.price} maxBookableSessions={maxBookableSessions} />}

        {!onAvailabilitySelect && <ButtonIcon icon={<Icon as={BsArrowRightCircle} boxSize="24px" />} />}
      </HStack>
    </Box>
  );
};

export default Availability;
