import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { apiGetAvailabilities } from '../../../../constants';
import { CoreContext } from '../../../../context/CoreContext';
import FilteredList from '../../../generic/FilteredList';
import AvailabilitiesList from './AvailabilitiesList';
import { AVAILABILITIES_FILTERS } from './AvailabilitiesListFilters';
import AvailabilitiesListMultiSelect from './AvailabilitiesListMultiSelect';

const AvailabilitiesFilteredList = () => {
  const { coreData } = useContext(CoreContext);
  const { multiple_selection: isMultipleSelection } = coreData.booking_engine;

  const { productId } = useParams();

  // This goes outside hooks because we need it before anything else
  // It also doesnt go in AVAILABILITIES_FILTERS because it wouldnt reload the date
  // So if the user is browsing at 23h50 then the filter will be wrong
  AVAILABILITIES_FILTERS.DATE.default = new Date();

  return (
    <FilteredList
      listType={isMultipleSelection ? AvailabilitiesListMultiSelect : AvailabilitiesList}
      endpoint={apiGetAvailabilities(productId)}
      propertyToReturn="availabilities"
      itemsPerScroll={AVAILABILITIES_FILTERS_PER_SCROLL}
      filtersToDisplay={filtersToDisplay}
      skeletonHeight="77px"
      showFiltersMobile
    />
  );
};

const AVAILABILITIES_FILTERS_PER_SCROLL = 20;

const filtersToDisplay = [AVAILABILITIES_FILTERS.DATE, AVAILABILITIES_FILTERS.HOUR, AVAILABILITIES_FILTERS.NB_TICKETS];

export default AvailabilitiesFilteredList;
