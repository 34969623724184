import { t } from '@lingui/macro';

import Image from '../../generic/Image';
import ImagesSlider from '../../generic/ImagesSlider';
import Video from '../../generic/Video';

const ProductMediaManager = ({ product, disableVideo = false, ...props }) => {
  const { description_image: mainImage, product_medias: productMedias, description_video: singleVideo } = product;

  if (singleVideo && !disableVideo) return <Video videoUrl={singleVideo} {...props} />;
  else if (productMedias && productMedias.length) return <ImagesSlider productMedias={productMedias} mainImage={mainImage} {...props} />;
  else if (mainImage) return <Image imageUrl={mainImage} alt={t`Product image`} {...props} />;
  return <></>;
};

export default ProductMediaManager;
