import { useClipboard, useToast } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { BsFillShareFill } from 'react-icons/bs';
import { useUpdateEffect } from 'usehooks-ts';

import ButtonIconLabel from '../../../generic/button/ButtonIconLabel';

const TOAST_ID = 'cartShareToast';

const CartActionShareCart = ({ storedCartId }) => {
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(getCartShareUrl(storedCartId));

  useUpdateEffect(() => {
    if (hasCopied && !toast.isActive(TOAST_ID)) {
      toast({
        id: TOAST_ID,
        title: t`Cart link copied to clipboard`,
        status: 'success',
        duration: 2500,
        variant: 'subtle',
      });
    }
  }, [hasCopied]);

  return (
    <ButtonIconLabel onClick={onCopy} icon={<BsFillShareFill />} id="CartShareAction">
      <Trans>Share my cart</Trans>
    </ButtonIconLabel>
  );
};

export default CartActionShareCart;

export const getCartShareUrl = storedCartId => {
  const url = window.location.href;
  if (url.endsWith('/cart')) {
    return url + '/' + storedCartId;
  }
  return url.endsWith('/cart/') ? url + storedCartId : url;
};
