import cloneDeep from 'lodash.clonedeep';

import { FIRST_NAME_FIELDNAME, LAST_NAME_FIELDNAME } from '../../components/business/checkout/CheckoutFieldsCustomer';
import { BIRTHDATE_FIELDNAME } from '../../components/generic/form/InputBirthDate';
import { EMAIL_FIELDNAME } from '../../components/generic/form/InputEmail';
import { PHONE_COUNTRYCODE_FIELDNAME, PHONE_FIELDNAME } from '../../components/generic/form/InputPhone';
import { adaptDateForBackend, adaptDateForFrontEnd } from '../dateUtil';
import { adaptPhoneForBackend } from '../phoneUtil';
import { trimObjectKeys } from '../strUtil';

export const handleParticipantDataForBackend = participant => {
  const participantCopy = cloneDeep(participant);
  delete participantCopy.displayed;

  if ('birth_date' in participantCopy) {
    participantCopy['birth_date'] = adaptDateForBackend(participantCopy['birth_date']);
  }

  trimObjectKeys(participantCopy, [FIRST_NAME_FIELDNAME, LAST_NAME_FIELDNAME, EMAIL_FIELDNAME]);

  participantCopy[PHONE_FIELDNAME] = adaptPhoneForBackend(participantCopy[PHONE_FIELDNAME], participantCopy[PHONE_COUNTRYCODE_FIELDNAME]);
  delete participantCopy[PHONE_COUNTRYCODE_FIELDNAME];

  // In case of a select, we need to send the label, not the id, that's why we do that replace
  if (participantCopy['extra_data_select']) {
    for (const [key, value] of Object.entries(participantCopy['extra_data_select'])) {
      participantCopy['extra_data'][key] = value;
    }
    delete participantCopy.extra_data_select;
  }
  return participantCopy;
};

export const handleParticipantDataForFrontEnd = participant => {
  const participantCopy = cloneDeep(participant);
  if (participantCopy['birth_date']) {
    participantCopy['birth_date'] = adaptDateForFrontEnd(participantCopy['birth_date']);
  }
  return participantCopy;
};

// small hack for the clients fields bc the birthdate doesnt match its specific data name
export const getCorrectClientFieldName = label => (label === BIRTHDATE_FIELDNAME ? 'birth_date' : label);

export const updateParticipantData = (copyParticipant, customDataId, label, newValue, additionalData) => {
  if (customDataId !== null) {
    copyParticipant.extra_data[label] = newValue;
    if (additionalData) {
      if (!copyParticipant.extra_data_select) {
        copyParticipant.extra_data_select = {};
      }
      copyParticipant.extra_data_select[label] = additionalData;
    }
    return;
  }

  const validLabel = getCorrectClientFieldName(label);
  copyParticipant[validLabel] = newValue;

  if (validLabel === PHONE_FIELDNAME) {
    copyParticipant[PHONE_COUNTRYCODE_FIELDNAME] = additionalData;
  }
};
