import { useMemo } from 'react';

import { LANG_SUPPORTED, LOCAL_STORAGE_APPLANG } from '../../../../constants';
import { getFlagFromLang } from '../../../../utils/countries';
import { getLangForTranslations } from '../../../../utils/langUtil';
import Select from '../../form/Select';

// This is used in the css, be careful changing this! (global.select.css)
const APPLANG_FIELDNAME = 'applang';

const getAppLangChoices = () => {
  const appLangChoices = [];
  for (const lang of LANG_SUPPORTED) {
    appLangChoices.push({ value: lang, label: getFlagFromLang(lang) });
  }
  return appLangChoices;
};

const AppLangSelector = () => {
  // useMemo because we only need to trigger this call once during the full user navigation
  const APP_LANG_CHOICES = useMemo(() => getAppLangChoices(), []);
  const CURRENT_APP_LANG = useMemo(() => getLangForTranslations(), []);

  // This is too complicated to handle via react so we do it this way and refresh
  const handleOnChange = (fName, fValue) => {
    localStorage.setItem(LOCAL_STORAGE_APPLANG, fValue);
    location.reload();
  };

  return (
    <Select
      displayLabel={false}
      fieldName={APPLANG_FIELDNAME}
      fieldValue={CURRENT_APP_LANG}
      fieldChoices={APP_LANG_CHOICES}
      classNamePrefix={APPLANG_FIELDNAME}
      onChange={handleOnChange}
      maxWidth="55px"
      height="25px"
      size="sm"
    />
  );
};

export default AppLangSelector;
