import { Icon, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { AiOutlineGift, AiOutlineShopping } from 'react-icons/ai';

import { ROUTE_PAGE_BUY_VOUCHER } from '../../../../constants';
import { BTN_ICON_BOXSIZE } from '../../../../utils/styleUtil';
import ButtonLink from '../../../generic/button/ButtonLink';

const ProductDetailBuyVoucherAction = ({ product }) => {
  const { voucher_name: productVoucherName } = product;
  return (
    <ButtonLink
      leftIcon={<Icon as={productVoucherName ? AiOutlineShopping : AiOutlineGift} boxSize={BTN_ICON_BOXSIZE} pb="3px" />}
      to={ROUTE_PAGE_BUY_VOUCHER}
      size="md"
    >
      <Text noOfLines={1} whiteSpace="normal">
        {productVoucherName}
      </Text>

      {!productVoucherName && <Trans>Voucher</Trans>}
    </ButtonLink>
  );
};

export default ProductDetailBuyVoucherAction;
