import { useCallback, useRef } from 'react';

import { Trans } from '@lingui/macro';

import 'react-datepicker/dist/react-datepicker.css';
import useIsMobile from '../../../hooks/useIsMobile';
import { addDaysToDate, getNextMonday, getThisMonday } from '../../../utils/dateUtil';
import DatepickerCommon, { closeDatePicker } from './DatepickerCommon';

export const DATERANGE_FILTER_NAME_START = 'start_date';
export const DATERANGE_FILTER_NAME_END = 'end_date';
export const DATE_FILTER_NAME = 'date';

const DateRangePicker = ({ fieldStartDate, fieldEndDate, onChangeDateRange, ...props }) => {
  const isMobile = useIsMobile();

  const pickerRef = useRef();

  const onClickThisWeek = useCallback(() => {
    onChangeDateRange(getThisMonday(), addDaysToDate(getThisMonday(), 6));
    closeDatePicker(pickerRef);
  }, []);
  const onClickNextWeek = useCallback(() => {
    onChangeDateRange(getNextMonday(), addDaysToDate(getNextMonday(), 6));
    closeDatePicker(pickerRef);
  }, []);
  const onClickNext15Days = useCallback(() => {
    onChangeDateRange(new Date(), addDaysToDate(new Date(), 15));
    closeDatePicker(pickerRef);
  }, []);

  return (
    <DatepickerCommon
      ref={pickerRef}
      isMobile={isMobile}
      selectsRange={true}
      startDate={fieldStartDate}
      endDate={fieldEndDate}
      onChange={updatedDate => onChangeDateRange(updatedDate[0], updatedDate[1])}
      monthsShown={isMobile ? 1 : 2}
      dateFormat="dd/MM"
      {...props}
    >
      <div className="react-datepicker__today-button" onClick={onClickThisWeek}>
        <Trans>This week</Trans>
      </div>
      <div className="react-datepicker__today-button" onClick={onClickNextWeek}>
        <Trans>Next week</Trans>
      </div>
      {!isMobile && (
        <div className="react-datepicker__today-button" onClick={onClickNext15Days}>
          <Trans>Next 15 days</Trans>
        </div>
      )}
    </DatepickerCommon>
  );
};

export default DateRangePicker;
