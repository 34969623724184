import { Text } from '@chakra-ui/react';
import { MdPhone } from 'react-icons/md';

import IconWithText from './IconWithText';

export const TEAM_CONTACT_ICONS_PROPS = { w: '20px', h: '20px' };

const TeamPhone = ({ teamPhone }) => {
  return (
    <>
      {teamPhone && (
        <IconWithText icon={MdPhone} iconProps={TEAM_CONTACT_ICONS_PROPS}>
          <Text>{teamPhone}</Text>
        </IconWithText>
      )}
    </>
  );
};

export default TeamPhone;
