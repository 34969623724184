import { Icon } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';

import ButtonIcon from '../button/ButtonIcon';

const InputTextBtnClear = ({ onClearValue }) => {
  return (
    <ButtonIcon
      icon={<Icon as={MdClose} onClick={onClearValue} boxSize="15px" color="red" />}
      style={{ position: 'absolute', top: 0, right: 0, display: 'table-cell', verticalAlign: 'middle', opacity: 1, zIndex: 100 }}
    />
  );
};

export default InputTextBtnClear;
