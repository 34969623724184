import { Button, Center, Divider, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { commonButtonProps } from '../button/ButtonLink';

const DevThemeButtonSave = ({ onSave, ...props }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="left">
      <PopoverTrigger>
        <Button onClick={onOpen} {...props}>
          <Trans>Save</Trans>
        </Button>
      </PopoverTrigger>

      <PopoverContent textAlign="center">
        <PopoverArrow />
        <PopoverBody>
          <Text>
            <Trans>The changes will be visible to all users, continue ?</Trans>
          </Text>
          <Center p="1rem">
            <Button {...commonButtonProps} onClick={onSave}>
              <Trans>Yes, save</Trans>
            </Button>
            <Divider maxW="30px" mx="1rem" />
            <Button {...commonButtonProps} variant="outline" onClick={onClose}>
              <Trans>No</Trans>
            </Button>
          </Center>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DevThemeButtonSave;
