export const findArrayItemByKey = (key, valueToSearch, array) => {
  for (const element of array) {
    if (element[key] === valueToSearch) {
      return element;
    }
  }
  return null;
};

export const findArrayItemById = (elementId, array) => findArrayItemByKey('id', elementId, array);

export const removeObjectFromArray = (array, object) => {
  const index = array.indexOf(object);
  if (index !== -1) {
    array.splice(index, 1);
  }
};

export const addOrRemoveFromArray = (array, value) => {
  const exists = array.includes(value);
  if (exists) {
    removeObjectFromArray(array, value);
    return;
  }

  array.push(value);
};

export const copyNonEmptyKeys = (from, to, keys) => {
  for (const key of keys) {
    // double equal to get undefined as well
    if (from[key] == null || from[key] === '') continue;

    to[key] = from[key];
  }
};

export const isArrEmpty = arr => !arr || arr.length <= 0;

const sortByKey = (a, b, key) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

export const sortObjectArrayByKey = (arr, key) => [...arr].sort((a, b) => sortByKey(a, b, key));
