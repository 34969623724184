const LAT_LNG_SEPARATOR = ',';

// set fields we want for response (to reduce api costs in case)
export const GMAPS_REQUESTED_FIELDS = ['formatted_address', 'geometry'];

export const containsLatLng = strValue => strValue != null && strValue.includes(LAT_LNG_SEPARATOR);

export const isValidLatLng = latLngObject => {
  if (latLngObject == null || isNaN(latLngObject.lat) || isNaN(latLngObject.lng)) {
    return false;
  }
  return true;
};
export const adaptPlaceForBackend = googlePlace => {
  if (googlePlace != null && googlePlace.geometry != null && googlePlace.geometry.location != null) {
    return googlePlace.geometry.location.lat() + LAT_LNG_SEPARATOR + googlePlace.geometry.location.lng();
  }
  return '';
};

export const adaptPlaceForFrontEnd = backendValue => {
  const splitted = backendValue ? backendValue.split(LAT_LNG_SEPARATOR) : [];
  if (splitted.length != 2) return null;
  return { lat: parseFloat(splitted[0]), lng: parseFloat(splitted[1]) };
};

export const getNameFromPlace = place => (place && place.formatted_address ? place.formatted_address : '');
