import { useContext } from 'react';

import { CoreContext } from '../context/CoreContext';

const useReadPaymentLinkTemplates = () => {
  const { coreData } = useContext(CoreContext);

  const choices = [];
  if (!coreData || !coreData.email_contents) {
    return choices;
  }

  for (const method of coreData.email_contents) {
    choices.push({ label: method.name, value: method.id });
  }
  return choices;
};

export default useReadPaymentLinkTemplates;
