import { useRef } from 'react';

import {
  Box,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { MdAccountCircle } from 'react-icons/md';

import { settings } from '../../settings/settings';
import ButtonIcon from './button/ButtonIcon';
import ButtonLink from './button/ButtonLink';

const YoplanningAccountInfo = ({ loggedUser }) => {
  const ref = useRef();

  const handleClick = e => {
    e.preventDefault();
    window.location.href = `${settings.YOPLANNING_ROOT_URL}/logout/`;
  };

  return (
    <Box position="fixed" top={{ base: 'unset', xl: '0' }} bottom={{ base: '.5em', xl: 'unset' }} right="0" margin={{ md: '.5rem' }} zIndex="1111111">
      <Popover placement="left-end">
        <PopoverTrigger>
          <ButtonIcon ref={ref} icon={<Icon as={MdAccountCircle} boxSize="44px" />} />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            <Text as="span">
              <Trans>Logged in as</Trans>
            </Text>
            <Text color="brand.color_main" as="span" fontWeight="500">
              {' ' + loggedUser.first_name + ' ' + loggedUser.last_name}
            </Text>
          </PopoverHeader>

          <PopoverBody>
            <ButtonLink onClick={handleClick} w="full">
              <Trans>Log out</Trans>
            </ButtonLink>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default YoplanningAccountInfo;
