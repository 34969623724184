import { useReadLocalStorage } from 'usehooks-ts';

import { getSpecificStorageKey } from '../utils/navigationUtil';

// This hooks replaces useReadLocalStorage so we manage the booking engine id
const useReadStorageBookingEngine = localStorageKey => {
  const value = useReadLocalStorage(getSpecificStorageKey(localStorageKey));
  return value;
};

export default useReadStorageBookingEngine;
