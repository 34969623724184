import { AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { BOTTOM_ROUNDED_CONTAINER_PROPS } from '../../../../utils/styleUtil';
import AccordionHeader from '../../../generic/AccordionHeader';
import VerticalStack from '../../../generic/layout/VerticalStack';
import CheckoutDetailActivity from './CheckoutDetailActivity';

const CheckoutDetailActivities = ({ onAccordionClick, cartData, isTaxIncluded, participantsChoices }) => {
  return (
    <AccordionItem>
      <AccordionHeader onClick={onAccordionClick}>
        <Trans>Activities</Trans>
      </AccordionHeader>

      <AccordionPanel bg="brand.background_secondary" {...BOTTOM_ROUNDED_CONTAINER_PROPS} shadow="">
        <VerticalStack>
          {cartData.ocSessionsWithResources.map(entry => {
            const groupedSessionGroups = entry[1];
            const sessGid = groupedSessionGroups.sessionGroupId;

            return (
              <CheckoutDetailActivity
                key={sessGid + '_' + entry[0]}
                groupedSessionGroups={groupedSessionGroups}
                cartData={cartData}
                isTaxIncluded={isTaxIncluded}
                participantsChoices={participantsChoices}
              />
            );
          })}
        </VerticalStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CheckoutDetailActivities;
