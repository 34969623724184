import { apiGetProducts } from '../../../../constants';
import useIsMobile from '../../../../hooks/useIsMobile';
import useReadProductFilters from '../../../../hooks/useReadProductFilters';
import FilteredList from '../../../generic/FilteredList';
import TeamLogo from '../../../generic/TeamLogo';
import ProductsSquare from './ProductsSquare';

const ProductsFilteredSquare = () => {
  const isMobile = useIsMobile();

  const filtersToDisplay = useReadProductFilters();

  return (
    <FilteredList
      listType={ProductsSquare}
      endpoint={apiGetProducts()}
      propertyToReturn="products"
      itemsPerScroll={PRODUCTS_PER_SCROLL}
      filtersToDisplay={filtersToDisplay}
      skeletonHeight="200px"
    >
      {!isMobile && <TeamLogo />}
    </FilteredList>
  );
};

const PRODUCTS_PER_SCROLL = 10;

export default ProductsFilteredSquare;
