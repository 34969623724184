import { Button } from '@chakra-ui/react';

const ButtonSubmit = ({ isLoading, ...props }) => {
  return (
    <Button type="submit" size={{ base: 'md', md: 'sm' }} variant="solid" isLoading={isLoading} {...props} m="3px">
      {props.children}
    </Button>
  );
};

export default ButtonSubmit;
