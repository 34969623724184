import { settings } from './settings/settings';

// This file should only contain important constants, not to be polluted with lots of variables

// ==============================================================================================
// Misc constants
// ==============================================================================================

// These langs are displayed in the footer's applang selector: this order is the display order!
export const LANG_SUPPORTED = ['fr', 'en', 'de', 'it', 'es', 'nl'];
export const LANG_FALLBACK = 'en';

export const GTM_ID_VAKARIO = 'GTM-KXNJ2TL';

export const YOPLANNING_COM_URL = 'https://yoplanning.com?utm_campaign=footer&utm_source=bookingengine&utm_medium=referral';

export const BUYNOW_FORM_ACTION = 'buynow';
export const ADDTOCART_FORM_ACTION = 'addtocart';

// ==============================================================================================
// API endpoints
// The relative urls are dynamically completed with the booking engine ID
// Eg: HOST/BOOKING_ENGINE_ID/settings
// The absolute urls do not need the ID
// ==============================================================================================

export const apiGetSettings = () => `/settings`;
export const apiUpdateSettings = () => `/update`;

export const apiGetProducts = () => `/products`;
export const apiGetProductDetail = productId => `${apiGetProducts()}/${productId}`;
export const apiGetAvailabilities = productId => `${apiGetProductDetail(productId)}/availabilities`;
export const apiGetAvailableDays = productId => `${apiGetProductDetail(productId)}/available-days`; // ?month=2&year=2023

const apiGetSessionGroup = () => `${settings.BOOKING_ENGINE_ROOT_URL}/session-group`;
export const apiGetAvailabilitiesDates = () => `${apiGetSessionGroup()}/dates`;
export const apiGetAvailabilitiesDetails = () => `${apiGetSessionGroup()}/details`;

export const apiGetCart = cartId => `/cart/${cartId}`;
export const apiSaveCart = () => `/cart/save-items`;
export const apiDeleteFromCart = () => `/cart/delete-items`;
export const apiSaveVoucher = () => `/cart/save-voucher`;

export const apiUpdateParticipants = () => `${settings.BOOKING_ENGINE_ROOT_URL}/update-participants`;

export const apiUseCode = () => `${settings.BOOKING_ENGINE_ROOT_URL}/use-code`;
export const apiCheckout = cartId => `${apiGetCart(cartId)}/checkout`;
export const apiGetClientPreviousOrder = clientId => `/client/${clientId}/get-previous-order`;

export const apiGetShopResources = () => `/resources`;
export const apiGetShopResourceDetail = resourceId => `${apiGetShopResources()}/${resourceId}`;

export const yoplClientQuickSearch = (teamId, onlyMainClient, onlyBusinessProvider, searchContent) =>
  `${settings.YOPLANNING_ROOT_URL}/customer/client-quick-search/${teamId}?limit=10&only_main_client=${onlyMainClient}&only_business_provider=${onlyBusinessProvider}&search=${searchContent}`;

export const yoplStaffQuickSearch = (teamId, searchContent) =>
  `${settings.YOPLANNING_ROOT_URL}/get-members/${teamId}?search=${searchContent}&offset=0&limit=10`;

// ==============================================================================================
// Routes (relatives)
// P1/P2... are used to make it more readable ^^ : they became normal terms that we use to refer to which process page it is
// ==============================================================================================
export const ROUTE_P1_PRODUCT_SELECTION = ''; // This is the root page
export const ROUTE_P2_AVA_SELECTION = 'product';
export const ROUTE_P3_SESSION_VARIANTS = 'session-variants';
export const ROUTE_PAGE_CART = 'cart';
export const ROUTE_P5_CHECKOUT_DETAILS = 'checkout-details';
export const ROUTE_P6_CHECKOUT = 'checkout';

export const ROUTE_PAGE_BUY_VOUCHER = 'buy-voucher';
export const ROUTE_PAGE_CHECKOUT_SUCCESS = 'checkout-success';
export const ROUTE_PAGE_CLEAN = 'clean';
export const ROUTE_PAGE_SHOP = 'shop';
export const ROUTE_PAGE_SHOP_BOOKING = 'shop-booking';

// ==============================================================================================
// Local storage keys and default values
// ==============================================================================================
export const LOCAL_STORAGE_CART = 'cartData';
export const LOCAL_STORAGE_CART_DEFVAL = null;
export const LOCAL_STORAGE_CART_ID = 'cartId';
export const LOCAL_STORAGE_CART_ID_DEFVAL = null;
export const LOCAL_STORAGE_CART_EXPIRE = 'cartExpire';
export const LOCAL_STORAGE_CART_EXPIRE_DEFVAL = null;
export const LOCAL_STORAGE_PRECART = 'preCartData';
export const LOCAL_STORAGE_PRECART_DEFVAL = {
  availabilities: [],
  itemsToDeleteForEdit: null,
};

export const LOCAL_STORAGE_CURRENCY = 'currency';
export const LOCAL_STORAGE_APPLANG = 'applang';
export const LOCAL_STORAGE_FORCEDLANG = 'forcedlang';

export const LOCAL_STORAGE_PARTICIPANTS = 'participantsData';
export const LOCAL_STORAGE_PARTICIPANTS_DEFVAL = [];

export const LOCAL_STORAGE_ASSIGN = 'activityAssignData';
export const LOCAL_STORAGE_ASSIGN_DEFVAL = [];

export const LOCAL_STORAGE_DEVTHEME = 'devThemeColors';
export const LOCAL_STORAGE_DEVTHEME_DEFVAL = null;

export const LOCAL_STORAGE_FILTERS = 'filters';
export const LOCAL_STORAGE_FILTERS_DEFVAL = {};

// ==============================================================================================
// CSRF related
// ==============================================================================================

export const CSRF_HEADER_NAME = 'X-CSRFToken';
export const CSRF_COOKIE_NAME = 'yop_csrftoken';

// ==============================================================================================
// Default theme (stored in db but this is a fallback)
// ==============================================================================================

export const COLOR_FALLBACK = {
  color_alert_error_icon: '#e53e3e',
  color_alert_success_icon: '#38a169',
  color_font: '#262626',
  color_font_buttons: '#FFFFFF',
  color_main: '#4B73E4',
  color_smallbox_border: '#EDEDED',

  background_alert_error: '#fed7d7',
  background_alert_success: '#c6f6d5',
  background_buttons_active: '#464d56',
  background_buttons_hover: '#111B2A',
  background_main: '#FFFFFF',
  background_container: '#FFFFFF',
  background_secondary: '#F6F7FD',
  background_smallbox: '#F6F7FD',
};
// ---------------------------
// Uncomment this when we need to change the colors in the backend db
// This is the exact format needed inside the db
// console.log(JSON.stringify({ colors: JSON.parse(JSON.stringify(COLOR_FALLBACK)) }));
// ---------------------------
