import { Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { getFullFrontUrl } from '../../../utils/navigationUtil';

const ButtonLink = ({ isDisabled = false, to = null, onClick, ...props }) => {
  return (
    <>
      {isDisabled && (
        <Button {...commonButtonProps} isDisabled {...props}>
          {props.children}
        </Button>
      )}

      {!isDisabled && to != null && (
        <Button {...commonButtonProps} as={RouterLink} to={getFullFrontUrl(to)} {...props}>
          {props.children}
        </Button>
      )}

      {!isDisabled && onClick && (
        <Button {...commonButtonProps} onClick={onClick} {...props}>
          {props.children}
        </Button>
      )}
    </>
  );
};

export const commonButtonProps = {
  size: { base: 'md', md: 'sm' },
  variant: 'solid',
};

export default ButtonLink;
