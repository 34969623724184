import { HStack, Icon, Text } from '@chakra-ui/react';
import { Plural } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BiTimer } from 'react-icons/bi';
import { FaTicketAlt } from 'react-icons/fa';
import { FiBox } from 'react-icons/fi';
import { MdPeople } from 'react-icons/md';

import { COMMON_GAP } from '../../../utils/styleUtil';
import ContainerPlural from '../../generic/layout/ContainerPlural';
import CartElementBox from './CartElementBox';

const boxIconProps = {
  boxSize: '22px',
  color: 'brand.color_main',
};

const CartElementBoxes = ({ nbParticipants, nbResources, voucherQuantity, voucherLabel, voucherExpiration }) => {
  const { i18n } = useLingui();

  return (
    <HStack gap={COMMON_GAP} flexWrap="wrap" mt=".3rem">
      {nbParticipants && (
        <CartElementBox>
          <Icon as={MdPeople} {...boxIconProps} />
          <ContainerPlural value={nbParticipants}>
            <Plural value={nbParticipants} one="participant" other="participants" />
          </ContainerPlural>
        </CartElementBox>
      )}

      {nbResources > 0 && (
        <CartElementBox>
          <Icon as={FiBox} {...boxIconProps} />
          <ContainerPlural value={nbResources}>
            <Plural value={nbResources} one="option" other="options" />
          </ContainerPlural>
        </CartElementBox>
      )}

      {voucherQuantity && (
        <CartElementBox>
          <Icon as={FaTicketAlt} {...boxIconProps} />
          <ContainerPlural value={voucherQuantity}>
            {voucherLabel}
            {!voucherLabel && <Plural value={voucherQuantity} one="voucher" other="vouchers" />}
          </ContainerPlural>
        </CartElementBox>
      )}

      {voucherExpiration && (
        <CartElementBox>
          <Icon as={BiTimer} {...boxIconProps} />
          <Text>
            {i18n.date(voucherExpiration, {
              dateStyle: 'long',
            })}
          </Text>
        </CartElementBox>
      )}
    </HStack>
  );
};

export default CartElementBoxes;
