import { useMemo } from 'react';

import { Box, Skeleton, Stack } from '@chakra-ui/react';

import { REQUEST_STATUS } from '../../utils/requestUtil';
import { ROUNDED_ELEMENT_PROPS } from '../../utils/styleUtil';
import AlertError from './error/AlertError';

const FetchLoaderSkeleton = ({ status, skeletonsNumber, skeletonHeight }) => {
  const skeletons = useMemo(() => createSkeletons(skeletonsNumber, skeletonHeight), []);
  return (
    <Box align="center">
      {status === REQUEST_STATUS.LOADING && <Stack>{skeletons}</Stack>}
      {status === REQUEST_STATUS.ERROR && <AlertError />}
    </Box>
  );
};

const createSkeletons = (skeletonsNumber, skeletonHeight) => {
  const skeletons = [];
  for (let i = 0; i < skeletonsNumber; i++) {
    skeletons.push(<Skeleton key={i} height={skeletonHeight} startColor="gray.400" endColor="gray.500" {...ROUNDED_ELEMENT_PROPS} />);
  }
  return skeletons;
};

export default FetchLoaderSkeleton;
