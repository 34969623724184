import { Box, Divider, Flex, Spacer, Text } from '@chakra-ui/react';

import useIsMobile from '../../../../hooks/useIsMobile';
import { COMMON_PADDING, TOP_ROUNDED_CONTAINER_PROPS } from '../../../../utils/styleUtil';
import TeamLogo from '../../TeamLogo';

const HeaderLogoLabel = props => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile && <Divider orientation="horizontal" />}

      <Box bg="brand.background_main" {...TOP_ROUNDED_CONTAINER_PROPS} p={{ base: '.5rem', md: COMMON_PADDING }}>
        <Flex minWidth="max-content" alignItems="center" justifyContent={{ base: 'center', md: undefined }}>
          {!isMobile && (
            <>
              <TeamLogo />
              <Spacer />
            </>
          )}

          <Text color="brand.color_main" fontSize="lg" fontWeight="600">
            {props.children}
          </Text>
        </Flex>
      </Box>
    </>
  );
};

export default HeaderLogoLabel;
