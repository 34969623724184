import { Flex, Text } from '@chakra-ui/react';
import { Trans, t } from '@lingui/macro';

import { YOPLANNING_COM_URL } from '../../../../constants';
import Image from '../../Image';

const PoweredBy = () => {
  const handleOnClick = () => window.open(YOPLANNING_COM_URL, '_blank', 'noopener,noreferrer');

  return (
    <Flex mr={{ base: '1rem', sm: '0' }} cursor="pointer" _hover={{ bg: 'gray.100' }} padding=".5rem 1rem" borderRadius="md" onClick={handleOnClick}>
      <Text fontSize="0.7rem" mr=".5em" pt="1px" color="#6B7785">
        <Trans>Powered by</Trans>
      </Text>
      <Image
        imageUrl={'/assets/logo-yoplanning.png'}
        addYoplanningRootToSrc={false}
        // Those sizes keep the original ratio!!
        w="78px"
        maxW="78px"
        maxH="20px"
        alt={t`Powered by Yoplanning`}
        style={{ objectFit: 'contain' }}
      />
    </Flex>
  );
};

export default PoweredBy;
