import { Box, Center } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useReadTeam from '../../../hooks/useReadTeam';
import { getFormattedCurrency } from '../../../utils/business/prices';
import LabelWithText from '../LabelWithText';

const ContainerTotal = ({ total }) => {
  const { isTaxIncluded } = useReadTeam();

  return (
    <Center>
      <Box textAlign="center" mt="1rem" mb="1rem">
        <LabelWithText
          label={isTaxIncluded ? <Trans>Total</Trans> : <Trans>Total (tax excl.)</Trans>}
          text={getFormattedCurrency(total)}
          labelSize="xs"
          textSize="lg"
          labelWeight="bold"
        />
      </Box>
    </Center>
  );
};

export default ContainerTotal;
