import { HStack } from '@chakra-ui/react';

import { LOCAL_STORAGE_CART_ID } from '../../../../constants';
import useReadStorageBookingEngine from '../../../../hooks/useReadStorageBookingEngine';
import { COMMON_PADDING } from '../../../../utils/styleUtil';
import CartActionDownloadQuote from './CartActionDownloadQuote';
import CartActionShareCart from './CartActionShareCart';

const CartActions = () => {
  const storedCartId = useReadStorageBookingEngine(LOCAL_STORAGE_CART_ID);

  return (
    <HStack p={COMMON_PADDING} justifyContent="center" alignItems="baseline" bg="brand.background_container" mt="2px">
      <CartActionDownloadQuote storedCartId={storedCartId} />
      <CartActionShareCart storedCartId={storedCartId} />
    </HStack>
  );
};

export default CartActions;
