import { useRef, useState } from 'react';

import { Flex, HStack, Icon, Link, Spinner, Tag, useClipboard, useDisclosure, useToast } from '@chakra-ui/react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import { Trans, t } from '@lingui/macro';
import { MdContentCopy } from 'react-icons/md';
import { useUpdateEffect } from 'usehooks-ts';

import useCleanStorage from '../../../../hooks/useCleanStorage';
import { COMMON_PADDING } from '../../../../utils/styleUtil';
import ButtonIcon from '../../../generic/button/ButtonIcon';
import { getCartShareUrl } from './CartActionShareCart';

const TOAST_ID = 'newCartToast';

const CartActionNewCart = ({ currentCartId }) => {
  const currentCartUrl = getCartShareUrl(currentCartId);
  const { hasCopied, onCopy } = useClipboard(currentCartUrl);

  const toast = useToast();

  const [isClicked, setIsClicked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const { cleanOrderGotoCart } = useCleanStorage(false);

  const onClickCreateNewCartLink = e => {
    e.preventDefault();
    setIsClicked(true);
    onOpen();
  };

  const onAlertCancel = () => {
    setIsClicked(false);
    toast.closeAll();
    onClose();
  };

  const onAlertConfirm = () => {
    onClose();
    toast.closeAll();
    cleanOrderGotoCart();
  };

  useUpdateEffect(() => {
    if (toast.isActive(TOAST_ID) || !isOpen || !isClicked) return;

    toast({
      id: TOAST_ID,
      title: hasCopied ? t`Cart link copied to clipboard` : t`Error while copying cart link`,
      status: hasCopied ? 'success' : 'error',
      duration: 2000,
      variant: 'subtle',
    });
  }, [hasCopied]);

  return (
    <Flex flexDir="row" justifyContent="flex-end" pr={COMMON_PADDING}>
      {isClicked && <Spinner size="sm" />}
      {!isClicked && (
        <Link onClick={onClickCreateNewCartLink}>
          <Trans>Create a new cart</Trans>
        </Link>
      )}

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onAlertCancel}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Trans>Create a new cart?</Trans>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Trans>Your current cart will still be accessible at this url:</Trans>

              <Tag size="sm" fontSize="xs" variant="outline" colorScheme="blue" p={1} mt={COMMON_PADDING}>
                {currentCartUrl}
              </Tag>

              <HStack gap="0" alignItems="center" justifyContent="flex-end">
                <Link onClick={onCopy}>
                  <Trans>Copy link</Trans>
                </Link>
                <ButtonIcon icon={<Icon as={MdContentCopy} boxSize="18px" />} onClick={onCopy} />
              </HStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="outline" ref={cancelRef} onClick={onAlertCancel}>
                <Trans>Cancel</Trans>
              </Button>
              <Button onClick={onAlertConfirm} ml={3}>
                <Trans>Continue</Trans>
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default CartActionNewCart;
