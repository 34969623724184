import { useState } from 'react';

import { Box, Collapse, HStack, Link, Spacer, VisuallyHidden } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useBoolean } from 'usehooks-ts';

import useIsMobile from '../../hooks/useIsMobile';
import useWriteFilters from '../../hooks/useWriteFilters';
import { settings } from '../../settings/settings';
import { isDateRangePickerFilter } from '../../utils/dateFilterUtil';
import { TOP_ROUNDED_CONTAINER_PROPS } from '../../utils/styleUtil';
import FilterBarMobileShowHideText from './FilterBarMobileShowHideText';
import FilterLine from './FilterLine';
import FilterResetLink from './FilterResetLink';
import DateRangePicker, { DATERANGE_FILTER_NAME_END, DATERANGE_FILTER_NAME_START } from './form/DateRangePicker';

// ==============================================================================================
// This component generates fields for filtering
// The fields to display are passed as a prop
// When a filter's state changes, a prop callback is called
// ==============================================================================================

const FilterBar = ({ filtersToDisplay, displayLimit, fetchNewFilterData, showFiltersMobile = false, ...props }) => {
  const isMobile = useIsMobile();

  const { value: isOpen, toggle: onToggle } = useBoolean(showFiltersMobile);

  const { filters, onFilterChange, onFilterDateRangeChange, canResetFilters, resetFiltersToDefault } = useWriteFilters(
    filtersToDisplay,
    displayLimit,
    fetchNewFilterData
  );

  const { needToDisplayMore, filterLines } = getFiltersForUI(filtersToDisplay);

  const [showFilters, setShowFilters] = useState(false);

  const renderFilter = filterField => {
    // invisible filters are not shown
    if (filterField.hiddenBehindShowMore && !showFilters) return <VisuallyHidden key={filterField.fieldName} />;

    if (isDateRangePickerFilter(filterField.type)) {
      return (
        <DateRangePicker
          key={filterField.fieldName}
          fieldStartDate={filters[DATERANGE_FILTER_NAME_START]}
          fieldEndDate={filters[DATERANGE_FILTER_NAME_END]}
          onChangeDateRange={onFilterDateRangeChange}
          {...filterField}
        />
      );
    }

    return <filterField.type key={filterField.fieldName} fieldValue={filters[filterField.fieldName]} onChange={onFilterChange} {...filterField} />;
  };

  return (
    <Box
      id="FilterBar"
      bg="brand.background_main"
      {...TOP_ROUNDED_CONTAINER_PROPS}
      pt={{ base: '.5rem', md: undefined }}
      // If one day we want to make the filter bar sticky, also dont forget to add boxShadow for styling
      // style={{ position: 'sticky', top: '0', zIndex: '1' }}
    >
      {isMobile && <FilterBarMobileShowHideText isOpen={isOpen} onToggle={onToggle} />}

      {/* Surcharge de l'overflow très importante sinon le datepicker sera caché derrière la page */}
      <Collapse in={!isMobile || isOpen} style={{ overflow: 'unset' }}>
        {filters &&
          filterLines.map((filterLine, i) => (
            <FilterLine key={'filterline_' + i} filterLineNumber={i} filters={filterLine} renderFilter={renderFilter} showFilters={showFilters}>
              {props.children}
            </FilterLine>
          ))}

        <HStack pt={{ base: '.5rem', sm: '2px' }}>
          {filters && needToDisplayMore && !showFilters && (
            <Link onClick={() => setShowFilters(true)} mt={filterLines.length > 1 ? '.5rem' : undefined}>
              <Trans>More filters</Trans>
            </Link>
          )}
          <Spacer />
          <FilterResetLink canResetFilters={canResetFilters} resetFiltersToDefault={resetFiltersToDefault} />
        </HStack>
      </Collapse>
    </Box>
  );
};

export default FilterBar;

// We get settings.FILTERS_PER_LINE per line
// If there are multiple lines then we will display the "more filters"
// Or if some filters are setup as hidden we will also display it
const getFiltersForUI = filtersToDisplay => {
  let needToDisplayMore = false;
  const filterLines = [];

  let currentFilterLine = 0;
  filtersToDisplay.forEach(function (filter) {
    if (!(currentFilterLine in filterLines)) {
      filterLines[currentFilterLine] = [];
    }

    filterLines[currentFilterLine].push(filter);

    // "show more" will be displayed if we have hidden filters or another line
    if (filter.hiddenBehindShowMore || currentFilterLine > 0) {
      needToDisplayMore = true;
    }

    if (filterLines[currentFilterLine].length >= settings.FILTERS_PER_LINE) {
      currentFilterLine++;
    }
  });
  return { needToDisplayMore, filterLines };
};
