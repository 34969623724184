import React, { useState } from 'react';

import { setCurrencyStorage } from '../utils/business/prices';

// =====================================================================================
// This context is dedicated to the core data received from the first backend query
// It should not be written on, check other available contexts if you need writing
// =====================================================================================

export const CoreContext = React.createContext({});
CoreContext.displayName = 'CoreContext (BookingEngine)';

export const CoreContextProvider = ({ data, children }) => {
  const [coreData, setCoreData] = useState(data);
  const coreContextValue = { coreData, setCoreData };

  // Small hack for numberUtil because we cant call hooks outside components
  setCurrencyStorage(data.booking_engine.team.currency);

  return <CoreContext.Provider value={coreContextValue}>{children}</CoreContext.Provider>;
};
