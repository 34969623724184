import { useContext } from 'react';

import { CoreContext } from '../context/CoreContext';
import useReadCanBuyVoucher from './useReadCanBuyVoucher';

const useReadBuyVoucherMode = product => {
  const { coreData } = useContext(CoreContext);
  const { voucher_only: isModeBcOnly } = coreData.booking_engine;

  const canBuyVoucher = useReadCanBuyVoucher(product);
  return { canBuyVoucher, isModeBcOnly };
};
export default useReadBuyVoucherMode;
