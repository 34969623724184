import { Box } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { ROUTE_P5_CHECKOUT_DETAILS, ROUTE_P6_CHECKOUT } from '../../../constants';
import useReadNavigationToCheckoutDetail from '../../../hooks/useReadNavigationToCheckoutDetail';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, TOP_ROUNDED_CONTAINER_PROPS } from '../../../utils/styleUtil';
import ButtonLink from '../../generic/button/ButtonLink';
import ContainerTotal from '../../generic/layout/ContainerTotal';

const CartTotalSimple = ({ cartData, cartSize, isTaxIncluded }) => {
  const { areDetailsRequired, isOrderComptoir } = useReadNavigationToCheckoutDetail(cartData);
  const cartTotal = getTotalFromCart(cartData, isTaxIncluded);

  return (
    <Box bg="brand.background_main" {...TOP_ROUNDED_CONTAINER_PROPS} {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
      <ContainerTotal total={cartTotal} />

      <ButtonLink
        to={areDetailsRequired && isOrderComptoir ? ROUTE_P5_CHECKOUT_DETAILS : ROUTE_P6_CHECKOUT}
        w="100%"
        isDisabled={cartSize === 0}
        className="CartValidate"
      >
        <Trans>Validate my cart</Trans>
      </ButtonLink>
    </Box>
  );
};

export default CartTotalSimple;

const getTotalFromCart = (cartData, isTaxIncluded) => {
  if (!cartData || !cartData.totalPrice) return 0.0;

  const { totalPrice } = cartData;
  return isTaxIncluded ? totalPrice.tax_included : totalPrice.tax_excluded;
};
