import { i18n } from '@lingui/core';

import { LOCAL_STORAGE_CURRENCY } from '../../constants';
import { findArrayItemById } from '../arrayUtil';
import { getBookingEngineIdFromUrl } from '../navigationUtil';

// /////////////////////////////////////////////////////////////////////////////
// This file contains business utilities related to price management
// Anything related that is common goes here
// /////////////////////////////////////////////////////////////////////////////

// This is used to know if the selected price is the standard price (= no variation, no discount)
export const STANDARD_PRICE_SELECT_VALUE = 'standardPrice';

// These dont use hooks because we cannot call hooks outside components
// And our currency is needed in the utils
export const setCurrencyStorage = currency => localStorage.setItem(getBookingEngineIdFromUrl() + '_' + LOCAL_STORAGE_CURRENCY, currency);

const getCurrencyStorage = () => localStorage.getItem(getBookingEngineIdFromUrl() + '_' + LOCAL_STORAGE_CURRENCY);

export const getFormattedCurrency = value => {
  const currency = getCurrencyStorage();
  if (!currency || isNaN(value)) return '-';

  return i18n.number(value, {
    style: 'currency',
    currency: currency,
  });
};

export const getPriceVariationToString = priceVariation => {
  const isPositive = isPriceVariationPositive(priceVariation);

  let str = isPositive ? '+' : '-';
  str += priceVariation.value ? getFormattedCurrency(Math.abs(priceVariation.value)) : Math.abs(priceVariation.percentage) + '%';
  return str;
};
const isPriceVariationPositive = priceVariation =>
  (priceVariation.value && priceVariation.value > 0) || (priceVariation.percentage && priceVariation.percentage > 0);

export const getCartItemPrice = (cartItem, isTaxIncluded) => parseFloat(isTaxIncluded ? cartItem.price : cartItem.price_tax_excluded);

export const getOptionsTotalForVariant = customOptions => {
  let price = 0.0;
  for (const option of customOptions) {
    const resourceId = option.selectedResource;
    if (!resourceId) continue;

    const resource = findArrayItemById(resourceId, option.resources);
    price += resource ? parseFloat(resource.price) : 0;
  }
  return price;
};

// This get the main price value, which can be two ways :
// Standard price : the main price
// Discount price : the main price with discount depending on the participant count
export const getMainPrice = (standardPrice, participantCount, priceVariations) => {
  let priceVariationId = STANDARD_PRICE_SELECT_VALUE;
  let priceValue = parseFloat(standardPrice);

  let participantDifference = null;
  for (const priceVariation of priceVariations) {
    if (!priceVariation.participant_threshold) continue;

    const participantThreshold = parseInt(priceVariation.participant_threshold);
    if (parseInt(participantCount) < participantThreshold) continue;

    const newDifference = parseInt(participantCount) - participantThreshold;
    if (participantDifference != null && newDifference > participantDifference) continue;
    participantDifference = newDifference;

    priceValue = parseFloat(priceVariation.computed_price);
    priceVariationId = priceVariation.id;
  }

  return { priceValue, priceVariationId };
};

// This get the selected price for a given variant
// The price can be the main one (standard or discount) or it can be a custom price
export const getVariantSelectedPrice = (variant, nbParticipants) => {
  if (!variant) return 0.0;

  if (variant.currentPriceVariationId === STANDARD_PRICE_SELECT_VALUE) {
    if (!variant.standardPrice) return 0.0;

    const { priceValue } = getMainPrice(variant.standardPrice, nbParticipants ?? variant.participant, variant.custom_prices);
    return priceValue;
  }

  const priceFound = findArrayItemById(variant.currentPriceVariationId, variant.custom_prices);

  // TEMP FOR DEBUG IN ROLLBAR
  if (priceFound == null) {
    console.error('Cannot find price ID:', variant.currentPriceVariationId, 'nb', nbParticipants, 'variant', JSON.stringify(variant));
  }
  return parseFloat(priceFound.computed_price);
};

export const getVariantTotalPerParticipant = (variant, nbAvaSelected) => {
  let price = 0.0;
  const priceValue = getVariantSelectedPrice(variant);

  const multiplicator = parseInt(variant.participant);
  price += multiplicator * priceValue;
  price += multiplicator * getOptionsTotalForVariant(variant.custom_options);

  price *= nbAvaSelected;
  return price;
};
