import { useMemo } from 'react';

import { findArrayItemById } from '../utils/arrayUtil';
import { getCartItemPrice } from '../utils/business/prices';

const useReadCartItemVoucher = (cartItem, cartProducts, isTaxIncluded) => {
  const { product, voucher, elementPrice, elementPriceWithoutPromo } = useMemo(
    () => getCartItemVoucher(cartItem, cartProducts, isTaxIncluded),
    [JSON.stringify(cartItem)]
  );
  return { product, voucher, elementPrice, elementPriceWithoutPromo };
};

export default useReadCartItemVoucher;

const getCartItemVoucher = (cartItem, cartProducts, isTaxIncluded) => {
  const { gift_voucher: voucher } = cartItem;

  const elementPrice = getCartItemPrice(cartItem, isTaxIncluded);
  const elementPriceWithoutPromo = cartItem.price_without_promo ? parseFloat(cartItem.price_without_promo) : elementPrice;
  const product = findArrayItemById(voucher.product_id, cartProducts);

  return { product, voucher, elementPrice, elementPriceWithoutPromo };
};
