import { useToast } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { useEffectOnce } from 'usehooks-ts';

import { ROUTE_P1_PRODUCT_SELECTION } from '../../constants';
import useCleanStorage from '../../hooks/useCleanStorage';
import useNavigateBookingEngine from '../../hooks/useNavigateBookingEngine';

const TOAST_ID = 'cleanToast';

const CleanPage = () => {
  const navigate = useNavigateBookingEngine();
  const {} = useCleanStorage(true);
  const toast = useToast();

  useEffectOnce(() => {
    if (!toast.isActive(TOAST_ID)) {
      toast({
        id: TOAST_ID,
        title: t`Clean up successful`,
        status: 'success',
        duration: 2000,
        variant: 'subtle',
      });
    }
    navigate(ROUTE_P1_PRODUCT_SELECTION);
  });

  return <></>;
};

export default CleanPage;
