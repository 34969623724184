import { Box, useRadio } from '@chakra-ui/react';

import { COMMON_INPUT_HEIGHT } from '../../../utils/styleUtil';

const CustomRadioContainer = ({ isFirstItem, isLastItem, ...props }) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label" w="full" textAlign="center" borderRight={isLastItem ? undefined : '1px solid #cbcbcb'}>
      <input {...input} />
      <Box
        {...radio}
        cursor="pointer"
        _checked={{
          bg: 'brand.color_main',
          color: 'brand.color_font_buttons',
        }}
        px={3}
        py={1.5}
        h={COMMON_INPUT_HEIGHT}
        borderLeftRadius={isFirstItem ? 'md' : undefined}
        borderRightRadius={isLastItem ? 'md' : undefined}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default CustomRadioContainer;
