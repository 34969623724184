import { Collapse, UnorderedList } from '@chakra-ui/react';

import AvailabilityDateTimeMore from './AvailabilityDateTimeMore';

const AvailabilityMoreDatesList = ({ moreDateShown, data }) => {
  // stopPropagation to not trigger click on parent element (availability for example)
  return (
    <Collapse animateOpacity in={moreDateShown && data}>
      <UnorderedList onClick={e => e.stopPropagation()} pl="5px">
        {data && data.sessions.map((session, index) => <AvailabilityDateTimeMore session={session} key={index} />)}
      </UnorderedList>
    </Collapse>
  );
};

export default AvailabilityMoreDatesList;
