import { Flex, Spacer, Text } from '@chakra-ui/react';

import { getFormattedCurrency } from '../../../utils/business/prices';

const CartSessionPrice = ({ isTaxIncluded, groupedSessionGroups, ...props }) => {
  const { totalTaxIncluded, totalTaxIncludedWithoutPromo, totalTaxExcluded, totalTaxExcludedWithoutPromo } = groupedSessionGroups;

  const price = isTaxIncluded ? totalTaxIncluded : totalTaxExcluded;
  const priceWithoutPromo = isTaxIncluded ? totalTaxIncludedWithoutPromo : totalTaxExcludedWithoutPromo;

  if (priceWithoutPromo <= 0) return <></>;
  return (
    <>
      <Spacer />

      <Flex {...props}>
        {priceWithoutPromo !== price && <Text {...propsPriceWithoutPromo}>{getFormattedCurrency(priceWithoutPromo)}</Text>}

        <Text fontWeight="bold">{getFormattedCurrency(price)}</Text>
      </Flex>
    </>
  );
};

export default CartSessionPrice;

const propsPriceWithoutPromo = {
  textDecoration: 'line-through',
  mr: '.5rem',
  fontSize: 'sm',
};
