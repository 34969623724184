import { useEffect, useState } from 'react';

import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { settings } from '../settings/settings';
import { GMAPS_REQUESTED_FIELDS } from '../utils/mapsUtil';

const useFetchGmapsPlace = () => {
  const [isPlaceServiceLoaded, setPlaceServiceLoaded] = useState(false);

  const { placesService } = usePlacesService({
    apiKey: settings.GMAPS_API_KEY,
  });

  useEffect(() => {
    if (isPlaceServiceLoaded) return;

    setPlaceServiceLoaded(placesService != null);
  }, [placesService]);

  const getPlaceFromName = (placeToSearch, onPlaceResult) => {
    placesService.findPlaceFromQuery(
      {
        fields: GMAPS_REQUESTED_FIELDS,
        query: placeToSearch,
      },
      placeDetails => {
        onPlaceResult(placeDetails.length > 0 ? placeDetails[0] : null);
      }
    );
  };

  const getPlaceFromLatLng = (latLngObject, onPlaceResult) => {
    placesService.nearbySearch(
      {
        location: {
          lat: latLngObject.lat,
          lng: latLngObject.lng,
        },
        radius: 1, // required
      },
      placeDetails => {
        onPlaceResult(placeDetails.length > 0 ? placeDetails[0] : null);
      }
    );
  };

  return { isPlaceServiceLoaded, getPlaceFromName, getPlaceFromLatLng };
};

export default useFetchGmapsPlace;
