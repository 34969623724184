import { VisuallyHidden } from '@chakra-ui/react';

import Select from './Select';

const SelectHiddenIfOne = props => {
  return (
    <>
      {props.fieldChoices.length > 1 && <Select {...props} />}

      {props.fieldChoices.length === 1 && (
        <VisuallyHidden>
          <Select
            {...props}
            // disable useless props when hidden
            displayLabel={false}
            formatOptionLabel={undefined}
            minWidth={undefined}
            maxWidth={undefined}
            height={undefined}
            size={undefined}
          />
        </VisuallyHidden>
      )}
    </>
  );
};

export default SelectHiddenIfOne;
