import { defineMessage } from '@lingui/macro';

import Select from '../../generic/form/Select';

export const PAYMENT_METHOD_FIELDNAME = 'payment_method';

const CheckoutPaymentMethod = ({ paymentMethods, ...props }) => {
  return (
    <Select fieldName={PAYMENT_METHOD_FIELDNAME} fieldLabel={defineMessage({ message: 'Payment method' })} fieldChoices={paymentMethods} {...props} />
  );
};

export default CheckoutPaymentMethod;
