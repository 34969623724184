import useEditCartElement from '../../../hooks/useEditCartElement';
import ButtonEdit from '../../generic/button/ButtonEdit';

const CartElementEditAction = ({ availabilities, product, position, alignItems, blockActions }) => {
  const { triggerEdit } = useEditCartElement(availabilities, product);

  const handleClick = () => {
    triggerEdit();
  };

  return <ButtonEdit onClick={handleClick} position={position} alignItems={alignItems} blockActions={blockActions} />;
};

export default CartElementEditAction;
