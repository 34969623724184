import { useContext, useRef, useState } from 'react';

import { Progress } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import { apiGetAvailableDays } from '../../../constants';
import { ExchangeContext } from '../../../context/ExchangeContext';
import useFetch from '../../../hooks/useFetch';
import useIsMobile from '../../../hooks/useIsMobile';
import { dateToIsoString, getDaysOfMonthArray } from '../../../utils/dateUtil';
import { getFullApiUrl } from '../../../utils/navigationUtil';
import { jsonToQueryParams } from '../../../utils/requestUtil';
import DatepickerCommon, { closeReopenDatePicker } from './DatepickerCommon';

const DatePickerAvailabilities = ({ fieldName, fieldValue, onChange, ...props }) => {
  const isMobile = useIsMobile();
  const { i18n } = useLingui();

  const { exchangeData } = useContext(ExchangeContext);
  const pickerRef = useRef();

  const { productId } = useParams();

  // We are using the standard useFetch because no need for cart expiration check etc... and all the overhead
  const [fetchUrl, setFetchUrl] = useState(null);
  const { data } = useFetch(fetchUrl);
  const [isLoading, setIsLoading] = useState(false);

  const [availabilitiesQueryData, setAvalabilitiesQueryData] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [disabledDays, setDisabledDays] = useState([]);

  useEffectOnce(() => {
    refreshAvailabilitiesQueryData(fieldValue);
  });

  const handleChange = newDate => {
    onChange(fieldName, newDate);
    refreshAvailabilitiesQueryData(newDate);
  };

  const refreshAvailabilitiesQueryData = newDate => {
    if (!newDate) return;

    const year = newDate.getFullYear();
    const monthJs = newDate.getMonth();
    const month = monthJs + 1;
    if (availabilitiesQueryData && availabilitiesQueryData.year === year && availabilitiesQueryData.month === month) return;

    setDisabledDays(getDaysOfMonthArray(year, monthJs));

    // do not call backend on typo failure
    if (year < 2000 || year > 2999) return;

    setIsLoading(true);
    setAvalabilitiesQueryData({ month: month, year: year });
  };

  useUpdateEffect(() => {
    if (!availabilitiesQueryData) return;

    const newFetchUrl = getFullApiUrl(apiGetAvailableDays(productId) + '?' + jsonToQueryParams(availabilitiesQueryData));
    setFetchUrl(newFetchUrl);
  }, [JSON.stringify(availabilitiesQueryData)]);

  useUpdateEffect(() => {
    if (!data || !data.days) return;
    setIsLoading(false);

    // month minus because...JS
    manageDays(availabilitiesQueryData.year, availabilitiesQueryData.month - 1);
  }, [data]);

  const manageDays = (year, monthJs) => {
    const availableDaysArray = [];
    const disabledDaysArray = [];

    const now = new Date();
    const monthStart = new Date(year, monthJs, 1);
    const monthEnd = new Date(year, monthStart.getMonth() + 1, 0);
    for (let d = monthStart; d <= monthEnd; d.setDate(d.getDate() + 1)) {
      const isAvailableDay = d >= now && data.days.includes(dateToIsoString(d));
      pushDay(isAvailableDay ? availableDaysArray : disabledDaysArray, d);
    }

    setAvailableDays(availableDaysArray);
    setDisabledDays(disabledDaysArray);
  };

  const pushDay = (array, date) => {
    array.push(new Date(date));
  };

  const handleClickFirstAvailability = () => {
    if (!exchangeData.firstAvailabilityDate) return;

    const newDate = new Date(exchangeData.firstAvailabilityDate);
    if (fieldValue && fieldValue.toString() === newDate.toString()) return;

    handleChange(newDate);
    closeReopenDatePicker(pickerRef);
  };

  return (
    <DatepickerCommon
      ref={pickerRef}
      isMobile={isMobile}
      selected={fieldValue}
      onChange={handleChange}
      onMonthChange={refreshAvailabilitiesQueryData}
      onCalendarOpen={() => refreshAvailabilitiesQueryData(fieldValue)}
      highlightDates={availableDays}
      excludeDates={disabledDays}
      adjustDateOnChange
      {...props}
    >
      {isLoading && <Progress size="xs" isIndeterminate w="full" h="4px" />}

      {exchangeData.firstAvailabilityDate && (
        <div className="react-datepicker__today-button" onClick={handleClickFirstAvailability}>
          <Trans>
            First date:
            {' ' +
              i18n.date(exchangeData.firstAvailabilityDate, {
                dateStyle: 'medium',
              })}
          </Trans>
        </div>
      )}
    </DatepickerCommon>
  );
};

export default DatePickerAvailabilities;
