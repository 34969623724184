import { Text } from '@chakra-ui/react';

const LabelWithText = ({ label, labelSize = 'xs', labelWeight, text, textSize = 'sm', as, mt, marginBottom }) => {
  return (
    <>
      <Text color="brand.color_main" fontSize={labelSize} fontWeight={labelWeight} as={as} mt={mt}>
        {label}
      </Text>
      <Text fontSize={textSize} as={as} marginBottom={marginBottom}>
        {text}
      </Text>
    </>
  );
};

export default LabelWithText;
