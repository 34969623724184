import { Center, CircularProgress } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { REQUEST_STATUS } from '../../utils/requestUtil';
import AlertError from './error/AlertError';

const FetchLoaderSpinner = ({ status = REQUEST_STATUS.LOADING }) => {
  return (
    <Center>
      {status === REQUEST_STATUS.LOADING && <CircularProgress isIndeterminate color="blue.500" mt="1rem" />}
      {status === REQUEST_STATUS.ERROR && <AlertError message={<Trans>Unable to retrieve product information, please try again later.</Trans>} />}
    </Center>
  );
};

export default FetchLoaderSpinner;
