import { useState } from 'react';

import { useLingui } from '@lingui/react';
import { useUpdateEffect } from 'usehooks-ts';

import { getFormattedCurrency } from '../utils/business/prices';
import { REQUEST_STATUS } from '../utils/requestUtil';
import useFetch from './useFetch';

const useFetchClientPreviousOrder = () => {
  const { i18n } = useLingui();

  const [previousOrderDetails, setPreviousOrderDetails] = useState(null);

  const [fetchUrl, setFetchUrl] = useState(null);
  const { data, status } = useFetch(fetchUrl, 'order');

  const setFetchOrderUrl = url => {
    // When a selected client has been removed
    if (!url) {
      setPreviousOrderDetails(null);
      return;
    }

    setFetchUrl(url);
  };

  useUpdateEffect(() => {
    if (!data || status === REQUEST_STATUS.ERROR) {
      setPreviousOrderDetails(null);
      return;
    }

    const formattedDate = i18n.date(data.creation_date, { dateStyle: 'medium' });
    setPreviousOrderDetails(`${data.reference} (${formattedDate}, ${getFormattedCurrency(data.global_price)})`);
  }, [data, status]);

  return { setFetchOrderUrl, previousOrderDetails };
};

export default useFetchClientPreviousOrder;
