import { useCallback } from 'react';

import { Accordion } from '@chakra-ui/react';

import useWriteParticipantsAssign from '../../../hooks/useWriteParticipantsAssign';
import CheckoutDetailActivities from './activities/CheckoutDetailActivities';
import { Accordions } from './CheckoutDetailAccordionData';
import CheckoutDetailParticipants from './CheckoutDetailParticipants';

const CheckoutDetailAccordionMobile = ({ openedAccordion, setOpenedAccordion, isTaxIncluded, ...props }) => {
  const { autoAssignFinished, participantsChoices } = useWriteParticipantsAssign(props.cartData.ocSessionsWithResources);

  // We go step by step so we don't display all (but we display the previouses)
  const canDisplayAccordion = accordionIndex => openedAccordion >= accordionIndex;

  const onNamesClick = useCallback(() => setOpenedAccordion(Accordions.PARTICIPANTS_NAMES), []);
  const onActivityClick = useCallback(() => setOpenedAccordion(Accordions.ACTIVITIES), []);

  return (
    <Accordion index={openedAccordion} allowMultiple={false}>
      <CheckoutDetailParticipants onAccordionClick={onNamesClick} {...props} />

      {autoAssignFinished.current && canDisplayAccordion(Accordions.ACTIVITIES) && (
        <CheckoutDetailActivities
          onAccordionClick={onActivityClick}
          isTaxIncluded={isTaxIncluded}
          participantsChoices={participantsChoices}
          {...props}
        />
      )}
    </Accordion>
  );
};

export default CheckoutDetailAccordionMobile;
