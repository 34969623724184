import VariantLinesContainer from '../VariantLinesContainer';
import VariantLinePerPerson from './VariantLinePerPerson';

const VariantsListPerPerson = ({
  defaultVariant,
  variants,
  isFree,
  onVariantAdd,
  onVariantDelete,
  onOptionUpdate,
  nbAvaSelected,
  loggedUser,
  placesAvailable,
  placesMax,
  totalParticipants,
}) => {
  return (
    <VariantLinesContainer
      canAddVariant={defaultVariant.canAddVariant}
      onVariantAdd={onVariantAdd}
      placesAvailable={placesAvailable}
      placesMax={placesMax}
    >
      {variants.map((variant, index) => (
        <VariantLinePerPerson
          key={index}
          variant={variant}
          onVariantDelete={onVariantDelete}
          isFree={isFree}
          index={index}
          onOptionUpdate={onOptionUpdate}
          nbAvaSelected={nbAvaSelected}
          loggedUser={loggedUser}
          totalParticipants={totalParticipants}
          allDefaultOptions={defaultVariant.custom_options}
          placesAvailable={placesAvailable}
          placesMax={placesMax}
        />
      ))}
    </VariantLinesContainer>
  );
};

export default VariantsListPerPerson;
