import React from 'react';

import ReactDOM from 'react-dom/client';

import 'react-phone-input-2/lib/plain.css';
import './global.css';
import './global.datepicker.css';
import './global.phone.css';
import './global.select.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
