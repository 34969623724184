import { apiGetProducts } from '../../../../constants';
import useIsMobile from '../../../../hooks/useIsMobile';
import useReadProductFilters from '../../../../hooks/useReadProductFilters';
import FilteredList from '../../../generic/FilteredList';
import TeamLogo from '../../../generic/TeamLogo';
import ProductsList from './ProductsList';

// ==============================================================================================
// This component displays a list of products with its filter bar
// ==============================================================================================

const ProductsFilteredList = () => {
  const isMobile = useIsMobile();

  const filtersToDisplay = useReadProductFilters();

  return (
    <FilteredList
      listType={ProductsList}
      endpoint={apiGetProducts()}
      propertyToReturn="products"
      itemsPerScroll={PRODUCTS_PER_SCROLL}
      filtersToDisplay={filtersToDisplay}
      skeletonHeight="115px"
    >
      {!isMobile && <TeamLogo />}
    </FilteredList>
  );
};

const PRODUCTS_PER_SCROLL = 20;

export default ProductsFilteredList;
