import { Flex } from '@chakra-ui/react';

import { getCorrectClientFieldName } from '../../../../utils/business/participants';
import SpecificDataCustom from './SpecificDataCustom';
import SpecificDataStandard from './SpecificDataStandard';

const AllSpecificData = ({ additionalDataNeeded, participant, onParticipantDataUpdate }) => {
  const commonProps = {
    onParticipantDataUpdate: onParticipantDataUpdate,
  };

  const getCustomDataProps = data => {
    return {
      data: data,
      fieldValue: participant.extra_data[data.label] ?? '',
      ...commonProps,
    };
  };

  return (
    <Flex flexWrap="wrap" gap=".7rem" mt="1rem">
      {additionalDataNeeded.standardData.map(data => (
        <SpecificDataStandard key={data.label} data={data} fieldValue={participant[getCorrectClientFieldName(data.label)] ?? ''} {...commonProps} />
      ))}

      {/* For good ui, we display the textareas at the end */}
      {additionalDataNeeded.customData
        .filter(data => data.data_type !== 'textarea')
        .map(data => (
          <SpecificDataCustom key={data.id} {...getCustomDataProps(data)} />
        ))}
      {additionalDataNeeded.customData
        .filter(data => data.data_type === 'textarea')
        .map(data => (
          <SpecificDataCustom key={data.id} {...getCustomDataProps(data)} />
        ))}
    </Flex>
  );
};

export default AllSpecificData;
