import { t } from '@lingui/macro';

import useWriteSeo from '../../hooks/useWriteSeo';
import ShopResourceBookingForm from '../business/shop/ShopResourceBookingForm';

const ShopResourceBookingPage = () => {
  useWriteSeo({ title: t`Resource details` });

  return <ShopResourceBookingForm />;
};

export default ShopResourceBookingPage;
