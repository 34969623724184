import { Flex, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import ButtonLink from '../button/ButtonLink';

const ErrorFallback = () => {
  return (
    <Flex justifyContent="center" mt="4rem">
      <VStack>
        <Text>
          <Trans>Oops! Something went wrong.</Trans>
        </Text>

        <ButtonLink
          onClick={() => {
            localStorage.clear();
            location.reload();
          }}
        >
          <Trans>Back to home</Trans>
        </ButtonLink>
      </VStack>
    </Flex>
  );
};

export default ErrorFallback;
