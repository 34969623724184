const useReadVoucherChoices = productVouchers => {
  const choices = getVoucherChoices(productVouchers);
  return choices;
};

export default useReadVoucherChoices;

const getVoucherChoices = productVouchers => {
  const choices = [];
  for (const voucher of productVouchers) {
    choices.push({
      ...voucher,
      value: voucher.id,
    });
  }
  return choices;
};
