import { Flex, Spacer, Text } from '@chakra-ui/react';

import { getFormattedCurrency } from '../../../utils/business/prices';

const CartElementPrice = ({ elementPrice, elementPriceWithoutPromo, ...props }) => {
  if (elementPriceWithoutPromo <= 0) return <></>;
  return (
    <>
      <Spacer />

      <Flex {...props}>
        {elementPriceWithoutPromo !== elementPrice && <Text {...propsPriceWithoutPromo}>{getFormattedCurrency(elementPriceWithoutPromo)}</Text>}

        <Text fontWeight="bold">{getFormattedCurrency(elementPrice)}</Text>
      </Flex>
    </>
  );
};

export default CartElementPrice;

const propsPriceWithoutPromo = {
  textDecoration: 'line-through',
  mr: '.5rem',
  fontSize: 'sm',
};
