import { HStack } from '@chakra-ui/react';
import { defineMessage } from '@lingui/macro';

import InputText from '../../generic/form/InputText';
import SelectCountry from '../../generic/form/SelectCountry';

export const STREET_FIELDNAME = 'street';
export const CITY_FIELDNAME = 'city';
export const POSTALCODE_FIELDNAME = 'postal_code';
export const COUNTRY_FIELDNAME = 'country';

const CheckoutFieldsAddress = ({ paymentData, ...props }) => {
  return (
    <>
      <InputText
        fieldName={STREET_FIELDNAME}
        fieldValue={paymentData[STREET_FIELDNAME]}
        fieldLabel={streetLabel}
        fieldPlaceholder={streetPlaceholder}
        fieldMaxLength={100} // same as backend
        {...props}
      />
      <InputText
        fieldName={CITY_FIELDNAME}
        fieldValue={paymentData[CITY_FIELDNAME]}
        fieldLabel={cityLabel}
        fieldPlaceholder={cityPlaceholder}
        fieldMaxLength={50} // same as backend
        {...props}
      />
      <HStack>
        <InputText
          fieldName={POSTALCODE_FIELDNAME}
          fieldValue={paymentData[POSTALCODE_FIELDNAME]}
          fieldLabel={zipcodeLabel}
          fieldPlaceholder={zipcodePlaceholder}
          fieldMaxLength={20} // same as backend
          {...props}
        />
        <SelectCountry fieldValue={paymentData[COUNTRY_FIELDNAME]} {...props} />
      </HStack>
    </>
  );
};

export default CheckoutFieldsAddress;

const streetLabel = defineMessage({ message: 'Street' });
const streetPlaceholder = defineMessage({ message: '21 flower street' });
const cityLabel = defineMessage({ message: 'City' });
const cityPlaceholder = defineMessage({ id: 'CHECKOUT_CITY_NAME', message: 'City name' });
const zipcodeLabel = defineMessage({ message: 'Zip code' });
const zipcodePlaceholder = defineMessage({ id: 'CHECKOUT_ZIPCODE', message: 'Zip code' });
