import { Box, Center, Link, Spinner } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { REQUEST_STATUS } from '../../../utils/requestUtil';
import VerticalStack from './VerticalStack';

export const scrollTopContainerLoadMore = () => {
  document.getElementById('ContainerLoadMore')?.scrollTo(0, 0);
};

// ==============================================================================================
// This component is used to manage scrollable elements like a list for example
// The callback prop "onLoadMore" is triggered when we click the load more button on the bottom of the component
// If the callback prop is not specified (eg: no more data) then the button is not visible
// ==============================================================================================

const ContainerLoadMore = ({ onLoadMore, status, ...props }) => {
  const handleClick = e => {
    e.preventDefault();
    if (status === REQUEST_STATUS.LOADING) return;

    onLoadMore();
  };

  return (
    <Box id="ContainerLoadMore">
      <VerticalStack>
        {props.children}

        {onLoadMore && (
          <Center>
            <Link onClick={handleClick}>
              <Trans>Load more</Trans>
            </Link>
            {status === REQUEST_STATUS.LOADING && <Spinner ml=".5rem" size="xs" />}
          </Center>
        )}
      </VerticalStack>
    </Box>
  );
};

export default ContainerLoadMore;
