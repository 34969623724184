import { useMemo } from 'react';

const useCalcCartSize = cartData => {
  const cartSize = useMemo(() => getCartSize(cartData), [JSON.stringify(cartData)]);
  return cartSize;
};

export default useCalcCartSize;

export const getCartSize = cartData => {
  if (!cartData) return 0;

  // malformed cart data in storage, might be cart expiration
  if (!cartData.ocSessionsWithResources) return 0;

  return cartData.ocSessionsWithResources.length + cartData.ocVouchers.length + cartData.ocSingleResources.length;
};
