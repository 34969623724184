import { Box, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { getFormattedCurrency } from '../../../../utils/business/prices';
import { ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import CartElementDeleteAction from '../CartElementDeleteAction';

const CartElementVoucherSmall = ({ product, productTitleProps, elementPrice, cartOrderId, cartItemId, voucherId, blockActions, showDelete }) => {
  return (
    <Box bg="brand.background_main" {...ROUNDED_ELEMENT_PROPS} paddingRight=".7rem">
      <HStack spacing="24px" position="relative">
        <VStack align="left" flexGrow="1" width="100%">
          <Box>
            <Text {...productTitleProps}>
              <Trans>Voucher usage :</Trans>
            </Text>

            <Text {...productTitleProps}>{' ' + product.title + ' '}</Text>

            {product.voucher_name && (
              <Text {...productTitleProps} fontStyle="italic">
                {'(' + product.voucher_name + ')'}
              </Text>
            )}
          </Box>
        </VStack>
        <Spacer />

        <HStack alignItems="center" flexShrink={0}>
          <Text fontWeight="bold">{getFormattedCurrency(elementPrice)}</Text>
          {showDelete && (
            <CartElementDeleteAction
              cartOrderId={cartOrderId}
              getItemIdsToDelete={() => [cartItemId, voucherId]}
              position="relative"
              justifyContent="flex-end"
            />
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

export default CartElementVoucherSmall;
