import { Flex, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { MdWork } from 'react-icons/md';

import useIsMobile from '../../../hooks/useIsMobile';
import CollapsibleText from '../../generic/CollapsibleText';
import IconWithText from '../../generic/IconWithText';
import Image from '../../generic/Image';
import TeamLogo from '../../generic/TeamLogo';
import YoplanningHtml from '../../generic/YoplanningHtml';

const ICONS_PROPS = { w: '18px', h: '18px' };

const ShopResourceDetail = ({ shopResource }) => {
  const isMobile = useIsMobile();

  return (
    <Flex flexDirection="column" gap=".5rem">
      {!isMobile && <TeamLogo />}

      <Text fontSize="xl">{shopResource.label}</Text>

      <Image imageUrl={shopResource.description_image} alt={t`Resource image`} maxH="200px" />

      {shopResource.description && (
        <CollapsibleText startingHeight={55} mb=".5rem" isShown={!isMobile}>
          <YoplanningHtml description={shopResource.description} />
        </CollapsibleText>
      )}

      {shopResource.team && (
        <IconWithText icon={MdWork} iconProps={ICONS_PROPS}>
          <Text>{shopResource.team.name}</Text>
        </IconWithText>
      )}
    </Flex>
  );
};

export default ShopResourceDetail;
