import { useContext } from 'react';

import { CoreContext } from '../context/CoreContext';

const useReadManufacturer = () => {
  const { coreData } = useContext(CoreContext);

  // If you want to easily override to see the behavior on P6 : comment/uncomment below
  const { is_manufacturer: isManufacturer, can_customise: isAdmin } = coreData;
  // const isManufacturer = true;
  // const isAdmin = true;

  const willSearchClients = !isManufacturer;
  const willSearchStaffs = isManufacturer && isAdmin;
  const canDisplaySelector = willSearchClients || willSearchStaffs;

  return { willSearchClients, willSearchStaffs, canDisplaySelector, isManufacturer };
};

export default useReadManufacturer;
