import { VStack } from '@chakra-ui/react';

const VerticalStack = props => {
  return (
    <VStack spacing="15px" margin="8px" align="stretch" {...props}>
      {props.children}
    </VStack>
  );
};

export default VerticalStack;
