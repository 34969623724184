import { Link } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

const FilterResetLink = ({ canResetFilters, resetFiltersToDefault }) => {
  const getLinkProps = () => {
    if (canResetFilters) {
      return {
        onClick: resetFiltersToDefault,
      };
    }

    return {
      opacity: 0.3,
      cursor: 'not-allowed',
    };
  };

  const linkProps = getLinkProps();
  return (
    <Link {...linkProps}>
      <Trans>Reset filters</Trans>
    </Link>
  );
};

export default FilterResetLink;
