import { t } from '@lingui/macro';

import useWriteSeo from '../../hooks/useWriteSeo';
import CheckoutDetail from '../business/checkoutdetails/CheckoutDetail';

// ==============================================================================================
// Page infos :
// This is the 5th page in the normal process, it comes after the cart validation
// This page is used to fill the participants infos and assign them to activities
// This page CANNOT be accessed directly from an url.
// Route infos :
// - standard navigation : no param
// ==============================================================================================

const CheckoutDetailPage = () => {
  useWriteSeo({ title: t`Who does what ?` });

  return <CheckoutDetail />;
};

export default CheckoutDetailPage;
