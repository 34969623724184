import { useState } from 'react';

import { useBoolean, useUpdateEffect } from 'usehooks-ts';

import usePostPromoCode from './usePostPromoCode';
import useWriteCartData from './useWriteCartData';

const useFormPromoCode = (teamId, cartOrderId) => {
  const [promoCode, setPromoCode] = useState('');

  const { data, status, writePromoCode } = usePostPromoCode(teamId, cartOrderId);

  const { value: promoCodeSuccess, setValue: setPromoCodeSuccess } = useBoolean(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setCartDataToWrite } = useWriteCartData();

  const onSubmitCode = e => {
    e.preventDefault();
    if (!promoCode) return;

    // If same code submitted then canWrite will be false
    const canWrite = writePromoCode(promoCode);
    setIsSubmitting(canWrite);
  };

  useUpdateEffect(() => {
    // When react reloads state
    if (!data) return;

    setIsSubmitting(false);

    // success is when backend replies with a cart
    const success = 'order' in data;
    setPromoCodeSuccess(success);
    if (success) {
      setPromoCode('');
      setCartDataToWrite(data);
    }
  }, [data]);

  return { onSubmitCode, isSubmitting, promoCode, setPromoCode, promoCodeSuccess, status, data };
};

export default useFormPromoCode;
