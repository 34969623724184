import Select from '../../../generic/form/Select';

const ShopFeature = ({ availableFeatures, ...props }) => {
  const choices = getSelectChoices(availableFeatures);
  return <Select fieldChoices={choices} isRequired {...props} />;
};

export default ShopFeature;

const getSelectChoices = featureValues => {
  const choices = [{ label: '--', value: '' }];
  for (const featureValue of featureValues) {
    choices.push({
      label: featureValue.label,
      value: featureValue.id,
    });
  }
  return choices;
};
