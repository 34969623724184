import { useEffect, useState } from 'react';

import { createBrowserHistory } from 'history';
import { useLocation, useParams } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import { apiGetCart, LOCAL_STORAGE_CART_ID, LOCAL_STORAGE_CART_ID_DEFVAL } from '../constants';
import { devMessage } from '../settings/settings';
import { getValidUuidOrNull } from '../utils/strUtil';
import useFetch from './useFetch';
import useReadCartMissingOrder from './useReadCartMissingOrder';
import useWriteCartData from './useWriteCartData';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const useFetchCart = () => {
  // When going from P3 to CART, we will not fetch the cart from backend
  // Because the POST on P3 replied with the full cart already
  const { state } = useLocation();
  const queryBackend = !state || state.queryBackend !== false;
  const history = createBrowserHistory();

  const { cartId: paramCartId } = useParams();
  const [storedCartId, setStoredCartId] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART_ID, LOCAL_STORAGE_CART_ID_DEFVAL);
  const cartId = getValidUuidOrNull(paramCartId || storedCartId);

  const cartFetchUrl = cartId && queryBackend ? apiGetCart(cartId) : null;
  const { data, status } = useFetch(cartFetchUrl);
  useReadCartMissingOrder(data);
  const [cartFetched, setCartFetched] = useState(cartFetchUrl === null ? true : false);

  const { setCartDataToWrite, cartData } = useWriteCartData();

  useEffectOnce(() => {
    setStoredCartId(cartId);
  });

  // When data was loaded (if we wanted to query backend) then we update stuff
  useUpdateEffect(() => {
    setCartDataToWrite(data);
    setCartFetched(true);
    devMessage('Cart retrieved from backend:', cartId);
  }, [data]);

  // Reset 'queryBackend' flag on cart fetch
  useEffect(() => {
    if (!cartFetched) return;

    if (history.location && history.location.state && history.location.state.queryBackend === false) {
      const state = { ...history.location.state };
      delete state.queryBackend;
      history.replace({ ...history.location, state });
    }
  }, [cartFetched]);

  return { cartData, status, cartFetched };
};

export default useFetchCart;
