import { parsePhoneNumber } from 'awesome-phonenumber';

// Keeping this if we decide to change behavior
// Our locales might be for example ["fr","ca"] so we want the secondary when possible bc more specific
// export const getPhoneCountryFromLocale = () => {
//   const browserLocales = getBrowserLocales();
//   return browserLocales.length > 1 ? browserLocales[1] : browserLocales[0];
// };

export const getPhonePreferredCountries = () => ['fr', 'be', 'ch', 'ca', 'gb', 'nl', 'it', 'es', 'de'];

export const adaptPhoneForBackend = (phoneNumber, countryIsoCode) => {
  const e164Number = getValidE164Number(phoneNumber, countryIsoCode);

  if (e164Number && !e164Number.startsWith('+')) {
    return '+' + e164Number;
  }
  return e164Number;
};

export const getValidE164Number = (phoneNumber, countryIsoCode) => {
  if (!phoneNumber || phoneNumber.length < 4) {
    return null;
  }

  // This is a dirty shit fix for a case that should not happen if users were NORMAL
  // If the user VOLUNTARILY removed the +33 (wtf) and starts putting 06xxx
  // Then phoneNumber will be +06xxx and it will fuck up
  const phoneNumberWithPlus = _preParsePhoneChecks(phoneNumber, countryIsoCode);

  const parsedNumber = parsePhoneNumber(phoneNumberWithPlus, { regionCode: countryIsoCode });
  if (!parsedNumber.valid || !parsedNumber.possible) {
    return null;
  }

  const e164Number = parsedNumber.number.e164;
  const countryDialCode = parsedNumber.countryCode;

  // parsePhoneNumber tends to add the prefix ON TOP of the EXISTING prefix, FUCK IT
  const badPrefix = `+${countryDialCode}${countryDialCode}`;
  if (e164Number.startsWith(badPrefix) && !phoneNumberWithPlus.startsWith(badPrefix)) return null;

  return e164Number.replace('+', '');
};

// I'm sorry
const _preParsePhoneChecks = (phoneNumber, countryIsoCode) => {
  if (!phoneNumber.startsWith('+')) phoneNumber = `+${phoneNumber}`;

  if (phoneNumber.length === 11 && countryIsoCode === 'fr') {
    if (phoneNumber.startsWith('+06')) phoneNumber = phoneNumber.replace('+06', `+336`);
    else if (phoneNumber.startsWith('+07')) phoneNumber = phoneNumber.replace('+07', `+337`);
  }

  return phoneNumber;
};
