import { Flex } from '@chakra-ui/react';

const FlexRow = props => {
  return (
    <Flex flexDirection="row" flexWrap={{ base: 'wrap', md: 'nowrap' }} gap={{ base: '0', md: '15px' }} {...props}>
      {props.children}
    </Flex>
  );
};

export default FlexRow;
