import { FormLabel } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';

const InputFormLabel = ({ fieldName, fieldLabel, noOfLines = 1 }) => {
  return (
    <FormLabel htmlFor={fieldName} noOfLines={noOfLines} title={fieldLabel.id ? t({ id: fieldLabel.id }) : t`${fieldLabel}`}>
      <Trans id={fieldLabel}></Trans>
    </FormLabel>
  );
};

export default InputFormLabel;
