import { Trans } from '@lingui/macro';

import { REQUEST_STATUS } from '../../../utils/requestUtil';
import AlertError from '../../generic/error/AlertError';

const ShopResourceBookingErrors = ({ shopResource, stockUnavailable, statusAddCart }) => {
  const resStock = shopResource.stock > 0 ? shopResource.stock : 0;

  return (
    <>
      {stockUnavailable && <AlertError message={<Trans>Not enough stock for this item. ({resStock})</Trans>} />}
      {statusAddCart === REQUEST_STATUS.ERROR && <AlertError />}
    </>
  );
};

export default ShopResourceBookingErrors;
