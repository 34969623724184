import { Icon } from '@chakra-ui/react';
import { IoIosNuclear } from 'react-icons/io';

import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import { devMessage } from '../../../settings/settings';
import ButtonIcon from './ButtonIcon';

const DevCleanButton = () => {
  const navigate = useNavigateBookingEngine();
  const cleanDevData = e => {
    e.preventDefault();
    localStorage.clear();
    navigate('');
    location.reload();
    console.clear();
    devMessage('Storage cleared and navigated back to home.');
  };

  return <ButtonIcon onClick={cleanDevData} icon={<Icon as={IoIosNuclear} boxSize="30px" />} color="red" ml=".5rem" />;
};

export default DevCleanButton;
