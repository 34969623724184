import { useState } from 'react';

import { t } from '@lingui/macro';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import { devMessage } from '../settings/settings';
import useCleanStorage from './useCleanStorage';
import useReadLoggedUser from './useReadLoggedUser';

export const CART_SOURCE_LOGGED_OFFICE = 'Office';
export const CART_SOURCE_UNLOGGED_ONLINE = 'Online';

const useReadCartSource = cartData => {
  const loggedUser = useReadLoggedUser();

  const [incoherentCartSource, setIncoherentCartSource] = useState(false);

  useEffectOnce(() => {
    if (!cartData) return;

    const expectedCartSource = loggedUser ? CART_SOURCE_LOGGED_OFFICE : CART_SOURCE_UNLOGGED_ONLINE;
    const isIncoherent = cartData.cartSource !== expectedCartSource;
    devMessage('Incoherent cart source?', isIncoherent, 'Expected:', expectedCartSource, 'got in cart:', cartData.cartSource);
    setIncoherentCartSource(isIncoherent);
  });

  const { cleanOrderGotoCart } = useCleanStorage(false);

  useUpdateEffect(() => {
    if (!incoherentCartSource) return;

    cleanOrderGotoCart(t`A connection change has been detected: your cart has been reset.`);
  }, [incoherentCartSource]);

  return null;
};

export default useReadCartSource;
