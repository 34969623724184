import { useEffect, useRef, useState } from 'react';

import { Collapse, Link } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useBoolean } from 'usehooks-ts';

import BorderLeftBox from './BorderLeftBox';

const CollapsibleText = ({ startingHeight, isShown = false, ...props }) => {
  const [hasOverflow, setHasOverflow] = useState(false);

  const { value: moreShown, toggle: toggleShown } = useBoolean(isShown);

  const refCollapse = useRef(null);

  const checkHasOverflow = textContainer => {
    return textContainer && (textContainer.offsetHeight < textContainer.scrollHeight || textContainer.offsetWidth < textContainer.scrollWidth);
  };

  useEffect(() => {
    setHasOverflow(isShown || checkHasOverflow(refCollapse.current));
  }, [hasOverflow]);

  return (
    <BorderLeftBox {...props}>
      <Collapse startingHeight={startingHeight} in={moreShown} ref={refCollapse}>
        {props.children}
      </Collapse>

      {hasOverflow && (
        <Link onClick={toggleShown} mt=".5rem" as="span">
          {moreShown && <Trans>Show less</Trans>}
          {!moreShown && <Trans>Show more</Trans>}
        </Link>
      )}
    </BorderLeftBox>
  );
};

export default CollapsibleText;
