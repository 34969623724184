import { useContext } from 'react';

import { t } from '@lingui/macro';

import { CoreContext } from '../../context/CoreContext';
import useWriteSeo from '../../hooks/useWriteSeo';
import ProductsFilteredList from '../business/product/list/ProductsFilteredList';
import ProductsFilteredSquare from '../business/product/square/ProductsFilteredSquare';

// ==============================================================================================
// Page description :
// This is the first (root) page we see when we open the booking engine
// Route infos :
//   -> facultative query params for the filter names and values, eg: /?tag=bike&nb_tickets=2
// ==============================================================================================

const ProductsSelectionPage = () => {
  useWriteSeo({ title: t`Products list`, description: t`List of products bookable online` });

  const { coreData } = useContext(CoreContext);
  const { product_view_type: productViewType } = coreData.booking_engine;

  if (productViewType === 'square') {
    return <ProductsFilteredSquare />;
  }

  return <ProductsFilteredList />;
};

export default ProductsSelectionPage;
