import { useMemo } from 'react';

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, HStack, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useIsMobile from '../../../../hooks/useIsMobile';
import { findArrayItemById } from '../../../../utils/arrayUtil';
import BorderLeftBox from '../../../generic/BorderLeftBox';
import AvailabilityDateTime from '../../availability/AvailabilityDateTime';
import CartElementBoxes from '../CartElementBoxes';
import CartElementDetails from '../CartElementDetails';
import CartProductImage from '../CartProductImage';
import CartSessionPrice from '../CartSessionPrice';

const CartElementSessionCheckout = ({ groupedSessionGroups, cartData, isTaxIncluded, isUnavailable, unavailableResources }) => {
  const isMobile = useIsMobile();
  const StackElement = isMobile ? VStack : HStack;

  const { availabilities, countParticipants, countResources, productId, sessions, detailResources } = groupedSessionGroups;
  const { products } = cartData;
  const product = useMemo(() => findArrayItemById(productId, products), [productId]);

  const cartElementPriceProps = {
    isTaxIncluded: isTaxIncluded,
    groupedSessionGroups: groupedSessionGroups,
  };

  return (
    <Box bg="brand.background_main" position="relative">
      <StackElement spacing="1rem">
        <CartProductImage productImage={product.description_image} />

        <VStack align="left" flexGrow="1" width="100%">
          {!isUnavailable && <Text fontSize="md">{product.title}</Text>}

          {isUnavailable && (
            <Alert status="error" bg="transparent" p="0">
              <AlertTitle fontSize="md">{product.title}</AlertTitle>
              <AlertIcon mr="5px" />
              <AlertDescription fontSize="xs" color="red" pt="2px">
                <Trans>This element is not available anymore.</Trans>
              </AlertDescription>
            </Alert>
          )}

          {!isUnavailable && unavailableResources.length > 0 && (
            <Alert status="error" bg="transparent" p="0">
              <AlertDescription fontSize="xs" color="red" pt="2px" fontWeight="500">
                <Trans>Some options are not available on this session :</Trans>

                <UnorderedList color="brand.color_font" ml="2rem" fontWeight="normal">
                  {unavailableResources.map(resource => (
                    <ListItem key={resource}>{resource}</ListItem>
                  ))}
                </UnorderedList>
              </AlertDescription>
            </Alert>
          )}

          <BorderLeftBox>
            <HStack align="left" flexGrow="1" alignItems="baseline">
              <AvailabilityDateTime sessions={sessions} dateStyle="long" />

              {/* elements all come from the same session group id so we dont care, we take the first avalability */}
              <CartElementDetails sessionGroupId={availabilities[0].session_group_id} detailResources={detailResources} />
            </HStack>

            <CartElementBoxes nbParticipants={countParticipants} nbResources={countResources} />
          </BorderLeftBox>
        </VStack>
      </StackElement>

      <CartSessionPrice {...cartElementPriceProps} justifyContent="flex-end" mt=".5rem" />
    </Box>
  );
};

export default CartElementSessionCheckout;
