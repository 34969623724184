import { Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, COMMON_PADDING } from '../../../utils/styleUtil';
import ButtonLink from '../../generic/button/ButtonLink';

const SessionVariantNotBookable = () => {
  const navigate = useNavigateBookingEngine();

  return (
    <VStack bg="brand.background_secondary" p={COMMON_PADDING} {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
      <Text fontSize="sm" fontStyle="italic">
        <Trans>This session is already in your cart, there is no more place available.</Trans>
      </Text>

      <ButtonLink onClick={() => navigate(-1)}>
        <Trans>Go back</Trans>
      </ButtonLink>
    </VStack>
  );
};

export default SessionVariantNotBookable;
