import { useEffectOnce } from 'usehooks-ts';

const useWriteSeo = ({ title, description }) => {
  useEffectOnce(() => {
    document.title = title;

    if (description) {
      document.querySelector('meta[name="description"]').setAttribute('content', description);
    }
  });
};

export default useWriteSeo;
