import { useMemo } from 'react';

import { Box, Text } from '@chakra-ui/layout';

import { COMMON_PADDING } from '../../../utils/styleUtil';
import BorderLeftBox from '../../generic/BorderLeftBox';
import AvailabilityDateTime from '../availability/AvailabilityDateTime';
import AvailabilityMoreDates from '../availability/AvailabilityMoreDates';

const SessionVariantsProduct = ({ product, availabilities }) => {
  const { title: productTitle } = product;

  const { sessionGroupIds, nbSessions } = useMemo(() => getAllSelectedSessions(availabilities), [JSON.stringify(availabilities)]);

  return (
    <>
      <Box bg="brand.background_main" p={COMMON_PADDING} pt={{ base: '0', md: undefined }}>
        {availabilities.length <= 1 && <Text fontSize="xl">{productTitle}</Text>}
        {availabilities.length > 1 && (
          <Text fontSize="xl">
            {productTitle} (x{availabilities.length})
          </Text>
        )}

        <BorderLeftBox>
          <AvailabilityDateTime sessions={availabilities} />
          <AvailabilityMoreDates sessionGroupIds={sessionGroupIds} nbSessions={nbSessions} />
        </BorderLeftBox>
      </Box>
    </>
  );
};

export default SessionVariantsProduct;

const getAllSelectedSessions = availabilities => {
  const sessionGroupIds = [];
  let nbSessions = 0;

  for (const ava of availabilities) {
    sessionGroupIds.push(ava.session_group_id);
    nbSessions += parseInt(ava.nb_sessions);
  }
  return { sessionGroupIds, nbSessions };
};
