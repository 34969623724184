import { useState } from 'react';

import { Box, FormControl, Input, InputGroup } from '@chakra-ui/react';
import { defineMessage, Trans } from '@lingui/macro';

import { INVALID_EMAIL_CLASSNAME, isValidEmailString } from '../../../hooks/useFormValidate';
import InputFormLabel from './InputFormLabel';

export const EMAIL_FIELDNAME = 'email';

const InputEmail = ({
  fieldName = EMAIL_FIELDNAME,
  fieldValue,
  displayLabel = true,
  isRequired,
  onChange,
  minWidth,
  maxWidth,
  isDisabled,
  inputCustomStyle,
}) => {
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleOnBlur = e => {
    const email = e.target.value;
    setIsValidEmail(email ? isValidEmailString(email) : true);
  };

  return (
    <FormControl isRequired={isRequired} minW={minWidth} maxW={maxWidth}>
      {displayLabel && <InputFormLabel fieldName={fieldName} fieldLabel={emailLabel} />}

      <InputGroup>
        <Input
          type="email"
          name={fieldName}
          id={fieldName}
          placeholder="john.doe@gmail.com"
          value={fieldValue}
          onChange={e => onChange(fieldName, e.target.value)}
          onBlur={handleOnBlur}
          isDisabled={isDisabled}
          style={inputCustomStyle}
          className={isValidEmail ? undefined : INVALID_EMAIL_CLASSNAME}
        />
        {!isValidEmail && (
          <Box className="invalid-data-message">
            <Trans>Invalid email</Trans>
          </Box>
        )}
      </InputGroup>
    </FormControl>
  );
};

export default InputEmail;

const emailLabel = defineMessage({ message: 'Email' });
