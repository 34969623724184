import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { BsArrowRightCircle } from 'react-icons/bs';

import { LOCAL_STORAGE_PRECART, LOCAL_STORAGE_PRECART_DEFVAL, ROUTE_P2_AVA_SELECTION } from '../../../constants';
import useIsMobile from '../../../hooks/useIsMobile';
import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import useWriteStorageBookingEngine from '../../../hooks/useWriteStorageBookingEngine';
import { ITEM_LIST_HOVER, ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import BorderLeftBox from '../../generic/BorderLeftBox';
import ButtonIcon from '../../generic/button/ButtonIcon';
import YoplanningHtml from '../../generic/YoplanningHtml';
import ProductImageMobile from './ProductImageMobile';
import ProductMediaManager from './ProductMediaManager';
import ProductPrice from './ProductPrice';
import ProductProximityTag from './ProductProximityTag';

// ==============================================================================================
// This component displays a product
// ==============================================================================================

const Product = ({ product }) => {
  const isMobile = useIsMobile();
  const StackElement = isMobile ? VStack : HStack;

  // eslint-disable-next-line no-unused-vars
  const [preCartData, setPreCartData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PRECART, LOCAL_STORAGE_PRECART_DEFVAL);

  const navigate = useNavigateBookingEngine();
  const onProductClick = e => {
    e.preventDefault();
    setPreCartData(LOCAL_STORAGE_PRECART_DEFVAL);
    navigate(`${ROUTE_P2_AVA_SELECTION}/${product.id}`);
  };

  const productHasOneOrMoreImages = product.description_image || product.temp_name;

  return (
    <Box bg="brand.background_main" onClick={onProductClick} {...ITEM_LIST_HOVER} {...ROUNDED_ELEMENT_PROPS} className="Product">
      <StackElement spacing={{ base: '5px', md: '.7rem' }}>
        {!isMobile && <ProductMediaManager product={product} disableVideo maxW={{ base: '100%', md: '160px' }} />}

        {isMobile && productHasOneOrMoreImages && <ProductImageMobile product={product} />}

        <VStack align="left" flexGrow="1" width="100%" minWidth="0">
          <Text fontSize="md">{product.title}</Text>

          <BorderLeftBox>
            <YoplanningHtml description={product.description} noOfLines={3} />

            <ProductProximityTag proximity={product.proximity} locality={product.locality} />
          </BorderLeftBox>
        </VStack>

        {/* In mobile if no image then we have to display the price here (otherwise the price is on top of the image) */}
        {isMobile && !productHasOneOrMoreImages && (
          <Box pt={{ base: '.5rem', md: '0' }}>
            <ProductPrice price={product.price_from} isFree={product.free} />
          </Box>
        )}

        {!isMobile && (
          <>
            <Box pt={{ base: '.5rem', md: '0' }}>
              <ProductPrice price={product.price_from} isFree={product.free} />
            </Box>
            <ButtonIcon icon={<Icon as={BsArrowRightCircle} boxSize="24px" />} />
          </>
        )}
      </StackElement>
    </Box>
  );
};

export default Product;
