import cloneDeep from 'lodash.clonedeep';
import { useUpdateEffect } from 'usehooks-ts';

import { findArrayItemById } from '../utils/arrayUtil';
import { getMainPrice, STANDARD_PRICE_SELECT_VALUE } from '../utils/business/prices';

const useWriteVariantsPrices = (sessionGroupId, variants, totalParticipants, setPreCartVariant) => {
  // We make it simple : anytime the total of participant changes (quantity change or new variant added/removed for example)
  // Then we recalculate all variants prices so that if there's a change, we update the state and it will trigger all the re-renders needed
  // Before we did specific things depending if we were on "per session" / "per person" modes but that's too complicated and confusing
  useUpdateEffect(() => {
    const copyVariants = cloneDeep(variants);
    if (updateSeveralVariantsPrices(copyVariants, totalParticipants)) {
      setPreCartVariant(sessionGroupId, copyVariants);
    }
  }, [totalParticipants]);

  return {};
};

export default useWriteVariantsPrices;

// We keep the selected-price updated depending on our participants
// This way the discount-prices stay selected, otherwise the selected-price would be empty
export const updateSeveralVariantsPrices = (availabilityVariants, totalParticipants) => {
  let variantsChanged = false;
  for (const variant of availabilityVariants) {
    const { currentPriceVariationId, custom_prices: allCustomPrices } = variant;
    const { priceVariationId } = getMainPrice(variant.standardPrice, totalParticipants, allCustomPrices);

    // We do the change only if has a main price (standard/discount)
    // Because if the user selected another price then we don't want to change his choice
    const hasMainPrice =
      currentPriceVariationId === STANDARD_PRICE_SELECT_VALUE ||
      findArrayItemById(currentPriceVariationId, allCustomPrices).participant_threshold != null;

    if (hasMainPrice && currentPriceVariationId !== priceVariationId) {
      variant.currentPriceVariationId = priceVariationId;
      variantsChanged = true;
    }
  }

  // This bool allows use to skip state changes when nothing has changed
  return variantsChanged;
};
