import Product from '../Product';

const ProductsList = ({ data: products }) => {
  return (
    <>
      {/* when rendering components using map, a unique key attribute is required to help react manage the list */}
      {products.map(element => (
        <Product key={element.id} product={element} />
      ))}
    </>
  );
};

export default ProductsList;
