import { ListItem } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const AvailabilityDateTimeMore = ({ session }) => {
  const { i18n } = useLingui();
  const { start_date: startDate, end_date: endDate } = session;

  return (
    <ListItem fontSize="xs">
      {i18n.date(startDate, {
        dateStyle: 'medium',
      }) + ', '}
      <Trans>from</Trans>
      {' ' +
        i18n.date(startDate, {
          timeStyle: 'short',
        }) +
        ' '}
      <Trans>to</Trans>
      {' ' +
        i18n.date(endDate, {
          timeStyle: 'short',
        })}
    </ListItem>
  );
};

export default AvailabilityDateTimeMore;
