// used for edit/deletion
export const getAvailabilitiesAndResourcesIds = availabilities => {
  const itemsIds = [];
  for (const availability of availabilities) {
    itemsIds.push(availability.id);
    for (const resource of availability.resources) {
      itemsIds.push(resource.id);
    }
  }
  return itemsIds;
};
