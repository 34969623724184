import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { FiBox } from 'react-icons/fi';

import useIsMobile from '../../../../hooks/useIsMobile';
import useReadCartItemSingleResource from '../../../../hooks/useReadCartItemSingleResource';
import { BTN_ICON_BOXSIZE } from '../../../../utils/styleUtil';
import CartElementPrice from '../CartElementPrice';
import CartProductImage from '../CartProductImage';

const CartElementSingleResourceCheckout = ({ cartItem, cartData, isTaxIncluded, isUnavailable }) => {
  const isMobile = useIsMobile();
  const StackElement = isMobile ? VStack : HStack;

  const { resource, elementPrice, elementPriceWithoutPromo } = useReadCartItemSingleResource(cartItem, cartData.resources, isTaxIncluded);

  const cartElementPriceProps = {
    elementPrice: elementPrice,
    elementPriceWithoutPromo: elementPriceWithoutPromo,
  };

  return (
    <Box bg="brand.background_main" position="relative">
      <StackElement spacing="24px">
        <CartProductImage productImage={resource.description_image} />

        <Flex gap=".5rem" minH={{ base: undefined, md: '30px' }}>
          <Icon as={FiBox} boxSize={BTN_ICON_BOXSIZE} color="brand.color_main" />

          {!isUnavailable && (
            <Text fontSize="md" wordBreak="break-word">
              {resource.label} (x{cartItem.quantity})
            </Text>
          )}

          {isUnavailable && (
            <Alert status="error" bg="transparent" p="0" flexDirection="column" alignItems="flex-start">
              <AlertTitle fontSize="md" wordBreak="break-word">
                {resource.label} (x{cartItem.quantity})
              </AlertTitle>
              <HStack>
                <AlertIcon mr="0px" />
                <AlertDescription fontSize="xs" color="red" pt="2px">
                  <Trans>This element is not available anymore.</Trans>
                </AlertDescription>
              </HStack>
            </Alert>
          )}
        </Flex>
      </StackElement>

      <CartElementPrice {...cartElementPriceProps} justifyContent="flex-end" mt=".5rem" />
    </Box>
  );
};

export default CartElementSingleResourceCheckout;
