// All this stuff in this file is used to manage the fact..
// ..that we have the booking engine id in the root path
// I couldnt find a better way to handle this

import { settings } from '../settings/settings';
import { isAbsoluteUrl } from './requestUtil';

// This one is used to the backend calls, we have to do this because :
// - To display the app we need the translations loaded
// - To have the translations we need to query the backend
// - To query the backend we need to get the booking engine id from the url
// Soooo I decided to put the api url constants as relative (eg: "/cart/save-items")
// (- Except for the urls without booking engine id, they are already full -)
// And in here we construct the full url
export const getFullApiUrl = url => {
  if (!url || isAbsoluteUrl(url)) return url;

  const bookingEngineId = getBookingEngineIdFromUrl();
  if (!bookingEngineId) {
    console.error('No booking engine ID in url', url);
    return url;
  }

  return `${settings.BOOKING_ENGINE_ROOT_URL}/${bookingEngineId}${url}`;
};

// This one is for the front navigation
// It used the various relative Routes in our pages components
export const getFullFrontUrl = relativeRoutePath => {
  const bookingEngineId = getBookingEngineIdFromUrl();
  return `/${bookingEngineId}/${relativeRoutePath}`;
};

// Usually for this we would use a react router hook...
// ...except we have to get this before loading the app and its routes
export const getBookingEngineIdFromUrl = () => {
  const pathes = window.location.pathname.split('/');
  const bookingEngineId = pathes[1];
  return bookingEngineId;
};

export const getSpecificStorageKey = localStorageKey => {
  const bookingEngineId = getBookingEngineIdFromUrl();
  return `${bookingEngineId}_${localStorageKey}`;
};
