import { Button, Center, Divider, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { commonButtonProps } from '../button/ButtonLink';

const DevThemeButtonHardReset = ({ onHardReset, ...props }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="left">
      <PopoverTrigger>
        <Button variant="outline" onClick={onOpen} {...props}>
          <Trans>Reset</Trans>
        </Button>
      </PopoverTrigger>

      <PopoverContent textAlign="center">
        <PopoverArrow />
        <PopoverBody>
          <Text>
            <Trans>Your custom theme will be removed and the default theme will be used, continue ?</Trans>
          </Text>
          <Center p="1rem">
            <Button {...commonButtonProps} onClick={onHardReset}>
              <Trans>Yes, reset</Trans>
            </Button>
            <Divider maxW="30px" mx="1rem" />
            <Button {...commonButtonProps} variant="outline" onClick={onClose}>
              <Trans>No</Trans>
            </Button>
          </Center>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DevThemeButtonHardReset;
