import { Box, Divider, Flex, HStack } from '@chakra-ui/react';

import { COMMON_GAP, ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import ButtonDelete from '../../../generic/button/ButtonDelete';
import OptionCustom from '../options/OptionCustom';
import OptionParticipant from '../options/OptionParticipant';
import VariantParticipantDetails from '../VariantParticipantDetails';

const VariantLinePerSession = ({ variant, onVariantDelete, loggedUser, allDefaultOptions, placesAvailable, placesMax, ...props }) => {
  return (
    <Box bg="brand.background_main" position="relative" {...ROUNDED_ELEMENT_PROPS}>
      <HStack>
        <OptionParticipant
          fieldValue={variant.participant}
          loggedUser={loggedUser}
          placesAvailable={placesAvailable}
          placesMax={placesMax}
          {...props}
        />
      </HStack>

      {loggedUser && <Divider orientation="horizontal" mt=".9rem" mb=".9rem" />}
      {!loggedUser && <VariantParticipantDetails participantDetails={variant.participantDetails} countOptions={variant.custom_options.length} />}

      <Flex flexFlow="wrap" gap={COMMON_GAP}>
        {variant.custom_options.map((option, index) => (
          <OptionCustom key={index} data={option} defaultOption={allDefaultOptions[index]} variantParticipants={variant.participant} {...props} />
        ))}
      </Flex>

      {props.index > 0 && <ButtonDelete onClick={() => onVariantDelete(props.index)} enableSpinner={false} />}
    </Box>
  );
};

export default VariantLinePerSession;
