// These are the settings specific to production mode

const YOPLANNING_ROOT_URL = 'https://yoplanning.pro'; // No trailing slash !

export const SETTINGS_PROD = {
  YOPLANNING_ROOT_URL: YOPLANNING_ROOT_URL,
  BOOKING_ENGINE_ROOT_URL: `${YOPLANNING_ROOT_URL}/booking-engine`, // No trailing slash !

  ROLLBAR_ENV_NAME: 'prod',
};
