const useReadAvailability = (availability, product) => {
  const { price_per_participant: isPricePerParticipant } = product ?? {};
  const { nb_tickets: nbPlaces, max_participants: maxParticipantsPerSession } = availability ?? {};

  const maxAvailablePlaces = getMaxAvailablePlaces(isPricePerParticipant, nbPlaces, maxParticipantsPerSession);
  const maxBookableSessions = maxParticipantsPerSession ? Math.floor(nbPlaces / Math.max(maxParticipantsPerSession, 1)) : null;

  return { maxAvailablePlaces, maxBookableSessions };
};

export default useReadAvailability;

// exceptionnel commentaire en français pour nos futurs nous
//
// en prix par session, on veut que les gens book des sessions différentes (en mettant 2 fois la même session au panier)
// c'est à dire qu'on book UNE SEULE SESSION A LA FOIS donc c'est normal qu'on limite au max_participants
// (qui ne devrait pas être supérieur au nb_tickets mais on min() par sécu)
//
// en prix par participant c'est le nb_tickets soit le nombre de places total
const getMaxAvailablePlaces = (isPricePerParticipant, nbPlaces, maxParticipantsPerSession) => {
  if (isPricePerParticipant) {
    return nbPlaces;
  }

  // having min(1,null) would return zero so fuck nope
  if (maxParticipantsPerSession != null && nbPlaces != null) {
    return Math.min(maxParticipantsPerSession, nbPlaces);
  }
  return maxParticipantsPerSession != null ? maxParticipantsPerSession : nbPlaces;
};
