import { useMemo } from 'react';

import { BIRTHDATE_FIELDNAME } from '../components/generic/form/InputBirthDate';
import { EMAIL_FIELDNAME } from '../components/generic/form/InputEmail';
import { PHONE_FIELDNAME } from '../components/generic/form/InputPhone';
import { LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_CART } from '../constants';
import { findArrayItemById } from '../utils/arrayUtil';
import useReadStorageBookingEngine from './useReadStorageBookingEngine';

const useReadAdditionalData = participantId => {
  const activityAssignData = useReadStorageBookingEngine(LOCAL_STORAGE_ASSIGN);
  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);

  const additionalDataNeeded = useMemo(
    () => getAdditionalDataNeeded(participantId, activityAssignData, cartData),
    [JSON.stringify(activityAssignData)]
  );
  return additionalDataNeeded;
};

export default useReadAdditionalData;

const getAdditionalDataNeeded = (participantId, itemsAttributionData, cartData) => {
  const { products, specificPersonData, ocSessionsWithResources } = cartData;

  // if one day you want to display additional data depending on activities assignation
  // then uncomment this line below, for now we decided to rollback on this feature
  // and decided to display all extra data even if the participant is not assigned to some products
  const itemIds = null; // getItemIds(itemsAttributionData, participantId);
  const productIds = getProductIds(ocSessionsWithResources, itemIds);
  const productSpecificData = getSpecificDataForProducts(productIds, products);

  return {
    standardData: getStandardDataNeeded(productIds, products), // => birthday, phone, lang... yoplanning data
    customData: getCustomDataNeeded(productSpecificData, specificPersonData), // => these are created by the yoplanning users
  };
};

// eslint-disable-next-line no-unused-vars
const getItemIds = (itemsAttributionData, participantId) => {
  const itemIds = [];
  for (const item of itemsAttributionData) {
    if (item.client_id === participantId) {
      itemIds.push(item.id);
    }
  }
  return itemIds;
};

const getProductIds = (ocSessionsWithResources, itemIds) => {
  const productIds = [];

  ocSessionsWithResources.map(entry => {
    const groupedSessionGroups = entry[1];
    for (const availability of groupedSessionGroups.availabilities) {
      if (productIds.includes(groupedSessionGroups.productId)) continue;

      if (itemIds === null || itemIds.includes(availability.id)) {
        productIds.push(groupedSessionGroups.productId);
        break;
      }
    }
  });
  return productIds;
};

const getSpecificDataForProducts = (productIds, products) => {
  const specificDataIds = [];
  const specificDataInfo = [];
  for (const product of products) {
    if (!productIds.includes(product.id)) continue;
    if (!product.participant_details_required) continue;

    for (const spData of product.specific_data) {
      // anti duplicates
      if (specificDataIds.includes(spData.specific_person_data_id)) {
        // handle the case where a product has something that another has at a lower level
        const isRequired = spData.required;
        for (const generatedSpData of specificDataInfo) {
          if (generatedSpData.specific_person_data_id !== spData.specific_person_data_id) continue;
          generatedSpData.required = Math.max(generatedSpData.required, isRequired);
        }
        continue;
      }

      specificDataIds.push(spData.specific_person_data_id);

      // the element id and if it's required/optional/not asked
      specificDataInfo.push(spData);
    }
  }
  return specificDataInfo;
};

const getCustomDataNeeded = (productSpecificData, allSpecificData) => {
  const customData = [];
  for (const spData of productSpecificData) {
    const spDataDetails = findArrayItemById(spData.specific_person_data_id, allSpecificData);
    customData.push({ ...spDataDetails, required: spData.required });
  }
  customData.sort((a, b) => (a.name.length > b.name.length ? 1 : -1));
  return customData;
};

const getStandardDataNeeded = (productIds, products) => {
  const standardData = [];
  for (const product of products) {
    if (!productIds.includes(product.id)) continue;
    if (!product.participant_details_required) continue;

    pushStandardDataInfo(standardData, product, BIRTHDATE_FIELDNAME, EMAIL_FIELDNAME, 'language', PHONE_FIELDNAME);
  }
  return standardData;
};

export const pushStandardDataInfo = (standardDataArray, product, ...fields) => {
  pushParticipantNameInfo(standardDataArray);

  for (const field of fields) {
    const isRequired = product[`${field}_required`];
    if (isRequired === -1) continue;

    let isFound = false;
    for (const standardData of standardDataArray) {
      if (standardData.label !== field) continue;

      // handle the case where a product has something that another has at a lower level
      standardData.required = Math.max(standardData.required, isRequired);
      isFound = true;
      break;
    }
    if (isFound) continue;

    standardDataArray.push({ label: field, required: isRequired });
  }
};

const pushParticipantNameInfo = standardDataArray => {
  let isFound = false;
  for (const standardData of standardDataArray) {
    if (standardData.label === 'first_name' || standardData.label === 'last_name') {
      isFound = true;
      break;
    }
  }

  if (!isFound) {
    standardDataArray.push({ label: 'first_name', required: 1 });
    standardDataArray.push({ label: 'last_name', required: 1 });
  }
};
