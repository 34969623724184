import { apiGetShopResources } from '../../../constants';
import useIsMobile from '../../../hooks/useIsMobile';
import FilteredList from '../../generic/FilteredList';
import TeamLogo from '../../generic/TeamLogo';
import ShopList from './ShopList';
import { RESOURCES_FILTERS } from './ShopListFilters';

const ShopFilteredList = () => {
  const isMobile = useIsMobile();

  return (
    <FilteredList
      listType={ShopList}
      endpoint={apiGetShopResources()}
      propertyToReturn="resources"
      itemsPerScroll={RESOURCES_PER_SCROLL}
      filtersToDisplay={filtersToDisplay}
      skeletonHeight="115px"
    >
      {!isMobile && <TeamLogo />}
    </FilteredList>
  );
};

const RESOURCES_PER_SCROLL = 20;

const filtersToDisplay = [RESOURCES_FILTERS.TITLE];

export default ShopFilteredList;
