import { useRef } from 'react';

import {
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { MdOutlineInfo } from 'react-icons/md';

import useFetchSelectedOptionDetail from '../../../../hooks/useFetchSelectedOptionDetail';
import { REQUEST_STATUS } from '../../../../utils/requestUtil';
import { COMMON_PADDING } from '../../../../utils/styleUtil';
import ButtonIcon from '../../../generic/button/ButtonIcon';
import YoplanningHtml from '../../../generic/YoplanningHtml';

const SelectedOptionDetail = ({ selectedId }) => {
  const ref = useRef();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const { detail, status } = useFetchSelectedOptionDetail(isOpen, selectedId);

  if (!selectedId) return <></>;
  return (
    <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <ButtonIcon ref={ref} icon={<Icon as={MdOutlineInfo} boxSize="24px" />} onClick={onToggle} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        {detail && (
          <PopoverHeader>
            <Text color="brand.color_main" fontWeight="500" pr={COMMON_PADDING}>
              {detail.label}
            </Text>
          </PopoverHeader>
        )}

        <PopoverBody>
          {status === REQUEST_STATUS.LOADING && <Spinner ml=".5rem" size="xs" />}
          {status === REQUEST_STATUS.ERROR && (
            <Text fontSize="12px" fontWeight="300" color="red">
              <Trans>Cannot retrieve details, please try again later.</Trans>
            </Text>
          )}

          {detail && detail.description && <YoplanningHtml description={detail.description} />}

          {detail && !detail.description && (
            <Text>
              <Trans>No description.</Trans>
            </Text>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SelectedOptionDetail;
