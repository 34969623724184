import { IoMdTrash } from 'react-icons/io';

import useDeleteFromCart from '../../../hooks/useDeleteFromCart';
import ButtonDelete from '../../generic/button/ButtonDelete';

const CartElementDeleteAction = ({ cartOrderId, getItemIdsToDelete, position, alignItems, blockActions, ...props }) => {
  const { setItemIdsToDelete } = useDeleteFromCart(cartOrderId);

  const handleClick = () => {
    setItemIdsToDelete(getItemIdsToDelete());
  };

  return (
    <ButtonDelete
      onClick={handleClick}
      position={position}
      alignItems={alignItems}
      className="CartDelete"
      showAlert
      blockActions={blockActions}
      defaultIcon={IoMdTrash}
      {...props}
    />
  );
};

export default CartElementDeleteAction;
