import { Flex, Text } from '@chakra-ui/react';
import { defineMessage, Plural, Trans } from '@lingui/macro';

import useReadVoucherChoices from '../../../hooks/useReadVoucherChoices';
import { COMMON_PADDING } from '../../../utils/styleUtil';
import SelectHiddenIfOne from '../../generic/form/SelectHiddenIfOne';
import SelectOptionWithPrice from '../../generic/form/SelectOptionWithPrice';
import LabelWithText from '../../generic/LabelWithText';
import ContainerPlural from '../../generic/layout/ContainerPlural';

const BuyVoucherDetail = ({ productTitle, productVouchers, selectedVoucher, handleVoucherSelect }) => {
  const choices = useReadVoucherChoices(productVouchers);

  return (
    <Flex flexDirection="column">
      <Text pt={COMMON_PADDING} fontSize="xl" pb=".5rem">
        {productTitle}
      </Text>

      <SelectHiddenIfOne
        fieldLabel={defineMessage({ message: 'Select a voucher' })}
        fieldChoices={choices}
        onChange={handleVoucherSelect}
        formatOptionLabel={SelectOptionWithPrice}
        isRequired
        selectFirstChoice
        triggerChangeOnUpdate
        maxWidth="60%"
        minWidth="320px"
      />

      {selectedVoucher && selectedVoucher.validity && (
        <LabelWithText
          as="div"
          mt=".5rem"
          label={<Trans>Validity</Trans>}
          text={
            <ContainerPlural value={selectedVoucher.validity}>
              <Plural value={selectedVoucher.validity} one="month" other="months" />
            </ContainerPlural>
          }
        />
      )}
    </Flex>
  );
};

export default BuyVoucherDetail;
