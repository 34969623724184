import { Link, Spinner, Text } from '@chakra-ui/react';
import { Plural, Trans } from '@lingui/macro';

import { REQUEST_STATUS } from '../../../utils/requestUtil';


const AvailabilityMoreDatesLink = ({ moreDateShown, nbSessions, status, onClick }) => {
  const handleClick = e => {
    // Do not trigger click on the parent bloc (availability for example)
    e.stopPropagation();
    onClick();
  };

  return (
    <>
      <Link onClick={handleClick} as="span">
        {moreDateShown && <Trans>Hide details</Trans>}
        {!moreDateShown && <Trans>Display details - 
            {nbSessions} <Plural value={nbSessions} one="session" other="sessions" />
        </Trans>}

        {moreDateShown && status === REQUEST_STATUS.LOADING && <Spinner ml=".5rem" size="xs" />}
      </Link>

      {moreDateShown && status === REQUEST_STATUS.ERROR && (
        <Text fontSize="10px" fontWeight="300" color="red">
          <Trans>Cannot retrieve session dates, please try again later.</Trans>
        </Text>
      )}
    </>
  );
};

export default AvailabilityMoreDatesLink;
