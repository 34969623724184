import { useState } from 'react';

import { Box } from '@chakra-ui/react';
import { t } from '@lingui/macro';

import useIsMobile from '../../hooks/useIsMobile';
import useWriteSeo from '../../hooks/useWriteSeo';
import { RESPONSIVE_WIDTH_BOX_HALF } from '../../utils/styleUtil';
import AvailabilitiesOrVoucherMode from '../business/AvailabilitiesOrVoucherMode';
import ProductDetail from '../business/product/detail/ProductDetail';
import FlexRow from '../generic/layout/FlexRow';
// ==============================================================================================
// Page infos :
// This is the 2nd page we see in the booking engine, after selecting a product we get the availabilities
// This page CAN be accessed directly from an url.
// Route infos :
// - standard navigation :
//   -> mandatory path param which is the productId eg: /product/productId
// - direct navigation : same
// ==============================================================================================

const AvailabilitiesSelectionPage = () => {
  useWriteSeo({ title: t`Product detail and availabilities` });

  const isMobile = useIsMobile();
  const [displayAvaOnMobile, setDisplayAvaOnMobile] = useState(false);

  if (isMobile) {
    return (
      <FlexRow>
        <Box {...RESPONSIVE_WIDTH_BOX_HALF}>
          {!displayAvaOnMobile && <ProductDetail setDisplayAvaOnMobile={setDisplayAvaOnMobile} />}
          {displayAvaOnMobile && <AvailabilitiesOrVoucherMode />}
        </Box>
      </FlexRow>
    );
  }

  return (
    <FlexRow>
      <Box {...RESPONSIVE_WIDTH_BOX_HALF}>
        <ProductDetail />
      </Box>
      <Box {...RESPONSIVE_WIDTH_BOX_HALF}>
        <AvailabilitiesOrVoucherMode />
      </Box>
    </FlexRow>
  );
};

export default AvailabilitiesSelectionPage;
