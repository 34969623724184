import { Flex, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useReadCanBuyVoucher from '../../../../hooks/useReadCanBuyVoucher';
import useReadTeam from '../../../../hooks/useReadTeam';
import useWriteFirstAvailabilityDate from '../../../../hooks/useWriteFirstAvailabilityDate';
import useWritePreCart from '../../../../hooks/useWritePreCart';
import { COMMON_PADDING } from '../../../../utils/styleUtil';
import TeamMail from '../../../generic/TeamMail';
import TeamPhone from '../../../generic/TeamPhone';
import ProductDetailBuyVoucherAction from '../../product/detail/ProductDetailBuyVoucherAction';

const AvailabilitiesFilteredListEmpty = () => {
  useWriteFirstAvailabilityDate(null);

  const { teamMail, teamPhone } = useReadTeam();

  const { preCartData } = useWritePreCart();
  const { product } = preCartData;

  const canBuyVoucher = useReadCanBuyVoucher(product);

  if (!product) return <></>;

  if (product.sell_online === 0 && canBuyVoucher) {
    return (
      <Flex {...CONTAINER_PROPS}>
        <Text {...TEXT_PROPS}>
          {product.voucher_name && <Trans>This product is only available via {product.voucher_name}.</Trans>}
          {!product.voucher_name && <Trans>This product is only available as a voucher.</Trans>}
        </Text>
        <ProductDetailBuyVoucherAction product={product} />
      </Flex>
    );
  }

  return (
    <Flex {...CONTAINER_PROPS}>
      <Text {...TEXT_PROPS}>
        {product.be_nosession_msg}
        {!product.be_nosession_msg && <Trans>No session available, please contact us.</Trans>}
      </Text>

      <TeamPhone teamPhone={teamPhone} />
      <TeamMail teamMail={teamMail} />
    </Flex>
  );
};

export default AvailabilitiesFilteredListEmpty;

const TEXT_PROPS = {
  mb: '.5rem',
  fontStyle: 'italic',
};
const CONTAINER_PROPS = {
  p: COMMON_PADDING,
  flexDirection: 'column',
  fontSize: 'sm',
  alignItems: 'center',
};
