import { i18n } from '@lingui/core';
import dpde from 'date-fns/locale/de';
import dpes from 'date-fns/locale/es';
import dpfr from 'date-fns/locale/fr';
import dpit from 'date-fns/locale/it';
import dpnl from 'date-fns/locale/nl';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import { LANG_FALLBACK, LANG_SUPPORTED, LOCAL_STORAGE_APPLANG, LOCAL_STORAGE_FORCEDLANG } from '../constants';
import { messages as deMessages } from '../locales/de/messages';
import { messages as enMessages } from '../locales/en/messages';
import { messages as esMessages } from '../locales/es/messages';
import { messages as frMessages } from '../locales/fr/messages';
import { messages as itMessages } from '../locales/it/messages';
import { messages as nlMessages } from '../locales/nl/messages';
import { devMessage } from '../settings/settings';

// This returns an array of 2 values (if possible else 1) => [mainLocale, secondaryLocale] eg: ["fr","ca"]
// Our locale/lang lib (linguiJS) doesnt understand 'FR' or 'fr-CA' so it will use the mainLocale which would be 'fr' in our example
// Our phone field or other localized fields will use the secondaryLocale to have a canadian phone prefix in our example
export const getBrowserLocales = () => {
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  const formattedLocale = userLocale.toLowerCase();
  if (formattedLocale.includes('-')) {
    return formattedLocale.split('-');
  }
  return [formattedLocale];
};

const getSupportedTranslationLang = browserMainLocale => (LANG_SUPPORTED.includes(browserMainLocale) ? browserMainLocale : LANG_FALLBACK);

// THIS is responsible for the interface LANG !
// HOW DOES IT WORK:
// ---- IF user has selected lang (from the footer dropdown) ==> OK (The user selected locale is common to all BEs!)
// ---- ELSE IF the BE has a forced lang (from the BE config) => OK
// ---- ELSE we use the browser locale
// FINALLY on that lang, we use it if we support it, IF NOT then we fallback to LANG_FALLBACK
export const getLangForTranslations = doLog => {
  const userSelectedLang = localStorage.getItem(LOCAL_STORAGE_APPLANG);
  const beForcedLang = localStorage.getItem(LOCAL_STORAGE_FORCEDLANG);
  const automaticLang = getBrowserLocales()[0];

  if (doLog) {
    devMessage('userLang=', userSelectedLang, 'forcedLang=', beForcedLang, 'autoLang=', automaticLang);
  }

  if (userSelectedLang != null) return getSupportedTranslationLang(userSelectedLang);
  if (beForcedLang != null && beForcedLang != '') return getSupportedTranslationLang(beForcedLang);
  return getSupportedTranslationLang(automaticLang);
};

export const initTranslations = bookingEngineForcedLang => {
  // we set/replace the forced lang (even if null)
  localStorage.setItem(LOCAL_STORAGE_FORCEDLANG, bookingEngineForcedLang ?? '');

  const locale = getLangForTranslations(true);
  devMessage('Lang: will actually use supported lang:', locale);

  // Load and activate the lingui translations
  i18n.load({
    de: deMessages,
    en: enMessages,
    es: esMessages,
    fr: frMessages,
    it: itMessages,
    nl: nlMessages,
  });
  i18n.activate(locale);

  // Same for the datepicker locale management
  registerLocale('de', dpde);
  registerLocale('es', dpes);
  registerLocale('fr', dpfr);
  registerLocale('it', dpit);
  registerLocale('nl', dpnl);
  setDefaultLocale(locale);
};
