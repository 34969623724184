import { Text } from '@chakra-ui/react';
import { MdEmail } from 'react-icons/md';

import IconWithText from './IconWithText';
import { TEAM_CONTACT_ICONS_PROPS } from './TeamPhone';

const TeamMail = ({ teamMail }) => {
  return (
    <>
      {teamMail && (
        <IconWithText icon={MdEmail} iconProps={TEAM_CONTACT_ICONS_PROPS}>
          <Text>{teamMail}</Text>
        </IconWithText>
      )}
    </>
  );
};

export default TeamMail;
