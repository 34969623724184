import { HStack } from '@chakra-ui/react';

import { COMMON_PADDING } from '../../../../utils/styleUtil';
import OptionPrice from '../options/OptionPrice';
import VariantLinesContainer from '../VariantLinesContainer';
import VariantLinePerSession from './VariantLinePerSession';

const VariantsListPerSession = ({
  defaultVariant,
  variants,
  isFree,
  onVariantAdd,
  onVariantDelete,
  onOptionUpdate,
  loggedUser,
  placesAvailable,
  placesMax,
  totalParticipants,
}) => {
  return (
    <>
      {!isFree && (
        <HStack bg="brand.background_main" px={COMMON_PADDING} pb={COMMON_PADDING} gap="2rem">
          {/* In the "per session" mode, the price is common for all variants
         So for the price we only care about the first one (that cannot be deleted) */}
          <OptionPrice variant={variants[0]} totalParticipants={totalParticipants} index={0} onOptionUpdate={onOptionUpdate} />
        </HStack>
      )}

      <VariantLinesContainer
        canAddVariant={defaultVariant.canAddVariant}
        onVariantAdd={onVariantAdd}
        placesAvailable={placesAvailable}
        placesMax={placesMax}
      >
        {variants.map((variant, index) => (
          <VariantLinePerSession
            key={index}
            variant={variant}
            onVariantDelete={onVariantDelete}
            index={index}
            onOptionUpdate={onOptionUpdate}
            loggedUser={loggedUser}
            allDefaultOptions={defaultVariant.custom_options}
            placesAvailable={placesAvailable}
            placesMax={placesMax}
          />
        ))}
      </VariantLinesContainer>
    </>
  );
};

export default VariantsListPerSession;

export const getTotalParticipants = variants => {
  let participants = 0;
  for (const variant of variants) {
    // the field was emptied by the user so we dont want a NaN result
    if (variant.participant == '') continue;

    participants += parseInt(variant.participant);
  }
  return participants;
};
