import { findArrayItemById } from '../utils/arrayUtil';
import { getCartItemPrice } from '../utils/business/prices';

const useReadCartItemSingleResource = (cartItem, cartResources, isTaxIncluded) => {
  const elementPrice = getCartItemPrice(cartItem, isTaxIncluded);
  const elementPriceWithoutPromo = cartItem.price_without_promo ? parseFloat(cartItem.price_without_promo) : elementPrice;
  const resource = findArrayItemById(cartItem.resource_id, cartResources);

  return { resource, elementPrice, elementPriceWithoutPromo };
};

export default useReadCartItemSingleResource;
