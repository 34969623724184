import { t } from '@lingui/macro';
import { useUpdateEffect } from 'usehooks-ts';

import useCleanStorage from './useCleanStorage';

const useReadCartMissingOrder = data => {
  const { cleanOrderGotoCart } = useCleanStorage(false);

  useUpdateEffect(() => {
    if (!data || data.success !== false || data.unavailable_items) return;

    const defaultErrorMsg = t`This order has been handled already.`;
    cleanOrderGotoCart(data.error ?? defaultErrorMsg);
  }, [data]);
};

export default useReadCartMissingOrder;
