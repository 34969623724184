import { useContext } from 'react';

import { Alert, AlertIcon, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { CoreContext } from '../../../context/CoreContext';
import useCalcDeposit from '../../../hooks/useCalcDeposit';
import useFetchCheckoutClient from '../../../hooks/useFetchCheckoutClient';
import useFormCheckout from '../../../hooks/useFormCheckout';
import useReadManufacturer from '../../../hooks/useReadManufacturer';
import { NO_PAYMENT_METHOD, ONLINE_PAYMENT_METHOD, PAYMENT_LINK_METHOD, PAYMENT_TERMINAL_METHOD } from '../../../hooks/useReadPaymentMethods';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import { COMMON_GAP, ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import BorderLeftBox from '../../generic/BorderLeftBox';
import ButtonSubmit from '../../generic/button/ButtonSubmit';
import AlertError from '../../generic/error/AlertError';
import LabelWithText from '../../generic/LabelWithText';
import TeamMail from '../../generic/TeamMail';
import TeamPhone from '../../generic/TeamPhone';
import CheckoutBusinessProvSelect, { BUSINESS_PROV_SELECTOR_FIELDNAME } from './CheckoutBusinessProvSelect';
import CheckoutClientSelectBloc from './CheckoutClientSelectBloc';
import { DEPOSIT_FIELDNAME } from './CheckoutDeposit';
import CheckoutDeposit from './CheckoutDeposit';
import CheckoutFieldsAddress from './CheckoutFieldsAddress';
import CheckoutFieldsCustomer from './CheckoutFieldsCustomer';
import CheckoutGeneralConditions from './CheckoutGeneralConditions';
import CheckoutMailToggle, { SEND_BOOKING_MAIL_FIELDNAME } from './CheckoutMailToggle';
import CheckoutOptIn from './CheckoutOptIn';
import CheckoutPaymentLinkTemplate, { PAYMENTLINK_TEMPLATE_FIELDNAME } from './CheckoutPaymentLinkTemplate';
import CheckoutPaymentMethod, { PAYMENT_METHOD_FIELDNAME } from './CheckoutPaymentMethod';

const CheckoutForm = ({ cartMainClientId, totalPrice, unavailableItems, setUnavailableItems }) => {
  const { coreData } = useContext(CoreContext);
  const {
    team,
    billing_address_required: billingAddressRequired,
    pre_payment_message: prePaymentMessage,
    business_provider: forcedBusinessProvider,
  } = coreData.booking_engine;
  const loggedUser = coreData.user;

  const { willSearchClients: searchClients, willSearchStaffs: searchStaffs } = useReadManufacturer();

  const { clientSelectorChoices, onClientSelectorSearchChange, isClientSelectorLoading } = useFetchCheckoutClient(searchClients, false, searchStaffs);

  const depositAmount = useCalcDeposit(loggedUser, totalPrice.tax_included);

  const { handleSubmit, paymentData, dataProps, isSubmitting, status, data, paymentMethods, previousOrderDetails } = useFormCheckout(
    cartMainClientId,
    setUnavailableItems,
    clientSelectorChoices,
    searchStaffs,
    team.currency,
    totalPrice
  );

  const isPaymentLink = paymentData[PAYMENT_METHOD_FIELDNAME] === PAYMENT_LINK_METHOD;
  const isPaymentForm = paymentData[PAYMENT_METHOD_FIELDNAME] === ONLINE_PAYMENT_METHOD;
  const isPaymentNone = paymentData[PAYMENT_METHOD_FIELDNAME] === NO_PAYMENT_METHOD;
  const isPaymentTerminal = paymentData[PAYMENT_METHOD_FIELDNAME] === PAYMENT_TERMINAL_METHOD;
  const canShowPayBtn = () => status !== REQUEST_STATUS.ERROR && (!data || data.success === true);
  const canShowMailToggle = () => loggedUser && !isPaymentLink && !isPaymentForm && !isPaymentTerminal;

  const isTotalAboveZero = totalPrice && parseFloat(totalPrice.tax_included) > 0;

  return (
    <form method="POST" onSubmit={handleSubmit}>
      {loggedUser && (
        <CheckoutClientSelectBloc
          paymentData={paymentData}
          dataProps={dataProps}
          clientSelectorChoices={clientSelectorChoices}
          onClientSelectorSearchChange={onClientSelectorSearchChange}
          isClientSelectorLoading={isClientSelectorLoading}
          previousOrderDetails={previousOrderDetails}
        />
      )}

      <VStack spacing="10px">
        <CheckoutFieldsCustomer paymentData={paymentData} loggedUser={loggedUser} {...dataProps} />
        {billingAddressRequired !== -1 && (
          <CheckoutFieldsAddress paymentData={paymentData} {...dataProps} isRequired={billingAddressRequired === 1} />
        )}

        {loggedUser && <CheckoutPaymentMethod paymentMethods={paymentMethods} fieldValue={paymentData[PAYMENT_METHOD_FIELDNAME]} {...dataProps} />}
        {isPaymentLink && <CheckoutPaymentLinkTemplate fieldValue={paymentData[PAYMENTLINK_TEMPLATE_FIELDNAME]} {...dataProps} />}

        {prePaymentMessage && (
          <>
            <Divider orientation="horizontal" />
            <BorderLeftBox w="full">
              <LabelWithText label={<Trans>Note from the seller</Trans>} text={prePaymentMessage} />
            </BorderLeftBox>
            <Divider orientation="horizontal" />
          </>
        )}

        {!loggedUser && <CheckoutGeneralConditions teamName={team.name} teamCgv={team.cgv} {...dataProps} />}
        {!loggedUser && <CheckoutOptIn {...dataProps} />}

        {loggedUser && !forcedBusinessProvider && (
          <CheckoutBusinessProvSelect fieldValue={paymentData[BUSINESS_PROV_SELECTOR_FIELDNAME]} {...dataProps} />
        )}

        {canShowMailToggle() && <CheckoutMailToggle fieldValue={paymentData[SEND_BOOKING_MAIL_FIELDNAME]} {...dataProps} />}

        {!loggedUser && depositAmount != null && (
          <CheckoutDeposit deposit={paymentData[DEPOSIT_FIELDNAME]} depositAmount={depositAmount} {...dataProps} />
        )}

        {canShowPayBtn() && (
          <ButtonSubmit w="100%" isLoading={isSubmitting} isDisabled={paymentMethods.length <= 0}>
            {!loggedUser && isTotalAboveZero && <Trans>Pay</Trans>}
            {!loggedUser && !isTotalAboveZero && <Trans>Create</Trans>}

            {loggedUser && isPaymentLink && <Trans>Send</Trans>}
            {loggedUser && isPaymentNone && <Trans>Create</Trans>}
            {loggedUser && !isPaymentLink && !isPaymentNone && <Trans>Pay</Trans>}
          </ButtonSubmit>
        )}

        {status === REQUEST_STATUS.ERROR && <AlertError fontSize="sm" message={data ? data.error : undefined} />}
        {unavailableItems && (
          <Alert status="error" {...ROUNDED_ELEMENT_PROPS}>
            <Flex flexDir="column" gap={COMMON_GAP} alignSelf="flex-start" fontSize="xs">
              <HStack>
                <AlertIcon />
                <Text fontSize="sm">
                  <Trans>Some elements of your cart are not available. Please check your cart or contact us.</Trans>
                </Text>
              </HStack>

              <TeamPhone teamPhone={team.phone_number} />
              <TeamMail teamMail={team.email} />
            </Flex>
          </Alert>
        )}
        {paymentMethods.length <= 0 && (
          <AlertError fontSize="sm" message={<Trans>Online selling is not setup, please contact an administrator.</Trans>} />
        )}
      </VStack>
    </form>
  );
};

export default CheckoutForm;
