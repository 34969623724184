import { useState } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { apiUseCode } from '../constants';
import usePost from './usePost';

const usePostPromoCode = (teamId, storedCartId) => {
  const [postData, setPostData] = useState(null);
  const { data, status, executePost } = usePost(apiUseCode(), postData);

  const writePromoCode = promoCode => {
    if (postData && postData.code === promoCode) return false;

    const formattedData = {
      order_id: storedCartId,
      team_id: teamId,
      code: promoCode,
    };
    setPostData(formattedData);
    return true;
  };

  useUpdateEffect(() => {
    executePost();
  }, [JSON.stringify(postData)]);

  return { data, status, writePromoCode };
};

export default usePostPromoCode;
