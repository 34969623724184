import { useRef, useState } from 'react';

import { Button, Drawer, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Icon, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import cloneDeep from 'lodash.clonedeep';
import { FaPaintBrush } from 'react-icons/fa';
import { useUpdateEffect } from 'usehooks-ts';

import DevThemeButtons from '../components/generic/devtheme/DevThemeButtons';
import DevThemeColorList from '../components/generic/devtheme/DevThemeColorList';
import { apiUpdateSettings, COLOR_FALLBACK, LOCAL_STORAGE_DEVTHEME, LOCAL_STORAGE_DEVTHEME_DEFVAL } from '../constants';
import usePostOutsideRouter from './usePostOutsideRouter';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const useDevelopmentTheme = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clicked, setClicked] = useState(false);
  const btnRef = useRef();

  const [devColors, setDevColors] = useWriteStorageBookingEngine(LOCAL_STORAGE_DEVTHEME, LOCAL_STORAGE_DEVTHEME_DEFVAL);
  const currentThemeColors = useRef(null);

  const [formattedData, setFormattedData] = useState(null);
  const { status, executePost } = usePostOutsideRouter(apiUpdateSettings(), formattedData);

  const canShowDevThemeComponent = (isAllowed, _currentThemeColors) => {
    if (!isAllowed) return false;

    // Cannot change state here otherwise it will infinite loop the App rendering
    currentThemeColors.current = _currentThemeColors;
    return true;
  };

  const getDevThemeColors = (isGod, _currentThemeColors) => {
    if (!canShowDevThemeComponent(isGod, _currentThemeColors)) return;
    return devColors ?? _currentThemeColors;
  };

  const onColorUpdate = (key, value) => {
    const copyDevColors = cloneDeep(devColors || currentThemeColors.current);
    copyDevColors[key] = value;
    setDevColors(copyDevColors);
  };

  const onSave = () => {
    setClicked(true);
    setFormattedData({
      ui_settings: {
        colors: devColors,
      },
    });
  };

  const onHardReset = () => {
    setClicked(true);
    setFormattedData({
      ui_settings: {
        colors: COLOR_FALLBACK,
      },
    });
    setDevColors(LOCAL_STORAGE_DEVTHEME_DEFVAL);
  };

  useUpdateEffect(() => {
    if (!formattedData) return;

    executePost();
  }, [formattedData]);

  useUpdateEffect(() => {
    location.reload();
  }, [status]);

  const onApply = () => {
    setClicked(true);
    location.reload();
  };

  const onReset = () => {
    setClicked(true);
    setDevColors(LOCAL_STORAGE_DEVTHEME_DEFVAL);
    location.reload();
  };

  const getDevThemeComponent = (isGod, currentThemeColors) => {
    if (!canShowDevThemeComponent(isGod, currentThemeColors)) return <></>;

    const colorsToUse = devColors ?? currentThemeColors;
    return (
      <>
        <Button
          ref={btnRef}
          onClick={onOpen}
          position="fixed"
          bottom=".4em"
          left=".4em"
          className="chakra-portal"
          zIndex="1"
          borderRadius="full"
          boxSize="38px"
        >
          <Icon as={FaPaintBrush} boxSize="22px" />
        </Button>

        <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Trans>Theme customization</Trans>
            </DrawerHeader>

            <DevThemeColorList colorsToUse={colorsToUse} onColorUpdate={onColorUpdate} />
            <DevThemeButtons clicked={clicked} onSave={onSave} onApply={onApply} onReset={onReset} onHardReset={onHardReset} />
          </DrawerContent>
        </Drawer>
      </>
    );
  };

  return { getDevThemeColors, getDevThemeComponent };
};

export default useDevelopmentTheme;
