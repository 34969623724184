import { Trans } from '@lingui/macro';

import useReadBuyVoucherMode from '../../hooks/useReadBuyVoucherMode';
import useWritePreCart from '../../hooks/useWritePreCart';
import AvailabilitiesFilteredList from '../business/availability/list/AvailabilitiesFilteredList';
import BuyVoucherOnlyMode from '../business/buyvoucher/BuyVoucherOnlyMode';
import AlertError from '../generic/error/AlertError';

const AvailabilitiesOrVoucherMode = () => {
  const { preCartData } = useWritePreCart();
  const { product } = preCartData;

  const { canBuyVoucher, isModeBcOnly } = useReadBuyVoucherMode(product);

  if (!product) return <></>;
  return (
    <>
      {!isModeBcOnly && <AvailabilitiesFilteredList />}
      {isModeBcOnly && canBuyVoucher && <BuyVoucherOnlyMode product={product} />}
      {isModeBcOnly && !canBuyVoucher && <AlertError message={<Trans>This product is currently unavailable.</Trans>} />}
    </>
  );
};

export default AvailabilitiesOrVoucherMode;
