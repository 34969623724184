import { Box, Divider, Flex, HStack, Icon, Spacer, Text, VStack } from '@chakra-ui/react';
import { FiBox } from 'react-icons/fi';

import useIsMobile from '../../../../hooks/useIsMobile';
import useReadCartItemSingleResource from '../../../../hooks/useReadCartItemSingleResource';
import { BTN_ICON_BOXSIZE, ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import CartElementDeleteAction from '../CartElementDeleteAction';
import CartElementPrice from '../CartElementPrice';
import CartProductImage from '../CartProductImage';

const CartElementSingleResource = ({ cartItem, cartData, isTaxIncluded, blockActions }) => {
  const isMobile = useIsMobile();
  const StackElement = isMobile ? VStack : HStack;

  const { cartOrderId, resources } = cartData;
  const { resource, elementPrice, elementPriceWithoutPromo } = useReadCartItemSingleResource(cartItem, resources, isTaxIncluded);

  const cartElementPriceProps = {
    elementPrice: elementPrice,
    elementPriceWithoutPromo: elementPriceWithoutPromo,
  };

  return (
    <Box bg="brand.background_main" {...ROUNDED_ELEMENT_PROPS} position="relative">
      <StackElement spacing="24px">
        <CartProductImage productImage={resource.description_image} />

        <Flex gap=".5rem" minH={{ base: undefined, md: '30px' }}>
          <Icon as={FiBox} boxSize={BTN_ICON_BOXSIZE} color="brand.color_main" />
          <Text fontSize="md" wordBreak="break-word">
            {resource.label} (x{cartItem.quantity})
          </Text>
        </Flex>
      </StackElement>

      {!isMobile && (
        <>
          <CartElementPrice {...cartElementPriceProps} position="absolute" bottom="4px" right=".6rem" />
          <CartElementDeleteAction cartOrderId={cartOrderId} getItemIdsToDelete={() => [cartItem.id]} blockActions={blockActions} />
        </>
      )}

      {isMobile && (
        <>
          <Divider orientation="horizontal" mt="1rem" />
          <HStack alignItems="flex-end">
            <CartElementDeleteAction
              cartOrderId={cartOrderId}
              getItemIdsToDelete={() => [cartItem.id]}
              position="relative"
              alignItems="flex-end"
              blockActions={blockActions}
            />
            <Spacer />
            <CartElementPrice {...cartElementPriceProps} />
          </HStack>
        </>
      )}
    </Box>
  );
};

export default CartElementSingleResource;
