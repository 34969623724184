import { Text } from '@chakra-ui/react';

const CheckoutDividerTitle = ({ isVisible, ...props }) => {
  if (!isVisible) return <></>;
  return (
    <Text w="full" textAlign="center" fontSize="xs" fontWeight="200" textTransform="uppercase" mb="1em">
      {props.children}
    </Text>
  );
};

export default CheckoutDividerTitle;
