import { useContext } from 'react';

import { CoreContext } from '../context/CoreContext';

const useReadLoggedUser = () => {
  const { coreData } = useContext(CoreContext);
  return coreData.user;
};

export default useReadLoggedUser;
