import { t } from '@lingui/macro';

const useReadProductTeams = productTeams => {
  const all = t({
    id: 'ALL_MASCULINE',
    message: 'All',
  });

  const choices = [{ label: all, value: '' }];
  if (!productTeams) {
    return choices;
  }

  const teamIds = Object.keys(productTeams);
  teamIds.forEach(teamId => choices.push({ label: productTeams[teamId], value: teamId }));
  return choices;
};

export default useReadProductTeams;
