import { useContext } from 'react';

import { CoreContext } from '../context/CoreContext';

const useReadDeposit = () => {
  const { coreData } = useContext(CoreContext);

  // If you want to easily override to see the behavior on P6 : comment/uncomment below
  const { deposit: deposit, deposit_percentage: isDepositPercentage } = coreData.booking_engine;
  // const deposit = 30;
  // const isDepositPercentage = true;

  const depositValue = deposit != null ? parseFloat(deposit) : null;
  const hasDeposit = depositValue != null;

  return { hasDeposit, depositValue, isDepositPercentage };
};

export default useReadDeposit;
