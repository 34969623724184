import { useContext } from 'react';

import { CoreContext } from '../context/CoreContext';

const useReadTeam = () => {
  const { coreData } = useContext(CoreContext);
  const {
    id: teamId,
    logo: teamLogo,
    tax_included: isTaxIncluded,
    email: teamMail,
    phone_number: teamPhone,
    vendor_id: vendorId,
    country: teamCountry,
    currency: teamCurrency,
  } = coreData.booking_engine.team;

  const hasVendor = vendorId != null;
  return { teamId, teamLogo, isTaxIncluded, teamMail, teamPhone, hasVendor, teamCountry, teamCurrency };
};

export default useReadTeam;
