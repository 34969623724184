import { useMemo } from 'react';

import { t } from '@lingui/macro';

import useIsMobile from '../../../../hooks/useIsMobile';
import InputText, { getDynamicInputMaxWidth } from '../../../generic/form/InputText';
import InputTextArea from '../../../generic/form/InputTextArea';
import Select from '../../../generic/form/Select';

const SpecificDataCustom = ({ data, fieldValue, onParticipantDataUpdate }) => {
  const isMobile = useIsMobile();

  // label is internal, name is public
  const { id, data_type: dataType, label, name, required, choices } = data;
  const fieldChoices = useMemo(() => getFieldChoices(choices), [data]);

  const fieldProps = {
    fieldName: id,
    isRequired: required === 1,
    maxWidth: { base: '', md: getDynamicInputMaxWidth(data.name, isMobile) },
    fieldLabel: name,
    fieldPlaceholder: name,
    fieldValue: fieldValue,
    onChange: (fName, fieldValue, selectLabel) => onParticipantDataUpdate(id, label, fieldValue, selectLabel),
  };

  return (
    <>
      {(dataType === 'number' || dataType === 'text') && <InputText fieldType={dataType} {...fieldProps} />}
      {dataType === 'textarea' && <InputTextArea {...fieldProps} maxWidth={undefined} />}
      {dataType === 'select' && <Select fieldChoices={fieldChoices} {...fieldProps} />}
    </>
  );
};

export default SpecificDataCustom;

const getFieldChoices = choices => {
  const fieldChoices = [{ label: t`None`, value: '' }];
  for (const choice of choices) {
    fieldChoices.push({ label: choice.name, value: choice.id });
  }
  return fieldChoices;
};
