import { useRef } from 'react';

import { FormControl } from '@chakra-ui/react';
import { defineMessage, t } from '@lingui/macro';
import PhoneInput from 'react-phone-input-2';

import useReadTeam from '../../../hooks/useReadTeam';
import { getPhonePreferredCountries, getValidE164Number } from '../../../utils/phoneUtil';
import { COMMON_INPUT_HEIGHT } from '../../../utils/styleUtil';
import InputFormLabel from './InputFormLabel';

export const PHONE_FIELDNAME = 'phone_number';
export const PHONE_COUNTRYCODE_FIELDNAME = 'phone_country_code';

const InputPhone = ({ fieldValue, onChange, isRequired, minWidth, maxWidth, displayLabel = true }) => {
  const { teamCountry } = useReadTeam();

  // When the user first loads the page the input is empty
  // If it's required then technically it is INVALID (bc empty)
  // But since it's a first load and there's no interaction yet we don't want to display the red border
  const userHasInteracted = useRef(false);

  // Before we wanted to have the phone country from the locale, uncomment this if you want this back
  // const defaultCountry = useMemo(() => getPhoneCountryFromLocale(), []);
  const preferredCountries = getPhonePreferredCountries();

  // On change we also send the country code so that later..
  // ..before sending to backend we'll get the valid e164 formatted number
  const handleOnChange = (phone, data, event, formattedValue) => {
    onChange(PHONE_FIELDNAME, phone, data.countryCode);
  };

  const getInputClass = () => {
    let className = 'chakra-input ';
    if (!isRequired) return className;

    className += 'phone-required ';
    if (!fieldValue && !userHasInteracted.current) {
      className += 'invalid-number-hidden ';
    }
    return className;
  };

  const handleInputValidation = (inputValue, countryData) => {
    const emptyPhoneOrOnlyCountryCode = !inputValue || inputValue === countryData.dialCode;

    // If required and: [the phone is empty - OR - there's only the countryDialCode] ==> INVALID
    if (isRequired && emptyPhoneOrOnlyCountryCode) return false;

    // If the inputValue can be formatted to a valid number ==> VALID
    const formattedNumber = getValidE164Number('+' + inputValue, countryData.iso2);
    if (formattedNumber != null) return true;

    // If [the phone is empty - OR - there's only the countryDialCode] then it wasn't required ==> VALID (we'll adapt that before sending to backend)
    if (emptyPhoneOrOnlyCountryCode) return true;

    // Finally this means we have a user value and it is invalid !
    return t`Invalid number`;
  };

  return (
    <FormControl isRequired={isRequired} minW={minWidth} maxW={maxWidth}>
      {displayLabel && <InputFormLabel fieldName={PHONE_FIELDNAME} fieldLabel={defineMessage({ message: 'Phone number' })} />}

      <PhoneInput
        country={teamCountry.toLowerCase()}
        preferredCountries={preferredCountries}
        preserveOrder={['preferredCountries']}
        enableSearch
        value={fieldValue}
        onChange={handleOnChange}
        inputProps={{
          name: PHONE_FIELDNAME,
          required: isRequired,
        }}
        onBlur={() => {
          userHasInteracted.current = true;
        }}
        // Translations
        placeholder={t`+XX...`}
        searchPlaceholder={t`Search`}
        searchNotFound={t`No entries to show`}
        // Styling
        inputClass={getInputClass()}
        containerStyle={{
          fontSize: 'var(--chakra-fontSizes-sm)',
          border: '1px solid',
          borderColor: 'var(--chakra-colors-brand-color_main)',
          borderRadius: 'var(--chakra-radii-md)',
          WebkitPaddingEnd: 'var(--chakra-space-4)',
          paddingInlineEnd: 'var(--chakra-space-4)',
          height: COMMON_INPUT_HEIGHT,
        }}
        inputStyle={{
          width: 'inherit',
          border: 'unset',
          background: 'transparent',
          height: '32px',
          borderRadius: 'var(--chakra-radii-md)',
        }}
        buttonStyle={{
          border: 'unset',
          borderRight: '1px solid #cacaca',
        }}
        // Configuration to make the field and its indicator the way we want to
        countryCodeEditable
        // disableCountryCode
        disableCountryGuess
        autoFormat={false}
        isValid={handleInputValidation}
      />
    </FormControl>
  );
};

export default InputPhone;
