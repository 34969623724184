import { HStack } from '@chakra-ui/react';
import { defineMessage } from '@lingui/macro';

import useReadManufacturer from '../../../hooks/useReadManufacturer';
import useReadParticipantsLangChoices from '../../../hooks/useReadParticipantsLangChoices';
import InputEmail, { EMAIL_FIELDNAME } from '../../generic/form/InputEmail';
import InputPhone, { PHONE_FIELDNAME } from '../../generic/form/InputPhone';
import InputText from '../../generic/form/InputText';
import Select from '../../generic/form/Select';
import { CLIENT_SELECTOR_FIELDNAME } from './CheckoutClientSelect';

export const FIRST_NAME_FIELDNAME = 'first_name';
export const LAST_NAME_FIELDNAME = 'last_name';
export const LANGUAGE_FIELDNAME = 'language';

const CheckoutFieldsCustomer = ({ paymentData, loggedUser, ...props }) => {
  const langChoices = useReadParticipantsLangChoices();

  const { canDisplaySelector, willSearchStaffs } = useReadManufacturer();
  // We disable some fields if:
  // - a client has been selected (because it's autofilled)
  // - we're selecting a staff (so there's no manual input, no creation) [also it means we are in manufacturer (admin) mode]
  // - we cannot display selectors (because the logged user will be used) [also it means we are in manufacturer (non admin) mode]
  const disableFields = paymentData[CLIENT_SELECTOR_FIELDNAME].value !== null || !canDisplaySelector || willSearchStaffs;
  const isMailFieldDisabled = disableFields && paymentData[CLIENT_SELECTOR_FIELDNAME].hasMailAddress;

  return (
    <>
      <HStack>
        <InputText
          fieldName={FIRST_NAME_FIELDNAME}
          fieldLabel={firstnameLabel}
          fieldPlaceholder="John"
          fieldValue={paymentData[FIRST_NAME_FIELDNAME]}
          isDisabled={disableFields}
          {...props}
        />
        <InputText
          fieldName={LAST_NAME_FIELDNAME}
          fieldLabel={lastnameLabel}
          fieldPlaceholder="Doe"
          fieldValue={paymentData[LAST_NAME_FIELDNAME]}
          isDisabled={disableFields}
          {...props}
        />
      </HStack>
      <InputEmail fieldValue={paymentData[EMAIL_FIELDNAME]} isDisabled={isMailFieldDisabled} {...props} />
      <InputPhone fieldValue={paymentData[PHONE_FIELDNAME]} {...props} isRequired={loggedUser == null} />

      <Select
        fieldName={LANGUAGE_FIELDNAME}
        selectFirstChoice={false}
        fieldValue={paymentData[LANGUAGE_FIELDNAME]}
        fieldLabel={langLabel}
        fieldChoices={langChoices}
        {...props}
        isRequired={false}
      />
    </>
  );
};

export default CheckoutFieldsCustomer;

const firstnameLabel = defineMessage({ message: 'First name' });
const lastnameLabel = defineMessage({ message: 'Last name' });
const langLabel = defineMessage({ message: 'Language' });
