import { t } from '@lingui/macro';

import useWriteSeo from '../../hooks/useWriteSeo';
import Cart from '../business/cart/Cart';

// ==============================================================================================
// Page infos :
// This page CAN be accessed directly from an url.
// Route infos :
// - standard navigation : no param
// - direct navigation :
//   -> mandatory path param which is the orderId eg: /cart/orderId
// ==============================================================================================

const CartPage = () => {
  useWriteSeo({ title: t`My cart` });

  return <Cart />;
};

export default CartPage;
