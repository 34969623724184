import { Box } from '@chakra-ui/layout';
import { t, Trans } from '@lingui/macro';

import useCalcSessionVariantsTotal from '../../../hooks/useCalcSessionVariantsTotal';
import useIsMobile from '../../../hooks/useIsMobile';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, RESPONSIVE_WIDTH_BOX_RIGHT, TOP_ROUNDED_CONTAINER_PROPS } from '../../../utils/styleUtil';
import AlertError from '../../generic/error/AlertError';
import Image from '../../generic/Image';
import ContainerTotal from '../../generic/layout/ContainerTotal';
import SessionVariantsActions from './SessionVariantsActions';

const SessionVariantsTotal = ({
  availabilities,
  product,
  requestedAction,
  statusAddCart,
  statusLoadVariants,
  invalidResources,
  duplicateParticipants,
  itemsToDeleteForEdit,
  setRequestedAction,
  canBookMore,
}) => {
  const isMobile = useIsMobile();

  const { price_per_participant: isPricePerPerson } = product;

  const total = useCalcSessionVariantsTotal(isPricePerPerson, availabilities);

  return (
    <Box
      h="100%"
      bg="brand.background_main"
      {...RESPONSIVE_WIDTH_BOX_RIGHT}
      {...TOP_ROUNDED_CONTAINER_PROPS}
      {...BOTTOM_ROUNDED_CONTAINER_PROPS}
      pt={isMobile ? '0' : undefined}
    >
      {!isMobile && <Image imageUrl={product.description_image} alt={t`Product image`} />}

      {canBookMore && (
        <>
          <ContainerTotal total={total} />

          {statusAddCart === REQUEST_STATUS.ERROR && <AlertError />}
          {invalidResources && <AlertError message={<Trans>Some selected resources are unavailable, please check your data.</Trans>} />}
          {duplicateParticipants && <AlertError message={<Trans>Participants names must be different.</Trans>} />}

          <SessionVariantsActions
            statusAddCart={statusAddCart}
            requestedAction={requestedAction}
            isMobile={isMobile}
            statusLoadVariants={statusLoadVariants}
            itemsToDeleteForEdit={itemsToDeleteForEdit}
            setRequestedAction={setRequestedAction}
          />
        </>
      )}
    </Box>
  );
};

export default SessionVariantsTotal;
