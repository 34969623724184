import { useMemo } from 'react';

import { t } from '@lingui/macro';

import { getFormattedCurrency, getMainPrice, STANDARD_PRICE_SELECT_VALUE } from '../utils/business/prices';

const useReadPriceChoices = (variant, totalParticipants) => {
  const choices = useMemo(() => getPriceChoices(variant, totalParticipants), [variant.participant, totalParticipants]);
  return choices;
};

export default useReadPriceChoices;

// This fills the select containing all available prices
// The first option of the select is the main price : either standard or discount
const getPriceChoices = (variant, totalParticipants) => {
  const mainPriceChoice = getMainPriceChoice(variant, totalParticipants);

  const choices = [mainPriceChoice];

  for (const priceVariation of variant.custom_prices) {
    if (priceVariation.participant_threshold) continue;

    choices.push({
      label: priceVariation.label,
      value: priceVariation.id,
      price: getFormattedCurrency(priceVariation.computed_price),
    });
  }
  return choices;
};

const getMainPriceChoice = (variant, totalParticipants) => {
  const mainPriceData = getMainPrice(variant.standardPrice, totalParticipants ?? variant.participant, variant.custom_prices);

  const formattedPriceValue = getFormattedCurrency(mainPriceData.priceValue);

  const mainPriceLabel = mainPriceData.priceVariationId === STANDARD_PRICE_SELECT_VALUE ? t`Standard price` : t`Special price`;
  return {
    label: mainPriceLabel,
    value: mainPriceData.priceVariationId,
    price: formattedPriceValue,
  };
};
