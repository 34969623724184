import { ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

const CartElementDetailOptions = ({ detailResources }) => {
  return (
    <>
      <Text fontWeight="500">Option(s)</Text>

      {detailResources.length > 0 && (
        <UnorderedList onClick={e => e.stopPropagation()} pl="5px">
          {detailResources.map((resource, index) => (
            <ListItem fontSize="xs" key={index}>
              <Text as="span">{resource.label + ' '}</Text>
              <Text as="span" color="brand.color_main" fontWeight="500">
                (x{resource.quantity})
              </Text>
            </ListItem>
          ))}
        </UnorderedList>
      )}

      {detailResources.length <= 0 && (
        <Text>
          <Trans>No options added for this product.</Trans>
        </Text>
      )}
    </>
  );
};

export default CartElementDetailOptions;
