import { defineMessage } from '@lingui/macro';

import InputRadioCard from '../../generic/form/InputRadioCard';

export const SEND_BOOKING_MAIL_FIELDNAME = 'send_booking_mail';

const CheckoutMailToggle = props => {
  return <InputRadioCard fieldLabel={defineMessage({ message: 'Send booking email ?' })} fieldName={SEND_BOOKING_MAIL_FIELDNAME} {...props} />;
};

export default CheckoutMailToggle;
