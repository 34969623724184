import useWriteFirstAvailabilityDate from '../../../../hooks/useWriteFirstAvailabilityDate';
import Availability from '../Availability';

const AvailabilitiesList = ({ data: availabilities }) => {
  useWriteFirstAvailabilityDate(availabilities ? availabilities[0].start_date : null);

  return (
    <>
      {availabilities.map(availability => (
        <Availability key={availability.session_group_id} availability={availability} />
      ))}
    </>
  );
};

export default AvailabilitiesList;
