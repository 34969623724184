import { SETTINGS_DEV } from './settings.dev';
import { SETTINGS_PROD } from './settings.prod';

// These settings are common between dev and prod
// These settings can be overwritten by having the same key in dev or in prod
const commonSettings = {
  // this is a built-in environment variable, you can read it from process.env.NODE_ENV
  // if run 'npm start' => 'development', if run 'npm test' => 'test', if run 'npm run build' => 'production'
  // You CANNOT override NODE_ENV manually => https://create-react-app.dev/docs/adding-custom-environment-variables/
  // eslint-disable-next-line no-undef
  DEBUG: process.env.NODE_ENV === 'development',

  // Displays the devMessages even if not DEBUG
  TRACE_MODE: false,

  // Displays "MAX=X" on the P3 InputNumber of participants
  TRACE_VARIANT_MAX_PLACES: false,

  // Displays the stock information on the P3 options select (if applicable)
  TRACE_VARIANT_MAX_STOCK: false,

  // N days after its creation date, the cart will expire
  // This settings might be overriden in dev settings for test purposes
  CART_DAYS_EXPIRATION: 13,

  // Max number of voucher we can buy at once
  VOUCHER_BUY_LIMIT: 100,

  // Max number of participants when we set the qty on P3
  PARTICIPANTS_QTY_LIMIT: 100,

  IMAGE_SLIDER_DELAY_MS: 3000,

  ROLLBAR_TOKEN: 'acf8ad943ebf46af9b9f0046ad9efbf2',
  ROLLBAR_ENABLE_IN_DEV: false,

  GTM_ENABLE_IN_DEV: false,

  GMAPS_API_KEY: 'AIzaSyCnCsVwGUR4us4r92b5eAdilflE-vEUenk',

  FILTERS_PER_LINE: 4,
};

const envSettings = commonSettings.DEBUG ? SETTINGS_DEV : SETTINGS_PROD;
// This is the global constant that can be used to read settings
export const settings = Object.assign({}, commonSettings, envSettings);

// using devError() will alert in DEV and warn in PROD, useful to remind us for stuff when dev
export const devError = msg => {
  if (settings.DEBUG) {
    alert(msg);
    console.error(msg);
  } else console.warn(msg);
};

// using devMessage() will log to console if conditions met, useful for development/debug
export const devMessage = settings.DEBUG || settings.TRACE_MODE ? console.log.bind(window.console, '%c[devMessage]', 'color: green') : function () {};
devMessage('App loaded with settings:', settings);
