import { Box } from '@chakra-ui/react';

import { RESPONSIVE_WIDTH_BOX_LEFT } from '../../../utils/styleUtil';
import HeaderLogoLabel from './header/HeaderLogoLabel';

const ContainerLeft = ({ title, bg, ...props }) => {
  return (
    <Box {...RESPONSIVE_WIDTH_BOX_LEFT}>
      <HeaderLogoLabel>{title}</HeaderLogoLabel>
      <Box>{props.children}</Box>
    </Box>
  );
};

export default ContainerLeft;
