import { useRef } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { LOCAL_STORAGE_CART_ID, ROUTE_PAGE_CART, ROUTE_P6_CHECKOUT, BUYNOW_FORM_ACTION } from '../constants';
import { devMessage } from '../settings/settings';
import { REQUEST_STATUS } from '../utils/requestUtil';
import useNavigateBookingEngine from './useNavigateBookingEngine';
import usePostCart from './usePostCart';
import useReadStorageBookingEngine from './useReadStorageBookingEngine';
import useWriteCartData from './useWriteCartData';

const useFormShopResource = preCartData => {
  const storedCartId = useReadStorageBookingEngine(LOCAL_STORAGE_CART_ID);

  const { data, status: statusAddCart, writeCart } = usePostCart(storedCartId, preCartData.availabilities);

  const { setCartDataToWrite, cartData, cartWriteSuccess } = useWriteCartData();

  const requestedAction = useRef();
  const setRequestedAction = action => {
    requestedAction.current = action;
  };

  const onSubmitAddToCart = e => {
    e.preventDefault();

    // dirty fix for this: https://trello.com/c/qaONwidU/1058-bug-be
    // when we click and something was "natively" required then we are not aware of it in the code
    // this should have been managed differently by having a REQUEST_STATUS.NOT_STARTED first instead of LOADING
    // this would have a big refactoring impact though
    setRequestedAction(requestedAction.current.replace('before_submit_', ''));

    const shopResourceChild = getShopResourceChild(preCartData);
    writeCart({ ...preCartData, shopResourceChild: shopResourceChild });
  };

  // When the cart backend query finished
  useUpdateEffect(() => {
    if (statusAddCart === REQUEST_STATUS.OK) {
      setCartDataToWrite(data);
    }
  }, [data, statusAddCart]);

  // when the cart success (and data) are set
  const navigate = useNavigateBookingEngine();
  useUpdateEffect(() => {
    const isBuyNow = requestedAction.current === BUYNOW_FORM_ACTION;
    setRequestedAction(null);

    if (!cartWriteSuccess) return;

    if (isBuyNow) {
      navigate(ROUTE_P6_CHECKOUT);
      return;
    }

    const cartUrl = `${ROUTE_PAGE_CART}/${cartData.cartOrderId}`;
    devMessage('Navigating to cart:', cartUrl);
    navigate(cartUrl, { state: { queryBackend: false } });
  }, [cartWriteSuccess]);

  return { onSubmitAddToCart, statusAddCart, requestedAction, setRequestedAction };
};

export default useFormShopResource;

const getShopResourceChild = preCartData => {
  const { shopResource, shopResourceChildren } = preCartData;
  if (!shopResource.feature_one && !shopResource.feature_two) return null;

  for (const childResource of shopResourceChildren) {
    const isMatch = childResource.feature_value_one_id == shopResource.feature_one && childResource.feature_value_two_id == shopResource.feature_two;
    if (isMatch) {
      return childResource;
    }
  }

  // This should not be happening, I'll check in Rollbar if this error is showing
  // If so then we'll have to manage this case
  console.error('find child', shopResource.feature_one, shopResource.feature_two);
  console.error('in', shopResourceChildren);
  throw new Error('Resource child not found');
};
