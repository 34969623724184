import { useState } from 'react';

import cloneDeep from 'lodash.clonedeep';
import { useUpdateEffect } from 'usehooks-ts';

import { apiGetAvailabilitiesDetails, LOCAL_STORAGE_CART, ROUTE_P3_SESSION_VARIANTS } from '../constants';
import { devMessage } from '../settings/settings';
import { findArrayItemById, findArrayItemByKey } from '../utils/arrayUtil';
import { getAvailabilitiesAndResourcesIds } from '../utils/business/availabilities';
import { handleParticipantDataForFrontEnd } from '../utils/business/participants';
import { STANDARD_PRICE_SELECT_VALUE } from '../utils/business/prices';
import useNavigateBookingEngine from './useNavigateBookingEngine';
import usePost from './usePost';
import { createDefaultVariant, getAvailabilitiesDetailRequestData } from './usePostLoadVariant';
import useReadLoggedUser from './useReadLoggedUser';
import useReadStorageBookingEngine from './useReadStorageBookingEngine';
import useReadTeam from './useReadTeam';
import useWritePreCart from './useWritePreCart';
import { refreshStockOnEdit } from './useWriteVariantsStock';

const useEditCartElement = (availabilities, product) => {
  const loggedUser = useReadLoggedUser();
  const { teamId } = useReadTeam();

  const { setPreCartData } = useWritePreCart();
  const [preCartWritten, setPreCartWritten] = useState(false);

  const [formattedData, setFormattedData] = useState(null);
  const { data, executePost } = usePost(apiGetAvailabilitiesDetails(), formattedData);

  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);

  const triggerEdit = () => {
    const allSessionGroupIds = getAvailabilitiesDetailRequestData(availabilities, teamId);
    setFormattedData(allSessionGroupIds);
  };

  useUpdateEffect(() => {
    executePost();
  }, [formattedData]);

  useUpdateEffect(() => {
    // When react reload state in dev
    if (!data) return;

    const defaultVariant = createDefaultVariant(data, loggedUser);
    const preCartAvailabilities = formatAvailabilitiesForEdit(availabilities, defaultVariant, loggedUser, cartData.clients, data);
    const itemsToDeleteForEdit = getAvailabilitiesAndResourcesIds(availabilities);

    setPreCartData({
      product: product,
      availabilities: preCartAvailabilities,
      defaultVariant: defaultVariant,
      itemsToDeleteForEdit: itemsToDeleteForEdit,
    });
    devMessage('EditVariant: recreated availabilities:', preCartAvailabilities);
    devMessage('EditVariant: recreated def variant:', defaultVariant);
    setPreCartWritten(true);
  }, [data]);

  const navigate = useNavigateBookingEngine();
  useUpdateEffect(() => {
    if (!preCartWritten) return;

    setPreCartWritten(false);
    navigate(ROUTE_P3_SESSION_VARIANTS);
  }, [preCartWritten]);

  return { triggerEdit };
};

export default useEditCartElement;

const formatAvailabilitiesForEdit = (availabilities, defaultVariant, loggedUser, clients, data) => {
  const preCartAvailabilities = [];

  for (const ava of availabilities) {
    const sessGid = ava.session_group_id;
    let foundAva = findArrayItemByKey('session_group_id', sessGid, preCartAvailabilities);
    if (!foundAva) {
      foundAva = cloneDeep(ava);
      foundAva.nb_tickets = data.nb_tickets;
      foundAva.max_participants = data.max_participants;
      foundAva.nb_sessions = data.nb_sessions;
      foundAva.start_date = data.start_date;
      foundAva.variants = [];
      preCartAvailabilities.push(foundAva);
    }

    appendVariantData(foundAva.variants, defaultVariant, ava, loggedUser, clients);
  }
  return preCartAvailabilities;
};

const appendVariantData = (variants, defaultVariant, ava, loggedUser, clients) => {
  // In logged mode, we group our variants by price+options
  if (loggedUser) {
    const similarVariant = findSimilarVariant(variants, ava);
    if (similarVariant) {
      const newQuantity = parseInt(ava.quantity);
      similarVariant.participant += newQuantity;
      refreshStockOnEdit(defaultVariant, similarVariant, -newQuantity);
      return;
    }
  }

  // If not found or in loggedout mode then we create a variant
  createVariant(variants, defaultVariant, ava, clients);
};

const findSimilarVariant = (variants, ava) => {
  const priceVariationId = ava.price_variation_id ?? STANDARD_PRICE_SELECT_VALUE;
  for (const variant of variants) {
    const samePriceVariation = variant.currentPriceVariationId === priceVariationId;
    const subSessionWithZeroPrice = ava.price_variation_id === null && ava.price === '0.00';
    if (!samePriceVariation && !subSessionWithZeroPrice) continue;

    const variantOptions = [];
    for (const option of variant.custom_options) {
      if (!option.selectedResource) continue;
      variantOptions.push(option.selectedResource);
    }

    const avaOptions = [];
    for (const resource of ava.resources) {
      avaOptions.push(resource.resource_id);
    }

    if (variantOptions.length === avaOptions.length && variantOptions.every(e => avaOptions.includes(e))) {
      return variant;
    }
  }
  return null;
};

const createVariant = (variants, defaultVariant, ava, clients) => {
  const newVariant = cloneDeep(defaultVariant);
  newVariant.participant = parseInt(ava.quantity);
  newVariant.currentPriceVariationId = ava.price_variation_id ?? STANDARD_PRICE_SELECT_VALUE;

  // When unlogged, we fill the participants data
  if (newVariant.participantDetails && ava.client_id) {
    const foundClient = findArrayItemById(ava.client_id, clients);
    if (foundClient) {
      newVariant.participantDetails.client = handleParticipantDataForFrontEnd(foundClient);
    }
  }

  for (const resource of ava.resources) {
    markOptionAsSelected(resource.resource_id, newVariant.custom_options);
  }

  refreshStockOnEdit(defaultVariant, newVariant, -newVariant.participant);
  variants.push(newVariant);
};

const markOptionAsSelected = (resourceId, variantOptions) => {
  for (const option of variantOptions) {
    const foundChoice = findArrayItemById(resourceId, option.resources);
    if (!foundChoice) continue;
    if (option.selectedResource) continue;

    option.selectedResource = resourceId;
    break;
  }
};
