import { useEffect, useState } from 'react';

import cloneDeep from 'lodash.clonedeep';

import { OPTION_PARTICIPANT_KEYNAME } from '../components/business/sessionvariants/options/OptionParticipant';
import { OPTION_PRICE_KEYNAME } from '../components/business/sessionvariants/options/OptionPrice';
import { devMessage } from '../settings/settings';
import { findArrayItemById } from '../utils/arrayUtil';

const useWriteVariantsStock = (defaultVariant, variants, totalParticipants, maxPlaces) => {
  const [placesAvailable, setPlacesAvailable] = useState(null);

  // Every time the total of participant changes, we update the available places
  useEffect(() => {
    if (!maxPlaces) return;

    setPlacesAvailable(Math.max(0, maxPlaces - totalParticipants));
  }, [totalParticipants]);

  const updateOptionsStock = (key, newValue, index) => {
    if (defaultVariant.custom_options.length <= 0 || key === OPTION_PRICE_KEYNAME) return null;

    devMessage('Request updateOptionsStock, key:', key, 'newVal:', newValue, 'variantIdx:', index);
    const updatedVariant = variants[index];
    let updatedDefaultVariant = null;
    const copyDefaultVariant = cloneDeep(defaultVariant);

    // We changed the participant count, maybe +- 1 but also maybe +-N if the user writes text in input
    // So we will update **ALL** the options stocks with the participant difference
    if (key === OPTION_PARTICIPANT_KEYNAME) {
      const diff = getDiffParticipant(updatedVariant.participant, newValue);
      updatedDefaultVariant = refreshAllOptionsStocks(copyDefaultVariant, updatedVariant.custom_options, diff);
    }
    // Else we selected a new option or switched option
    // So we will decrease the old option stock (if applicable) and increase the new option stock
    // All of this with the current participant value (if 42 participants, we +-42, not +-1)
    else {
      updatedDefaultVariant = refreshSingleOptionStock(copyDefaultVariant, updatedVariant.custom_options, key, newValue, updatedVariant.participant);
    }

    // We updated the option stock directly inside the defaultVariant for easier access
    devMessage('Actual refresh occured ? ', updatedDefaultVariant);
    return updatedDefaultVariant;
  };

  return { placesAvailable, updateOptionsStock };
};

export default useWriteVariantsStock;

const getDiffParticipant = (oldParticipantValue, newParticipantValue) => {
  // if participant isNaN, it means the field was empty prior to update
  const nbParticipants = isNaN(parseInt(oldParticipantValue)) ? 0 : parseInt(oldParticipantValue);

  // if no newValue it means we just cleared the input, so we will add the whole nbParticipants
  const diffParticipant = newParticipantValue ? nbParticipants - parseInt(newParticipantValue) : nbParticipants;
  // diff > 0 will increase the available stock
  // diff < 0 will remove elements from stock

  devMessage('diffParticipant old:', oldParticipantValue, 'new:', newParticipantValue, 'actual:', nbParticipants, '=>', diffParticipant);
  return diffParticipant;
};

const refreshAllOptionsStocks = (copyDefaultVariant, variantOptionsToRead, stockDifference) => {
  devMessage('refreshAllOptionsStocks because participant count was changed, stockDifference:', stockDifference);

  let variantWasUpdated = false;

  for (let i = 0; i < variantOptionsToRead.length; i++) {
    const element = variantOptionsToRead[i];
    if (!element.selectedResource) continue;

    // Careful, we read the variant for its selectedResource but we always update the stock on the defaultVariant !
    const resourceToUpdate = findArrayItemById(element.selectedResource, copyDefaultVariant.custom_options[i].resources);
    const resourceUpdated = updateOptionStockValue(resourceToUpdate, stockDifference);
    if (!variantWasUpdated) {
      variantWasUpdated = resourceUpdated;
    }
  }

  // we do this to prevent useless state updates
  return variantWasUpdated ? copyDefaultVariant : null;
};

// On edit we are creating the defaultVariant so we write on it directly
export const refreshStockOnEdit = (defaultVariant, editedVariant, stockDifference) =>
  refreshAllOptionsStocks(defaultVariant, editedVariant.custom_options, stockDifference);

const updateOptionStockValue = (resource, stockDifference) => {
  // no === because we need to catch undefined as well
  if (!resource || resource.current_stock == null) return false;

  const maxStock = parseInt(resource.current_stock);

  // no triple !== because we wanna get the undefined too
  const calculatedStock = resource.calculatedStock != null ? parseInt(resource.calculatedStock) : maxStock;

  resource.calculatedStock = calculatedStock + stockDifference;
  devMessage('updating stock', 'old:', calculatedStock, 'new:', resource.calculatedStock, 'max:', maxStock);
  return true;
};

const refreshSingleOptionStock = (copyDefaultVariant, variantOptionsToRead, key, newSelectedResource, stockDifference) => {
  devMessage('refreshSingleOptionStock because option was changed, stockDifference:', stockDifference);

  const optionToUpdate = getSelectedOption(copyDefaultVariant.custom_options, key);
  if (!optionToUpdate) return null; // means it's not an option, it's some other select

  const oldSelectedOption = getSelectedOption(variantOptionsToRead, key);
  const oldSelectedResource = oldSelectedOption ? oldSelectedOption.selectedResource : null;
  devMessage('oldOptSel', oldSelectedResource, 'newOptSel', newSelectedResource);

  const previousResource = oldSelectedResource ? findArrayItemById(oldSelectedResource, optionToUpdate.resources) : null;
  const newResource = findArrayItemById(newSelectedResource, optionToUpdate.resources);

  // We increase/decrease the option stock if option removed (old) or selected (new)
  const previousStockUpdated = updateOptionStockValue(previousResource, +stockDifference);
  const newStockUpdated = updateOptionStockValue(newResource, -stockDifference);

  // we do this to prevent useless state updates
  return previousStockUpdated || newStockUpdated ? copyDefaultVariant : null;
};

const getSelectedOption = (variantOptions, key) => {
  for (const element of variantOptions) {
    if (element.id !== key) continue;
    return element;
  }
  return null;
};
