import { t } from '@lingui/macro';

const useReadTags = productTags => {
  const choices = [{ label: t`All`, value: '' }];
  if (!productTags) {
    return choices;
  }

  // const sortedArray = sortObjectArrayByKey(productTags, 'label'); // do not use alpha order but backend order
  for (const tag of productTags) {
    choices.push({ label: tag.label, value: tag.id });
  }
  return choices;
};

export default useReadTags;
