import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { getPriceVariationToString } from '../../../../utils/business/prices';
import { ROUNDED_ELEMENT_PROPS, SMALLBOX_PROPS } from '../../../../utils/styleUtil';

const ProductDetailSpecialPrices = ({ specialPrices }) => {
  return (
    <Box bg="brand.background_smallbox" fontSize="xs" flexShrink={0} {...ROUNDED_ELEMENT_PROPS} {...SMALLBOX_PROPS}>
      <Text color="brand.color_main">
        <Trans>Special prices</Trans>
      </Text>

      <UnorderedList pl=".5rem">
        {specialPrices.map((spePrice, index) => (
          <ListItem key={index}>
            {spePrice.label + ' : '}

            <Text as="span">{getPriceVariationToString(spePrice)}</Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default ProductDetailSpecialPrices;
