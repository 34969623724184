import { useBoolean, useUpdateEffect } from 'usehooks-ts';

import { apiGetAvailabilitiesDates } from '../constants';
import usePost from './usePost';

const useFetchMoreDates = sessionGroupIds => {
  const { value: moreDateShown, toggle: toggleMoreDates } = useBoolean(false);

  const { data, status, executePost } = usePost(apiGetAvailabilitiesDates(), { session_group_ids: sessionGroupIds });

  useUpdateEffect(() => {
    if (moreDateShown && !data) {
      executePost();
    }
  }, [moreDateShown]);

  return { moreDateShown, toggleMoreDates, data, status };
};

export default useFetchMoreDates;
