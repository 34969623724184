import { Divider, HStack, Text } from '@chakra-ui/react';

const HDivider = props => {
  return (
    <HStack mb=".3rem" mt=".7rem">
      <Divider />
      <Text fontSize="xs" fontWeight="200" textTransform="uppercase" as="span">
        {props.children}
      </Text>
      <Divider />
    </HStack>
  );
};

export default HDivider;
