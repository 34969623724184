import { Box } from '@chakra-ui/layout';
import { t, Trans } from '@lingui/macro';

import { BOTTOM_ROUNDED_CONTAINER_PROPS, RESPONSIVE_WIDTH_BOX_RIGHT, TOP_ROUNDED_CONTAINER_PROPS } from '../../../utils/styleUtil';
import ButtonSubmit from '../../generic/button/ButtonSubmit';
import Image from '../../generic/Image';
import ContainerTotal from '../../generic/layout/ContainerTotal';

const BuyVoucherTotal = ({ selectedVoucher, productImage, isLoading }) => {
  return (
    <Box h="100%" bg="brand.background_main" {...RESPONSIVE_WIDTH_BOX_RIGHT} {...TOP_ROUNDED_CONTAINER_PROPS} {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
      <Image imageUrl={productImage} alt={t`Product image`} />

      {selectedVoucher && <ContainerTotal total={selectedVoucher.price * selectedVoucher.quantity} />}

      <ButtonSubmit w="100%" isLoading={isLoading || selectedVoucher == null}>
        <Trans>Add to cart</Trans>
      </ButtonSubmit>
    </Box>
  );
};

export default BuyVoucherTotal;
