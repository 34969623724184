import { Center, VStack } from '@chakra-ui/react';
import { Trans, t } from '@lingui/macro';

import { ROUTE_P1_PRODUCT_SELECTION } from '../../constants';
import useWriteSeo from '../../hooks/useWriteSeo';
import ButtonLink from '../generic/button/ButtonLink';
import AlertError from '../generic/error/AlertError';

const NotFoundPage = () => {
  useWriteSeo({ title: t`404 - Page not found` });

  return (
    <Center>
      <VStack>
        <AlertError message={<Trans>This page does not exist.</Trans>} />

        <ButtonLink to={ROUTE_P1_PRODUCT_SELECTION}>
          <Trans>Go back home</Trans>
        </ButtonLink>
      </VStack>
    </Center>
  );
};

export default NotFoundPage;
