import { Flex, Spacer, Text } from '@chakra-ui/react';

const CheckoutTaxElement = ({ leftPart, leftSize = 'sm', rightPart, rightSize = 'sm' }) => {
  return (
    <Flex w="100%">
      <Text fontSize={leftSize}>{leftPart}</Text>
      <Spacer />
      <Text fontSize={rightSize}>{rightPart}</Text>
    </Flex>
  );
};

export default CheckoutTaxElement;
