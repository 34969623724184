import { Alert, AlertIcon } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';

const AlertError = ({ message, ...props }) => {
  return (
    <Alert status="error" {...ROUNDED_ELEMENT_PROPS} {...props}>
      <AlertIcon />
      {!message && <Trans>An error occured, please try again later.</Trans>}
      {message}
    </Alert>
  );
};

export default AlertError;
