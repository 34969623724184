import { useState } from 'react';

import { Box } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useBoolean } from 'usehooks-ts';

import { LOCAL_STORAGE_CART } from '../../../constants';
import useFormValidate from '../../../hooks/useFormValidate';
import useReadStorageBookingEngine from '../../../hooks/useReadStorageBookingEngine';
import { BOTTOM_ROUNDED_CONTAINER_PROPS } from '../../../utils/styleUtil';
import HeaderLogoLabel from '../../generic/layout/header/HeaderLogoLabel';
import { LAST_ACCORDION } from './CheckoutDetailAccordionData';
import CheckoutDetailAccordionDesktop from './CheckoutDetailAccordionDesktop';
import CheckoutDetailErrors from './CheckoutDetailErrors';
import CheckoutDetailNextStep from './CheckoutDetailNextStep';

const CheckoutDetailFormDesktop = ({ isTaxIncluded, updateParticipants }) => {
  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);
  const { isInvalidForm, duplicateParticipants } = useFormValidate();

  // On desktop all accordions are open by default
  const [openedAccordion, setOpenedAccordion] = useState([...Array(LAST_ACCORDION).keys()]);

  const { value: isLoading, setTrue: setLoadingTrue } = useBoolean(false);

  const handleSubmit = e => {
    e.preventDefault();

    if (isInvalidForm(e.nativeEvent.target)) {
      return;
    }

    if (updateParticipants()) {
      setLoadingTrue();
    }
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <HeaderLogoLabel>
        <Trans>Who does what ?</Trans>
      </HeaderLogoLabel>

      <Box borderColor="brand.background_main" p="8px" {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
        <CheckoutDetailAccordionDesktop
          cartData={cartData}
          openedAccordion={openedAccordion}
          setOpenedAccordion={setOpenedAccordion}
          isTaxIncluded={isTaxIncluded}
        />
      </Box>
      <CheckoutDetailErrors duplicateParticipants={duplicateParticipants} />
      <CheckoutDetailNextStep isLoading={isLoading} isLastStep />
    </form>
  );
};

export default CheckoutDetailFormDesktop;
