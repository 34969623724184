import { Divider, Link, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useFetchMoreDates from '../../../hooks/useFetchMoreDates';
import CartElementDetailOptions from './details/CartElementDetailOptions';
import CartElementDetailSessions from './details/CartElementDetailSessions';

const CartElementDetails = ({ sessionGroupId, detailResources }) => {
  const { toggleMoreDates, data, status } = useFetchMoreDates([sessionGroupId]);

  // stopPropagation to not trigger click on parent element
  const handleClick = e => {
    e.stopPropagation();
    toggleMoreDates();
  };

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Link onClick={handleClick} as="span">
            <Trans>Display details</Trans>
          </Link>
        </PopoverTrigger>

        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <CartElementDetailOptions detailResources={detailResources} />

              <Divider orientation="horizontal" m=".5rem" />

              <CartElementDetailSessions data={data} status={status} />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export default CartElementDetails;
