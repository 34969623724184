import { useMemo } from 'react';

import { Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { settings } from '../../../../settings/settings';
import InputNumber from '../../../generic/form/InputNumber';

export const OPTION_PARTICIPANT_KEYNAME = 'participant';

const OptionParticipant = ({ fieldValue, index, onOptionUpdate, loggedUser, placesAvailable, placesMax }) => {
  // We trigger this calculation only when the placesAvailable changes
  // The participant change will trigger an update on placesAvailable
  const maximum = useMemo(() => (loggedUser ? getMaximumParticipant(fieldValue, placesAvailable, placesMax) : null), [placesAvailable]);

  return (
    <>
      {loggedUser && (
        <>
          <InputNumber
            fieldValue={fieldValue}
            fieldMaximum={maximum}
            onChange={(fieldName, newValue) => onOptionUpdate(OPTION_PARTICIPANT_KEYNAME, newValue, index)}
            isRequired
            minWidth="70px"
            maxWidth="70px"
            displayLabel={false}
            size="sm"
          />
          <Text fontSize="sm" color="brand.color_main">
            <Trans>participant(s)</Trans>
          </Text>
          {settings.TRACE_VARIANT_MAX_PLACES && <Text>MAX={maximum}</Text>}
        </>
      )}

      {!loggedUser && (
        <Text fontSize="sm" color="brand.color_main">
          <Trans>Participant {index + 1}</Trans>
        </Text>
      )}
    </>
  );
};

export default OptionParticipant;

const getMaximumParticipant = (currentParticipant, placesAvailable, placesMax) => {
  // no limit
  if (placesAvailable == null) return undefined;

  let max;
  if (currentParticipant == null || currentParticipant === '') {
    max = placesAvailable;
  } else {
    max = parseInt(currentParticipant) + parseInt(placesAvailable);
  }

  return Math.min(max, placesMax);
};
