import { Accordion } from '@chakra-ui/react';

import useWriteParticipantsAssign from '../../../hooks/useWriteParticipantsAssign';
import { addOrRemoveFromArray } from '../../../utils/arrayUtil';
import VerticalStack from '../../generic/layout/VerticalStack';
import CheckoutDetailActivities from './activities/CheckoutDetailActivities';
import { Accordions } from './CheckoutDetailAccordionData';
import CheckoutDetailParticipants from './CheckoutDetailParticipants';

const CheckoutDetailAccordionDesktop = ({ openedAccordion, setOpenedAccordion, isTaxIncluded, ...props }) => {
  const { autoAssignFinished, participantsChoices } = useWriteParticipantsAssign(props.cartData.ocSessionsWithResources);

  const onNamesClick = () => onAccordionClick(Accordions.PARTICIPANTS_NAMES);
  const onActivityClick = () => onAccordionClick(Accordions.ACTIVITIES);

  const onAccordionClick = accordionIndex => {
    const newOpenedAccordion = [...openedAccordion];
    addOrRemoveFromArray(newOpenedAccordion, accordionIndex);
    setOpenedAccordion(newOpenedAccordion);
  };

  return (
    <Accordion index={openedAccordion} allowMultiple>
      <VerticalStack borderColor="transparent">
        <CheckoutDetailParticipants onAccordionClick={onNamesClick} {...props} />

        {autoAssignFinished.current && (
          <CheckoutDetailActivities
            onAccordionClick={onActivityClick}
            isTaxIncluded={isTaxIncluded}
            participantsChoices={participantsChoices}
            {...props}
          />
        )}
      </VerticalStack>
    </Accordion>
  );
};

export default CheckoutDetailAccordionDesktop;
