import { Divider, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useReadCheckoutPrice from '../../../hooks/useReadCheckoutPrice';
import { getFormattedCurrency } from '../../../utils/business/prices';
import CheckoutTaxElement from './CheckoutTaxElement';

const CheckoutTaxes = ({ priceData }) => {
  const { priceTaxIncluded, priceTaxExcluded, taxesLabel, taxesTotal } = useReadCheckoutPrice(priceData);

  return (
    <VStack minW="180px" maxW={{ base: undefined, md: '250px' }} fontWeight="bold" alignItems="flex-start">
      <CheckoutTaxElement leftPart={<Trans>Sub total</Trans>} rightPart={getFormattedCurrency(priceTaxExcluded)} />

      <CheckoutTaxElement leftPart={<Trans>Tax</Trans>} rightPart={getFormattedCurrency(taxesTotal)} />
      <Text fontSize="9px">{taxesLabel}</Text>

      <Divider orientation="horizontal" borderColor="brand.color_main" />

      <CheckoutTaxElement leftPart={<Trans>Total tax incl.</Trans>} rightPart={getFormattedCurrency(priceTaxIncluded)} rightSize="md" />
    </VStack>
  );
};

export default CheckoutTaxes;
