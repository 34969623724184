import { useLocalStorage } from 'usehooks-ts';

import { getSpecificStorageKey } from '../utils/navigationUtil';

// This hooks replaces useLocalStorage so we manage the booking engine id
const useWriteStorageBookingEngine = (localStorageKey, defaultValue) => {
  const [value, setValue] = useLocalStorage(getSpecificStorageKey(localStorageKey), defaultValue);
  return [value, setValue];
};

export default useWriteStorageBookingEngine;
