import React, { useState } from 'react';

// =====================================================================================
// This context is dedicated to exchange specific data between components
// when there is no better alternative
// =====================================================================================

export const ExchangeContext = React.createContext({});
ExchangeContext.displayName = 'ExchangeContext (BookingEngine)';

export const ExchangeContextProvider = ({ data, children }) => {
  const [exchangeData, setExchangeData] = useState(data);
  const exchangeContextValue = { exchangeData, setExchangeData };

  return <ExchangeContext.Provider value={exchangeContextValue}>{children}</ExchangeContext.Provider>;
};
