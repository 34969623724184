import { useEffect, useState } from 'react';

const useIsWidthBelow = maxWidth => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return width <= maxWidth;
};

export default useIsWidthBelow;
