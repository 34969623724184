import { Box, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useCalcCartSize from '../../../hooks/useCalcCartSize';
import useFetchCart from '../../../hooks/useFetchCart';
import useReadLoggedUser from '../../../hooks/useReadLoggedUser';
import useReadTeam from '../../../hooks/useReadTeam';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, COMMON_PADDING, RESPONSIVE_WIDTH_BOX_RIGHT } from '../../../utils/styleUtil';
import AlertError from '../../generic/error/AlertError';
import FetchLoaderSpinner from '../../generic/FetchLoaderSpinner';
import ContainerLeft from '../../generic/layout/ContainerLeft';
import FlexRow from '../../generic/layout/FlexRow';
import CartActionNewCart from './actionsbtn/CartActionNewCart';
import CartActions from './actionsbtn/CartActions';
import CartElementsList from './CartElementsList';
import CartTotalSimple from './CartTotalSimple';

const Cart = () => {
  const { cartData, status, cartFetched } = useFetchCart();
  const cartSize = useCalcCartSize(cartData);
  const isCartLoadedWithStuff = cartFetched && cartSize !== 0;

  const { isTaxIncluded } = useReadTeam();

  const loggedUser = useReadLoggedUser();

  // We dont check STATUS LOADING/OK because in some cases we dont query the backend
  // So when checking STATUS, we only read ERROR
  // Also we use cartFetched state to control display because the cartData might contain the old data
  return (
    <FlexRow>
      <ContainerLeft title={<Trans>My cart</Trans>}>
        <Box bg="brand.background_secondary" p="8px" {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
          {!cartFetched && <FetchLoaderSpinner status={status} />}
          {status === REQUEST_STATUS.ERROR && <AlertError />}

          {isCartLoadedWithStuff && <CartElementsList cartData={cartData} isTaxIncluded={isTaxIncluded} />}
          {cartFetched && cartSize === 0 && (
            <Text fontSize="sm" align="center" pt={COMMON_PADDING}>
              <Trans>There are no elements in your cart.</Trans>
            </Text>
          )}

          {isCartLoadedWithStuff && loggedUser && cartData.cartOrderId && <CartActionNewCart currentCartId={cartData.cartOrderId} />}
        </Box>
      </ContainerLeft>

      <Box {...RESPONSIVE_WIDTH_BOX_RIGHT}>
        {cartFetched && (
          <>
            <CartTotalSimple cartData={cartData} cartSize={cartSize} isTaxIncluded={isTaxIncluded} />
            {cartSize !== 0 && <CartActions />}
          </>
        )}
      </Box>
    </FlexRow>
  );
};

export default Cart;
