import { t } from '@lingui/macro';

import { ROUTE_P1_PRODUCT_SELECTION } from '../../constants';
import useNavigateBookingEngine from '../../hooks/useNavigateBookingEngine';
import useReadTeam from '../../hooks/useReadTeam';
import Image from './Image';

const TeamLogo = props => {
  const { teamLogo } = useReadTeam();

  const navigate = useNavigateBookingEngine();
  const goHome = () => {
    navigate(ROUTE_P1_PRODUCT_SELECTION);
  };

  return (
    <Image
      imageUrl={teamLogo}
      maxW="130px"
      maxH="60px"
      alt={t`Team logo`}
      style={{ objectFit: 'contain' }}
      onClick={goHome}
      cursor="pointer"
      {...props}
    />
  );
};

export default TeamLogo;
