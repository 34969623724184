// basic len check for now
export const getValidUuidOrNull = possibleUuid => {
  return possibleUuid && possibleUuid.length === 36 ? possibleUuid : null;
};

export const isStrNullOrEmpty = str => str == null || str.trim() === '';

export const trimObjectKeys = (obj, keys) => {
  for (const key of keys) {
    if (key in obj && obj[key] != null) {
      obj[key] = obj[key].trim();
    }
  }
};
