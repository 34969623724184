import { Box, Divider, HStack, Spacer, Text, VStack } from '@chakra-ui/react';

import useIsMobile from '../../../../hooks/useIsMobile';
import useReadCartItemVoucher from '../../../../hooks/useReadCartItemVoucher';
import { ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import BorderLeftBox from '../../../generic/BorderLeftBox';
import CartElementBoxes from '../CartElementBoxes';
import CartElementDeleteAction from '../CartElementDeleteAction';
import CartElementPrice from '../CartElementPrice';
import CartProductImage from '../CartProductImage';
import CartElementVoucherSmall from './CartElementVoucherSmall';

const productTitleProps = {
  as: 'span',
  fontSize: 'md',
};

const CartElementVoucher = ({ cartItem, cartData, isTaxIncluded, blockActions }) => {
  const isMobile = useIsMobile();
  const StackElement = isMobile ? VStack : HStack;

  const { cartOrderId, products } = cartData;
  const { product, voucher, elementPrice, elementPriceWithoutPromo } = useReadCartItemVoucher(cartItem, products, isTaxIncluded);
  const { title: productTitle, description_image: productImage } = product;
  const { id: voucherId, quantity: voucherQuantity, expiration: voucherExpiration, label: voucherLabel } = voucher;

  const cartElementPriceProps = {
    elementPrice: elementPrice,
    elementPriceWithoutPromo: elementPriceWithoutPromo,
  };

  if (elementPrice < 0) {
    return (
      <CartElementVoucherSmall
        product={product}
        productTitleProps={productTitleProps}
        elementPrice={elementPrice}
        cartOrderId={cartOrderId}
        cartItemId={cartItem.id}
        voucherId={voucherId}
        blockActions={blockActions}
        showDelete
      />
    );
  }

  return (
    <Box bg="brand.background_main" {...ROUNDED_ELEMENT_PROPS} position="relative">
      <StackElement spacing="24px">
        <CartProductImage productImage={productImage} />

        <VStack align="left" flexGrow="1" width="100%">
          <Text {...productTitleProps}>{productTitle}</Text>

          <HStack align="left" flexGrow="1" alignItems="left">
            <BorderLeftBox>
              <CartElementBoxes voucherQuantity={voucherQuantity} voucherExpiration={voucherExpiration} voucherLabel={voucherLabel} />
            </BorderLeftBox>
          </HStack>
        </VStack>
      </StackElement>

      {!isMobile && (
        <>
          <CartElementPrice {...cartElementPriceProps} position="absolute" bottom="4px" right=".6rem" />
          <CartElementDeleteAction cartOrderId={cartOrderId} getItemIdsToDelete={() => [cartItem.id, voucherId]} blockActions={blockActions} />
        </>
      )}

      {isMobile && (
        <>
          <Divider orientation="horizontal" mt="1rem" />
          <HStack alignItems="flex-end">
            <CartElementDeleteAction
              cartOrderId={cartOrderId}
              getItemIdsToDelete={() => [cartItem.id, voucherId]}
              position="relative"
              alignItems="flex-end"
              blockActions={blockActions}
            />
            <Spacer />
            <CartElementPrice {...cartElementPriceProps} />
          </HStack>
        </>
      )}
    </Box>
  );
};

export default CartElementVoucher;
