import useIsMobile from '../../../hooks/useIsMobile';
import DatepickerCommon from './DatepickerCommon';

const DatePicker = ({ fieldName, fieldValue, onChange, ...props }) => {
  const isMobile = useIsMobile();

  return <DatepickerCommon isMobile={isMobile} selected={fieldValue} onChange={date => onChange(fieldName, date)} {...props} />;
};

export default DatePicker;
