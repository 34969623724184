import { defineMessage } from '@lingui/macro';

import { COUNTRY_LIST_FOR_SELECT } from '../../../utils/countries';
import Select from './Select';

const SelectCountry = props => {
  return <Select fieldName="country" fieldLabel={defineMessage({ message: 'Country' })} fieldChoices={COUNTRY_LIST_FOR_SELECT} {...props} />;
};

export default SelectCountry;
