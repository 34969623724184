import { extendTheme } from '@chakra-ui/react';

export const getThemeFromColors = colors =>
  extendTheme({
    colors: {
      brand: colors,
    },
    styles: {
      global: {
        body: {
          bg: 'none',
        },
      },
    },
    fonts: {
      heading: `'InterVariable', sans-serif`,
      body: `'InterVariable', sans-serif`,
    },
    // Default chakra-ui sizes are in comments
    fontSizes: {
      xs: '0.8rem', // 0.75
      sm: '0.9rem', // 0.875
      md: '1.1rem', // 1
      lg: '1.2rem', // 1.125
      xl: '1.4rem', // 1.25
    },
    components: {
      Alert: {
        baseStyle: props => {
          const { status } = props;

          const customTheming = ['error', 'success'].includes(status) && {
            container: {
              bg: 'brand.background_alert_' + status,
              color: 'brand.color_font',
            },
            icon: {
              color: 'brand.color_alert_' + status + '_icon',
            },
          };
          return {
            ...customTheming,
          };
        },
      },
      Button: {
        baseStyle: {
          fontWeight: 'normal',
          borderRadius: '5px',
        },
        variants: {
          solid: {
            bg: 'brand.color_main',
            color: 'brand.color_font_buttons',
            _hover: {
              bg: { base: undefined, md: 'brand.background_buttons_hover' },
              _disabled: {
                bg: { base: undefined, md: 'brand.background_buttons_hover' },
              },
            },
            _active: {
              bg: 'brand.background_buttons_active',
            },
            _loading: { _hover: { bg: { base: undefined, md: 'brand.background_buttons_hover' } } },
          },
          icon: {
            color: 'brand.color_main',
            bg: 'transparent',
            _hover: { bg: { base: undefined, md: 'transparent' }, opacity: { base: '1', md: '.6' } },
            _active: { bg: 'transparent', opacity: '.3' },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          fontSize: '.8rem',
          marginBottom: '1px',
          color: 'brand.color_main',
        },
      },
      Input: {
        sizes: {
          md: {
            field: {
              height: '35px',
              fontSize: 'sm',
            },
          },
        },

        variants: {
          outline: {
            field: {
              borderColor: 'brand.color_main',
            },
          },
        },

        defaultProps: {
          focusBorderColor: 'brand.color_main',
        },
      },
      Link: {
        baseStyle: {
          color: 'brand.color_main',
          textDecoration: 'underline',
          fontSize: 'xs',
        },
      },
      NumberInput: {
        sizes: {
          md: {
            field: {
              height: '35px',
              fontSize: 'sm',
            },
          },
          sm: {
            field: {
              borderRadius: '0.375rem', // same as chakra md tags
              height: '30px',
            },
          },
        },

        variants: {
          outline: {
            field: {
              borderColor: 'brand.color_main',
            },
          },
        },

        defaultProps: {
          focusBorderColor: 'brand.color_main',
        },
      },
      Select: {
        sizes: {
          md: {
            field: {
              height: '35px',
              fontSize: 'sm',
            },
          },
          xs: {
            field: {
              borderRadius: '0.375rem', // same as chakra md tags
            },
          },
        },

        variants: {
          outline: {
            field: {
              borderColor: 'brand.color_main',
            },
          },
        },
      },
      Textarea: {
        variants: {
          outline: {
            borderColor: 'brand.color_main',
          },
        },

        defaultProps: {
          focusBorderColor: 'brand.color_main',
        },
      },
    },
  });
