import { useEffect, useState } from 'react';

import { Flex, HStack, Icon, VStack } from '@chakra-ui/react';
import { BsDot } from 'react-icons/bs';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useInterval } from 'usehooks-ts';

import { settings } from '../../settings/settings';
import { COMMON_GAP } from '../../utils/styleUtil';
import ButtonIcon from './button/ButtonIcon';
import Image from './Image';

const ImagesSlider = ({ mainImage, productMedias, w = '100%', maxW, h, maxH, ...props }) => {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setPlaying] = useState(true);

  const constructSlides = () => {
    const slidesToSet = [];
    if (mainImage) slidesToSet.push(mainImage);

    for (const data of productMedias) {
      if (data['media_image']) slidesToSet.push(data['media_image']);
    }

    return slidesToSet;
  };

  useEffect(() => {
    setSlides(constructSlides());
    setCurrentIndex(0);
  }, [JSON.stringify(productMedias), mainImage]);

  useInterval(
    () => {
      if (!isPlaying) return;

      if (currentIndex === slides.length - 1) {
        setCurrentIndex(0);
        setPlaying(false);
        return;
      }

      setCurrentIndex(currentIndex + 1);
    },
    isPlaying ? settings.IMAGE_SLIDER_DELAY_MS : null
  );

  const gotoNextSlide = () => {
    setPlaying(false);

    const newIndex = currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const gotoPrevSlide = () => {
    setPlaying(false);

    const newIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  if (!slides) return <></>;

  return (
    <VStack w={w} maxW={maxW} h={h} maxH={maxH}>
      <>
        {slides.map((image, index) => (
          <Image key={index} imageUrl={image} slideDisplay={index == currentIndex ? 'yes' : 'no'} {...props} />
        ))}
      </>

      <Flex w="full" my={COMMON_GAP} h="4px" alignItems="center" justifyContent="center">
        <ButtonIcon icon={<Icon as={FaAngleLeft} boxSize="24px" />} onClick={gotoPrevSlide} />

        <HStack gap="0">
          {slides.map((image, index) => (
            <Icon as={BsDot} key={index} color={index == currentIndex ? 'brand.color_main' : 'gray.400'} boxSize="40px" overflow="hidden" />
          ))}
        </HStack>

        <ButtonIcon icon={<Icon as={FaAngleRight} boxSize="24px" />} onClick={gotoNextSlide} />
      </Flex>
    </VStack>
  );
};

export default ImagesSlider;
