import { DrawerBody, FormLabel, HStack, Input } from '@chakra-ui/react';
import { t } from '@lingui/macro';

const DevThemeColorList = ({ colorsToUse, onColorUpdate }) => {
  // This **MUST** be inside the component for translations to work
  // The order below will be the display order in the dev theme tool
  const THEME_COLOR_LABELS = {
    color_main: t`Main color`,
    background_main: t`Main background`,
    background_secondary: t`Secondary background`,
    background_container: t`Global container`,
    color_font: t`Font`,
    color_font_buttons: t`Buttons font`,

    // These are less important
    background_smallbox: t`Smallbox background`,
    color_smallbox_border: t`Smallbox border`,
    background_buttons_active: t`Buttons background (click)`,
    background_buttons_hover: t`Buttons background (hover)`,
    background_alert_success: t`Success alert background`,
    background_alert_error: t`Error alert background`,
    color_alert_success_icon: t`Success alert icon`,
    color_alert_error_icon: t`Error alert icon`,
  };

  return (
    <DrawerBody>
      {Object.entries(THEME_COLOR_LABELS).map((k, v) => (
        <HStack key={k[0]} spacing="0">
          <Input
            type="color"
            onChange={e => onColorUpdate(k[0], e.target.value)}
            minW="30px"
            w="30px"
            p="0"
            border="none"
            value={colorsToUse[k[0]]}
          />
          <FormLabel color="black" fontSize="xs" pl=".5rem">
            {k[1]}
          </FormLabel>
        </HStack>
      ))}
    </DrawerBody>
  );
};

export default DevThemeColorList;
