import { Flex, Radio } from '@chakra-ui/react';
import { defineMessage } from '@lingui/macro';

import { settings } from '../../../settings/settings';
import InputNumber from '../../generic/form/InputNumber';
import InputRadio from '../../generic/form/InputRadio';

const BuyVoucherQuantity = ({ selectedVoucher, handleVoucherUpdate, flexDirection = 'row' }) => {
  return (
    <Flex gap="1rem" flexDirection={flexDirection} alignItems={flexDirection == 'column' ? 'center' : undefined}>
      <InputNumber
        fieldLabel={defineMessage({ message: 'Quantity' })}
        fieldValue={selectedVoucher.quantity}
        onChange={(fieldName, newValue) => handleVoucherUpdate({ quantity: newValue })}
        isRequired
        minWidth="115px"
        maxWidth="115px"
        fieldMaximum={settings.VOUCHER_BUY_LIMIT}
      />

      {selectedVoucher.quantity > 1 && (
        <InputRadio
          fieldValue={selectedVoucher.isMultiUsage === true ? 1 : selectedVoucher.quantity}
          onChange={() => handleVoucherUpdate({ toggleMultiUsage: true })}
          fieldLabel={defineMessage({ message: 'Number of recipients' })}
        >
          <Radio value={1}>1</Radio>
          <Radio value={selectedVoucher.quantity}>{selectedVoucher.quantity}</Radio>
        </InputRadio>
      )}
    </Flex>
  );
};

export default BuyVoucherQuantity;
