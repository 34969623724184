import { defineMessage } from '@lingui/macro';

import useReadPaymentLinkTemplates from '../../../hooks/useReadPaymentLinkTemplates';
import Select from '../../generic/form/Select';

export const PAYMENTLINK_TEMPLATE_FIELDNAME = 'email_content_id';

const CheckoutPaymentLinkTemplate = props => {
  const paymentLinkTemplates = useReadPaymentLinkTemplates();

  return (
    <Select
      fieldName={PAYMENTLINK_TEMPLATE_FIELDNAME}
      fieldLabel={defineMessage({ message: 'Email template' })}
      fieldChoices={paymentLinkTemplates}
      triggerChangeOnUpdate
      {...props}
    />
  );
};

export default CheckoutPaymentLinkTemplate;
