import { useContext } from 'react';

import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { Plural, Trans } from '@lingui/macro';

import { CoreContext } from '../../../../context/CoreContext';
import useWriteVariants from '../../../../hooks/useWriteVariants';
import { settings } from '../../../../settings/settings';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, COMMON_GAP, COMMON_PADDING, ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import FetchLoaderSkeleton from '../../../generic/FetchLoaderSkeleton';
import InputNumber from '../../../generic/form/InputNumber';
import ContainerPlural from '../../../generic/layout/ContainerPlural';
import { OPTION_PARTICIPANT_KEYNAME } from '../options/OptionParticipant';
import SessionVariantNotBookable from '../SessionVariantNotBookable';

const SessionVariantsByQuantity = ({ availability, defaultVariant, statusLoadVariants, maxAvailablePlaces, canBookMore }) => {
  const { coreData } = useContext(CoreContext);
  const { show_nb_remaining_tickets: showNbPlaces } = coreData.booking_engine;

  const { areVariantsLoaded, variants, onOptionUpdate, placesAvailable } = useWriteVariants(availability, defaultVariant, maxAvailablePlaces);

  if (!areVariantsLoaded()) {
    return (
      <Box bg="brand.background_secondary" p={COMMON_PADDING} {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
        <FetchLoaderSkeleton status={statusLoadVariants} skeletonsNumber={1} skeletonHeight="100px" />
      </Box>
    );
  }

  if (!canBookMore) return <SessionVariantNotBookable />;

  // When we're here we already have a variant on which we only change the number of participants
  // All required checks have been done previously by the form hook
  return (
    <Box bg="brand.background_secondary" p={COMMON_GAP} {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
      <Box bg="brand.background_main" position="relative" {...ROUNDED_ELEMENT_PROPS}>
        <Flex wrap="wrap" gap={COMMON_GAP} alignItems="center" justifyContent="center">
          <InputNumber
            fieldValue={variants[0].participant}
            fieldMaximum={maxAvailablePlaces ?? settings.PARTICIPANTS_QTY_LIMIT}
            onChange={(fieldName, newValue) => onOptionUpdate(OPTION_PARTICIPANT_KEYNAME, newValue, 0)}
            isRequired
            minWidth="70px"
            maxWidth="70px"
            displayLabel={false}
            size="sm"
          />
          <Text fontSize="sm" color="brand.color_main">
            <Trans>participant(s)</Trans>
          </Text>
          {settings.TRACE_VARIANT_MAX_PLACES && <Text>MAX={maxAvailablePlaces ?? settings.PARTICIPANTS_QTY_LIMIT}</Text>}
        </Flex>
      </Box>

      {maxAvailablePlaces !== 1 && placesAvailable !== null && showNbPlaces && (
        <Center>
          <Text fontSize="13px" as="span" color={placesAvailable === 0 ? 'red' : undefined} pt={COMMON_GAP}>
            {'('}
            <ContainerPlural value={placesAvailable} style={{ display: 'inline' }}>
              <Plural value={placesAvailable} one="place available" other="places available" />
            </ContainerPlural>
            {')'}
          </Text>
        </Center>
      )}
    </Box>
  );
};

export default SessionVariantsByQuantity;
