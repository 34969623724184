import { useMemo } from 'react';

const useReadCheckoutPrice = priceData => {
  const { taxes, tax_excluded: priceTaxExcluded, tax_included: priceTaxIncluded } = priceData;

  const { taxesLabel, taxesTotal } = useMemo(() => formatTaxes(taxes), [JSON.stringify(taxes)]);

  return { priceTaxIncluded, priceTaxExcluded, taxesLabel, taxesTotal };
};

const formatTaxes = taxes => {
  let taxesLabel = '';
  let taxesTotal = 0.0;
  for (const key of Object.keys(taxes)) {
    taxesLabel += key + ', ';
    taxesTotal += parseFloat(taxes[key]);
  }
  taxesLabel = taxesLabel.length > 0 ? '(' + taxesLabel.substring(0, taxesLabel.length - 2) + ')' : null;
  return { taxesLabel, taxesTotal };
};

export default useReadCheckoutPrice;
