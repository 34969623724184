import { HStack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { COMMON_GAP } from '../../../utils/styleUtil';
import ButtonDelete from '../../generic/button/ButtonDelete';

const CheckoutDetailParticipantHeader = ({ participantId, participantNumber, minParticipantCount, onParticipantDelete }) => {
  const canDeleteParticipant = participantNumber > minParticipantCount;

  return (
    <HStack gap={COMMON_GAP} justifyContent="center" alignItems="center" position="relative">
      <Text fontSize="md" color="brand.color_main" mt=".5rem">
        <Trans>Participant {participantNumber}</Trans>
      </Text>

      {canDeleteParticipant && (
        <ButtonDelete
          onClick={() => onParticipantDelete(participantId)}
          position={{ base: 'relative', md: 'absolute' }}
          w={{ base: 'full', md: 'auto' }}
        />
      )}
    </HStack>
  );
};

export default CheckoutDetailParticipantHeader;
