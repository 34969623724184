import { Box } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { getFormattedCurrency } from '../../../utils/business/prices';
import LabelWithText from '../../generic/LabelWithText';

const labelProps = {
  labelSize: 'md',
  textSize: '10px',
  labelWeight: 'bold',
};

const AvailabilityPriceInfo = ({ product, price, maxBookableSessions }) => {
  const { free: isFree, price_per_participant: isPricePerParticipant } = product;

  return (
    <Box>
      {isFree && <LabelWithText label={<Trans>Free</Trans>} text={<></>} {...labelProps} />}

      {!isFree && (
        <LabelWithText label={getFormattedCurrency(price)} text={isPricePerParticipant ? <></> : <Trans>per session</Trans>} {...labelProps} />
      )}
      {!isFree && !isPricePerParticipant && maxBookableSessions && (
        <LabelWithText label="" text={<Trans>({maxBookableSessions} available)</Trans>} {...labelProps} />
      )}
    </Box>
  );
};

export default AvailabilityPriceInfo;
