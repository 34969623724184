import { useState } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import {
  apiUpdateParticipants,
  LOCAL_STORAGE_ASSIGN,
  LOCAL_STORAGE_ASSIGN_DEFVAL,
  LOCAL_STORAGE_CART,
  LOCAL_STORAGE_CART_DEFVAL,
  LOCAL_STORAGE_PARTICIPANTS,
  LOCAL_STORAGE_PARTICIPANTS_DEFVAL,
} from '../constants';
import { handleParticipantDataForBackend } from '../utils/business/participants';
import useCalcCartSize from './useCalcCartSize';
import usePost from './usePost';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const usePostParticipants = () => {
  const [cartData, setCartData] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART, LOCAL_STORAGE_CART_DEFVAL);
  const cartSize = useCalcCartSize(cartData);

  // eslint-disable-next-line no-unused-vars
  const [participantsData, setParticipantsData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PARTICIPANTS, LOCAL_STORAGE_PARTICIPANTS_DEFVAL);
  // eslint-disable-next-line no-unused-vars
  const [activityAssignData, setActivityAssignData] = useWriteStorageBookingEngine(LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_ASSIGN_DEFVAL);

  const [postData, setPostData] = useState(null);
  const { data, status, executePost } = usePost(apiUpdateParticipants(), postData);

  const [isSuccess, setIsSuccess] = useState(null);

  const [hasUnAssignedParticipants, setHasUnAssignedParticipants] = useState(false);

  const updateParticipants = () => {
    const _hasUnAssignedParticipantsCount = checkHasUnAssignedParticipants();
    setHasUnAssignedParticipants(_hasUnAssignedParticipantsCount);
    if (_hasUnAssignedParticipantsCount) return false;

    const newParticipantsData = [];
    for (const participant of participantsData) {
      if (!participant.displayed) continue;

      newParticipantsData.push(handleParticipantDataForBackend(participant));
    }
    setPostData({ clients: newParticipantsData, items: activityAssignData });
    return true;
  };

  const checkHasUnAssignedParticipants = () => {
    for (const participant of participantsData) {
      if (!participant.displayed) continue;

      const countParticipantsItems = getCountParticipantItems(participant.id);
      if (countParticipantsItems === 0) return true;
    }
    return false;
  };

  const getCountParticipantItems = participantId => {
    let count = 0;
    for (const item of activityAssignData) {
      if (item.client_id === participantId) count++;
    }
    return count;
  };

  useUpdateEffect(() => {
    executePost();
  }, [postData]);

  useUpdateEffect(() => {
    const success = data ? data.success : false;
    if (success) {
      // We update the cartData so that when we go back or something...
      // ...we will have the saved data inside the cart
      updateCartData();
    }
    setIsSuccess(success);
  }, [status]);

  const updateCartData = () => {
    const newCartData = {
      ...cartData,
      clients: participantsData,
    };
    setCartData(newCartData);
  };

  return { cartSize, isSuccess, status, hasUnAssignedParticipants, updateParticipants };
};

export default usePostParticipants;
