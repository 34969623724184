import { useEffectOnce } from 'usehooks-ts';

import { ROUTE_P1_PRODUCT_SELECTION } from '../../../constants';
import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import useReadCanBuyVoucher from '../../../hooks/useReadCanBuyVoucher';
import useWritePreCart from '../../../hooks/useWritePreCart';
import BuyVoucherForm from './BuyVoucherForm';

const BuyVoucher = () => {
  const { preCartData } = useWritePreCart();
  const { product } = preCartData;

  const canBuyVoucher = useReadCanBuyVoucher(product);

  const navigate = useNavigateBookingEngine();
  useEffectOnce(() => {
    if (!canBuyVoucher) {
      navigate(ROUTE_P1_PRODUCT_SELECTION);
    }
  });

  if (!canBuyVoucher) return <></>;
  return <BuyVoucherForm product={product} />;
};

export default BuyVoucher;
