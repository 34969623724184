import { AccordionButton, AccordionIcon, Box } from '@chakra-ui/react';

import useIsMobile from '../../hooks/useIsMobile';
import { COMMON_PADDING } from '../../utils/styleUtil';

const AccordionHeader = ({ onClick, ...props }) => {
  const isMobile = useIsMobile();

  return (
    <h2>
      <AccordionButton
        bg="brand.background_secondary"
        p={COMMON_PADDING}
        borderTopRadius="10px"
        onClick={onClick}
        _hover={{ bg: 'brand.background_secondary' }}
      >
        <Box flex="1" textAlign="left" fontSize="sm" textTransform="uppercase" fontWeight="500">
          {props.children}
        </Box>
        {!isMobile && <AccordionIcon />}
      </AccordionButton>
    </h2>
  );
};

export default AccordionHeader;
