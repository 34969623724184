import { FormControl, HStack, useRadioGroup } from '@chakra-ui/react';
import { i18n } from '@lingui/core';
import { msg } from '@lingui/macro';

import { COMMON_INPUT_HEIGHT } from '../../../utils/styleUtil';
import CustomRadioContainer from './CustomRadioContainer';
import InputFormLabel from './InputFormLabel';

export const RADIO_YES = msg`Yes`;
const RADIO_NO = msg`No`;

const InputRadioCard = ({ fieldName, fieldLabel, fieldValue, fieldChoices, displayLabel = true, isRequired, onChange, minWidth, maxWidth }) => {
  const validFieldChoices = fieldChoices ?? [i18n._(RADIO_YES), i18n._(RADIO_NO)];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: fieldName,
    value: fieldValue,
    onChange: newValue => onChange(fieldName, newValue),
  });
  const group = getRootProps();

  return (
    <FormControl isRequired={isRequired} minW={minWidth} maxW={maxWidth}>
      {displayLabel && <InputFormLabel fieldName={fieldName} fieldLabel={fieldLabel} />}
      <HStack {...group} gap={0} spacing={0} borderColor="brand.color_main" borderWidth="1px" borderRadius="md" h={COMMON_INPUT_HEIGHT}>
        {validFieldChoices.map(value => {
          const radio = getRadioProps({ value });
          const isFirstItem = value === validFieldChoices[0];
          const isLastItem = value === validFieldChoices[validFieldChoices.length - 1];
          return (
            <CustomRadioContainer key={value} {...radio} isFirstItem={isFirstItem} isLastItem={isLastItem}>
              {value}
            </CustomRadioContainer>
          );
        })}
      </HStack>
    </FormControl>
  );
};

export default InputRadioCard;
