import { forwardRef } from 'react';

import { IconButton } from '@chakra-ui/react';

// using forwardRef here to be able to include this component into the PopoverTrigger

const ButtonIcon = forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    color="brand.color_main"
    bg="transparent"
    _hover={{ bg: { base: undefined, md: 'transparent' }, opacity: { base: '1', md: '.6' } }}
    _active={{ bg: 'transparent', opacity: '.3' }}
    cursor="pointer"
    {...props}
  />
));

ButtonIcon.displayName = 'ButtonIcon';

export default ButtonIcon;
