import { useRef, useState } from 'react';

import { Icon, IconButton, Spinner, useDisclosure } from '@chakra-ui/react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { IoMdTrash } from 'react-icons/io';
import { MdClose } from 'react-icons/md';

import useIsMobile from '../../../hooks/useIsMobile';

const ButtonDelete = ({
  onClick,
  enableSpinner = true,
  position = 'absolute',
  alignItems,
  showAlert = false,
  defaultIcon,
  blockActions,
  ...props
}) => {
  const isMobile = useIsMobile();
  const iconToUse = defaultIcon ?? (isMobile ? IoMdTrash : MdClose);

  const [isClicked, setIsClicked] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const triggerActions = () => {
    if (blockActions) return;
    onClick();
  };

  const handleClick = e => {
    e.preventDefault();
    if (enableSpinner) {
      setIsClicked(true);
    }

    if (showAlert) {
      onOpen();
      return;
    }

    triggerActions();
  };

  const handleAlertClose = () => {
    setIsClicked(false);
    onClose();
  };

  const handleAlertConfirm = () => {
    triggerActions();
    onClose();
  };

  return (
    <>
      {!isClicked && (
        <IconButton
          icon={<Icon as={iconToUse} boxSize={{ base: '25px', md: '20px' }} />}
          onClick={handleClick}
          color="red"
          bg="transparent"
          position={position}
          alignItems={alignItems}
          top="0"
          right="0"
          style={{
            opacity: blockActions ? '.4' : undefined,
          }}
          _hover={{
            bg: 'transparent',
            opacity: '.5',
          }}
          _active={{
            bg: 'transparent',
            opacity: '.2',
          }}
          {...props}
        />
      )}

      {isClicked && (
        <Spinner
          position={position}
          alignItems={alignItems}
          top={position === 'relative' ? '-10px' : '15px'}
          right={position === 'relative' ? '0' : '15px'}
          size="sm"
        />
      )}

      {showAlert && (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleAlertClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <Trans>Delete cart element</Trans>
              </AlertDialogHeader>

              <AlertDialogBody>
                {blockActions && <Trans>You cannot delete this element now. Others items have to be deleted first.</Trans>}
                {!blockActions && <Trans>Are you sure? You cannot undo this action afterwards.</Trans>}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleAlertClose}>
                  {blockActions && <Trans>Close</Trans>}
                  {!blockActions && <Trans>Cancel</Trans>}
                </Button>
                {!blockActions && (
                  <Button bg="red" onClick={handleAlertConfirm} ml={3}>
                    <Trans>Delete</Trans>
                  </Button>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default ButtonDelete;
