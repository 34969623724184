import { useToast } from '@chakra-ui/react';
import { useEffectOnce } from 'usehooks-ts';

import {
  LOCAL_STORAGE_ASSIGN,
  LOCAL_STORAGE_ASSIGN_DEFVAL,
  LOCAL_STORAGE_CART,
  LOCAL_STORAGE_CART_DEFVAL,
  LOCAL_STORAGE_CART_EXPIRE,
  LOCAL_STORAGE_CART_EXPIRE_DEFVAL,
  LOCAL_STORAGE_CART_ID,
  LOCAL_STORAGE_CART_ID_DEFVAL,
  LOCAL_STORAGE_PARTICIPANTS,
  LOCAL_STORAGE_PARTICIPANTS_DEFVAL,
  LOCAL_STORAGE_PRECART,
  LOCAL_STORAGE_PRECART_DEFVAL,
  ROUTE_PAGE_CART,
} from '../constants';
import { devMessage } from '../settings/settings';
import useNavigateBookingEngine from './useNavigateBookingEngine';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const TOAST_ID = 'cleanToastId';

/* eslint-disable no-unused-vars */
const useCleanStorage = (cleanAtStart = true) => {
  const toast = useToast();
  const navigate = useNavigateBookingEngine();

  const [activityAssignData, setActivityAssignData] = useWriteStorageBookingEngine(LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_ASSIGN_DEFVAL);
  const [cartData, setCartData] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART, LOCAL_STORAGE_CART_DEFVAL);
  const [cartExpire, setCartExpire] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART_EXPIRE, LOCAL_STORAGE_CART_EXPIRE_DEFVAL);
  const [cartId, setCartId] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART_ID, LOCAL_STORAGE_CART_ID_DEFVAL);
  const [participantsData, setParticipantsData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PARTICIPANTS, LOCAL_STORAGE_PARTICIPANTS_DEFVAL);
  const [preCartData, setPreCartData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PRECART, LOCAL_STORAGE_PRECART_DEFVAL);

  useEffectOnce(() => {
    if (!cleanAtStart) return;
    devMessage('Full storage cleanup');

    cleanAll();
  });

  const cleanVariants = () => {
    setPreCartData(LOCAL_STORAGE_PRECART_DEFVAL);
  };

  const cleanCart = () => {
    setCartData(LOCAL_STORAGE_CART_DEFVAL);
    setCartExpire(LOCAL_STORAGE_CART_EXPIRE_DEFVAL);
    setCartId(LOCAL_STORAGE_CART_ID_DEFVAL);
  };

  const cleanActivityAssign = () => {
    setActivityAssignData(LOCAL_STORAGE_ASSIGN_DEFVAL);
  };

  const cleanParticipants = () => {
    setParticipantsData(LOCAL_STORAGE_PARTICIPANTS_DEFVAL);
  };

  // This is the global all clean up
  const cleanAll = () => {
    cleanVariants();
    cleanCart();
    cleanParticipants();
    cleanActivityAssign();
  };

  // This is the cleanup done when the cart expires or when the order has already been paid
  // Or when the user decides to create a new cart (cart page)
  const cleanOrderGotoCart = message => {
    if (toast.isActive(TOAST_ID)) return;

    if (message) {
      toast({
        id: TOAST_ID,
        title: message,
        status: 'error',
        duration: 4000,
        variant: 'subtle',
      });
    }

    // We dont clean participants data bc might be reusable
    cleanVariants();
    cleanCart();
    cleanActivityAssign();

    navigate(ROUTE_PAGE_CART);
  };

  return { cleanVariants, cleanCart, cleanActivityAssign, cleanParticipants, cleanOrderGotoCart, cleanAll };
};

export default useCleanStorage;
