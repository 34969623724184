import { t } from '@lingui/macro';
import { useSearchParams } from 'react-router-dom';

import useWriteSeo from '../../hooks/useWriteSeo';
import SessionVariantsDirectAccess from '../business/sessionvariants/SessionVariantsDirectAccess';
import SessionVariantsForm from '../business/sessionvariants/SessionVariantsForm';

// ==============================================================================================
// Page infos :
// This is the 3rd page we see in the booking engine, after selecting an availability, we checkout the session group
// This page CAN be accessed directly from an url.
// Route infos :
// - standard navigation : no param, we reuse page 2 data
// - direct navigation : TODO
// ==============================================================================================

const SessionVariantsPage = () => {
  useWriteSeo({ title: t`Availability details` });

  const [queryParams] = useSearchParams();
  const sessionGroupId = queryParams.get('sessionGroupId');
  return (
    <>
      {!sessionGroupId && <SessionVariantsForm />}
      {sessionGroupId && <SessionVariantsDirectAccess sessionGroupId={sessionGroupId} />}
    </>
  );
};

export default SessionVariantsPage;
