import { FormControl, InputGroup, Textarea } from '@chakra-ui/react';
import { i18n } from '@lingui/core';

import InputFormLabel from './InputFormLabel';

const InputTextArea = ({ fieldName, fieldLabel, fieldPlaceholder, fieldValue, displayLabel = true, isRequired, onChange, minWidth, maxWidth }) => {
  return (
    <FormControl isRequired={isRequired} minW={minWidth} maxW={maxWidth}>
      {displayLabel && <InputFormLabel fieldName={fieldName} fieldLabel={fieldLabel} />}

      <InputGroup>
        <Textarea
          name={fieldName}
          id={fieldName}
          placeholder={fieldPlaceholder && i18n._(fieldPlaceholder)}
          value={fieldValue}
          onChange={e => onChange(fieldName, e.target.value)}
        />
      </InputGroup>
    </FormControl>
  );
};

export default InputTextArea;
