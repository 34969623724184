import { useMemo } from 'react';

import cloneDeep from 'lodash.clonedeep';

import { OPTION_PARTICIPANT_KEYNAME } from '../components/business/sessionvariants/options/OptionParticipant';
import { getTotalParticipants } from '../components/business/sessionvariants/persession/VariantsListPerSession';
import { deleteStateArrayIndex } from '../utils/stateUtil';
import useWritePreCart from './useWritePreCart';
import useWriteVariantsPrices from './useWriteVariantsPrices';
import useWriteVariantsStock from './useWriteVariantsStock';

const useWriteVariants = (availability, defaultVariant, maxAvailablePlaces) => {
  const { session_group_id: sessionGroupId, variants } = availability;

  const { setPreCartVariant } = useWritePreCart();

  const totalParticipants = useMemo(() => getTotalParticipants(variants), [JSON.stringify(variants)]);

  const { placesAvailable, updateOptionsStock } = useWriteVariantsStock(defaultVariant, variants, totalParticipants, maxAvailablePlaces);

  const {} = useWriteVariantsPrices(sessionGroupId, variants, totalParticipants, setPreCartVariant);

  const onOptionUpdate = (key, newValue, index) => {
    // If we altered the stock then the defaultVariant will be updated
    // If not it will be null to prevent doing useless state updates
    // Also we update stock **BEFORE** updating option because at this time we have the old+new values
    const updatedDefaultVariant = updateOptionsStock(key, newValue, index);

    const newData = updateOption(variants, key, newValue, index);
    setPreCartVariant(sessionGroupId, newData, updatedDefaultVariant);
  };

  const onVariantAdd = () => {
    setPreCartVariant(sessionGroupId, [...variants, cloneDeep(defaultVariant)]);
  };

  const onVariantDelete = index => {
    const updatedDefaultVariant = updateOptionsStock(OPTION_PARTICIPANT_KEYNAME, 0, index);
    setPreCartVariant(sessionGroupId, deleteStateArrayIndex(variants, index), updatedDefaultVariant);
  };

  const areVariantsLoaded = () => {
    return defaultVariant && variants && variants.length > 0;
  };

  return { areVariantsLoaded, variants, onVariantAdd, onVariantDelete, onOptionUpdate, placesAvailable, totalParticipants };
};

export default useWriteVariants;

const updateOption = (array, key, newValue, index) => {
  const newData = cloneDeep(array);

  if (key in newData[index]) {
    newData[index][key] = newValue;
    return newData;
  }

  for (const element of newData[index].custom_options) {
    if (element.id === key) {
      element.selectedResource = newValue;
      return newData;
    }
  }

  return array;
};
