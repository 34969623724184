import { useEffect, useMemo, useRef } from 'react';

import { t } from '@lingui/macro';
import cloneDeep from 'lodash.clonedeep';

import { LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_ASSIGN_DEFVAL, LOCAL_STORAGE_PARTICIPANTS, LOCAL_STORAGE_PARTICIPANTS_DEFVAL } from '../constants';
import { findArrayItemById, isArrEmpty } from '../utils/arrayUtil';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const useWriteParticipantsAssign = ocSessionsWithResources => {
  const autoAssignFinished = useRef(false);

  // eslint-disable-next-line no-unused-vars
  const [participantsData, setParticipantsData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PARTICIPANTS, LOCAL_STORAGE_PARTICIPANTS_DEFVAL);
  const [activityAssignData, setActivityAssignData] = useWriteStorageBookingEngine(LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_ASSIGN_DEFVAL);

  const participantsChoices = useMemo(() => getParticipantsChoices(participantsData), [JSON.stringify(participantsData)]);

  useEffect(() => {
    if (!participantsChoices || participantsChoices.length <= 0) return;

    autoAssignFinished.current = false;

    const newData = cloneDeep(activityAssignData);

    for (const entry of ocSessionsWithResources) {
      let index = 0;
      for (const ava of entry[1].availabilities) {
        // The participant choices were updated and are not in line with the cart yet
        const participantId = participantsChoices[index]?.value;
        if (!participantId) return;

        assignParticipantToAvailability(ava, newData, participantId);
        index++;
      }
    }

    setActivityAssignData(newData);
    autoAssignFinished.current = true;
  }, [participantsChoices]);

  return { autoAssignFinished, participantsChoices };
};
export default useWriteParticipantsAssign;

const getParticipantsChoices = participantsData => {
  const choices = [];
  if (!participantsData) return choices;

  let number = 1;
  for (const participant of participantsData) {
    if (!participant.displayed) continue;

    const label = getParticipantDisplayName(participant, number);
    choices.push({ value: participant.id, label: label });

    number++;
  }
  return choices;
};

export const assignParticipantToAvailability = (availability, activityAssignData, participantId) => {
  assignParticipantToItem(availability.id, activityAssignData, participantId);

  if (isArrEmpty(availability.resources)) return;

  // if the ava has options, we assign the client to those items as well !!
  for (const option of availability.resources) {
    assignParticipantToItem(option.id, activityAssignData, participantId);
  }
};

const assignParticipantToItem = (itemId, activityAssignData, participantId) => {
  const itemFound = findArrayItemById(itemId, activityAssignData);
  if (itemFound) {
    itemFound.client_id = participantId;
  } else {
    activityAssignData.push({ id: itemId, client_id: participantId });
  }
};

const getParticipantDisplayName = (participant, number) => {
  let label = (participant.first_name ?? '') + ' ' + (participant.last_name ?? '');
  if (label.trim() === '') {
    label = t`Participant` + ' ' + number;
  }
  return label;
};
