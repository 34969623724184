import { useState } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { apiSaveCart, LOCAL_STORAGE_CART_ID, LOCAL_STORAGE_CART_ID_DEFVAL } from '../constants';
import { gtmPushAddSessionToCart } from '../gtm';
import { findArrayItemById } from '../utils/arrayUtil';
import { handleParticipantDataForBackend } from '../utils/business/participants';
import { getVariantSelectedPrice, STANDARD_PRICE_SELECT_VALUE } from '../utils/business/prices';
import { isValidQuantityNumber } from '../utils/numberUtil';
import { getReferrerHost, REQUEST_STATUS } from '../utils/requestUtil';
import { getValidUuidOrNull } from '../utils/strUtil';
import useDeleteFromCart from './useDeleteFromCart';
import usePost from './usePost';
import useReadTeam from './useReadTeam';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const usePostCart = (receivedStoredCartId, availabilities) => {
  const { teamId, teamCurrency } = useReadTeam();
  const [preCartData, setPreCartData] = useState(null);
  const [formattedData, setFormattedData] = useState(null);
  const { data, status, executePost } = usePost(apiSaveCart(), formattedData);

  // eslint-disable-next-line no-unused-vars
  const [storedCartId, setStoredCartId] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART_ID, LOCAL_STORAGE_CART_ID_DEFVAL);

  // These are used for the edit feature
  const { setItemIdsToDelete, status: cartDeleteStatus } = useDeleteFromCart(storedCartId, true);
  const [preCartDataForEdit, setPreCartDataForEdit] = useState(null);

  const writeCart = newPreCartData => {
    setPreCartData(newPreCartData);
  };

  const editCart = newPreCartData => {
    if (JSON.stringify(newPreCartData) === JSON.stringify(preCartData)) {
      return false;
    }

    setPreCartDataForEdit(newPreCartData);

    // This change of state will trigger the delete
    setItemIdsToDelete(newPreCartData.itemsToDeleteForEdit);
    return true;
  };

  useUpdateEffect(() => {
    if (cartDeleteStatus === REQUEST_STATUS.OK) {
      setPreCartData(preCartDataForEdit);
    }
  }, [cartDeleteStatus]);

  // Only trigger the format if the actual state has changed !
  useUpdateEffect(() => {
    if (preCartData === null) return;

    if (preCartData.availabilities && preCartData.availabilities.length > 0) {
      setFormattedData(formatPreCartDataForBooking(preCartData.availabilities[0], availabilities, teamId, receivedStoredCartId));
    } else {
      setFormattedData(formatPreCartDataForShop(preCartData.shopResource, preCartData.shopResourceChild, teamId, receivedStoredCartId));
    }
  }, [JSON.stringify(preCartData)]);

  useUpdateEffect(() => {
    if (preCartData == null) return; // react reload

    // Push only on normal add, not on edit
    if (cartDeleteStatus === REQUEST_STATUS.LOADING) {
      gtmPushAddSessionToCart(preCartData, teamCurrency);
    }
    executePost();
  }, [formattedData]);

  useUpdateEffect(() => {
    if (!data || !data.order) return;
    setStoredCartId(data.order.id);
  }, [data]);

  return { data, status, writeCart, editCart };
};

export default usePostCart;

// During multi select we apply the variant to a single availability
// And at this time when sending data, we will apply the variant to all availabilities selected
const formatPreCartDataForBooking = (availabilityToCopy, allAvailabilities, teamId, storedCartId) => {
  const formattedData = getInitialFormattedData(teamId, storedCartId);
  let variantId = 0;
  for (const availability of allAvailabilities) {
    for (const variant of availabilityToCopy.variants) {
      const sessGid = availability.session_group_id;
      // const cartGid = availability.cart_group_id;
      variantId++;

      formattedData.items.push(createSessionItem(variant, sessGid, variantId));

      for (const option of variant.custom_options) {
        if (!option.selectedResource) continue;

        formattedData.items.push(createSessionResourceItem(sessGid, variant.participant, option, variantId));
      }
    }
  }
  return formattedData;
};

const getInitialFormattedData = (teamId, storedCartId) => {
  const validCartId = getValidUuidOrNull(storedCartId);
  const formattedData = { items: [] };
  formattedData[validCartId ? 'order_id' : 'team_id'] = validCartId ?? teamId;
  formattedData['origin'] = getReferrerHost();
  return formattedData;
};

const createSessionItem = (variant, sessionGroupId, variantId) => {
  const variantPrice = getVariantSelectedPrice(variant);
  const quantity = checkQuantity(variant.participant);
  const sessionItem = {
    session_group_id: sessionGroupId,
    price: variantPrice ?? 0,
    quantity: quantity,
    variant_id: variantId,
  };

  if (variant.participantDetails) {
    sessionItem['client'] = handleParticipantDataForBackend(variant.participantDetails.client);
  }

  if (variant.currentPriceVariationId !== STANDARD_PRICE_SELECT_VALUE && variantPrice != null) {
    sessionItem['price_variation_id'] = variant.currentPriceVariationId;
  }

  return sessionItem;
};

const createSessionResourceItem = (sessionGroupId, itemQuantity, option, variantId) => {
  const resourceId = option.selectedResource;
  const resource = findArrayItemById(resourceId, option.resources);
  const quantity = checkQuantity(itemQuantity);
  const formattedItem = {
    resource_target_group_id: sessionGroupId,
    resource_id: resourceId,
    price: resource.price,
    quantity: quantity,
    variant_id: variantId,
    // cart_group_id: cartGroupId,
  };
  return formattedItem;
};

const createSingleResourceItem = (resource, resourceChild) => {
  const quantity = checkQuantity(resource.quantity);
  const formattedItem = {
    resource_id: resourceChild ? resourceChild.id : resource.id,
    price: resource.price,
    quantity: quantity,
  };
  return formattedItem;
};

const formatPreCartDataForShop = (shopResource, shopResourceChild, teamId, storedCartId) => {
  const formattedData = getInitialFormattedData(teamId, storedCartId);
  formattedData.items.push(createSingleResourceItem(shopResource, shopResourceChild));
  return formattedData;
};

const checkQuantity = quantity => {
  const parsedQuantity = parseInt(quantity);
  if (!isValidQuantityNumber(parsedQuantity)) throw new Error();
  return parsedQuantity;
};
