// These are the settings specific to development mode

// No trailing slash !
const YOPLANNING_ROOT_URL = 'http://localhost:8000';
// const YOPLANNING_ROOT_URL = 'http://192.168.205.128:8000'; // Access from windows (requires ALLOWED_HOSTS in django dev cfg)

export const SETTINGS_DEV = {
  YOPLANNING_ROOT_URL: YOPLANNING_ROOT_URL,
  BOOKING_ENGINE_ROOT_URL: `${YOPLANNING_ROOT_URL}/booking-engine`, // No trailing slash !

  // Override the base settings in we want to test expiration
  CART_DAYS_EXPIRATION: 1,

  ROLLBAR_ENV_NAME: 'dev',
};
