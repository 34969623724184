import { findArrayItemById } from '../utils/arrayUtil';

const useReadUnavailableItems = (unavailableItems, allResources) => {
  const isSessionGroupUnavailable = sessGid => unavailableItems && unavailableItems.group_ids.includes(sessGid);
  const isVoucherUnavailable = giftVoucherId => unavailableItems && unavailableItems.voucher_ids.includes(giftVoucherId);
  const isSingleResourceUnavailable = resourceId => unavailableItems && unavailableItems.dry_resource_ids.includes(resourceId);

  const getUnavailableSessionResources = sessGid => {
    const unavailableResourceNames = [];
    if (!unavailableItems) return unavailableResourceNames;

    for (const elem of unavailableItems.resources) {
      if (elem.group_id !== sessGid) continue;

      const resource = findArrayItemById(elem.resource_id, allResources);
      unavailableResourceNames.push(resource.label);
    }

    return unavailableResourceNames;
  };

  return { isSessionGroupUnavailable, isVoucherUnavailable, isSingleResourceUnavailable, getUnavailableSessionResources };
};

export default useReadUnavailableItems;
