import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useIsMobile from '../../../../hooks/useIsMobile';
import useReadCartItemVoucher from '../../../../hooks/useReadCartItemVoucher';
import BorderLeftBox from '../../../generic/BorderLeftBox';
import CartElementBoxes from '../CartElementBoxes';
import CartElementPrice from '../CartElementPrice';
import CartProductImage from '../CartProductImage';
import CartElementVoucherSmall from './CartElementVoucherSmall';

const productTitleProps = {
  as: 'span',
  fontSize: 'md',
};

const CartElementVoucherCheckout = ({ cartItem, cartData, isTaxIncluded, isUnavailable }) => {
  const isMobile = useIsMobile();
  const StackElement = isMobile ? VStack : HStack;

  const { products } = cartData;
  const { product, voucher, elementPrice, elementPriceWithoutPromo } = useReadCartItemVoucher(cartItem, products, isTaxIncluded);
  const { title: productTitle, description_image: productImage } = product;
  const { quantity: voucherQuantity, expiration: voucherExpiration, label: voucherLabel } = voucher;

  if (elementPrice < 0) {
    return <CartElementVoucherSmall product={product} productTitleProps={productTitleProps} elementPrice={elementPrice} />;
  }

  return (
    <Box bg="brand.background_main" position="relative">
      <StackElement spacing="1rem">
        <CartProductImage productImage={productImage} />

        <VStack align="left" flexGrow="1" width="100%">
          {!isUnavailable && <Text {...productTitleProps}>{productTitle}</Text>}

          {isUnavailable && (
            <Alert status="error" bg="transparent" p="0">
              <AlertTitle fontSize="md">{productTitle}</AlertTitle>
              <AlertIcon mr="5px" />
              <AlertDescription fontSize="xs" color="red" pt="2px">
                <Trans>This element is not available anymore.</Trans>
              </AlertDescription>
            </Alert>
          )}

          <HStack align="left" flexGrow="1" alignItems="left">
            <BorderLeftBox>
              <CartElementBoxes voucherQuantity={voucherQuantity} voucherExpiration={voucherExpiration} voucherLabel={voucherLabel} />
            </BorderLeftBox>
          </HStack>
        </VStack>
      </StackElement>

      <CartElementPrice elementPrice={elementPrice} elementPriceWithoutPromo={elementPriceWithoutPromo} justifyContent="flex-end" mt=".5rem" />
    </Box>
  );
};

export default CartElementVoucherCheckout;
