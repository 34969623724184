import { IconButton, Link, VStack } from '@chakra-ui/react';

const ButtonIconLabel = ({ icon, onClick, iconBg, id, ...props }) => {
  return (
    <VStack textAlign="center" alignItems="center" onClick={onClick} id={id}>
      <IconButton variant="icon" bg={iconBg} fontSize="25px" icon={icon} />
      <Link maxWidth="90px">{props.children}</Link>
    </VStack>
  );
};

export default ButtonIconLabel;
