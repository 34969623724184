import { Link, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useBoolean } from 'usehooks-ts';

import useFormPromoCode from '../../../hooks/useFormPromoCode';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import ButtonSubmit from '../../generic/button/ButtonSubmit';
import InputText from '../../generic/form/InputText';

const CheckoutPromoCode = ({ teamId, cartOrderId }) => {
  const { value: isPromoFieldDisplayed, setTrue: displayPromoField } = useBoolean(false);

  const { onSubmitCode, isSubmitting, promoCode, setPromoCode, promoCodeSuccess, status, data } = useFormPromoCode(teamId, cartOrderId);

  if (!isPromoFieldDisplayed) {
    return (
      <Link onClick={displayPromoField} fontWeight="bold">
        <Trans>I have a voucher/promotional code</Trans>
      </Link>
    );
  }

  return (
    <form method="POST" onSubmit={onSubmitCode}>
      <VStack maxW={{ base: undefined, md: '200px' }} minW={{ base: undefined, md: '200px' }}>
        <InputText
          fieldName="code"
          fieldValue={promoCode}
          displayLabel={false}
          fieldPlaceholder="XXXXXX"
          onChange={(fName, fValue) => setPromoCode(fValue)}
        />

        <ButtonSubmit w="100%" isLoading={isSubmitting}>
          <Trans>Apply</Trans>
        </ButtonSubmit>

        {data && promoCodeSuccess && (
          <Text color="green" fontSize="xs">
            <Trans>The code is valid!</Trans>
          </Text>
        )}

        {data && !promoCodeSuccess && (
          <Text {...voucherErrorProps}>
            {data.error}
            {!data.error && <Trans>The code is invalid.</Trans>}
          </Text>
        )}

        {status === REQUEST_STATUS.ERROR && (
          <Text {...voucherErrorProps}>
            <Trans>An error occured, please try again later.</Trans>
          </Text>
        )}
      </VStack>
    </form>
  );
};

export default CheckoutPromoCode;

const voucherErrorProps = {
  color: 'red',
  fontSize: 'xs',
};
