import { ErrorBoundary, Provider } from '@rollbar/react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import ErrorFallback from './components/generic/error/ErrorFallback';
import Header from './components/generic/layout/header/Header';
import HeaderMobile from './components/generic/layout/header/HeaderMobile';
import AvailabilitiesSelectionPage from './components/pages/AvailabilitiesSelectionPage';
import BuyVoucherPage from './components/pages/BuyVoucherPage';
import CartPage from './components/pages/CartPage';
import CheckoutDetailPage from './components/pages/CheckoutDetailPage';
import CheckoutPage from './components/pages/CheckoutPage';
import CheckoutSuccessPage from './components/pages/CheckoutSuccessPage';
import CleanPage from './components/pages/CleanPage';
import NotFoundPage from './components/pages/NotFoundPage';
import ProductsSelectionPage from './components/pages/ProductsSelectionPage';
import SessionVariantsPage from './components/pages/SessionVariantsPage';
import ShopPage from './components/pages/ShopPage';
import ShopResourceBookingPage from './components/pages/ShopResourceBookingPage';
import {
  ROUTE_P2_AVA_SELECTION,
  ROUTE_PAGE_BUY_VOUCHER,
  ROUTE_PAGE_CART,
  ROUTE_P5_CHECKOUT_DETAILS,
  ROUTE_P6_CHECKOUT,
  ROUTE_PAGE_CHECKOUT_SUCCESS,
  ROUTE_PAGE_SHOP_BOOKING,
  ROUTE_P3_SESSION_VARIANTS,
  ROUTE_PAGE_CLEAN,
  ROUTE_P1_PRODUCT_SELECTION,
  ROUTE_PAGE_SHOP,
} from './constants';
import useIsMobile from './hooks/useIsMobile';
import { settings } from './settings/settings';

const AppRouting = ({ hasProducts, hasShopResources, shopOnly }) => {
  const isMobile = useIsMobile();

  const headerProps = {
    hasProducts: hasProducts,
    hasShopResources: hasShopResources,
    shopOnly: shopOnly,
  };

  const rollbarConfig = {
    accessToken: settings.ROLLBAR_TOKEN,
    environment: settings.ROLLBAR_ENV_NAME,
    enabled: settings.DEBUG ? settings.ROLLBAR_ENABLE_IN_DEV : true,
  };

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={ErrorFallback}>
        <BrowserRouter>
          <Routes>
            <Route path="/:bookingEngineId" element={<Outlet />}>
              <Route path="*" element={isMobile ? <HeaderMobile {...headerProps} /> : <Header {...headerProps} />}>
                {/* PAGES */}
                <Route path={ROUTE_P1_PRODUCT_SELECTION} element={shopOnly ? <ShopPage /> : <ProductsSelectionPage />} />

                <Route path={`${ROUTE_P2_AVA_SELECTION}/:productId`} element={<AvailabilitiesSelectionPage />} />

                <Route path={ROUTE_P3_SESSION_VARIANTS} element={<SessionVariantsPage />} />

                <Route path={ROUTE_PAGE_CART} element={<CartPage />} />
                <Route path={`${ROUTE_PAGE_CART}/:cartId`} element={<CartPage />} />

                <Route path={ROUTE_P5_CHECKOUT_DETAILS} element={<CheckoutDetailPage />} />

                <Route path={ROUTE_P6_CHECKOUT} element={<CheckoutPage />} />

                <Route path={ROUTE_PAGE_CHECKOUT_SUCCESS} element={<CheckoutSuccessPage />} />

                <Route path={ROUTE_PAGE_SHOP} element={<ShopPage />} />
                <Route path={`${ROUTE_PAGE_SHOP_BOOKING}/:resourceId`} element={<ShopResourceBookingPage />} />

                <Route path={ROUTE_PAGE_BUY_VOUCHER} element={<BuyVoucherPage />} />

                <Route path={ROUTE_PAGE_CLEAN} element={<CleanPage />} />

                {/* ELSE: NOT FOUND */}
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  );
};

export default AppRouting;
