import VerticalStack from '../../generic/layout/VerticalStack';
import CartElementSession from './elementtypes/CartElementSession';
import CartElementSingleResource from './elementtypes/CartElementSingleResource';
import CartElementVoucher from './elementtypes/CartElementVoucher';

const CartElementsList = ({ cartData, isTaxIncluded }) => {
  const cartElemProps = {
    cartData: cartData,
    isTaxIncluded: isTaxIncluded,
  };

  const cartHasNegativeLines = cartData.ocVouchers.some(function (cartItem) {
    return cartItem.price_tax_excluded < 0;
  });

  return (
    <VerticalStack>
      {cartData.ocSessionsWithResources.map(entry => {
        const groupedSessionGroups = entry[1];
        const sessGid = groupedSessionGroups.sessionGroupId;

        const { totalTaxExcluded, totalTaxExcludedWithoutPromo } = groupedSessionGroups;
        const isPriceWithoutPromo = totalTaxExcluded === totalTaxExcludedWithoutPromo;

        return (
          <CartElementSession
            key={sessGid + '_' + entry[0]}
            groupedSessionGroups={groupedSessionGroups}
            blockActions={cartHasNegativeLines}
            showEdit={isPriceWithoutPromo}
            {...cartElemProps}
          />
        );
      })}

      {cartData.ocVouchers.map(cartItem => (
        <CartElementVoucher key={cartItem.id} cartItem={cartItem} blockActions={cartHasNegativeLines} {...cartElemProps} />
      ))}

      {cartData.ocSingleResources.map(cartItem => (
        <CartElementSingleResource key={cartItem.id} cartItem={cartItem} blockActions={cartHasNegativeLines} {...cartElemProps} />
      ))}
    </VerticalStack>
  );
};

export default CartElementsList;
