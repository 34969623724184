import { Text } from '@chakra-ui/react';
import DOMPurify from 'dompurify';

const YoplanningHtml = ({ description, ...props }) => {
  return (
    <Text
      fontSize="xs"
      className="yoplanningDescription"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(description), // we have an html description so this is advised..
      }}
      {...props}
    />
  );
};

export default YoplanningHtml;
