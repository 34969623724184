import { Box, HStack, Spacer, Stack, Text, VStack } from '@chakra-ui/react';
import { t } from '@lingui/macro';

import useIsMobile from '../../../../hooks/useIsMobile';
import { findArrayItemById } from '../../../../utils/arrayUtil';
import { getFormattedCurrency } from '../../../../utils/business/prices';
import { ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import BorderLeftBox from '../../../generic/BorderLeftBox';
import Image from '../../../generic/Image';
import AvailabilityDateTime from '../../availability/AvailabilityDateTime';
import CartElementDetails from '../../cart/CartElementDetails';
import ActivityAssignList from './ActivityAssignList';

const CheckoutDetailActivity = ({ groupedSessionGroups, cartData, isTaxIncluded, participantsChoices }) => {
  const isMobile = useIsMobile();

  const { availabilities, totalTaxIncluded, totalTaxExcluded, productId, sessions, detailResources } = groupedSessionGroups;
  const { products, resources } = cartData;

  const elementPrice = isTaxIncluded ? totalTaxIncluded : totalTaxExcluded;
  const product = findArrayItemById(productId, products);
  const { title: productTitle, description_image: productImage } = product;

  return (
    <>
      <Box bg="brand.background_main" {...ROUNDED_ELEMENT_PROPS}>
        <HStack>
          <VStack alignItems="start">
            <Text fontSize="md">{productTitle}</Text>

            <BorderLeftBox>
              <AvailabilityDateTime sessions={sessions} />

              {/* elements all come from the same session group id so we dont care, we take the first avalability */}
              <CartElementDetails sessionGroupId={availabilities[0].session_group_id} detailResources={detailResources} />
            </BorderLeftBox>
          </VStack>

          {!isMobile && (
            <>
              <Spacer />
              <Image imageUrl={productImage} alt={t`Product image`} maxW="130px" />
            </>
          )}
        </HStack>

        <ActivityAssignList
          availabilities={availabilities}
          isTaxIncluded={isTaxIncluded}
          resources={resources}
          participantsChoices={participantsChoices}
        />

        {elementPrice > 0 && (
          <Stack alignItems="end">
            <Text fontWeight="bold">{getFormattedCurrency(elementPrice)}</Text>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default CheckoutDetailActivity;
