import { useContext, useRef } from 'react';

import { Alert, AlertIcon, Box, Center, CircularProgress } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffectOnce } from 'usehooks-ts';

import { LOCAL_STORAGE_CART } from '../../../constants';
import { CoreContext } from '../../../context/CoreContext';
import { gtmPushPurchase } from '../../../gtm';
import useCleanStorage from '../../../hooks/useCleanStorage';
import { CART_SOURCE_LOGGED_OFFICE } from '../../../hooks/useReadCartSource';
import useReadStorageBookingEngine from '../../../hooks/useReadStorageBookingEngine';
import useReadTeam from '../../../hooks/useReadTeam';
import { settings } from '../../../settings/settings';
import { ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';

const CheckoutSuccess = () => {
  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);
  const isOrderOffice = cartData != null && cartData.cartSource === CART_SOURCE_LOGGED_OFFICE;

  const { coreData } = useContext(CoreContext);
  const { success_url: successUrl } = coreData.booking_engine;

  // isOrderOffice IS VERY IMPORTANT
  // in online mode + iframe, the localStorage might be different before/after payment (because different origin)
  // so after payment cartData can be unreliable in this mode, so we dont want to check if cartSource is online
  // instead we read the office flag which is more reliable because no origin shit
  const doRedirection = useRef(successUrl && !isOrderOffice);

  const { teamCurrency } = useReadTeam();

  const { cleanAll } = useCleanStorage(false);

  const handlePurchaseSuccess = () => {
    cleanAll();
    if (doRedirection.current) {
      window.top.location.href = successUrl;
    }
  };

  useEffectOnce(() => {
    // No gtm : fire the callback directly and redirect if needed
    if (window.dataLayer == null || (settings.DEBUG && !settings.GTM_ENABLE_IN_DEV)) {
      handlePurchaseSuccess();
    }
    // Else gtm is here : gtm will fire the callback once the event is sent
    else {
      // TODO online+iframe cart is null!
      gtmPushPurchase(cartData, teamCurrency, handlePurchaseSuccess);
    }
  });

  return (
    <Center>
      {doRedirection.current && <CircularProgress isIndeterminate color="blue.500" mt="1rem" />}

      {!doRedirection.current && (
        <Box bg="brand.background_main" w={{ base: '100%', md: '50%' }} {...ROUNDED_ELEMENT_PROPS}>
          <Alert status="success" rounded={4}>
            <AlertIcon />
            <Trans>Your order was successfully received, thank you!</Trans>
          </Alert>
        </Box>
      )}
    </Center>
  );
};

export default CheckoutSuccess;
