import { defineMessage } from '@lingui/macro';

import useReadLoggedUser from '../../../../hooks/useReadLoggedUser';
import useReadParticipantsLangChoices from '../../../../hooks/useReadParticipantsLangChoices';
import InputBirthDate, { BIRTHDATE_FIELDNAME } from '../../../generic/form/InputBirthDate';
import InputEmail, { EMAIL_FIELDNAME } from '../../../generic/form/InputEmail';
import InputPhone, { PHONE_FIELDNAME } from '../../../generic/form/InputPhone';
import InputText from '../../../generic/form/InputText';
import Select from '../../../generic/form/Select';

const SpecificDataStandard = ({ data, fieldValue, onParticipantDataUpdate }) => {
  const { label, required } = data;

  const langChoices = useReadParticipantsLangChoices();
  const loggedUser = useReadLoggedUser();

  const fieldProps = {
    isRequired: loggedUser && label === PHONE_FIELDNAME ? false : required === 1,
    maxWidth: { base: '', md: '200px' },
    fieldValue: fieldValue,
    onChange: (fName, fieldValue, additionalData) => onParticipantDataUpdate(null, label, fieldValue, additionalData),
    className: 'ParticipantData_' + label,
  };

  return (
    <>
      {label === 'first_name' && <InputText fieldLabel={firstNameLabel} fieldPlaceholder={firstNameLabel} {...fieldProps} />}
      {label === 'last_name' && <InputText fieldLabel={lastNameLabel} fieldPlaceholder={lastNameLabel} {...fieldProps} />}

      {label === BIRTHDATE_FIELDNAME && <InputBirthDate {...fieldProps} />}

      {label === EMAIL_FIELDNAME && <InputEmail {...fieldProps} />}

      {label === 'language' && <Select fieldLabel={langLabel} fieldChoices={langChoices} triggerChangeOnUpdate {...fieldProps} />}

      {label === PHONE_FIELDNAME && <InputPhone {...fieldProps} />}
    </>
  );
};

export default SpecificDataStandard;

const firstNameLabel = defineMessage({ message: 'First name' });
const lastNameLabel = defineMessage({ message: 'Last name' });
const langLabel = defineMessage({ message: 'Language' });
