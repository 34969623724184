import { forwardRef } from 'react';

import { Input } from '@chakra-ui/react';

export const dateToIsoString = date => {
  if (!date || date === '') return '';

  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  return newDate.toISOString().substring(0, 10);
};

// This by default creates a list from 0h to 23h
// If the startHour is specified, eg: 6, then
// it will create a list from 6h to 23h and from 0h to 5h
export const getFilterHourArray = (startHour = 0, hourStep = 1) => {
  const hourFilter = [{ label: '-', value: '' }];

  const pushHour = hour => {
    return hourFilter.push({ label: hour + 'h', value: hour });
  };

  for (let i = startHour; i < 24; i += hourStep) {
    pushHour(i);
  }
  for (let i = 0; i < startHour; i += hourStep) {
    pushHour(i);
  }

  return hourFilter;
};

export const addDaysToDate = (dateObj, days) => {
  dateObj.setDate(dateObj.getDate() + days);
  return dateObj;
};

export const getThisMonday = () => {
  const date = new Date();
  const day = date.getDay() || 7; // Get current day number, converting Sun. to 7
  // Only manipulate the date if it isn't Mon.
  if (day !== 1) {
    date.setHours(-24 * (day - 1)); // Set the hours to day number minus 1 multiplied by negative 24
  }
  return date;
};

export const getNextMonday = () => addDaysToDate(getThisMonday(), 7);

// This is used to adapt react datepicker with chakra UI
const customDateInput = (props, ref) => <Input borderColor="brand.color_main" ref={ref} {...props} cursor="pointer" />;
export const ChakraDateInput = forwardRef(customDateInput);

// input type text for date : automatic slash management
// Modified from https://stackoverflow.com/a/44138117
export const DATE_SEPARATOR_FRONTEND = ' / '; // We use spaces for the automatic slash management below
const MAX_DATE_LEN = 14; // "DD / MM / YYYY", the spaces are used to delete automatically
export const autoHandleDateSlash = inputDateValue => {
  if (inputDateValue.length > MAX_DATE_LEN) return inputDateValue.substr(0, MAX_DATE_LEN);

  // This is when we delete
  if (/\D\/$/.test(inputDateValue)) {
    inputDateValue = inputDateValue.substr(0, inputDateValue.length - 3);
  }

  const splittedDate = inputDateValue.split('/').map(function (v) {
    return v.replace(/\D/g, '');
  });

  if (splittedDate[0]) splittedDate[0] = checkDateSplitElement(splittedDate[0], 31);
  if (splittedDate[1]) splittedDate[1] = checkDateSplitElement(splittedDate[1], 12);

  const unSplittedDate = splittedDate.map(function (v, i) {
    return v.length == 2 && i < 2 ? v + DATE_SEPARATOR_FRONTEND : v;
  });
  const formattedDate = unSplittedDate.join('').substr(0, MAX_DATE_LEN);
  return formattedDate;
};

const checkDateSplitElement = (splitDateValue, maxAllowed) => {
  if (splitDateValue.charAt(0) !== '0' || splitDateValue == '00') {
    let num = parseInt(splitDateValue);
    if (isNaN(num) || num <= 0 || num > maxAllowed) num = 1;
    const numStr = num.toString();
    splitDateValue = num > parseInt(maxAllowed.toString().charAt(0)) && numStr.length == 1 ? '0' + num : numStr;
  }
  return splitDateValue;
};

// Adapt the date from the text input to the format needed by backend
export const adaptDateForBackend = date => {
  if (!date) return null;
  const [dd, mm, yyyy] = date.split(DATE_SEPARATOR_FRONTEND);
  return `${yyyy}-${mm}-${dd}`;
};
// And the reverse operation when we load the participants data
export const adaptDateForFrontEnd = date => {
  if (date.includes('/')) return date; // already formatted
  const [yyyy, mm, dd] = date.split('-');
  return `${dd}${DATE_SEPARATOR_FRONTEND}${mm}${DATE_SEPARATOR_FRONTEND}${yyyy}`;
};

// Returns null if date is not complete, false if complete and invalid
export const isValidPastDate = dateStr => {
  if (!dateStr) return null;

  const [dd, mm, yyyy] = dateStr.split(DATE_SEPARATOR_FRONTEND);
  if (!dd || !mm || !yyyy) return null;

  const yearStr = yyyy.trim();
  if (yearStr.length != 4) return null;

  const date = new Date(`${yearStr}-${mm.trim()}-${dd.trim()}`);
  const year = date.getFullYear();
  if (isNaN(year) || year < 1900) return false;

  // 31 FEB ETC... WTF
  if (date.getMonth() + 1 !== parseInt(mm)) {
    return false;
  }

  return new Date() > date;
};

export const getDaysOfMonthArray = (year, month) => {
  const daysOfMonth = [];
  const monthStart = new Date(year, month, 1);
  const monthEnd = new Date(year, month + 1, 0);
  for (let d = monthStart; d <= monthEnd; d.setDate(d.getDate() + 1)) {
    daysOfMonth.push(new Date(d));
  }
  return daysOfMonth;
};

export const isSameDayOfDates = (dateA, dateB) => dateA && dateB && dateA.toDateString() === dateB.toDateString();
