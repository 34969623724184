import { HStack } from '@chakra-ui/react';

const SelectLayout = props => {
  if (!props.additionalComponentHRight) return props.children;

  return (
    <HStack spacing={0} gap={0}>
      {props.children}
      {props.additionalComponentHRight}
    </HStack>
  );
};

export default SelectLayout;
