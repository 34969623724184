import { ButtonGroup, Flex, Icon, IconButton, Link, Spacer, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { TiShoppingCart } from 'react-icons/ti';
import { VscArrowLeft } from 'react-icons/vsc';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import {
  LOCAL_STORAGE_CART,
  ROUTE_PAGE_CART,
  ROUTE_P5_CHECKOUT_DETAILS,
  ROUTE_P6_CHECKOUT,
  ROUTE_PAGE_CHECKOUT_SUCCESS,
  ROUTE_PAGE_SHOP_BOOKING,
  ROUTE_P1_PRODUCT_SELECTION,
  ROUTE_PAGE_SHOP,
} from '../../../../constants';
import useCalcCartSize from '../../../../hooks/useCalcCartSize';
import useNavigateBookingEngine from '../../../../hooks/useNavigateBookingEngine';
import useReadCartSource from '../../../../hooks/useReadCartSource';
import useReadNavigationToCheckoutDetail from '../../../../hooks/useReadNavigationToCheckoutDetail';
import useReadStorageBookingEngine from '../../../../hooks/useReadStorageBookingEngine';
import { settings } from '../../../../settings/settings';
import { BTN_ICON_BOXSIZE } from '../../../../utils/styleUtil';
import ButtonLink from '../../button/ButtonLink';
import DevCleanButton from '../../button/DevCleanButton';
import ExitActionContainer from '../../ExitActionContainer';
import TeamLogo from '../../TeamLogo';

const HeaderMobile = ({ hasProducts, hasShopResources, shopOnly }) => {
  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);
  const cartSize = useCalcCartSize(cartData);
  useReadCartSource(cartData);

  const navigate = useNavigateBookingEngine();
  const handleGoBack = e => {
    e.preventDefault();
    navigate(-1);
  };

  const handleGotoCart = e => {
    e.preventDefault();
    navigate(ROUTE_PAGE_CART);
  };

  const { areDetailsRequired } = useReadNavigationToCheckoutDetail(cartData);
  const handleGotoCheckoutDetail = e => {
    e.preventDefault();
    navigate(areDetailsRequired ? ROUTE_P5_CHECKOUT_DETAILS : ROUTE_PAGE_CART);
  };

  const handleContinueShopping = e => {
    e.preventDefault();
    navigate(ROUTE_P1_PRODUCT_SELECTION);
  };

  const arrowLeftIcon = <Icon as={VscArrowLeft} boxSize={BTN_ICON_BOXSIZE} />;
  const btnGoBack = <IconButton onClick={handleGoBack} icon={arrowLeftIcon} />;
  const btnGoBackCart = <IconButton onClick={handleGotoCart} icon={arrowLeftIcon} />;
  const btnGoBackCheckoutDetail = <IconButton onClick={handleGotoCheckoutDetail} icon={arrowLeftIcon} />;
  const btnContinueShopping = <IconButton onClick={handleContinueShopping} icon={arrowLeftIcon} />;

  const btnGotoCart = (
    <ButtonLink to={ROUTE_PAGE_CART} leftIcon={<Icon as={TiShoppingCart} boxSize={BTN_ICON_BOXSIZE} />}>
      <Text pt="1px">({cartSize})</Text>
    </ButtonLink>
  );

  const btnGotoShop =
    hasShopResources && !shopOnly ? (
      <Link as={RouterLink} to={ROUTE_PAGE_SHOP}>
        <Trans>Shop</Trans>
      </Link>
    ) : (
      <></>
    );

  const btnGotoBooking =
    hasProducts && !shopOnly ? (
      <Link as={RouterLink} onClick={handleContinueShopping}>
        <Trans>Booking</Trans>
      </Link>
    ) : (
      <></>
    );

  return (
    <>
      <Flex h="60px" minWidth="max-content" alignItems="center" p=".5rem" bg="brand.background_main">
        <Routes>
          <Route path={ROUTE_P1_PRODUCT_SELECTION} element={<></>} />
          <Route path={ROUTE_PAGE_SHOP} element={<></>} />
          <Route path={`${ROUTE_PAGE_CART}/*`} element={btnContinueShopping} />
          <Route path={ROUTE_PAGE_CHECKOUT_SUCCESS} element={btnContinueShopping} />
          <Route path={ROUTE_P5_CHECKOUT_DETAILS} element={btnGoBackCart} />
          <Route path={ROUTE_P6_CHECKOUT} element={btnGoBackCheckoutDetail} />
          <Route path="*" element={btnGoBack} />
        </Routes>

        <TeamLogo ml=".5rem" mt="4px" maxH="55px" />

        {/* This is a cleaning button displayed in development */}
        {settings.DEBUG && <DevCleanButton />}

        <Spacer />

        <ButtonGroup spacing=".7rem" alignItems="center">
          <Routes>
            <Route path={`${ROUTE_P1_PRODUCT_SELECTION}`} element={btnGotoShop} />
            <Route path={`${ROUTE_PAGE_SHOP}`} element={btnGotoBooking} />
            <Route path={`${ROUTE_PAGE_SHOP_BOOKING}/*`} element={btnGotoBooking} />
            <Route path="*" element={<></>} />
          </Routes>

          <Routes>
            <Route path={`${ROUTE_PAGE_CART}/*`} element={<></>} />
            <Route path={`${ROUTE_PAGE_CHECKOUT_SUCCESS}`} element={<></>} />
            <Route path="*" element={btnGotoCart} />
          </Routes>

          <ExitActionContainer />
        </ButtonGroup>
      </Flex>
      <Outlet />
    </>
  );
};

export default HeaderMobile;
