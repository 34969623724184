import { Divider } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useReadManufacturer from '../../../hooks/useReadManufacturer';
import CheckoutClientSelect, { CLIENT_SELECTOR_FIELDNAME } from './CheckoutClientSelect';
import CheckoutDividerTitle from './CheckoutDividerTitle';
import CheckoutMergeOrderCheckbox, { MERGE_ORDER_FIELDNAME } from './CheckoutMergeOrderCheckbox';

const CheckoutClientSelectBloc = ({
  paymentData,
  dataProps,
  clientSelectorChoices,
  onClientSelectorSearchChange,
  isClientSelectorLoading,
  previousOrderDetails,
}) => {
  const { canDisplaySelector, willSearchClients, willSearchStaffs } = useReadManufacturer();

  if (!canDisplaySelector) return <></>;
  return (
    <>
      <CheckoutDividerTitle isVisible>
        {willSearchClients && <Trans>Use existing client</Trans>}
        {willSearchStaffs && <Trans>Use existing staff</Trans>}
      </CheckoutDividerTitle>

      <CheckoutClientSelect
        fieldValue={paymentData[CLIENT_SELECTOR_FIELDNAME].value}
        {...dataProps}
        fieldChoices={clientSelectorChoices}
        handleSearchChange={onClientSelectorSearchChange}
        isLoading={isClientSelectorLoading}
        willSearchStaffs={willSearchStaffs}
      />

      {previousOrderDetails && (
        <CheckoutMergeOrderCheckbox fieldValue={paymentData[MERGE_ORDER_FIELDNAME]} previousOrderDetails={previousOrderDetails} {...dataProps} />
      )}

      <Divider orientation="horizontal" mt="1em" mb="1em" />

      <CheckoutDividerTitle isVisible={willSearchClients && paymentData[CLIENT_SELECTOR_FIELDNAME].value === null}>
        <Trans>Or create a new client</Trans>
      </CheckoutDividerTitle>
    </>
  );
};

export default CheckoutClientSelectBloc;
