import { useMemo } from 'react';

import { AspectRatio, Image as ChakraImage, Fade } from '@chakra-ui/react';

import { settings } from '../../settings/settings';
import { isAbsoluteUrl } from '../../utils/requestUtil';

const Image = ({ imageUrl, w = '100%', maxW, h, maxH, addYoplanningRootToSrc = true, slideDisplay, ...props }) => {
  const imageFullUrl = useMemo(() => getImageSrc(imageUrl, addYoplanningRootToSrc), [imageUrl]);

  return (
    <>
      {imageFullUrl && (
        <AspectRatio w={w} maxW={maxW} h={h} maxH={maxH} ratio={16 / 9} display={slideDisplay === 'no' ? 'none' : undefined}>
          <>
            {slideDisplay && (
              <Fade
                transition={{ exit: { delay: 0.9 }, enter: { duration: 0.9 } }}
                in={slideDisplay === 'yes'}
                style={{ borderRadius: '10px', flexShrink: '0' }}
              >
                <ChakraImage src={imageFullUrl} {...props} />
              </Fade>
            )}

            {!slideDisplay && <ChakraImage src={imageFullUrl} borderRadius="10px" flexShrink="0" {...props} />}
          </>
        </AspectRatio>
      )}
    </>
  );
};

export default Image;

const getImageSrc = (imageUrl, addYoplanningRootToSrc) => {
  // In production we have google storage absolute urls
  // Or also we manually asked to not add yop root
  if (!imageUrl || isAbsoluteUrl(imageUrl) || !addYoplanningRootToSrc) {
    return imageUrl;
  }

  // But otherwise the image is stored in yoplanning
  // Eg: /media/uploads/...
  return settings.YOPLANNING_ROOT_URL + imageUrl;
};
