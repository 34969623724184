import { getNearestMultipleOf } from '../utils/numberUtil';
import useReadDeposit from './useReadDeposit';

const useCalcDeposit = (loggedUser, totalPrice) => {
  const { hasDeposit, depositValue, isDepositPercentage } = useReadDeposit();
  if (loggedUser) return null;

  return hasDeposit ? computeDepositAmount(parseFloat(totalPrice), depositValue, isDepositPercentage) : null;
};

export default useCalcDeposit;

const computeDepositAmount = (totalPrice, depositValueFromConfig, depositPercentage) => {
  if (!depositValueFromConfig || totalPrice <= 0) return null;
  if (depositPercentage) {
    return computeDepositAmountPercentage(totalPrice, depositValueFromConfig);
  }

  return totalPrice > depositValueFromConfig ? depositValueFromConfig : null;
};

// In percentage we want the rest-to-pay-later to be a round number !
// CAREFUL: THIS CALC IS SYNCED WITH THE BACKEND AT : depositService.py !!!
export const computeDepositAmountPercentage = (totalPrice, depositPercentValue) => {
  if (depositPercentValue <= 0 || depositPercentValue >= 100) return null;
  if (totalPrice <= 5) return null; // we dont want to fuckup the roundings below

  // we get the amount to pay later and round it to the nearest multiple of N
  const nonDepositPercentValue = 100 - depositPercentValue;
  let toPayLater = (nonDepositPercentValue * totalPrice) / 100;
  toPayLater = getNearestMultipleOf(toPayLater, 5);

  // nothing to pay later ? no need for deposit
  if (toPayLater <= 0) return null;

  // and the amound to pay now will be approximative and probably not round
  const toPayNow = totalPrice - toPayLater;

  // this is additional sec in case but tests havent triggered this code yet
  if (toPayNow <= 0 || toPayNow > totalPrice) return null;

  return toPayNow.toFixed(2);
};
