import { Box } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import { ROUTE_P6_CHECKOUT, ROUTE_P1_PRODUCT_SELECTION } from '../../../constants';
import useIsMobile from '../../../hooks/useIsMobile';
import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import usePostParticipants from '../../../hooks/usePostParticipants';
import useReadTeam from '../../../hooks/useReadTeam';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import AlertError from '../../generic/error/AlertError';
import CheckoutDetailFormDesktop from './CheckoutDetailFormDesktop';
import CheckoutDetailFormMobile from './CheckoutDetailFormMobile';

const CheckoutDetail = () => {
  const isMobile = useIsMobile();

  const { isTaxIncluded } = useReadTeam();
  const { cartSize, isSuccess, status, hasUnAssignedParticipants, updateParticipants } = usePostParticipants();

  const navigate = useNavigateBookingEngine();
  useEffectOnce(() => {
    if (cartSize <= 0) {
      navigate(ROUTE_P1_PRODUCT_SELECTION);
    }
  });

  useUpdateEffect(() => {
    if (isSuccess) {
      navigate(ROUTE_P6_CHECKOUT);
    }
  }, [isSuccess]);

  const commonProps = {
    isTaxIncluded: isTaxIncluded,
    updateParticipants: updateParticipants,
  };

  if (cartSize <= 0) return <></>;
  return (
    <Box id="CheckoutDetail">
      {isMobile && <CheckoutDetailFormMobile {...commonProps} />}
      {!isMobile && <CheckoutDetailFormDesktop {...commonProps} />}

      {hasUnAssignedParticipants && <AlertError mt=".5rem" message={<Trans>Some participants are not assigned to any activity.</Trans>} />}

      {status === REQUEST_STATUS.OK && isSuccess === false && (
        <AlertError mt=".5rem" message={<Trans>An error occured, please check your participants data.</Trans>} />
      )}

      {status === REQUEST_STATUS.ERROR && <AlertError mt=".5rem" message={<Trans>An error occured, please try again later.</Trans>} />}
    </Box>
  );
};

export default CheckoutDetail;
