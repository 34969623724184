import { Box, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import InputCheckbox from '../../generic/form/InputCheckbox';

const textProps = {
  as: 'span',
};

export const OPT_IN_FIELDNAME = 'opt_in';

const CheckoutOptIn = ({ ...dataProps }) => {
  return (
    <InputCheckbox fieldName={OPT_IN_FIELDNAME} {...dataProps} isRequired={false}>
      <Box fontSize="xs" pl={{ base: '.4rem', md: undefined }}>
        <Text {...textProps}>
          <Trans>I agree to receive marketing emails</Trans>{' '}
        </Text>
      </Box>
    </InputCheckbox>
  );
};

export default CheckoutOptIn;
