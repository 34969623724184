import { useContext, useEffect, useRef } from 'react';

import { ExchangeContext } from '../context/ExchangeContext';
import useWritePreCart from './useWritePreCart';

const useWriteFirstAvailabilityDate = firstAvailabilityDate => {
  const { exchangeData, setExchangeData } = useContext(ExchangeContext);

  const { preCartData } = useWritePreCart();
  const { id: productId } = preCartData.product ?? {};
  const productIdRef = useRef(productId);

  useEffect(() => {
    if (!canUpdateDate()) return;

    setExchangeData({ ...exchangeData, firstAvailabilityDate: firstAvailabilityDate ?? null });
  }, [firstAvailabilityDate]);

  const canUpdateDate = () => {
    if (productIdRef.current !== productId) return true;
    if (!exchangeData.firstAvailabilityDate) return true;
    if (firstAvailabilityDate && firstAvailabilityDate < exchangeData.firstAvailabilityDate) return true;
    return false;
  };
};

export default useWriteFirstAvailabilityDate;
