import { forwardRef } from 'react';

import { FormControl, Input, InputGroup } from '@chakra-ui/react';
import { i18n } from '@lingui/core';

import { DYN_INPUT_WIDTH_LEVEL_1, DYN_INPUT_WIDTH_LEVEL_2 } from '../../../utils/styleUtil';
import InputFormLabel from './InputFormLabel';
import InputTextBtnClear from './InputTextBtnClear';

// ==============================================================================================
// This component is used to generate a text input either as a filter or inside a form
// When the component value is changed, we trigger an onChange callback provided by a parent
// ==============================================================================================

const InputText = (
  {
    fieldName,
    fieldLabel,
    fieldPlaceholder,
    fieldValue,
    fieldType = 'text',
    fieldMaxLength,
    displayLabel = true,
    isRequired,
    onChange,
    onClearValue,
    minWidth,
    maxWidth,
    isDisabled,
    isInvalid,
    pattern,
    inputCustomStyle,
    className,
  },
  ref
) => {
  return (
    <FormControl isRequired={isRequired} minW={minWidth} maxW={maxWidth}>
      {displayLabel && <InputFormLabel fieldName={fieldName} fieldLabel={fieldLabel} />}

      <InputGroup>
        <Input
          ref={ref}
          type={fieldType}
          name={fieldName}
          id={fieldName}
          placeholder={fieldPlaceholder && i18n._(fieldPlaceholder)}
          value={fieldValue}
          onChange={e => onChange(fieldName, e.target.value)}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          pattern={pattern}
          style={inputCustomStyle}
          className={className}
          maxLength={fieldMaxLength}
        />
        {onClearValue && fieldValue && <InputTextBtnClear onClearValue={onClearValue} />}
      </InputGroup>
    </FormControl>
  );
};

// we forward the ref that we have received (used by the google maps input)
export default forwardRef(InputText);

export const getDynamicInputMaxWidth = (label, isMobile) => {
  // no max: takes full width
  if (isMobile) return undefined;

  const labelSize = label.length;
  if (labelSize < 25) return DYN_INPUT_WIDTH_LEVEL_1;
  else if (labelSize < 40) return DYN_INPUT_WIDTH_LEVEL_2;
  return undefined;
};
