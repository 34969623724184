import cloneDeep from 'lodash.clonedeep';

import { LOCAL_STORAGE_PARTICIPANTS, LOCAL_STORAGE_PARTICIPANTS_DEFVAL } from '../constants';
import { updateParticipantData } from '../utils/business/participants';
import useReadAdditionalData from './useReadAdditionalData';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const useWriteParticipantsData = participantId => {
  const additionalDataNeeded = useReadAdditionalData(participantId);
  const hasAdditionalDataNeeded = additionalDataNeeded.standardData.length > 0 || additionalDataNeeded.customData.length > 0;

  const [participantsData, setParticipantsData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PARTICIPANTS, LOCAL_STORAGE_PARTICIPANTS_DEFVAL);

  const onParticipantDataUpdate = (customDataId, label, newValue, additionalData) => {
    const newData = cloneDeep(participantsData);
    for (const aParticipant of newData) {
      if (aParticipant.id !== participantId) continue;

      updateParticipantData(aParticipant, customDataId, label, newValue, additionalData);
      break;
    }

    setParticipantsData(newData);
  };

  return { additionalDataNeeded, hasAdditionalDataNeeded, onParticipantDataUpdate };
};

export default useWriteParticipantsData;
