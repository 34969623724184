import { useEffectOnce } from 'usehooks-ts';

import { ROUTE_P1_PRODUCT_SELECTION } from '../../../constants';
import useFormSessionVariants from '../../../hooks/useFormSessionVariants';
import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import useWritePreCart from '../../../hooks/useWritePreCart';
import { isArrEmpty } from '../../../utils/arrayUtil';
import FetchLoaderSpinner from '../../generic/FetchLoaderSpinner';
import ContainerLeft from '../../generic/layout/ContainerLeft';
import FlexRow from '../../generic/layout/FlexRow';
import SessionVariantsByList from './display/SessionVariantsByList';
import SessionVariantsByQuantity from './display/SessionVariantsByQuantity';
import SessionVariantsProduct from './SessionVariantsProduct';
import SessionVariantsTotal from './SessionVariantsTotal';

const SessionVariantsForm = () => {
  const { preCartData } = useWritePreCart();
  const { product, availabilities } = preCartData;

  const {
    onSubmitAddToCart,
    setRequestedAction,
    statusLoadVariants,
    statusAddCart,
    requestedAction,
    itemsToDeleteForEdit,
    invalidResources,
    duplicateParticipants,
    maxAvailablePlaces,
    canBookMore,
    displayVariantsAsQuantity,
  } = useFormSessionVariants(preCartData, availabilities, product);

  const navigate = useNavigateBookingEngine();
  useEffectOnce(() => {
    if (isArrEmpty(availabilities)) {
      navigate(ROUTE_P1_PRODUCT_SELECTION);
    }
  });

  if (!product || isArrEmpty(availabilities)) return <FetchLoaderSpinner />;

  const commonPropsWithTotal = {
    product: product,
    statusLoadVariants: statusLoadVariants,
    canBookMore: canBookMore,
  };

  const commonPropsForVariantDisplay = {
    availability: availabilities[0],
    nbAvaSelected: availabilities.length,
    defaultVariant: preCartData.defaultVariant,
    maxAvailablePlaces: maxAvailablePlaces,
    ...commonPropsWithTotal,
  };

  return (
    <form onSubmit={onSubmitAddToCart}>
      <FlexRow>
        <ContainerLeft>
          <SessionVariantsProduct product={product} availabilities={availabilities} />

          {displayVariantsAsQuantity && <SessionVariantsByQuantity {...commonPropsForVariantDisplay} />}
          {!displayVariantsAsQuantity && <SessionVariantsByList {...commonPropsForVariantDisplay} />}
        </ContainerLeft>

        <SessionVariantsTotal
          availabilities={availabilities}
          requestedAction={requestedAction}
          statusAddCart={statusAddCart}
          invalidResources={invalidResources}
          duplicateParticipants={duplicateParticipants}
          itemsToDeleteForEdit={itemsToDeleteForEdit}
          setRequestedAction={setRequestedAction}
          {...commonPropsWithTotal}
        />
      </FlexRow>
    </form>
  );
};

export default SessionVariantsForm;
