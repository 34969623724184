import { useMemo } from 'react';

import cloneDeep from 'lodash.clonedeep';
import { useEffectOnce } from 'usehooks-ts';

import { LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_ASSIGN_DEFVAL, LOCAL_STORAGE_PARTICIPANTS, LOCAL_STORAGE_PARTICIPANTS_DEFVAL } from '../constants';
import { handleParticipantDataForFrontEnd } from '../utils/business/participants';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const useReadParticipants = cartData => {
  const { clients, ocSessionsWithResources } = cartData;

  const [participantsData, setParticipantsData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PARTICIPANTS, LOCAL_STORAGE_PARTICIPANTS_DEFVAL);
  const [activityAssignData, setActivityAssignData] = useWriteStorageBookingEngine(LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_ASSIGN_DEFVAL);

  const minParticipantCount = useMemo(() => getMinParticipantsCount(ocSessionsWithResources), [JSON.stringify(ocSessionsWithResources)]);

  const canAddParticipant = useMemo(() => hasParticipantNotYetDisplayed(participantsData), [JSON.stringify(participantsData)]);

  const actualParticipants = useMemo(() => participantsData.filter(client => client.displayed), [JSON.stringify(participantsData)]);

  useEffectOnce(() => {
    loadParticipantsDetails();
  });

  const loadParticipantsDetails = () => {
    const participants = [];
    let i = 0;
    for (const participant of clients) {
      i++;
      const isDisplayed = minParticipantCount >= i;
      participants.push(cleanParticipantDetails(isDisplayed, participant));
    }

    setParticipantsData(participants);
  };

  const onParticipantAdd = () => {
    const newData = cloneDeep(participantsData);
    for (const participant of newData) {
      if (participant.displayed) continue;

      participant.displayed = true;
      break;
    }
    setParticipantsData(newData);
  };

  const onParticipantDelete = participantId => {
    const newData = cloneDeep(participantsData);
    let i = -1;
    let newParticipant = null;
    for (const participant of newData) {
      i++;
      if (participant.id !== participantId) continue;

      newParticipant = cleanParticipantDetails(false, participant); // false = we turnoff the element display
      break;
    }

    deleteParticipantFromItems(participantId);
    newData.splice(i, 1);
    newData.push(newParticipant);
    setParticipantsData(newData);
  };

  const deleteParticipantFromItems = participantId => {
    const newData = cloneDeep(activityAssignData);
    for (const item of newData) {
      if (item.client_id === participantId) {
        item.client_id = '';
      }
    }
    setActivityAssignData(newData);
  };

  return {
    actualParticipants,
    minParticipantCount,
    canAddParticipant,
    onParticipantAdd,
    onParticipantDelete,
  };
};

export default useReadParticipants;

// Lets say we booked an item with 3 participants and another item with 5 participants
// => we will fill at least 5 people during the checkout detail !
const getMinParticipantsCount = ocSessionsWithResources => {
  let minParticipants = 1;

  ocSessionsWithResources.map(entry => {
    let participantsOfSessionGroup = 0;

    // TODO IMPROVE : do not loop but use the field countParticipants
    const groupedSessionGroups = entry[1];
    for (const availability of groupedSessionGroups.availabilities) {
      participantsOfSessionGroup += parseInt(availability.quantity);
    }

    minParticipants = Math.max(minParticipants, participantsOfSessionGroup);
  });
  return minParticipants;
};

const cleanParticipantDetails = (isDisplayed, participantToClean) => {
  const participant = handleParticipantDataForFrontEnd(participantToClean);

  participant.displayed = isDisplayed;

  // The cleaning occurs only when we delete a participant (is not displayed)
  // Or at the first load when we have "?" from the backend
  // Otherwise we keep the saved data
  if (!isDisplayed || (isDisplayed && participant.first_name === '?')) {
    participant.first_name = '';
  }
  if (!isDisplayed || (isDisplayed && participant.last_name === '?')) {
    participant.last_name = '';
  }
  return participant;
};

const hasParticipantNotYetDisplayed = participants => {
  return participants && participants.filter(participant => participant.displayed === false).length > 0;
};
