import { Box, Container } from '@chakra-ui/react';

import Footer from './components/generic/layout/footer/Footer';
import YoplanningAccountInfo from './components/generic/YoplanningAccountInfo';
import useIsMobile from './hooks/useIsMobile';

const AppContainer = props => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Box h="100%" minH="calc(100vh)" bg="brand.background_container" color="brand.color_font" px={{ base: 0, md: 2 }}>
        {props.loggedUser && <YoplanningAccountInfo loggedUser={props.loggedUser} />}
        {props.children}
        <Footer />
      </Box>
    );
  }

  return (
    <Container
      maxW="container.lg"
      my="3rem"
      bg="brand.background_container"
      color="brand.color_font"
      rounded="xl"
      boxShadow="rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset"
    >
      {props.loggedUser && <YoplanningAccountInfo loggedUser={props.loggedUser} />}

      <Container maxW="container.lg" pt="1rem" pb="2rem">
        {props.children}
        <Footer />
      </Container>
    </Container>
  );
};

export default AppContainer;
