import { useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import { LOCAL_STORAGE_CART_EXPIRE } from '../constants';
import useCleanStorage from './useCleanStorage';
import useReadStorageBookingEngine from './useReadStorageBookingEngine';

const useReadCartExpiration = () => {
  const cartExpire = useReadStorageBookingEngine(LOCAL_STORAGE_CART_EXPIRE);
  const [hasExpired, setHasExpired] = useState(false);
  const lastCheck = useRef();

  useEffectOnce(() => {
    if (!lastCheck.current || Date.now() - lastCheck.current > 60000) {
      lastCheck.current = Date.now();
      const expired = cartExpire ? cartExpire - lastCheck.current <= 0 : false;
      setHasExpired(expired);
    }
  });

  const { cleanOrderGotoCart } = useCleanStorage(false);

  useUpdateEffect(() => {
    if (!hasExpired) return;

    cleanOrderGotoCart(t`Your cart has expired, please try again.`);
  }, [hasExpired]);

  return hasExpired;
};

export default useReadCartExpiration;
