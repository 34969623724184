import { useState } from 'react';

import { defineMessage } from '@lingui/macro';

import { DATE_SEPARATOR_FRONTEND, autoHandleDateSlash, isValidPastDate } from '../../../utils/dateUtil';
import InputText from './InputText';

export const BIRTHDATE_FIELDNAME = 'birthdate';

const InputBirthDate = ({ onChange, ...props }) => {
  const [isInvalidDate, setInvalidDate] = useState(false);

  const handleDateChange = (fName, fieldValue) => {
    const formattedDate = autoHandleDateSlash(fieldValue);
    const isValidDate = isValidPastDate(formattedDate);
    setInvalidDate(isValidDate !== null && !isValidDate);

    onChange(fName, formattedDate);
  };

  return (
    <InputText
      fieldLabel={defineMessage({ message: 'Birthdate' })}
      fieldPlaceholder={defineMessage({ message: 'dd/mm/yyyy' })}
      className="inputDate"
      onChange={handleDateChange}
      pattern={'\\d{1,2}' + DATE_SEPARATOR_FRONTEND + '\\d{1,2}' + DATE_SEPARATOR_FRONTEND + '\\d{4}'}
      inputCustomStyle={{ wordSpacing: '-3px' }}
      isInvalid={isInvalidDate}
      {...props}
    />
  );
};

export default InputBirthDate;
