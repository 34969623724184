import { useMemo } from 'react';

import { Box, Divider, Flex, Text } from '@chakra-ui/react';

import { getFormattedCurrency, getVariantTotalPerParticipant } from '../../../../utils/business/prices';
import { COMMON_GAP, ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import ButtonDelete from '../../../generic/button/ButtonDelete';
import OptionCustom from '../options/OptionCustom';
import OptionParticipant from '../options/OptionParticipant';
import OptionPrice from '../options/OptionPrice';
import VariantParticipantDetails from '../VariantParticipantDetails';

const VariantLinePerPerson = ({
  variant,
  onVariantDelete,
  isFree,
  nbAvaSelected,
  loggedUser,
  allDefaultOptions,
  placesAvailable,
  placesMax,
  ...props
}) => {
  const variantTotal = useMemo(() => getVariantTotalPerParticipant(variant, nbAvaSelected), [JSON.stringify(variant)]);

  return (
    <Box bg="brand.background_main" position="relative" {...ROUNDED_ELEMENT_PROPS} paddingBottom="2rem">
      <Flex wrap="wrap" gap={COMMON_GAP} alignItems="center">
        <OptionParticipant
          fieldValue={variant.participant}
          loggedUser={loggedUser}
          placesAvailable={placesAvailable}
          placesMax={placesMax}
          {...props}
        />

        {!isFree && <OptionPrice variant={variant} {...props} displayInline />}
      </Flex>

      {loggedUser && <Divider orientation="horizontal" mt=".9rem" mb=".9rem" />}
      {!loggedUser && <VariantParticipantDetails participantDetails={variant.participantDetails} countOptions={variant.custom_options.length} />}

      <Flex flexFlow="wrap" gap={COMMON_GAP}>
        {variant.custom_options.map((option, index) => (
          <OptionCustom key={index} data={option} defaultOption={allDefaultOptions[index]} variantParticipants={variant.participant} {...props} />
        ))}
      </Flex>

      {props.index > 0 && <ButtonDelete onClick={() => onVariantDelete(props.index)} enableSpinner={false} />}

      {variantTotal > 0 && (
        <Text position={{ base: undefined, md: 'absolute' }} mt=".7rem" textAlign="right" bottom=".2rem" right=".5rem">
          {getFormattedCurrency(variantTotal)}
        </Text>
      )}
    </Box>
  );
};

export default VariantLinePerPerson;
