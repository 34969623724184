import { useState } from 'react';

import { Center, Divider, Flex } from '@chakra-ui/react';
import { defineMessage } from '@lingui/macro';
import cloneDeep from 'lodash.clonedeep';
import { useParams } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import { apiGetShopResourceDetail, ROUTE_PAGE_SHOP } from '../../../constants';
import useFetch from '../../../hooks/useFetch';
import useFormShopResource from '../../../hooks/useFormShopResource';
import useIsMobile from '../../../hooks/useIsMobile';
import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import useWritePreCart from '../../../hooks/useWritePreCart';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, COMMON_PADDING, TOP_ROUNDED_CONTAINER_PROPS } from '../../../utils/styleUtil';
import AlertError from '../../generic/error/AlertError';
import FetchLoaderSpinner from '../../generic/FetchLoaderSpinner';
import InputNumber from '../../generic/form/InputNumber';
import ContainerTotal from '../../generic/layout/ContainerTotal';
import SessionVariantsActions from '../sessionvariants/SessionVariantsActions';
import ShopFeaturesBloc from './features/ShopFeaturesBloc';
import ShopResourceBookingErrors from './ShopResourceBookingErrors';
import ShopResourceDetail from './ShopResourceDetail';

const ShopResourceBookingForm = () => {
  const isMobile = useIsMobile();

  const { resourceId } = useParams();
  const { data, status } = useFetch(apiGetShopResourceDetail(resourceId));

  const { preCartData, setPreCartShopResource } = useWritePreCart();
  const { shopResource } = preCartData;
  const { onSubmitAddToCart, statusAddCart, requestedAction, setRequestedAction } = useFormShopResource(preCartData);

  const [stockUnavailable, setStockUnavailable] = useState(false);

  const navigate = useNavigateBookingEngine();
  useEffectOnce(() => {
    if (!resourceId) {
      navigate(ROUTE_PAGE_SHOP);
    }
  });

  useUpdateEffect(() => {
    data.resource.quantity = 1;
    setPreCartShopResource(data.resource, data.child_resources);
  }, [data]);

  const setQuantity = quantity => {
    if (quantity < 0) return;

    const isStockUnavailable = shopResource.stock != null && quantity > shopResource.stock;
    setStockUnavailable(isStockUnavailable);

    const copyResource = cloneDeep(shopResource);
    copyResource.quantity = quantity;
    setPreCartShopResource(copyResource, data.child_resources);
  };

  if (!resourceId) return <></>;
  return (
    <form onSubmit={onSubmitAddToCart}>
      <Flex
        w={{ base: 'full', md: '70%' }}
        bg="brand.background_main"
        margin="0 auto"
        flexDirection="column"
        {...TOP_ROUNDED_CONTAINER_PROPS}
        {...BOTTOM_ROUNDED_CONTAINER_PROPS}
      >
        <FetchLoaderSpinner status={status} />
        {status === REQUEST_STATUS.ERROR && <AlertError />}

        {data && shopResource && (
          <>
            <ShopResourceDetail shopResource={shopResource} />
            <Divider orientation="horizontal" my={COMMON_PADDING} />

            <Center gap={{ base: '1rem', sm: '2rem' }} flexDirection={{ base: 'column', sm: 'row' }}>
              <InputNumber
                fieldLabel={defineMessage({ message: 'Quantity' })}
                fieldValue={shopResource.quantity}
                fieldMaximum={shopResource.stock != null ? shopResource.stock : undefined}
                onChange={(fieldName, newValue) => setQuantity(newValue)}
                isRequired
                {...dataWidthProps}
              />

              <ShopFeaturesBloc data={data} dataWidthProps={dataWidthProps} />

              <ContainerTotal total={data.resource.price * shopResource.quantity} />
            </Center>

            <ShopResourceBookingErrors shopResource={shopResource} stockUnavailable={stockUnavailable} statusAddCart={statusAddCart} />
            <Divider orientation="horizontal" my={COMMON_PADDING} />

            <Center gap={{ base: '.3rem', sm: '2rem' }}>
              <SessionVariantsActions
                statusAddCart={statusAddCart}
                requestedAction={requestedAction}
                setRequestedAction={setRequestedAction}
                isMobile={isMobile}
                statusLoadVariants={REQUEST_STATUS.OK}
                buttonsWidth={{ base: '50%', sm: '35%' }}
                //   itemsToDeleteForEdit={itemsToDeleteForEdit}
              />
            </Center>
          </>
        )}
      </Flex>
    </form>
  );
};

export default ShopResourceBookingForm;

const dataWidthProps = {
  minWidth: { base: '115px', sm: '115px' },
  maxWidth: { base: '200px', sm: '115px' },
};
