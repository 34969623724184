import { useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useDebounce, useEffectOnce } from 'usehooks-ts';

import { DATERANGE_FILTER_NAME_END, DATERANGE_FILTER_NAME_START } from '../components/generic/form/DateRangePicker';
import { LOCAL_STORAGE_FILTERS, LOCAL_STORAGE_FILTERS_DEFVAL } from '../constants';
import { isDatePickerFilter, isDateRangePickerFilter } from '../utils/dateFilterUtil';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

// This is the delay of the useDebounce to not spam the backend each time a state changes
const FILTER_UPDATE_DELAY_MS = 300;

const useReadFilters = (filtersToDisplay, displayLimit) => {
  const [queryParams] = useSearchParams();

  // This is the state of our filters : their name and current value
  // We also have the useDebounce to not spam the backend
  const [filters, setFilters] = useState(null);
  const debouncedFilters = useDebounce(filters, FILTER_UPDATE_DELAY_MS);

  const [savedFilters, setSavedFilters] = useWriteStorageBookingEngine(LOCAL_STORAGE_FILTERS, LOCAL_STORAGE_FILTERS_DEFVAL);

  useEffectOnce(() => {
    // If we're on a page without filters
    // Or if we dont want to load, just read values
    if (!filtersToDisplay) return;

    const loadedFilters = loadFilters();
    setFilters(loadedFilters);
  });

  const loadFilters = () => {
    const filterState = { offset: 0, limit: displayLimit };

    filtersToDisplay.map(function (filter) {
      if (isDateRangePickerFilter(filter.type)) {
        createDaterangeFilter(filterState, filter);
      } else {
        createStandardFilter(filterState, filter);
      }
    });
    return filterState;
  };

  const createDaterangeFilter = (filterState, filter) => {
    filterState[DATERANGE_FILTER_NAME_START] = getFilterValue(DATERANGE_FILTER_NAME_START, filter.type, filter.defaultStartDate);
    filterState[DATERANGE_FILTER_NAME_END] = getFilterValue(DATERANGE_FILTER_NAME_END, filter.type, filter.defaultEndDate);
  };

  const createStandardFilter = (filterState, filter) => {
    filterState[filter.fieldName] = getFilterValue(filter.fieldName, filter.type, filter.default);
  };

  // This manages the priority for the filter values
  // First from url, then from storage (saved), then the default
  const getFilterValue = (queryParamName, filterType, defaultFilterValue) => {
    const filterValue = queryParams.get(queryParamName) ?? savedFilters[queryParamName] ?? defaultFilterValue;

    // This is very important because depending on where the value comes from
    // Sometimes we have string iso dates, which will crash the datepicker if not cast to Date
    if (filterValue && isDatePickerFilter(filterType)) {
      return new Date(filterValue);
    }
    return filterValue;
  };

  return { filters, setFilters, debouncedFilters, savedFilters, setSavedFilters, getFilterValue };
};

export default useReadFilters;
