import cloneDeep from 'lodash.clonedeep';

import { DATERANGE_FILTER_NAME_END, DATERANGE_FILTER_NAME_START, DATE_FILTER_NAME } from '../components/generic/form/DateRangePicker';
import { devMessage } from '../settings/settings';
import { dateToIsoString } from './dateUtil';

export const REQUEST_STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  OK: 'ok',
};

export const jsonToQueryParams = originalJsonData => {
  // we dont want to mutate the original data
  const jsonData = cloneDeep(originalJsonData);

  formatDateForQuery(jsonData, DATERANGE_FILTER_NAME_START);
  formatDateForQuery(jsonData, DATERANGE_FILTER_NAME_END);
  formatDateForQuery(jsonData, DATE_FILTER_NAME); // generic DatePicker

  return new URLSearchParams(jsonData).toString();
};

const formatDateForQuery = (array, key) => {
  if (key in array) {
    array[key] = dateToIsoString(array[key]);
  }
};

export const addQueryParamToUrl = (url, queryParamString) => {
  const newUrl = url + (url.split('?')[1] ? '&' : '?') + queryParamString;
  return newUrl;
};

export const isAbsoluteUrl = urlString => {
  return urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0;
};

export const getCookie = name => {
  const value = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
  devMessage("Reading cookie '" + name + "' => '" + value + "'");
  return value;
};

export const getReferrerHost = () => {
  let referrerHostname = null;
  try {
    // ancestorOrigins not yet supported by FF (since 2014!!) cf: https://bugzilla.mozilla.org/show_bug.cgi?id=1085214
    const ancestor = location.ancestorOrigins != undefined ? location.ancestorOrigins[0] : null;
    const parentUrl = ancestor ?? document.referrer;
    referrerHostname = parentUrl ? new URL(parentUrl).host : null;
  } catch {}
  return referrerHostname;
};
