import { Text } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';

import { findArrayItemByKey } from '../../../utils/arrayUtil';
import { isStrNullOrEmpty } from '../../../utils/strUtil';
import Select from './Select';

export const MULTIPLE_CHOICES_SEPARATOR = ',';

const SelectMultipleChoices = ({ maxValuesToShow = 3, ...props }) => {
  // We override the default select behavior : this is used to retrieve what options are selected
  const getSelectedOptionOverride = () => {
    const valuesArray = props.fieldValue.split(MULTIPLE_CHOICES_SEPARATOR);

    const existingValues = [];
    for (const value of valuesArray) {
      if (value === '') continue; // ignore the default value if here
      const existingValue = findArrayItemByKey('value', value, props.fieldChoices) ?? findArrayItemByKey('label', value, props.fieldChoices);
      if (existingValue) {
        existingValues.push(existingValue);
      }
    }

    if (existingValues.length > 0) {
      return existingValues;
    }

    // we default to the first default choice then
    const defaultSelectedValue = props.fieldChoices.length > 0 ? props.fieldChoices[0] : undefined;
    return defaultSelectedValue;
  };

  // We override the default select behavior : this is used everytime a change is made
  const handleOnChangeOverride = (optionsArray, setSelectedOption) => {
    // nothing selected so the filter will just be empty
    if (!optionsArray.length) {
      props.onChange(props.fieldName, '');

      // and we default to the first default choice then
      const defaultSelectedValue = props.fieldChoices.length > 0 ? props.fieldChoices[0] : undefined;
      setSelectedOption(defaultSelectedValue);
      return;
    }

    // we format the filter before sending it
    let filterNewValue = '';
    for (const singleOption of optionsArray) {
      if (isStrNullOrEmpty(singleOption.value)) continue;

      filterNewValue += singleOption.value + MULTIPLE_CHOICES_SEPARATOR;
    }
    filterNewValue = filterNewValue.substring(0, filterNewValue.length - 1); // remove last separator
    props.onChange(props.fieldName, filterNewValue);
    setSelectedOption(optionsArray);
  };

  // Style the default option : no background and no remove-cross
  const additionalStyles = {
    multiValue: (base, state) => {
      return state.data.value === '' ? { ...base, backgroundColor: 'transparent' } : base;
    },
    multiValueLabel: (base, state) => {
      return base;
    },
    multiValueRemove: (base, state) => {
      return state.data.value === '' ? { ...base, display: 'none' } : base;
    },
  };

  const filterOptions = (candidate, input) => {
    // here i want to hide the empty default value from the list if something has been selected
    if (props.fieldValue !== '' && candidate.value === '') return false;

    // this is the default return by the library
    // return candidate.data.__isNew__ || candidate.label.includes(input);

    // we make it case insensitive
    return candidate.data.__isNew__ || candidate.label.toLowerCase().includes(input.toLowerCase());
  };

  // We override the MultiValue render to not have to display every selected option
  const CustomMultiValue = ({ children, ...props }) => {
    const { index, selectProps } = props;
    if (index < maxValuesToShow) {
      return <chakraComponents.MultiValue {...props}>{children}</chakraComponents.MultiValue>;
    }

    if (index === maxValuesToShow) {
      const remainingOptions = selectProps.value.length - maxValuesToShow;
      return (
        <Text ml="2px" fontSize="sm" as="span" color="brand.color_main">
          +{remainingOptions}
        </Text>
      );
    }

    return null;
  };

  return (
    <Select
      additionalStyles={additionalStyles}
      filterOptions={filterOptions}
      allowMultipleChoices
      className="ChakraReactSelectMulti"
      isClearable={false} // no global red cross icon
      isSearchable={true}
      getSelectedOptionOverride={getSelectedOptionOverride}
      handleOnChangeOverride={handleOnChangeOverride}
      componentsOverride={{ MultiValue: CustomMultiValue }}
      {...props}
    />
  );
};

export default SelectMultipleChoices;
