import { useMemo } from 'react';

import cloneDeep from 'lodash.clonedeep';

import { AVAILABILITIES_FILTERS } from '../components/business/availability/list/AvailabilitiesListFilters';
import { getTotalParticipants } from '../components/business/sessionvariants/persession/VariantsListPerSession';
import { LOCAL_STORAGE_PRECART, LOCAL_STORAGE_PRECART_DEFVAL } from '../constants';
import useReadFilters from './useReadFilters';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';
import { updateSeveralVariantsPrices } from './useWriteVariantsPrices';

const useWritePreCart = () => {
  const [preCartData, setPreCartData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PRECART, LOCAL_STORAGE_PRECART_DEFVAL);

  const { debouncedFilters, getFilterValue } = useReadFilters();
  const nbTicketFilter = AVAILABILITIES_FILTERS.NB_TICKETS;
  const filteredNbTickets = useMemo(
    () => getFilterValue(nbTicketFilter.fieldName, nbTicketFilter.type, nbTicketFilter.default),
    [JSON.stringify(debouncedFilters)]
  );

  const setPreCartProduct = data => {
    setPreCartData({ product: data, itemsToDeleteForEdit: null });
  };

  const setPreCartShopResource = (shopResource, shopResourceChildren) => {
    setPreCartData({ shopResource: shopResource, shopResourceChildren: shopResourceChildren, itemsToDeleteForEdit: null });
  };

  const setPreCartAvailabilities = data => {
    setPreCartData({ ...preCartData, availabilities: data });
  };

  const setPreCartVariant = (sessionGroupId, data, newDefaultVariant) => {
    const copyAvailabilities = cloneDeep(preCartData.availabilities);

    for (const availability of copyAvailabilities) {
      if (availability.session_group_id !== sessionGroupId) continue;

      availability.variants = data;
      break;
    }

    if (newDefaultVariant) {
      setPreCartData({ ...preCartData, availabilities: copyAvailabilities, defaultVariant: newDefaultVariant });
    } else {
      setPreCartAvailabilities(copyAvailabilities);
    }
  };

  const setPreCartDefaultVariant = (defaultVariant, loggedUser, product) => {
    const copyAvailabilities = cloneDeep(preCartData.availabilities);

    if (loggedUser) {
      createSingleVariant(copyAvailabilities, defaultVariant);
    } else {
      createSeveralVariants(copyAvailabilities, defaultVariant);
    }

    const newPreCartData = {
      ...preCartData,
      availabilities: copyAvailabilities,
      defaultVariant: defaultVariant,
      itemsToDeleteForEdit: null,
    };
    if (product) {
      newPreCartData['product'] = product;
    }
    setPreCartData(newPreCartData);
  };

  const createSingleVariant = (availabilities, defaultVariant) => {
    for (const availability of availabilities) {
      const firstVariant = cloneDeep(defaultVariant);
      firstVariant.participant = filteredNbTickets ?? 1;

      fillAvailabilityWithVariantData(availability, defaultVariant, [firstVariant]);
    }
  };

  const createSeveralVariants = (availabilities, defaultVariant) => {
    const maxVariantsToCreate = filteredNbTickets ?? 1;

    for (const availability of availabilities) {
      // We do this to not overbook, the nb_tickets is calculated across all sessions
      const actualMaxVariantsToCreate = availability.max_participants
        ? Math.min(availability.max_participants, maxVariantsToCreate)
        : maxVariantsToCreate;

      const availabilityVariants = [];

      for (let i = 1; i <= actualMaxVariantsToCreate; i++) {
        availabilityVariants.push(cloneDeep(defaultVariant));
      }

      // We added several variants so we have to update the prices, in case the discount price is available
      if (actualMaxVariantsToCreate > 1) {
        updateSeveralVariantsPrices(availabilityVariants, getTotalParticipants(availabilityVariants));
      }

      fillAvailabilityWithVariantData(availability, defaultVariant, availabilityVariants);
    }
  };

  const fillAvailabilityWithVariantData = (availability, defaultVariant, availabilityVariants) => {
    availability.variants = availabilityVariants;
    availability.nb_sessions = defaultVariant.nbSessions;

    // for direct access to P3
    if (defaultVariant.start_date && !availability.start_date) {
      availability.start_date = defaultVariant.start_date;
    }
  };

  return {
    preCartData,
    setPreCartProduct,
    setPreCartShopResource,
    setPreCartAvailabilities,
    setPreCartVariant,
    setPreCartDefaultVariant,
    setPreCartData,
  };
};

export default useWritePreCart;
