import { Box, Divider } from '@chakra-ui/layout';
import { Trans } from '@lingui/macro';

import useFormBuyVoucher from '../../../hooks/useFormBuyVoucher';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, COMMON_PADDING } from '../../../utils/styleUtil';
import AlertError from '../../generic/error/AlertError';
import ContainerLeft from '../../generic/layout/ContainerLeft';
import FlexRow from '../../generic/layout/FlexRow';
import BuyVoucherDetail from './BuyVoucherDetail';
import BuyVoucherQuantity from './BuyVoucherQuantity';
import BuyVoucherTotal from './BuyVoucherTotal';

const BuyVoucherForm = ({ product }) => {
  const { id: productId, title: productTitle, description_image: productImage } = product;
  const { vouchers: productVouchers, voucher_name: productVoucherName } = product;

  const { onSubmitSaveVoucher, isSubmitting, status, selectedVoucher, handleVoucherSelect, handleVoucherUpdate } = useFormBuyVoucher(
    productId,
    productVouchers
  );

  return (
    <form onSubmit={onSubmitSaveVoucher}>
      <FlexRow>
        <ContainerLeft title={productVoucherName ?? <Trans>Voucher</Trans>}>
          <Box bg="brand.background_main" p={COMMON_PADDING} pt="0" {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
            {status === REQUEST_STATUS.ERROR && <AlertError />}

            <BuyVoucherDetail
              productTitle={productTitle}
              productVouchers={productVouchers}
              selectedVoucher={selectedVoucher}
              handleVoucherSelect={handleVoucherSelect}
            />
            <Divider m=".5rem 0" />
            {selectedVoucher && <BuyVoucherQuantity selectedVoucher={selectedVoucher} handleVoucherUpdate={handleVoucherUpdate} />}
          </Box>
        </ContainerLeft>

        <BuyVoucherTotal selectedVoucher={selectedVoucher} productImage={productImage} isLoading={isSubmitting} />
      </FlexRow>
    </form>
  );
};

export default BuyVoucherForm;
