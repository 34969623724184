import { Box, Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { MdOutlineAccessTimeFilled, MdWork } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';

import { apiGetProductDetail } from '../../../../constants';
import { gtmPushViewItem } from '../../../../gtm';
import useFetch from '../../../../hooks/useFetch';
import useIsMobile from '../../../../hooks/useIsMobile';
import useReadBuyVoucherMode from '../../../../hooks/useReadBuyVoucherMode';
import useWritePreCart from '../../../../hooks/useWritePreCart';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, TOP_ROUNDED_CONTAINER_PROPS } from '../../../../utils/styleUtil';
import { formatDuration } from '../../../../utils/timeUtil';
import ButtonLink from '../../../generic/button/ButtonLink';
import CollapsibleText from '../../../generic/CollapsibleText';
import FetchLoaderSpinner from '../../../generic/FetchLoaderSpinner';
import IconWithText from '../../../generic/IconWithText';
import TeamLogo from '../../../generic/TeamLogo';
import YoplanningHtml from '../../../generic/YoplanningHtml';
import ProductMediaManager from '../ProductMediaManager';
import ProductDetailBuyVoucherAction from './ProductDetailBuyVoucherAction';
import ProductDetailPlace from './ProductDetailPlace';
import ProductDetailSpecialPrices from './ProductDetailSpecialPrices';
import ProductDetailSpecialPricesMobile from './ProductDetailSpecialPricesMobile';

// ==============================================================================================
// This component displays the details of one product
// ==============================================================================================

const ICONS_PROPS = { w: '18px', h: '18px' };

const ProductDetail = ({ setDisplayAvaOnMobile }) => {
  const isMobile = useIsMobile();

  const { productId } = useParams();
  const { data, status } = useFetch(apiGetProductDetail(productId));

  const { preCartData, setPreCartProduct } = useWritePreCart();
  const { product } = preCartData;
  const { canBuyVoucher, isModeBcOnly } = useReadBuyVoucherMode(product);

  useUpdateEffect(() => {
    setPreCartProduct(data.product);
    gtmPushViewItem(data.product.title);
  }, [data]);

  return (
    <Box bg="brand.background_main" fontSize="sm" {...TOP_ROUNDED_CONTAINER_PROPS} {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
      <FetchLoaderSpinner status={status} />

      {data && product && (
        <Flex flexDirection="column" gap=".5rem">
          {!isMobile && (
            <HStack>
              <TeamLogo />
              <Spacer />
              {canBuyVoucher && !isModeBcOnly && <ProductDetailBuyVoucherAction product={product} />}
            </HStack>
          )}

          <Text fontSize="xl">{product.title}</Text>

          <ProductMediaManager product={product} />

          {product.description && (
            <CollapsibleText startingHeight={55} mb=".5rem" isShown={!isMobile}>
              <YoplanningHtml description={product.description} />
            </CollapsibleText>
          )}

          {product.team && (
            <IconWithText icon={MdWork} iconProps={ICONS_PROPS}>
              <Text>{product.team.name}</Text>
            </IconWithText>
          )}

          {product.place && <ProductDetailPlace place={product.place} iconProps={ICONS_PROPS} />}
          {product.meeting_point && <Text pl="1.5rem">{product.meeting_point}</Text>}

          <HStack alignItems="baseline">
            {product.duration && !product.credit && (
              <IconWithText icon={MdOutlineAccessTimeFilled} iconProps={ICONS_PROPS}>
                <Text>{formatDuration(product.duration)}</Text>
              </IconWithText>
            )}

            {product.prices && product.prices.length > 0 && (
              <>
                <Spacer />
                {!isMobile && <ProductDetailSpecialPrices specialPrices={product.prices} />}
                {isMobile && <ProductDetailSpecialPricesMobile specialPrices={product.prices} />}
              </>
            )}
          </HStack>

          {isMobile && (
            <>
              <ButtonLink onClick={() => setDisplayAvaOnMobile(true)} my="1rem">
                <Trans>Book</Trans>
              </ButtonLink>

              {canBuyVoucher && <ProductDetailBuyVoucherAction product={product} />}
            </>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default ProductDetail;
