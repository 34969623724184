import { Box, Divider, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useReadUnavailableItems from '../../../hooks/useReadUnavailableItems';
import { COMMON_PADDING, ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import VerticalStack from '../../generic/layout/VerticalStack';
import CartElementSessionCheckout from '../cart/elementtypes/CartElementSessionCheckout';
import CartElementSingleResourceCheckout from '../cart/elementtypes/CartElementSingleResourceCheckout';
import CartElementVoucherCheckout from '../cart/elementtypes/CartElementVoucherCheckout';

const CheckoutCart = ({ cartData, isTaxIncluded, unavailableItems }) => {
  const { isSessionGroupUnavailable, isVoucherUnavailable, isSingleResourceUnavailable, getUnavailableSessionResources } = useReadUnavailableItems(
    unavailableItems,
    cartData.resources
  );

  const cartElemProps = {
    cartData: cartData,
    isTaxIncluded: isTaxIncluded,
  };

  return (
    <Box bg="brand.background_main" mt="1rem" p={COMMON_PADDING} {...ROUNDED_ELEMENT_PROPS}>
      <VerticalStack>
        <Text textAlign="right" fontSize="xs" fontWeight="bold" textTransform="uppercase" color="brand.color_main">
          <Trans>Order</Trans>
        </Text>

        {cartData.ocSessionsWithResources.map(entry => {
          const groupedSessionGroups = entry[1];
          const sessGid = groupedSessionGroups.sessionGroupId;

          const isUnavailable = isSessionGroupUnavailable(sessGid);
          const unavailableResources = getUnavailableSessionResources(sessGid);
          return (
            <Box key={sessGid + '_' + entry[0]}>
              <CartElementSessionCheckout
                groupedSessionGroups={groupedSessionGroups}
                isUnavailable={isUnavailable}
                unavailableResources={unavailableResources}
                {...cartElemProps}
              />
              <Divider orientation="horizontal" borderColor="brand.color_main" mt="15px" />
            </Box>
          );
        })}

        {cartData.ocVouchers.map(cartItem => {
          const isUnavailable = isVoucherUnavailable(cartItem.gift_voucher.id);
          return (
            <Box key={cartItem.id}>
              <CartElementVoucherCheckout cartItem={cartItem} isUnavailable={isUnavailable} {...cartElemProps} />
              <Divider orientation="horizontal" borderColor="brand.color_main" mt="15px" />
            </Box>
          );
        })}

        {cartData.ocSingleResources.map(cartItem => {
          const isUnavailable = isSingleResourceUnavailable(cartItem.resource_id);
          return (
            <Box key={cartItem.id}>
              <CartElementSingleResourceCheckout cartItem={cartItem} isUnavailable={isUnavailable} {...cartElemProps} />
              <Divider orientation="horizontal" borderColor="brand.color_main" mt="15px" />
            </Box>
          );
        })}
      </VerticalStack>
    </Box>
  );
};

export default CheckoutCart;
