import { useState } from 'react';

import { useDebounce, useUpdateEffect } from 'usehooks-ts';

import { FIRST_NAME_FIELDNAME, LAST_NAME_FIELDNAME } from '../components/business/checkout/CheckoutFieldsCustomer';
import { yoplClientQuickSearch, yoplStaffQuickSearch } from '../constants';
import { REQUEST_STATUS } from '../utils/requestUtil';
import useFetch from './useFetch';
import useReadLoggedUser from './useReadLoggedUser';
import useReadTeam from './useReadTeam';

const SEARCH_UPDATE_DELAY_MS = 400;

const useFetchCheckoutClient = (onlyMainClient = true, onlyBusinessProvider = false, onlyStaff = false) => {
  const { teamId } = useReadTeam();
  const loggedUser = useReadLoggedUser();

  const [fetchUrl, setFetchUrl] = useState(null);
  const { data, status } = useFetch(fetchUrl, onlyStaff ? 'members' : 'items');
  const [firstQueryDone, setFirstQueryDone] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, SEARCH_UPDATE_DELAY_MS);

  const [clientSelectorChoices, setClientSelectorChoices] = useState([]);

  const onClientSelectorSearchChange = newValue => {
    if (newValue) {
      setSearchValue(newValue);
    }
  };

  useUpdateEffect(() => {
    if (!loggedUser) return;

    // To prevent display loading on the first load because we have not queried yet
    if (!firstQueryDone) setFirstQueryDone(true);

    const newUrl = onlyStaff
      ? yoplStaffQuickSearch(teamId, debouncedValue)
      : yoplClientQuickSearch(teamId, onlyMainClient ? '1' : '0', onlyBusinessProvider ? '1' : '0', debouncedValue);
    setFetchUrl(newUrl);
  }, [debouncedValue]);

  useUpdateEffect(() => {
    const choices = [];
    if (data) {
      for (const client of data) {
        choices.push({
          ...client,
          label: getLabel(client),
          value: onlyStaff ? client.user_id : client.id,
        });
      }
    }
    setClientSelectorChoices(choices);
  }, [JSON.stringify(data)]);

  const getLabel = client => {
    if (onlyBusinessProvider && client['company'] != null) return client['company'];

    const clientFirstName = client[FIRST_NAME_FIELDNAME] ? client[FIRST_NAME_FIELDNAME].trim() : '';
    const clientLastName = client[LAST_NAME_FIELDNAME] ? client[LAST_NAME_FIELDNAME].trim() : '';
    return clientFirstName + ' ' + clientLastName;
  };

  const isClientSelectorLoading = firstQueryDone && status === REQUEST_STATUS.LOADING;
  return { clientSelectorChoices, onClientSelectorSearchChange, isClientSelectorLoading };
};

export default useFetchCheckoutClient;
