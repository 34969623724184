import { useContext } from 'react';

import { t } from '@lingui/macro';

import { CoreContext } from '../context/CoreContext';
import useReadTeam from './useReadTeam';

export const PAYMENT_LINK_METHOD = 'payment_link';
export const PAYMENT_TERMINAL_METHOD = 'terminal_method';

// We want to be able to select "no payment method"
// If so the backend will not create any payment
export const NO_PAYMENT_METHOD = 'none';

// even in connected mode, the staff may want to enter the credit card number of the client
// (like for a standard online payment)
export const ONLINE_PAYMENT_METHOD = 'payment_form';

const useReadPaymentMethods = () => {
  const { coreData } = useContext(CoreContext);
  const { hasVendor } = useReadTeam();

  const noPaymentChoice = { label: t`None`, value: NO_PAYMENT_METHOD };
  const paymentLinkChoice = { label: t`Payment link`, value: PAYMENT_LINK_METHOD };
  const onlinePaymentChoice = { label: t`Payment form`, value: ONLINE_PAYMENT_METHOD };

  const choices = [noPaymentChoice];
  if (hasVendor) choices.push(paymentLinkChoice, onlinePaymentChoice);

  if (!coreData || !coreData.payment_methods) {
    return choices;
  }

  // If it's a staff with not enough rights
  if (!coreData.show_all_payment_solutions) {
    // In manufacturer mode we'll allow None (the team might not have a vendor and we dont want paylink)
    if (coreData.is_manufacturer) return [noPaymentChoice];
    // Otherwise we'll allow staff to gen paylinks if there's a vendor
    return hasVendor ? [paymentLinkChoice] : [];
  }

  for (const [methodValue, methodLabel] of Object.entries(coreData.payment_methods)) {
    choices.push({ label: methodLabel, value: methodValue });
  }

  return choices;
};

export default useReadPaymentMethods;
