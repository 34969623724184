import { FormControl, RadioGroup, Stack } from '@chakra-ui/react';

import { COMMON_PADDING } from '../../../utils/styleUtil';
import InputFormLabel from './InputFormLabel';

const InputRadio = ({
  fieldName,
  fieldValue,
  fieldLabel,
  displayLabel = true,
  onChange,
  radioDirection = 'row',
  isRequired,
  minWidth,
  maxWidth,
  ...props
}) => {
  return (
    <FormControl isRequired={isRequired} minW={minWidth} maxW={maxWidth}>
      {displayLabel && <InputFormLabel fieldName={fieldName} fieldLabel={fieldLabel} />}

      <RadioGroup pl={COMMON_PADDING} id={fieldName} name={fieldName} onChange={newValue => onChange(fieldName, newValue)} value={fieldValue}>
        <Stack direction={radioDirection} gap="1rem">
          {props.children}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};

export default InputRadio;
