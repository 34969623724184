import { Box, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import InputCheckbox from '../../generic/form/InputCheckbox';

export const MERGE_ORDER_FIELDNAME = 'merge_order';

const CheckoutMergeOrderCheckbox = ({ previousOrderDetails, ...props }) => {
  return (
    <InputCheckbox fieldName={MERGE_ORDER_FIELDNAME} {...props} isRequired={false}>
      <Box fontSize="xs" pl={{ base: '.4rem', md: undefined }} mt=".8rem">
        <Text>
          <Trans>Add this cart to the last order:</Trans>
        </Text>
        <Text fontWeight="semibold">{previousOrderDetails}</Text>
      </Box>
    </InputCheckbox>
  );
};

export default CheckoutMergeOrderCheckbox;
