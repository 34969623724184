import { useState } from 'react';

import { devMessage } from '../settings/settings';

const useFormValidate = () => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [invalidResources, setInvalidResources] = useState(false);
  const [emptyRequiredField, setEmptyRequiredField] = useState(false);
  const [duplicateParticipants, setDuplicateParticipants] = useState(false);

  const isInvalidForm = form => {
    const notValidEmail = hasInvalidEmail(form);
    setInvalidEmail(notValidEmail);

    const notValidPhone = hasInvalidPhone(form);
    setInvalidPhone(notValidPhone);

    const notValidDate = hasInvalidDate(form);
    setInvalidDate(notValidDate);

    const notValidResources = hasInvalidResources(form);
    setInvalidResources(notValidResources);

    const notValidRequiredFields = hasEmptyRequiredFields(form);
    setEmptyRequiredField(notValidRequiredFields);

    const notUniqueParticipants = hasDuplicateParticipants(form);
    setDuplicateParticipants(notUniqueParticipants);

    devMessage(
      'Form not valid? email/phone/date/ress/required/dupl:',
      notValidEmail,
      notValidPhone,
      notValidDate,
      notValidResources,
      notValidRequiredFields,
      notUniqueParticipants
    );

    return notValidEmail || notValidPhone || notValidDate || notValidResources || notValidRequiredFields || notUniqueParticipants;
  };

  return { isInvalidForm, invalidEmail, invalidPhone, invalidDate, invalidResources, emptyRequiredField, duplicateParticipants };
};

export default useFormValidate;

// This counts our invalid selects
// This is easier than checking every select state
const hasInvalidResources = form => form.querySelectorAll('.ChakraReactSelect>div[data-invalid=true]').length > 0;

const hasInvalidPhone = form => {
  const invalidNumberInputs = form.querySelectorAll('input.invalid-number');
  if (!invalidNumberInputs.length) return false;

  // we manually have to trigger blur to get the InputPhone blur checks
  invalidNumberInputs.forEach(function (element) {
    element.focus();
    element.blur();
  });
  return true;
};

const hasInvalidDate = form => form.querySelectorAll('input.inputDate[aria-invalid=true]').length > 0;

export const INVALID_EMAIL_CLASSNAME = 'invalid-email';
const hasInvalidEmail = form => {
  const hasInvalidMailLabel = form.querySelectorAll('input.' + INVALID_EMAIL_CLASSNAME).length > 0;
  if (hasInvalidMailLabel) return true;

  // Else maybe blur hasnt been triggered (eg: we pressed enter on the mail input)
  const emailField = form.querySelector('input#email');
  const isEmailValueInvalid = emailField && emailField.required ? !isValidEmailString(emailField.value) : false;
  return isEmailValueInvalid;
};

export const isValidEmailString = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const hasEmptyRequiredFields = form => {
  const requiredFields = form.querySelectorAll('input[required]');
  for (const field of requiredFields) {
    if (field.value?.trim().length > 0 || field.checked) continue;

    return true;
  }
  return false;
};

const hasDuplicateParticipants = form => {
  const firstNames = form.querySelectorAll('input.ParticipantData_first_name');
  if (firstNames.length <= 0) return false;

  const lastNames = form.querySelectorAll('input.ParticipantData_last_name');

  const participants = [];
  for (let i = 0; i < firstNames.length; i++) {
    const fullName = firstNames[i].value.trim() + ' ' + lastNames[i].value.trim();
    if (fullName === ' ') continue;

    if (participants.includes(fullName)) {
      return true;
    }

    participants.push(fullName);
  }

  return false;
};
