import { useMemo } from 'react';

const useReadAvailabilityBookMore = (availabilities, product, cartData = null) => {
  const { price_per_participant: isPricePerParticipant } = product ?? {};

  const checkCanBookMore = () => {
    // double equal to catch undefined too
    if (cartData == null) return true;

    // no check for now in price per participant, might be improved to check we dont go above nbPlaces
    if (isPricePerParticipant) return true;

    if (!availabilities) return false;
    for (const availability of availabilities) {
      const { nb_tickets: nbPlaces, max_participants: maxParticipantsPerSession, session_group_id: sessionGroupId } = availability;
      const cartParticipants = getCountSessionParticipants(sessionGroupId, cartData.ocSessionsWithResources);
      const canBookMore = canBookMoreInPerSessionMode(nbPlaces, maxParticipantsPerSession, cartParticipants);
      if (!canBookMore) {
        return false;
      }
    }
    return true;
  };

  const canBookMore = useMemo(() => checkCanBookMore(), [JSON.stringify(availabilities)]);
  return canBookMore;
};

export default useReadAvailabilityBookMore;

const canBookMoreInPerSessionMode = (nbPlaces, maxParticipantsPerSession, cartParticipants) => {
  if (nbPlaces == null) return true; // no limit on available places

  // having a number of places without a max participant would be a misconfiguration but we'll allow it..
  if (maxParticipantsPerSession == null) return true;

  // having a max > nbPlaces shouldnt happen as well but we'll manage in case
  const actualMaxParticipants = maxParticipantsPerSession > nbPlaces ? nbPlaces : maxParticipantsPerSession;

  // In the improbable case we have 10 places and 3 max, we floor() to get 3 sessions available, not ceil() to get 4
  const sessionsTotalAvailable = Math.floor(nbPlaces / actualMaxParticipants);

  // In that same case with 10 in cart, this time we ceil() to get 4, we'll never sell incomplete sessions that dont respect the max
  const sessionsBookedInCart = Math.ceil(cartParticipants / actualMaxParticipants);

  const sessionsStillAvailable = sessionsTotalAvailable - sessionsBookedInCart;
  return sessionsStillAvailable > 0;
};

const getCountSessionParticipants = (sessionGroupId, ocSessionsWithResources) => {
  let cartParticipants = 0;
  ocSessionsWithResources.map(entry => {
    const groupedSessionGroups = entry[1];
    if (groupedSessionGroups.sessionGroupId === sessionGroupId) {
      cartParticipants += groupedSessionGroups.countParticipants;
    }
  });
  return cartParticipants;
};
