import { FormControl, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';
import { i18n } from '@lingui/core';

import InputFormLabel from './InputFormLabel';

// ==============================================================================================
// This component is used to generate an input number either as a filter or inside a form
// When the component value is changed, we trigger an onChange callback provided by a parent
// ==============================================================================================

const InputNumber = ({
  fieldName,
  fieldLabel,
  fieldPlaceholder,
  fieldValue,
  fieldMinimum = 1,
  fieldMaximum,
  displayLabel = true,
  isRequired,
  onChange,
  minWidth,
  maxWidth,
  size,
}) => {
  // prevent writing numbers out of range (fieldMinimum always set - fieldMaximum maybe not)
  const handleOnChange = newValue => {
    if (newValue < fieldMinimum) return;
    if (fieldMaximum && newValue > fieldMaximum) return;

    onChange(fieldName, newValue);
  };

  return (
    <FormControl isRequired={isRequired} minW={minWidth} maxW={maxWidth}>
      {displayLabel && <InputFormLabel fieldName={fieldName} fieldLabel={fieldLabel} />}

      <NumberInput
        name={fieldName}
        id={fieldName}
        value={fieldValue}
        onChange={handleOnChange}
        min={fieldMinimum}
        max={fieldMaximum}
        size={size}
        inputMode="numeric"
        isInvalid={isRequired && fieldValue == ''}
      >
        <NumberInputField placeholder={fieldPlaceholder && i18n._(fieldPlaceholder)} />
        <NumberInputStepper>
          <NumberIncrementStepper color="brand.color_main" borderColor="brand.color_main" />
          <NumberDecrementStepper color="brand.color_main" borderColor="brand.color_main" />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  );
};

export default InputNumber;
