import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { BsArrowRightCircle } from 'react-icons/bs';

import { ROUTE_PAGE_SHOP_BOOKING } from '../../../constants';
import useIsMobile from '../../../hooks/useIsMobile';
import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import { ITEM_LIST_HOVER, ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import BorderLeftBox from '../../generic/BorderLeftBox';
import ButtonIcon from '../../generic/button/ButtonIcon';
import YoplanningHtml from '../../generic/YoplanningHtml';
import ProductImageMobile from '../product/ProductImageMobile';
import ProductMediaManager from '../product/ProductMediaManager';
import ProductPrice from '../product/ProductPrice';

const ShopResource = ({ resource }) => {
  const isMobile = useIsMobile();
  const StackElement = isMobile ? VStack : HStack;

  const navigate = useNavigateBookingEngine();
  const onResourceClick = e => {
    e.preventDefault();
    navigate(`${ROUTE_PAGE_SHOP_BOOKING}/${resource.id}`);
  };

  const productHasOneOrMoreImages = resource.description_image || resource.temp_name;
  return (
    <Box bg="brand.background_main" onClick={onResourceClick} {...ITEM_LIST_HOVER} {...ROUNDED_ELEMENT_PROPS} className="Resource">
      <StackElement spacing={{ base: '5px', md: '.7rem' }}>
        {!isMobile && <ProductMediaManager product={resource} disableVideo maxW={{ base: '100%', md: '160px' }} />}

        {isMobile && productHasOneOrMoreImages && <ProductImageMobile product={resource} />}

        <VStack align="left" flexGrow="1" width="100%" minWidth="0">
          <Text fontSize="md">{resource.label}</Text>

          <BorderLeftBox>
            <YoplanningHtml description={resource.description} noOfLines={3} />
          </BorderLeftBox>
        </VStack>

        {isMobile && !resource.description_image && (
          <Box pt={{ base: '.5rem', md: '0' }}>
            <ProductPrice price={resource.price} isFree={false} />
          </Box>
        )}

        {!isMobile && (
          <>
            <Box pt={{ base: '.5rem', md: '0' }}>
              <ProductPrice price={resource.price} isFree={false} />
            </Box>
            <ButtonIcon icon={<Icon as={BsArrowRightCircle} boxSize="24px" />} />
          </>
        )}
      </StackElement>
    </Box>
  );
};

export default ShopResource;
