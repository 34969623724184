import { t } from '@lingui/macro';

import useWriteSeo from '../../hooks/useWriteSeo';
import CheckoutSuccess from '../business/checkout/CheckoutSuccess';

// ==============================================================================================
// Page infos :
// This page is displayed when the checkout page was sucessful
// This page will clean all cart data
// This page CANNOT be accessed directly from an url.
// Route infos :
// - standard navigation : no param, we reuse data from cart page
// ==============================================================================================

const CheckoutSuccessPage = () => {
  useWriteSeo({ title: t`Order success!` });

  return <CheckoutSuccess />;
};

export default CheckoutSuccessPage;
