import { useState } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { apiGetShopResourceDetail } from '../constants';
import useFetch from './useFetch';

const useFetchSelectedOptionDetail = (isOpen, selectedOptionId) => {
  const [fetchUrl, setFetchUrl] = useState(null);
  const { data, status } = useFetch(fetchUrl, 'resource');

  const [detail, setDetail] = useState(null);

  useUpdateEffect(() => {
    if (!isOpen || !selectedOptionId) {
      return;
    }

    const newFetchUrl = apiGetShopResourceDetail(selectedOptionId);
    if (newFetchUrl === fetchUrl) return;

    setDetail(null);
    setFetchUrl(newFetchUrl);
  }, [isOpen]);

  useUpdateEffect(() => {
    setDetail(data ? { description: data.description, label: data.label } : null);
  }, [data]);

  return { detail, status };
};

export default useFetchSelectedOptionDetail;
