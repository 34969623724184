import { useMemo } from 'react';

import { Divider, HStack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import cloneDeep from 'lodash.clonedeep';

import { assignParticipantToAvailability } from '../../../../hooks/useWriteParticipantsAssign';
import { findArrayItemById } from '../../../../utils/arrayUtil';
import { getCartItemPrice, getFormattedCurrency } from '../../../../utils/business/prices';
import { COMMON_GAP } from '../../../../utils/styleUtil';
import Select from '../../../generic/form/Select';
import CartElementBox from '../../cart/CartElementBox';

const ActivityAssignLine = ({
  activityAssignData,
  setActivityAssignData,
  participantsChoices,
  availability: ava,
  isTaxIncluded,
  cartResources,
  index,
}) => {
  const availabilityPrice = useMemo(() => getCartItemPrice(ava, isTaxIncluded), [JSON.stringify(ava)]);

  const onClientAddedToActivity = clientId => {
    const newData = cloneDeep(activityAssignData);
    assignParticipantToAvailability(ava, newData, clientId);
    setActivityAssignData(newData);
  };

  // This can happen and I have no idea why, here's some production data:
  // participantsChoices has only one participant: [{"value":"6bdbf3b0-49c9-4ee7-bedd-e38dc9ef1335","label":"Participant 1"}]
  // But missing index is "1" which means there's 2 availabilities (grouped together so it has to be the same kind)
  // ava: {"id":"7ba33d1e-5fad-4f80-8e20-ef349b10bb4c","price":"20.00","price_variation_label":null,"price_tax_excluded":"20.00","price_without_promo":null,"client_id":"b3be015b-07a4-4295-ac9b-57eea60f7c45","gift_voucher":null,"resource_id":null,"resource_target_group_id":null,"session_group_id":"07d005cb-e47c-442d-b5c4-8d0db668f548","label":null,"quantity":1,"price_variation_id":"6e6f6417-9334-410b-90a5-24d42b2a2792","cart_group_id":4,"resources":[{"id":"136e0539-9128-4e37-ba58-d64376089b70","price":"...
  // activityAssignData: [{"id":"9fd17671-5b51-4fbe-bf3c-75f73ac84d7e","client_id":"6bdbf3b0-49c9-4ee7-bedd-e38dc9ef1335"}]
  // So I'll just return empty to not block the client and they can decide to add participants
  if (index >= participantsChoices.length) return <></>;

  return (
    <>
      <Divider orientation="horizontal" />
      <HStack w="full" gap={{ base: '4px', md: COMMON_GAP }} py=".3rem" flexWrap="wrap">
        <Select
          fieldChoices={participantsChoices}
          fieldValue={participantsChoices[index].value}
          displayLabel={false}
          onChange={(fieldName, newValue) => onClientAddedToActivity(newValue)}
          maxWidth={{ base: '', md: '150px' }}
          isRequired
          size={{ base: 'md', md: 'xs' }}
        />

        {/* We display : custom price (label) / discount price (id but no label) / standard price (no id, no label) */}
        {availabilityPrice > 0 && (
          <CartElementBox flexGrow={{ base: '1', md: '0' }}>
            <Text w="full" textAlign={{ base: 'center', md: 'left' }}>
              {ava.price_variation_label && <>{ava.price_variation_label}</>}
              {ava.price_variation_id && !ava.price_variation_label && <Trans>Special price</Trans>}
              {!ava.price_variation_id && !ava.price_variation_label && <Trans>Standard price</Trans>}
              {` : ${getFormattedCurrency(availabilityPrice)}`}
            </Text>
          </CartElementBox>
        )}

        {ava.resources.map(res => {
          const relatedResource = findArrayItemById(res.resource_id, cartResources);
          const formattedPrice = getFormattedCurrency(relatedResource.price);
          return (
            <CartElementBox key={res.id} flexGrow={{ base: '1', md: '0' }}>
              <Text w="full" textAlign={{ base: 'center', md: 'left' }}>{`${relatedResource.label} : ${formattedPrice}`}</Text>
            </CartElementBox>
          );
        })}
      </HStack>
    </>
  );
};

export default ActivityAssignLine;
