import { Box } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { getFormattedCurrency } from '../../../utils/business/prices';
import { ROUNDED_ELEMENT_PROPS, SMALLBOX_PROPS } from '../../../utils/styleUtil';
import LabelWithText from '../../generic/LabelWithText';

const ProductPrice = ({ price, isFree }) => {
  return (
    <Box bg="brand.background_smallbox" flexShrink={0} {...ROUNDED_ELEMENT_PROPS} minWidth="160px" {...SMALLBOX_PROPS}>
      <LabelWithText label={<Trans>From</Trans>} text={isFree ? <Trans>Free</Trans> : getFormattedCurrency(price)} textSize="md" />
    </Box>
  );
};

export default ProductPrice;
