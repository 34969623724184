import { Button, Divider, DrawerFooter, HStack, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import DevThemeButtonHardReset from './DevThemeButtonHardReset';
import DevThemeButtonSave from './DevThemeButtonSave';

const DevThemeButtons = ({ clicked, onSave, onApply, onReset, onHardReset }) => {
  const btnProps = {
    isDisabled: clicked,
    w: 'full',
  };
  return (
    <DrawerFooter justifyContent="center">
      <VStack w="full">
        <HStack w="full" pb=".5rem">
          <Button onClick={onApply} {...btnProps}>
            <Trans>Refresh</Trans>
          </Button>
        </HStack>
        <Divider />
        <HStack w="full" pt=".5rem">
          <DevThemeButtonSave onSave={onSave} {...btnProps} />
          <DevThemeButtonHardReset onHardReset={onHardReset} {...btnProps} />
        </HStack>
      </VStack>
    </DrawerFooter>
  );
};

export default DevThemeButtons;
