import { Alert, AlertDescription, AlertIcon, CircularProgress, Flex, Icon, IconButton } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';

import { settings } from '../../../settings/settings';
import { ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import { closeBookingEngine, insideIframe } from '../ExitActionContainer';

const AppInitError = ({ hasError, ...props }) => {
  // const tempFuckSafari = () => {
  //   const promise = document.requestStorageAccess();
  //   promise.then(
  //     function () {
  //       location.reload();
  //     },
  //     function () {} // Storage access was denied.
  //   );
  // };

  return (
    <Flex justifyContent="center" alignItems="center" h="calc(100vh)">
      {!hasError && <CircularProgress isIndeterminate color="blue.500" />}
      {hasError && (
        <Alert status="error" maxW="380px" position="relative" {...ROUNDED_ELEMENT_PROPS}>
          <AlertIcon />
          <AlertDescription>{props.children}</AlertDescription>
          {(insideIframe() || settings.DEBUG) && (
            <IconButton
              icon={<Icon as={MdClose} boxSize="20px" color="red" />}
              onClick={closeBookingEngine}
              position="absolute"
              top="0"
              right="0"
              size="xs"
              bg="transparent"
            />
          )}
        </Alert>
      )}
    </Flex>
  );
};

export default AppInitError;
