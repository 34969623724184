import { Box, Collapse, Link, ListItem, Text, UnorderedList, useDisclosure, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { getPriceVariationToString } from '../../../../utils/business/prices';
import { ROUNDED_ELEMENT_PROPS, SMALLBOX_PROPS } from '../../../../utils/styleUtil';

const ProductDetailSpecialPricesMobile = ({ specialPrices }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <VStack alignItems="flex-end">
      <Link onClick={onToggle}>
        {!isOpen && <Trans>Display special prices</Trans>}
        {isOpen && <Trans>Hide special prices</Trans>}
      </Link>

      <Collapse animateOpacity in={isOpen}>
        <Box bg="brand.background_smallbox" fontSize="xs" flexShrink={0} {...ROUNDED_ELEMENT_PROPS} {...SMALLBOX_PROPS} py=".5rem">
          <UnorderedList>
            {specialPrices.map((spePrice, index) => (
              <ListItem key={index}>
                {spePrice.label + ' : '}

                <Text as="span">{getPriceVariationToString(spePrice)}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Collapse>
    </VStack>
  );
};

export default ProductDetailSpecialPricesMobile;
