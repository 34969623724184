import { Box, Flex, Spacer } from '@chakra-ui/react';

import useIsMobile from '../../../hooks/useIsMobile';
import { COMMON_PADDING, ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import CheckoutPromoCode from './CheckoutPromoCode';
import CheckoutTaxes from './CheckoutTaxes';

const CheckoutPromoTaxContainer = ({ teamId, cartOrderId, priceData }) => {
  const isMobile = useIsMobile();

  return (
    <Box bg="brand.background_main" {...ROUNDED_ELEMENT_PROPS}>
      <Flex bg="brand.background_smallbox" p={COMMON_PADDING} gap="1.5rem" {...ROUNDED_ELEMENT_PROPS} flexDirection={{ base: 'column', md: 'row' }}>
        {!isMobile && (
          <>
            <CheckoutPromoCode teamId={teamId} cartOrderId={cartOrderId} />
            <Spacer />
          </>
        )}

        <CheckoutTaxes priceData={priceData} />

        {isMobile && <CheckoutPromoCode teamId={teamId} cartOrderId={cartOrderId} />}
      </Flex>
    </Box>
  );
};

export default CheckoutPromoTaxContainer;
