import { AspectRatio } from '@chakra-ui/react';

const Video = ({ videoUrl, w = '100%', maxW, h, maxH }) => {
  const actualVideoUrl = adaptVideoSrc(videoUrl);

  return (
    <AspectRatio w={w} maxW={maxW} h={h} maxH={maxH} ratio={16 / 9}>
      <iframe src={actualVideoUrl} allowFullScreen />
    </AspectRatio>
  );
};

export default Video;

// It's 100% sure people will just paste youtube links instead of giving the PROPER youtube embed link
// So in preparation of this case, we'll adapt it here
// And later we can manage other cases of fuckery
const adaptVideoSrc = videoUrl => {
  return videoUrl.replace('/watch?v=', '/embed/');
};
