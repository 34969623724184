import { useEffect, useState } from 'react';

import { usePlacesWidget } from 'react-google-autocomplete';
import { useUpdateEffect } from 'usehooks-ts';

import useFetchGmapsPlace from '../../../hooks/useFetchGmapsPlace';
import { settings } from '../../../settings/settings';
import { GMAPS_REQUESTED_FIELDS, adaptPlaceForBackend, adaptPlaceForFrontEnd, getNameFromPlace, isValidLatLng } from '../../../utils/mapsUtil';
import { isStrNullOrEmpty } from '../../../utils/strUtil';
import InputText from './InputText';

export const LOCATION_FILTER_NAME = 'location';

const InputGoogleMapsPlace = ({ fieldValue, onChange, ...props }) => {
  const [formattedAddress, setFormattedAddress] = useState('');

  const [saveFilterProcessed, setSavedFilterProcessed] = useState(false);

  const onPlaceSelected = place => {
    if (place == null) return;

    const placeName = getNameFromPlace(place);
    const latLngValue = adaptPlaceForBackend(place);

    setFormattedAddress(placeName);
    onChange(props.fieldName, latLngValue);
  };

  const { ref } = usePlacesWidget({
    apiKey: settings.GMAPS_API_KEY,
    onPlaceSelected: onPlaceSelected,
    inputAutocompleteValue: formattedAddress,
    options: {
      fields: GMAPS_REQUESTED_FIELDS,
    },
  });

  // This needs to be loaded after usePlacesWidget !!
  const { isPlaceServiceLoaded, getPlaceFromName, getPlaceFromLatLng } = useFetchGmapsPlace();

  useEffect(() => {
    if (saveFilterProcessed) return;

    // no value to process, no need to wait for placeService
    if (isStrNullOrEmpty(fieldValue)) {
      setSavedFilterProcessed(true);
      return;
    }

    if (!isPlaceServiceLoaded) return;

    doProcessSavedFilter();
  }, [isPlaceServiceLoaded]);

  const doProcessSavedFilter = () => {
    const latLngFilter = adaptPlaceForFrontEnd(fieldValue);

    // no lat/lng, so we have a name
    if (latLngFilter == null) {
      getPlaceFromName(fieldValue, placeFound => {
        setSavedFilterProcessed(true);
        onPlaceSelected(placeFound);
      });
      return;
    }

    if (!isValidLatLng(latLngFilter)) {
      setSavedFilterProcessed(true);
      setFormattedAddress('');
      onChange(props.fieldName, '');
      return;
    }

    // we have a lat/lng, just need to set the name visually
    getPlaceFromLatLng(latLngFilter, placeFound => {
      setSavedFilterProcessed(true);
      setFormattedAddress(placeFound ? placeFound.name : '');
    });
  };

  // used to manage the reset of the filter
  useUpdateEffect(() => {
    if (fieldValue !== '') return;

    resetValue();
  }, [fieldValue]);

  const resetValue = () => {
    setFormattedAddress('');
    onChange(props.fieldName, '');
  };

  return (
    <InputText
      ref={ref}
      {...props}
      fieldValue={formattedAddress}
      onChange={(fName, fValue) => setFormattedAddress(fValue)}
      isDisabled={!saveFilterProcessed}
      onClearValue={() => resetValue()}
    />
  );
};

export default InputGoogleMapsPlace;
