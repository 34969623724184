import { Flex, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { settings } from '../../../settings/settings';
import { getFormattedCurrency } from '../../../utils/business/prices';

const SelectOptionWithPrice = ({ label, price, stock, stockMax, unavailable }) => {
  return (
    <Flex width="100%" alignItems="center" gap="2">
      {settings.TRACE_VARIANT_MAX_STOCK && stock != null && (
        <Text color={unavailable ? 'red' : 'green'}>
          {stock}/{stockMax}
        </Text>
      )}

      {/* THIS IS VERY SENSITIVE ! 
      I want to keep the options with the same width as the select
      At the same time I want to label to be as long as possible */}
      <Flex flexGrow="1" justifyContent="flex-start" style={{ overflow: 'hidden' }}>
        <Flex flexDir="column">
          <Text maxW="350px" style={{ overflow: 'visible', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {label}
          </Text>

          {stock != null && stock < 0 && (
            <Text fontSize="10px" color="red">
              <Trans>Not enough stock ({stockMax})</Trans>
            </Text>
          )}
        </Flex>
      </Flex>

      {price > 0 && (
        <Flex flexGrow="0" justifyContent="flex-end">
          <Text fontWeight="500" color="brand.color_main" textAlign="right">
            {getFormattedCurrency(price)}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default SelectOptionWithPrice;
