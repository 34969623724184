import { useEffect, useMemo, useState } from 'react';

import { devMessage } from '../settings/settings';
import { getLangForTranslations } from '../utils/langUtil';
import { getFullApiUrl } from '../utils/navigationUtil';
import { REQUEST_STATUS } from '../utils/requestUtil';
import useReadCartExpiration from './useReadCartExpiration';

// ==============================================================================================
// This hook is used to fetch data from a url (GET)
// If the url is a state and it changes, then this hook is triggered again
// We send back the data and also the status which is a value of REQUEST_STATUS
// If the data is a non empty array, we set hasMore to true, this flag is also sent back
// You can use propertyToReturn to return a specific property from the response

// If the flag replaceData is set to true, we replace the data
// Otherwise, if set to false then we append the new data to the existing fetched data
// ==============================================================================================

const useFetch = (url, propertyToReturn, refReplaceData, expectedCountResults) => {
  const CURRENT_APP_LANG = useMemo(() => getLangForTranslations(), []);

  const [data, setData] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [status, setStatus] = useState(REQUEST_STATUS.LOADING);
  const hasExpired = useReadCartExpiration();

  const getResponseData = data => {
    if (!propertyToReturn) {
      devMessage('RESPONSE (data):', data);

      // TEMP TEST BIDON MULTI IMAGE / VIDEO
      // if (data.product && data.product.description_image == '/media/cache/15/75/1575a8c31e77dae6e8381b9807d46d4d.jpg') {
      //   data.product.description_video = 'https://www.youtube.com/watch?v=7-VEPjqchak';
      //   data.product.temp_name = [
      //     data.product.description_image,
      //     'http://localhost:8000/media/uploads/team/logo/719ad97fff884fb1b71814eb4fa06d60_logo_yoplanning.png',
      //   ];
      // }
      // TEMP TEST BIDON MULTI IMAGE / VIDEO

      return data;
    }

    // TEMP TEST BIDON MULTI IMAGE / VIDEO
    // if (data[propertyToReturn][0]['description_image']) {
    //   data[propertyToReturn][0]['description_video'] = 'https://www.youtube.com/watch?v=7-VEPjqchak';
    //   data[propertyToReturn][0]['temp_name'] = [
    //     data[propertyToReturn][0]['description_image'],
    //     'http://localhost:8000/media/uploads/team/logo/719ad97fff884fb1b71814eb4fa06d60_logo_yoplanning.png',
    //   ];
    // }
    // TEMP TEST BIDON MULTI IMAGE / VIDEO

    devMessage('RESPONSE (prop):', data[propertyToReturn]);
    return data[propertyToReturn];
  };

  useEffect(() => {
    const fullUrl = getFullApiUrl(url);
    if (!fullUrl || hasExpired) return;
    setStatus(REQUEST_STATUS.LOADING);

    devMessage('CALL:', fullUrl);

    const replaceData = refReplaceData ? refReplaceData.current : true;
    const abortController = new AbortController();
    fetch(fullUrl, {
      credentials: 'include', // Required to store/retransmit ajax cookies (csrf/session) !!
      signal: abortController.signal,
      headers: {
        'Accept-Language': CURRENT_APP_LANG,
      },
    })
      .then(res => {
        if (!res.ok) throw Error();
        return res.json();
      })
      .then(rawData => {
        const responseData = getResponseData(rawData);

        const isArray = Array.isArray(responseData);
        setHasMore(isArray && responseData.length === expectedCountResults);

        if (replaceData) {
          setData(responseData);
        } else {
          setData(isArray ? data.concat(responseData) : data + responseData);
        }

        setStatus(REQUEST_STATUS.OK);
      })
      .catch(err => {
        if (err.name === 'AbortError') return;

        console.error(err);
        setStatus(REQUEST_STATUS.ERROR);
      });

    return () => abortController.abort();
  }, [url]);

  return { data, status, hasMore };
};

export default useFetch;
