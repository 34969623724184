import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { MdOutlineMyLocation } from 'react-icons/md';

import { getNearestMultipleOf } from '../../../utils/numberUtil';

const ProductProximityTag = ({ proximity: proximityMeters, locality, variant = 'outline' }) => {
  const getApproximateProximity = () => {
    const proximityKM = proximityMeters / 1000;
    if (proximityKM > 100) {
      return getNearestMultipleOf(proximityKM, 10) + ' km';
    }

    const approxProx = proximityKM < 5 ? '< 5' : getNearestMultipleOf(proximityKM, 5);
    return approxProx + ' km';
  };

  if (proximityMeters == null) return <></>;

  return (
    <Tag size="sm" variant={variant} colorScheme="blue" mt="5px">
      <TagLeftIcon as={MdOutlineMyLocation} />
      {locality}
      {!locality && <TagLabel>{getApproximateProximity()}</TagLabel>}
      {locality && <TagLabel ml={1}>{'(' + getApproximateProximity() + ')'}</TagLabel>}
    </Tag>
  );
};

export default ProductProximityTag;
