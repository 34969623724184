import { Box, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { getFormattedCurrency } from '../../../utils/business/prices';
import ProductMediaManager from './ProductMediaManager';

const ProductImageMobile = ({ product }) => {
  const { price_from: priceFrom, free: isFree } = product;
  return (
    <Box w="full" position="relative">
      <ProductMediaManager product={product} maxW={{ base: '100%', md: '140px' }} />

      <Box position="absolute" top="0" right="0" px=".5rem" py=".3rem" bg="rgba(0,0,0,.6)" color="white" borderBottomLeftRadius="10px">
        {isFree && <Trans>Free</Trans>}
        {!isFree && (
          <>
            <Text fontSize="xs" as="span">
              <Trans>Starting from</Trans>
            </Text>
            <Text as="span" fontWeight="500">
              {' ' + getFormattedCurrency(priceFrom)}
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProductImageMobile;
