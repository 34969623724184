import { Spinner, Text, UnorderedList } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { REQUEST_STATUS } from '../../../../utils/requestUtil';
import AvailabilityDateTimeMore from '../../availability/AvailabilityDateTimeMore';

const CartElementDetailSessions = ({ data, status }) => {
  return (
    <>
      <Text fontWeight="500">Session(s)</Text>
      {status === REQUEST_STATUS.LOADING && <Spinner ml=".5rem" size="xs" />}
      {status === REQUEST_STATUS.ERROR && (
        <Text fontSize="10px" fontWeight="300" color="red">
          <Trans>Cannot retrieve session dates, please try again later.</Trans>
        </Text>
      )}

      {data && (
        <UnorderedList onClick={e => e.stopPropagation()} pl="5px">
          {data.sessions.map((session, index) => (
            <AvailabilityDateTimeMore session={session} key={index} />
          ))}
        </UnorderedList>
      )}
    </>
  );
};

export default CartElementDetailSessions;
