import { useState } from 'react';

import { useEffectOnce } from 'usehooks-ts';

import useWritePreCart from '../../../hooks/useWritePreCart';
import { isArrEmpty } from '../../../utils/arrayUtil';
import FetchLoaderSpinner from '../../generic/FetchLoaderSpinner';
import SessionVariantsForm from './SessionVariantsForm';

const SessionVariantsDirectAccess = ({ sessionGroupId }) => {
  const { preCartData, setPreCartData } = useWritePreCart();
  const { product, availabilities } = preCartData;

  const [accessReady, setAccessReady] = useState(false);

  useEffectOnce(() => {
    // url sess already here and data is complete
    if (!isArrEmpty(availabilities) && availabilities[0].session_group_id === sessionGroupId && product !== null) {
      setAccessReady(true);
      return;
    }

    const emptyAva = [{ session_group_id: sessionGroupId }];
    setPreCartData({ ...preCartData, product: null, availabilities: emptyAva, queryProduct: true });
    setAccessReady(true);
  });

  return (
    <>
      {!accessReady && <FetchLoaderSpinner />}
      {accessReady && <SessionVariantsForm />}
    </>
  );
};

export default SessionVariantsDirectAccess;
