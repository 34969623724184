import { VStack } from '@chakra-ui/react';

import useFetchMoreDates from '../../../hooks/useFetchMoreDates';
import AvailabilityMoreDatesLink from './AvailabilityMoreDatesLink';
import AvailabilityMoreDatesList from './AvailabilityMoreDatesList';

const AvailabilityMoreDates = ({ sessionGroupIds, nbSessions }) => {
  const { moreDateShown, toggleMoreDates, data, status } = useFetchMoreDates(sessionGroupIds);

  return (
    <VStack align="left" alignItems="flex-start">
      <AvailabilityMoreDatesLink moreDateShown={moreDateShown} nbSessions={nbSessions} status={status} onClick={toggleMoreDates} />
      <AvailabilityMoreDatesList moreDateShown={moreDateShown} data={data} />
    </VStack>
  );
};

export default AvailabilityMoreDates;
