import ShopResource from './ShopResource';

const ShopList = ({ data: resources }) => {
  return (
    <>
      {resources.map(element => (
        <ShopResource key={element.id} resource={element} />
      ))}
    </>
  );
};

export default ShopList;
