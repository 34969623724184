import { useMemo } from 'react';

import { CART_SOURCE_LOGGED_OFFICE } from './useReadCartSource';

const useReadNavigationToCheckoutDetail = cartData => {
  const areDetailsRequired = useMemo(() => checkDetailsRequired(cartData), [JSON.stringify(cartData)]);
  const isOrderComptoir = cartData && cartData.cartSource === CART_SOURCE_LOGGED_OFFICE;
  return { areDetailsRequired, isOrderComptoir };
};

export default useReadNavigationToCheckoutDetail;

// We need details if we booked session groups and if the associated products require details
// For example if we only have vouchers then we wont ask for details
const checkDetailsRequired = cartData => {
  return (
    cartData &&
    cartData.ocSessionsWithResources &&
    cartData.ocSessionsWithResources.length > 0 &&
    cartData.products.filter(product => product.participant_details_required === true).length > 0
  );
};
