import { useState } from 'react';

import { Trans } from '@lingui/macro';
import cloneDeep from 'lodash.clonedeep';
import { useUpdateEffect } from 'usehooks-ts';

import { updateParticipantData } from '../../../utils/business/participants';
import HDivider from '../../generic/HDivider';
import AllSpecificData from '../checkoutdetails/specificdata/AllSpecificData';

const VariantParticipantDetails = ({ participantDetails, countOptions }) => {
  const [participantData, setParticipantData] = useState(participantDetails ? participantDetails.client : null);

  const onParticipantDataUpdate = (customDataId, label, newValue, additionalData) => {
    const copyParticipant = cloneDeep(participantData);
    updateParticipantData(copyParticipant, customDataId, label, newValue, additionalData);
    setParticipantData(copyParticipant);
  };

  useUpdateEffect(() => {
    participantDetails.client = participantData;
  }, [JSON.stringify(participantData)]);

  return (
    <>
      {participantDetails && participantDetails.hasAdditionalDataNeeded && (
        <>
          <HDivider>
            <Trans>Informations</Trans>
          </HDivider>

          <AllSpecificData
            additionalDataNeeded={participantDetails.additionalDataNeeded}
            participant={participantData}
            onParticipantDataUpdate={onParticipantDataUpdate}
          />
        </>
      )}

      {countOptions > 0 && (
        <HDivider>
          <Trans>Options</Trans>
        </HDivider>
      )}
    </>
  );
};

export default VariantParticipantDetails;
