import { Trans } from '@lingui/macro';

import AvailabilitiesFilteredListEmpty from '../business/availability/list/AvailabilitiesFilteredListEmpty';
import AvailabilitiesList from '../business/availability/list/AvailabilitiesList';
import AvailabilitiesListMultiSelect from '../business/availability/list/AvailabilitiesListMultiSelect';
import AlertError from './error/AlertError';

const FilteredListEmptyError = ({ listType }) => {
  if (listType === AvailabilitiesList || listType === AvailabilitiesListMultiSelect) return <AvailabilitiesFilteredListEmpty />;

  // ProductsList, ShopList and other future lists
  return <AlertError message={<Trans>There are no results to display.</Trans>} />;
};

export default FilteredListEmptyError;
