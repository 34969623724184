import { defineMessage } from '@lingui/macro';

import InputNumber from '../../generic/form/InputNumber';
import InputText from '../../generic/form/InputText';

export const RESOURCES_FILTERS = {
  TITLE: {
    type: InputText,
    fieldName: 'title',
    fieldLabel: defineMessage({ message: 'Search' }),
    fieldPlaceholder: defineMessage({ message: 'Resource name' }),
    default: '',
    maxWidth: '300px',
  },
  QUANTITY: {
    type: InputNumber,
    fieldName: 'quantity',
    fieldLabel: defineMessage({ message: 'Quantity' }),
    default: 1,
    fieldMinimum: 1,
    maxWidth: { base: '120px', md: '100px' },
  },
};
