import { useState } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { apiSaveVoucher } from '../constants';
import { getReferrerHost } from '../utils/requestUtil';
import usePost from './usePost';

const usePostVoucher = (teamId, storedCartId) => {
  const [postData, setPostData] = useState(null);
  const { data, status, executePost } = usePost(apiSaveVoucher(), postData);

  const writeVoucher = (productId, selectedVoucher) => {
    const formattedData = getInitialFormattedData(teamId, storedCartId, productId, selectedVoucher.id);

    formattedData.quantity = parseInt(selectedVoucher.quantity);
    if (formattedData.quantity > 1) {
      formattedData.multi_usage = selectedVoucher.isMultiUsage;
    }

    setPostData(formattedData);
  };

  useUpdateEffect(() => {
    executePost();
  }, [JSON.stringify(postData)]);

  return { data, status, writeVoucher };
};

const getInitialFormattedData = (teamId, storedCartId, productId, productVoucherId) => {
  const formattedData = {
    product_id: productId,
    product_voucher_id: productVoucherId,
  };
  formattedData[storedCartId ? 'order_id' : 'team_id'] = storedCartId ?? teamId;
  formattedData['origin'] = getReferrerHost();
  return formattedData;
};

export default usePostVoucher;
