import { Checkbox, FormControl } from '@chakra-ui/react';

const InputCheckbox = ({ fieldName, fieldValue, isRequired, onChange, ...props }) => {
  return (
    <FormControl>
      <Checkbox
        type="checkbox"
        id={fieldName}
        name={fieldName}
        required={isRequired}
        defaultChecked={fieldValue}
        onChange={e => onChange(fieldName, e.target.checked)}
        size={{ base: 'lg', md: 'md' }}
      >
        {props.children}
      </Checkbox>
    </FormControl>
  );
};

export default InputCheckbox;
