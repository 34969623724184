import { useEffect, useState } from 'react';

import cloneDeep from 'lodash.clonedeep';
import { useUpdateEffect } from 'usehooks-ts';

import useWritePreCart from './useWritePreCart';

const useReadAvailableFeatures = data => {
  const { preCartData, setPreCartShopResource } = useWritePreCart();
  const { shopResource } = preCartData;

  // the computed available options
  const [featureOneOptions, setFeatureOneOptions] = useState(null);
  const [featureTwoOptions, setFeatureTwoOptions] = useState(null);

  const [selectedFeatureOne, setSelectedFeatureOne] = useState(null);
  const [selectedFeatureTwo, setSelectedFeatureTwo] = useState(null);

  // This computes the available features on page load and on feature select
  const updateAvailableFeatures = featureIndexToUpdate => {
    const featureKey = getFeatureKeyName(featureIndexToUpdate);
    if (data[featureKey] == null) return;

    const filteredFeatureIds = getFilteredFeatureIds(data.child_resources, featureIndexToUpdate);
    const filteredFeatureData = getFilteredFeatureData(data[featureKey].feature_values, filteredFeatureIds);

    // console.log(
    //   'F update',
    //   featureIndexToUpdate,
    //   'filtered:',
    //   filteredFeatureData,
    //   'all:',
    //   data[featureKey].feature_values,
    //   'real:',
    //   data.child_resources
    // );
    if (featureIndexToUpdate === 1) {
      setFeatureOneOptions(filteredFeatureData);
    } else {
      setFeatureTwoOptions(filteredFeatureData);
    }
  };

  // This saves the feature change (which will trigger a compute update)
  const handleFeatureChange = (featureIndex, newValue) => {
    const featureKey = getFeatureKeyName(featureIndex);
    const copyResource = cloneDeep(shopResource);
    copyResource[featureKey] = newValue;
    setPreCartShopResource(copyResource, data.child_resources);

    if (featureIndex === 1) {
      setSelectedFeatureOne(newValue);
    } else {
      setSelectedFeatureTwo(newValue);
    }
  };

  // on page load : set both selects
  useEffect(() => {
    updateAvailableFeatures(1);
    updateAvailableFeatures(2);
  }, []);

  useUpdateEffect(() => {
    updateAvailableFeatures(1);
    updateAvailableFeatures(2);
  }, [shopResource.quantity]);

  // When F1 has changed, we'll compute F2 if necessary
  useUpdateEffect(() => {
    if (needToSyncOtherFeature(2)) {
      updateAvailableFeatures(2);
    }
  }, [selectedFeatureOne]);

  useUpdateEffect(() => {
    if (needToSyncOtherFeature(1)) {
      updateAvailableFeatures(1);
    }
  }, [selectedFeatureTwo]);

  const needToSyncOtherFeature = featureIndexToCheck => {
    const otherFeatureKey = getFeatureKeyName(featureIndexToCheck);

    // there is no other feature so no need to sync
    if (data[otherFeatureKey] == null) return false;

    // the other feature has not been selected OR this current one has been emptied, so we sync
    if (selectedFeatureOne == null || selectedFeatureTwo == null) return true;

    for (const childResource of data.child_resources) {
      // combination exists, no need to sync
      if (childResource.feature_one_value_id === selectedFeatureOne && childResource.feature_two_value_id !== selectedFeatureTwo) {
        return false;
      }
    }

    return true;
  };

  const getFilteredFeatureIds = (childResourcesData, featureIndexToUpdate) => {
    const featureValueKeyToUpdate = featureIndexToUpdate === 1 ? 'feature_value_one_id' : 'feature_value_two_id';

    // another feature is already selected so we will exclude those that dont match with the other feature
    const otherFeatureSelectedId = featureIndexToUpdate !== 1 ? selectedFeatureOne : selectedFeatureTwo;
    if (otherFeatureSelectedId) {
      const otherFeatureValueKey = featureIndexToUpdate !== 1 ? 'feature_value_one_id' : 'feature_value_two_id';
      return childResourcesData
        .filter(obj => hasEnoughStock(obj) && obj[otherFeatureValueKey] === otherFeatureSelectedId)
        .map(obj => obj[featureValueKeyToUpdate]);
    }

    // This resource has two features so we will exclude those that are misconfigured
    if (data.feature_one && data.feature_two) {
      return childResourcesData
        .filter(obj => hasEnoughStock(obj) && obj.feature_value_one_id != null && obj.feature_value_two_id != null)
        .map(obj => obj[featureValueKeyToUpdate]);
    }

    // Otherwise we just get the feature values with a stock
    return childResourcesData.filter(obj => hasEnoughStock(obj)).map(obj => obj[featureValueKeyToUpdate]);
  };

  const hasEnoughStock = resource => {
    // console.log('ES?', resource.stock, shopResource.quantity, resource.stock == null || resource.stock >= parseInt(shopResource.quantity));
    return resource.stock == null || resource.stock >= parseInt(shopResource.quantity);
  };

  return { featureOneOptions, featureTwoOptions, handleFeatureChange, shopResource };
};

export default useReadAvailableFeatures;

const getFilteredFeatureData = (unFilteredFeatureData, filteredFeatureIds) => {
  const filteredFeatureData = [];
  for (const featureValue of unFilteredFeatureData) {
    if (filteredFeatureIds.includes(featureValue.id)) {
      filteredFeatureData.push(featureValue);
    }
  }
  return filteredFeatureData;
};

const getFeatureKeyName = featureIndex => (featureIndex === 1 ? 'feature_one' : 'feature_two');
