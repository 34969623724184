import { ButtonGroup, Flex, Icon, Link, Spacer } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { TiShoppingCart } from 'react-icons/ti';
import { VscArrowLeft } from 'react-icons/vsc';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import {
  LOCAL_STORAGE_CART,
  ROUTE_PAGE_CART,
  ROUTE_P5_CHECKOUT_DETAILS,
  ROUTE_P6_CHECKOUT,
  ROUTE_PAGE_CHECKOUT_SUCCESS,
  ROUTE_PAGE_SHOP_BOOKING,
  ROUTE_P1_PRODUCT_SELECTION,
  ROUTE_PAGE_SHOP,
} from '../../../../constants';
import useCalcCartSize from '../../../../hooks/useCalcCartSize';
import useNavigateBookingEngine from '../../../../hooks/useNavigateBookingEngine';
import useReadCartSource from '../../../../hooks/useReadCartSource';
import useReadNavigationToCheckoutDetail from '../../../../hooks/useReadNavigationToCheckoutDetail';
import useReadStorageBookingEngine from '../../../../hooks/useReadStorageBookingEngine';
import { settings } from '../../../../settings/settings';
import { BTN_ICON_BOXSIZE, COMMON_PADDING } from '../../../../utils/styleUtil';
import ButtonLink from '../../button/ButtonLink';
import DevCleanButton from '../../button/DevCleanButton';
import ExitActionContainer from '../../ExitActionContainer';

const Header = ({ hasProducts, hasShopResources, shopOnly }) => {
  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);
  const cartSize = useCalcCartSize(cartData);
  useReadCartSource(cartData);

  const navigate = useNavigateBookingEngine();
  const handleGoBack = e => {
    e.preventDefault();
    navigate(-1);
  };

  const arrowLeftIcon = <Icon as={VscArrowLeft} boxSize={BTN_ICON_BOXSIZE} />;
  const btnGoBack = (
    <ButtonLink onClick={handleGoBack} leftIcon={arrowLeftIcon}>
      <Trans>Back</Trans>
    </ButtonLink>
  );

  const btnGoBackCart = (
    <ButtonLink to={ROUTE_PAGE_CART} leftIcon={arrowLeftIcon}>
      <Trans>Back</Trans>
    </ButtonLink>
  );

  const { areDetailsRequired } = useReadNavigationToCheckoutDetail(cartData);
  const btnGoBackCheckoutDetail = (
    <ButtonLink to={areDetailsRequired ? ROUTE_P5_CHECKOUT_DETAILS : ROUTE_PAGE_CART} leftIcon={arrowLeftIcon}>
      <Trans>Back</Trans>
    </ButtonLink>
  );

  const btnContinueShopping = (
    <ButtonLink to={ROUTE_P1_PRODUCT_SELECTION} leftIcon={arrowLeftIcon}>
      <Trans>Continue shopping</Trans>
    </ButtonLink>
  );

  const btnGotoCart = (
    <ButtonLink to={ROUTE_PAGE_CART} leftIcon={<Icon as={TiShoppingCart} boxSize={BTN_ICON_BOXSIZE} />}>
      <Trans>My cart ({cartSize})</Trans>
    </ButtonLink>
  );

  const btnGotoShop =
    hasShopResources && !shopOnly ? (
      <Link as={RouterLink} to={ROUTE_PAGE_SHOP} alignSelf="center" mr=".5rem">
        <Trans>Shop</Trans>
      </Link>
    ) : (
      <></>
    );

  const gotoBooking = e => {
    e.preventDefault();
    navigate(ROUTE_P1_PRODUCT_SELECTION);
  };

  const btnGotoBooking =
    hasProducts && !shopOnly ? (
      <Link as={RouterLink} onClick={gotoBooking} alignSelf="center" mr=".5rem">
        <Trans>Booking</Trans>
      </Link>
    ) : (
      <></>
    );

  /* 
    We're using routing to display components depending on the current route
  */
  return (
    <>
      <Flex minWidth="max-content" alignItems="center" pb={COMMON_PADDING}>
        <Routes>
          <Route path={ROUTE_P1_PRODUCT_SELECTION} element={<></>} />
          <Route path={ROUTE_PAGE_SHOP} element={<></>} />
          <Route path={`${ROUTE_PAGE_CART}/*`} element={btnContinueShopping} />
          <Route path={ROUTE_PAGE_CHECKOUT_SUCCESS} element={btnContinueShopping} />
          <Route path={ROUTE_P5_CHECKOUT_DETAILS} element={btnGoBackCart} />
          <Route path={ROUTE_P6_CHECKOUT} element={btnGoBackCheckoutDetail} />
          <Route path="*" element={btnGoBack} />
        </Routes>

        {/* This is a cleaning button displayed in development */}
        {settings.DEBUG && <DevCleanButton />}

        <Spacer />

        <ButtonGroup spacing="6px">
          <Routes>
            <Route path={`${ROUTE_P1_PRODUCT_SELECTION}`} element={btnGotoShop} />
            <Route path={`${ROUTE_PAGE_SHOP}`} element={btnGotoBooking} />
            <Route path={`${ROUTE_PAGE_SHOP_BOOKING}/*`} element={btnGotoBooking} />
            <Route path="*" element={<></>} />
          </Routes>

          <Routes>
            <Route path={`${ROUTE_PAGE_CART}/*`} element={<></>} />
            <Route path={`${ROUTE_PAGE_CHECKOUT_SUCCESS}`} element={<></>} />
            <Route path="*" element={btnGotoCart} />
          </Routes>

          <ExitActionContainer />
        </ButtonGroup>
      </Flex>
      <Outlet />
    </>
  );
};

export default Header;
