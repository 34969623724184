import { useCallback, useState } from 'react';

import { CSRF_COOKIE_NAME, CSRF_HEADER_NAME } from '../constants';
import { devMessage } from '../settings/settings';
import { getFullApiUrl } from '../utils/navigationUtil';
import { getCookie, REQUEST_STATUS } from '../utils/requestUtil';

// This one can be used outside <Router> because there is no navigation here
// TODO refactor with usePost.js - IN THE MEANTIME AVOID USING UNLESS YOU ARE SURE OF THE USAGE!!!!!
const usePostOutsideRouter = (url, jsonData) => {
  const payload = JSON.stringify(jsonData);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(REQUEST_STATUS.LOADING);

  const executePost = useCallback(() => {
    if (jsonData === null) return; // This can happen when react reloads state

    const abortController = new AbortController();

    const fullUrl = getFullApiUrl(url);
    devMessage('POST:', fullUrl);
    devMessage('POST-DATA:', jsonData);
    fetch(fullUrl, {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        [CSRF_HEADER_NAME]: getCookie(CSRF_COOKIE_NAME),
      },
      credentials: 'include', // Required to store/retransmit ajax cookies (csrf/session) !!
      signal: abortController.signal,
    })
      .then(res => {
        if (!res.ok) throw Error();
        return res.json();
      })
      .then(data => {
        devMessage('POST-RESPONSE:', data);
        setData(data);
        setStatus(REQUEST_STATUS.OK);
      })
      .catch(err => {
        if (err.name === 'AbortError') return;

        console.error(err);
        setStatus(REQUEST_STATUS.ERROR);
      });
  }, [url, payload]);

  return { data, status, executePost };
};

export default usePostOutsideRouter;
