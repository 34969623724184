import { useContext } from 'react';

import { Flex, Spacer } from '@chakra-ui/react';

import { CoreContext } from '../../../../context/CoreContext';
import AppLangSelector from './AppLangSelector';
import PoweredBy from './PoweredBy';

const Footer = () => {
  const { coreData } = useContext(CoreContext);
  const { show_yoplanning_logo: showYoplanningLogo } = coreData.booking_engine;
  const showAppLangSelector = true; // change this later if you want to use settings

  return (
    <Flex minW="max-content" mt=".5rem" alignItems="center">
      {showAppLangSelector && <AppLangSelector />}
      <Spacer />
      {showYoplanningLogo && <PoweredBy />}
    </Flex>
  );
};

export default Footer;
