import { Box, Link, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import InputCheckbox from '../../generic/form/InputCheckbox';

const textProps = {
  as: 'span',
};

const CheckoutGeneralConditions = ({ teamName, teamCgv, ...dataProps }) => {
  return (
    <InputCheckbox fieldName="generalConditions" {...dataProps}>
      <Box fontSize="xs" pl={{ base: '.4rem', md: undefined }}>
        <Text {...textProps}>
          <Trans>I agree to the general terms and conditions of</Trans>{' '}
        </Text>

        {teamCgv && (
          <Link href={teamCgv} isExternal>
            {teamName}
          </Link>
        )}
        {!teamCgv && <Text {...textProps}>{teamName}</Text>}
      </Box>
    </InputCheckbox>
  );
};

export default CheckoutGeneralConditions;
