import { useState } from 'react';

import { useToast } from '@chakra-ui/react';
import cloneDeep from 'lodash.clonedeep';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import { BIRTHDATE_FIELDNAME } from '../components/generic/form/InputBirthDate';
import { EMAIL_FIELDNAME } from '../components/generic/form/InputEmail';
import { PHONE_FIELDNAME } from '../components/generic/form/InputPhone';
import { apiGetAvailabilitiesDetails, ROUTE_P2_AVA_SELECTION, ROUTE_P1_PRODUCT_SELECTION } from '../constants';
import { devMessage } from '../settings/settings';
import { isArrEmpty } from '../utils/arrayUtil';
import { getMainPrice } from '../utils/business/prices';
import useNavigateBookingEngine from './useNavigateBookingEngine';
import usePost from './usePost';
import { pushStandardDataInfo } from './useReadAdditionalData';
import useReadLoggedUser from './useReadLoggedUser';
import useReadTeam from './useReadTeam';
import useWritePreCart from './useWritePreCart';

const usePostLoadVariant = (availabilities, queryProduct) => {
  const toast = useToast();

  const loggedUser = useReadLoggedUser();
  const { teamId } = useReadTeam();

  const { preCartData, setPreCartDefaultVariant } = useWritePreCart();
  const { itemsToDeleteForEdit } = preCartData;

  const [formattedData, setFormattedData] = useState(null);
  const { data, status, executePost } = usePost(apiGetAvailabilitiesDetails(), formattedData);

  const navigate = useNavigateBookingEngine();

  useEffectOnce(() => {
    devMessage('Going to load variants for AVA:', availabilities, 'queryProduct?', queryProduct);
    if (isArrEmpty(availabilities)) return;

    // On edit we already loaded everything
    if (itemsToDeleteForEdit) return;

    const allSessionGroupIds = getAvailabilitiesDetailRequestData(availabilities, teamId, queryProduct);
    setFormattedData(allSessionGroupIds);
  });

  useUpdateEffect(() => {
    executePost();
  }, [formattedData]);

  useUpdateEffect(() => {
    // When react reload state
    if (!data) return;

    if (!data.success) {
      showErrorToast(data.message);
      const productId = preCartData.product?.id;
      navigate(productId ? `${ROUTE_P2_AVA_SELECTION}/${productId}` : ROUTE_P1_PRODUCT_SELECTION);
      return;
    }

    // We tried to access P3 directly so we wanted to queryProduct as well (because it's not in storage)
    // But if for some reason the backend doesnt give the product then we move to P1
    const hasProductInData = 'product' in data;
    if (!hasProductInData && queryProduct) {
      navigate(ROUTE_P1_PRODUCT_SELECTION);
      return;
    }

    setPreCartDefaultVariant(createDefaultVariant(data, loggedUser), loggedUser, hasProductInData ? data['product'] : null);
  }, [data]);

  const showErrorToast = message => {
    if (!message || toast.isActive('P3_ERROR')) return;

    toast({
      id: 'P3_ERROR',
      title: message,
      status: 'error',
      duration: 4000,
      variant: 'subtle',
    });
  };

  return { status, itemsToDeleteForEdit };
};
export default usePostLoadVariant;

// This default variant belong to a selected availability
// Each time we add a new variant, this one will be cloned
export const createDefaultVariant = (availabilityDetail, loggedUser) => {
  const mainPriceData = getMainPrice(availabilityDetail.standard_price, 1, availabilityDetail.prices);

  const defaultVariant = {
    participant: 1,
    currentPriceVariationId: mainPriceData.priceVariationId,
    standardPrice: availabilityDetail.standard_price,
    canSelectPrice: canSelectPrice(availabilityDetail),
    canAddVariant: canAddVariant(availabilityDetail, loggedUser),
    participantDetails: loggedUser ? null : getParticipantDetails(availabilityDetail.client_data),
    nbSessions: availabilityDetail.nb_sessions,
    start_date: availabilityDetail.start_date ?? null,
  };
  defaultVariant.custom_options = formatCustomOptions(availabilityDetail.options);
  defaultVariant.custom_prices = cloneDeep(availabilityDetail.prices);
  return defaultVariant;
};

const canSelectPrice = availabilityDetail => {
  return availabilityDetail && availabilityDetail.prices.length > 0;
};

// When logged we dont need to add variants if no prices and no options (the variants would be the same)
// When unlogged we always need the button because we cannot manually enter the participants number
const canAddVariant = (availabilityDetail, loggedUser) => {
  if (loggedUser) {
    return (availabilityDetail && availabilityDetail.options.length > 0) || canSelectPrice(availabilityDetail);
  }
  return true;
};

const formatCustomOptions = options => {
  const copyOptions = cloneDeep(options);
  // uncomment if we decide to order alphabetically
  // copyOptions.sort((a, b) => (a.label.length > b.label.length ? 1 : -1));
  return copyOptions;
};

const getParticipantDetails = clientDataNeeded => {
  if (!clientDataNeeded.participant_details_required) return null;

  const standardData = [];
  pushStandardDataInfo(standardData, clientDataNeeded, BIRTHDATE_FIELDNAME, EMAIL_FIELDNAME, 'language', PHONE_FIELDNAME);
  const customData = getCustomData(clientDataNeeded.specific_data);

  return {
    additionalDataNeeded: {
      standardData: standardData,
      customData: customData,
    },
    hasAdditionalDataNeeded: standardData.length > 0 || customData.length > 0,
    client: { extra_data: {} },
  };
};

const getCustomData = extraData => {
  const customData = [];
  for (const data of extraData) {
    customData.push({ ...data.specific_person_data, required: data.required });
  }
  customData.sort((a, b) => (a.name.length > b.name.length ? 1 : -1));
  return customData;
};

export const getAvailabilitiesDetailRequestData = (availabilities, teamId, _queryProduct) => {
  const queryProduct = _queryProduct ?? false;
  devMessage('Will get session detail, queryProduct?', queryProduct);
  const allSessionGroupIds = [];
  for (const ava of availabilities) {
    allSessionGroupIds.push(ava.session_group_id);
  }
  return {
    session_group_ids: allSessionGroupIds,
    team_id: teamId,
    query_product: queryProduct,
  };
};
