const ContainerPlural = ({ value, one, other, ...props }) => {
  return (
    <div {...props}>
      {value + ' '}
      {props.children}
    </div>
  );
};

export default ContainerPlural;
