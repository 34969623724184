export const COMMON_PADDING = '1rem';
export const COMMON_GAP = '.7rem';
export const BTN_ICON_BOXSIZE = '21px';
export const COMMON_INPUT_HEIGHT = '35px'; // also in select css + theme.js

// We have different input sizes depending on their label length
// The latest level would be no width = full width
export const DYN_INPUT_WIDTH_LEVEL_1 = '200px';
export const DYN_INPUT_WIDTH_LEVEL_2 = '300px';

// This is used for our various containers that are rounded on the top
// For example the filterbar and the product details
export const TOP_ROUNDED_CONTAINER_PROPS = {
  borderTopRadius: { base: '0', md: '10px' },
  p: COMMON_PADDING,
  shadow: 'xs',
};

// Same but for the bottom (might not apply to the same component that's why 2 vars)
export const BOTTOM_ROUNDED_CONTAINER_PROPS = {
  pb: COMMON_PADDING,
  borderBottomRadius: { base: '0', md: '8px' },
  shadow: 'xs',
};

// This is used for our various rounded elements
// Such as products, availabilities...
export const ROUNDED_ELEMENT_PROPS = {
  padding: COMMON_PADDING,
  borderRadius: '8px',
  shadow: 'xs',
  // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
};

export const RESPONSIVE_WIDTH_BOX_LEFT = {
  w: { base: '100%', md: '70%' },
};

export const RESPONSIVE_WIDTH_BOX_RIGHT = {
  w: { base: '100%', md: '30%' },
};

export const RESPONSIVE_WIDTH_BOX_HALF = {
  w: { base: '100%', md: '50%' },
};

export const SMALLBOX_PROPS = {
  borderColor: 'brand.color_smallbox_border',
  borderWidth: '1px',
  shadow: '',
};

// For products and availabilities selection
export const ITEM_LIST_HOVER = {
  _hover: { cursor: 'pointer', outline: '1px solid', outlineColor: 'brand.color_main' },
};

export const ITEM_SQUARE_HOVER = {
  _hover: { cursor: 'pointer', shadow: 'dark-lg', opacity: '1' },
};

export const HOVER_UNDERLINE = {
  _hover: { textDecoration: 'underline' },
};
