import { useMemo } from 'react';

import { t } from '@lingui/macro';

const useReadParticipantsLangChoices = () => {
  return useMemo(
    () => [
      { label: t`French`, value: 'fr' },
      { label: t`English`, value: 'en' },
      { label: t`Dutch`, value: 'nl' },
      { label: t`Italian`, value: 'it' },
      { label: t`Spanish`, value: 'es' },
      { label: t`German`, value: 'de' },
      { label: t`Russian`, value: 'ru' },
      { label: t`Portuguese`, value: 'pt' },
      { label: t`Arabic`, value: 'ar' },
      { label: t`Turkish`, value: 'tr' },
      { label: t`Danish`, value: 'da' },
      { label: t`Norwegian`, value: 'no' },
      { label: t`Swedish`, value: 'sv' },
      { label: t`Finnish`, value: 'fi' },
      { label: t`Greek`, value: 'el' },
      { label: t`Hebrew`, value: 'he' },
      { label: t`Catalan`, value: 'ca' },
      { label: t`Corsican`, value: 'co' },
      { label: t`Basque`, value: 'eu' },
      { label: t`Breton`, value: 'br' },
    ],
    []
  );
};
export default useReadParticipantsLangChoices;
