import { VStack } from '@chakra-ui/react';

import { LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_ASSIGN_DEFVAL } from '../../../../constants';
import useWriteStorageBookingEngine from '../../../../hooks/useWriteStorageBookingEngine';
import ActivityAssignLine from './ActivityAssignLine';

const ActivityAssignList = ({ participantsChoices, availabilities, isTaxIncluded, resources }) => {
  const [activityAssignData, setActivityAssignData] = useWriteStorageBookingEngine(LOCAL_STORAGE_ASSIGN, LOCAL_STORAGE_ASSIGN_DEFVAL);

  return (
    <VStack alignItems="start" mt="1rem">
      {availabilities.map((ava, index) => (
        <ActivityAssignLine
          key={ava.id}
          index={index}
          activityAssignData={activityAssignData}
          setActivityAssignData={setActivityAssignData}
          participantsChoices={participantsChoices}
          availability={ava}
          isTaxIncluded={isTaxIncluded}
          cartResources={resources}
        />
      ))}
    </VStack>
  );
};

export default ActivityAssignList;
