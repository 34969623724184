import { Center } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import ButtonSubmit from '../../generic/button/ButtonSubmit';

const CheckoutDetailNextStep = ({ isLoading, isLastStep }) => {
  return (
    <Center mt="1rem">
      <ButtonSubmit name="next" isLoading={isLoading} w="200px">
        {!isLastStep && <Trans>Go to next step</Trans>}
        {isLastStep && <Trans>Pay</Trans>}
      </ButtonSubmit>
    </Center>
  );
};

export default CheckoutDetailNextStep;
