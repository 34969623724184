import cloneDeep from 'lodash.clonedeep';

export const updateStateArrayIndex = (array, index, key, newValue) => {
  const newData = cloneDeep(array);
  newData[index] = { ...newData[index], [key]: newValue };
  return newData;
};

export const deleteStateArrayIndex = (array, index) => {
  const newData = cloneDeep(array);
  newData.splice(index, 1);
  return newData;
};
