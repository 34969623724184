import { useState } from 'react';

import { Box } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useBoolean, useUpdateEffect } from 'usehooks-ts';

import { LOCAL_STORAGE_CART } from '../../../constants';
import useFormValidate from '../../../hooks/useFormValidate';
import useReadStorageBookingEngine from '../../../hooks/useReadStorageBookingEngine';
import { COMMON_PADDING } from '../../../utils/styleUtil';
import HeaderLogoLabel from '../../generic/layout/header/HeaderLogoLabel';
import { Accordions, LAST_ACCORDION } from './CheckoutDetailAccordionData';
import CheckoutDetailAccordionMobile from './CheckoutDetailAccordionMobile';
import CheckoutDetailErrors from './CheckoutDetailErrors';
import CheckoutDetailNextStep from './CheckoutDetailNextStep';

// In mobile we act as if we had different pages
// So we have multiple steps (accordions) before the real submit
const CheckoutDetailFormMobile = ({ isTaxIncluded, updateParticipants }) => {
  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);
  const { isInvalidForm, duplicateParticipants } = useFormValidate();

  const [openedAccordion, setOpenedAccordion] = useState(Accordions.PARTICIPANTS_NAMES);

  const { value: isLoading, setTrue: setLoadingTrue } = useBoolean(false);

  const handleSubmit = e => {
    e.preventDefault();

    if (isInvalidForm(e.nativeEvent.target)) {
      return;
    }

    setOpenedAccordion(openedAccordion + 1);
  };

  useUpdateEffect(() => {
    if (openedAccordion === LAST_ACCORDION && updateParticipants()) {
      setLoadingTrue();
    }
  }, [openedAccordion]);

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <HeaderLogoLabel>
        <Trans>Who does what ?</Trans>
      </HeaderLogoLabel>

      <Box p={COMMON_PADDING}>
        <CheckoutDetailAccordionMobile
          cartData={cartData}
          openedAccordion={openedAccordion}
          setOpenedAccordion={setOpenedAccordion}
          isTaxIncluded={isTaxIncluded}
        />
      </Box>
      <CheckoutDetailErrors duplicateParticipants={duplicateParticipants} />
      <CheckoutDetailNextStep isLoading={isLoading} isLastStep={openedAccordion >= LAST_ACCORDION - 1} />
    </form>
  );
};

export default CheckoutDetailFormMobile;
