import { t } from '@lingui/macro';

import useWriteSeo from '../../hooks/useWriteSeo';
import BuyVoucher from '../business/buyvoucher/BuyVoucher';

// ==============================================================================================
// Page infos :
// This is the alternative to SessionVariantsPage, when we want to gift a product
// This page CANNOT be accessed directly from an url.
// Route infos :
// - standard navigation : no param, we reuse page 2 data
// ==============================================================================================

const BuyVoucherPage = () => {
  useWriteSeo({ title: t`Voucher choice` });

  return <BuyVoucher />;
};

export default BuyVoucherPage;
