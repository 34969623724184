import { AccordionItem, AccordionPanel, Box, Divider } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { IoMdPersonAdd } from 'react-icons/io';

import useReadParticipants from '../../../hooks/useReadParticipants';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import AccordionHeader from '../../generic/AccordionHeader';
import ButtonAdd from '../../generic/button/ButtonAdd';
import VerticalStack from '../../generic/layout/VerticalStack';
import CheckoutDetailParticipantHeader from './CheckoutDetailParticipantHeader';
import CheckoutDetailParticipantData from './specificdata/CheckoutDetailParticipantData';

const CheckoutDetailParticipants = ({ onAccordionClick, cartData }) => {
  const { actualParticipants, minParticipantCount, canAddParticipant, onParticipantAdd, onParticipantDelete } = useReadParticipants(cartData);

  return (
    <AccordionItem>
      <AccordionHeader onClick={onAccordionClick}>
        <Trans>Participants</Trans>
      </AccordionHeader>

      <AccordionPanel bg="brand.background_secondary" {...BOTTOM_ROUNDED_CONTAINER_PROPS} shadow="">
        <VerticalStack>
          {actualParticipants.map((participant, index) => {
            return (
              <Box bg="brand.background_main" {...ROUNDED_ELEMENT_PROPS} key={participant.id}>
                <CheckoutDetailParticipantHeader
                  participantId={participant.id}
                  participantNumber={index + 1}
                  minParticipantCount={minParticipantCount}
                  onParticipantDelete={onParticipantDelete}
                />

                <Divider orientation="horizontal" mt=".5rem" />

                <CheckoutDetailParticipantData participant={participant} />
              </Box>
            );
          })}

          {canAddParticipant && <ButtonAdd onClick={onParticipantAdd} icon={IoMdPersonAdd} />}
        </VerticalStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CheckoutDetailParticipants;
