import { useRef, useState } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { MdEdit } from 'react-icons/md';

import ButtonIcon from './ButtonIcon';

const ButtonEdit = ({ onClick, enableSpinner = true, position = 'absolute', alignItems, blockActions, ...props }) => {
  const [isClicked, setIsClicked] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const handleClick = e => {
    e.preventDefault();
    if (enableSpinner) {
      setIsClicked(true);
    }

    if (blockActions) {
      onOpen();
      return;
    }

    onClick();
  };

  const handleAlertClose = () => {
    setIsClicked(false);
    onClose();
  };

  return (
    <>
      {!isClicked && (
        <ButtonIcon
          icon={<Icon as={MdEdit} boxSize={{ base: '25px', md: '20px' }} />}
          onClick={handleClick}
          position={position}
          alignItems={alignItems}
          top="0"
          right={position === 'relative' ? '0' : '25px'}
          style={{
            opacity: blockActions ? '.4' : undefined,
          }}
          {...props}
        />
      )}

      {isClicked && (
        <Spinner
          position={position}
          alignItems={alignItems}
          top={position === 'relative' ? '-10px' : '15px'}
          right={position === 'relative' ? '0' : '40px'}
          size="sm"
        />
      )}

      {blockActions && (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={handleAlertClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <Trans>Edit cart element</Trans>
              </AlertDialogHeader>

              <AlertDialogBody>
                <Trans>You cannot edit this element now. Others items have to be deleted first.</Trans>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleAlertClose}>
                  <Trans>Close</Trans>
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default ButtonEdit;
