export const padToDigits = (value, digits) => {
  return value.toString().padStart(digits, '0');
};

export const isValidQuantityNumber = quantity => {
  return quantity != null && quantity > 0;
};

export const getNearestMultipleOf = (value, multiple) => {
  const upperNearest = Math.ceil(value / multiple) * multiple;
  const lowerNearest = Math.round(value / multiple) * multiple;

  if (upperNearest - value < lowerNearest - value) return upperNearest;
  return lowerNearest;
};
