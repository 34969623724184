import { Trans, defineMessage } from '@lingui/macro';

import useFetchCheckoutClient from '../../../hooks/useFetchCheckoutClient';
import CheckoutClientSelect from './CheckoutClientSelect';

export const BUSINESS_PROV_SELECTOR_FIELDNAME = 'business_provider_id';

const CheckoutBusinessProvSelect = ({ fieldValue, ...props }) => {
  const {
    clientSelectorChoices: businessProvSelectorChoices,
    onClientSelectorSearchChange: onBusinessProvSelectorSearchChange,
    isClientSelectorLoading: isBusinessProvSelectorLoading,
  } = useFetchCheckoutClient(false, true);

  return (
    <CheckoutClientSelect
      fieldValue={fieldValue}
      {...props}
      fieldChoices={businessProvSelectorChoices}
      handleSearchChange={onBusinessProvSelectorSearchChange}
      isLoading={isBusinessProvSelectorLoading}
      fieldName={BUSINESS_PROV_SELECTOR_FIELDNAME}
      fieldPlaceholder={<Trans>Select business provider</Trans>}
      fieldLabel={defineMessage({ message: 'Business provider' })}
    />
  );
};

export default CheckoutBusinessProvSelect;
