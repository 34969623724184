import { Box } from '@chakra-ui/react';

import useReadLoggedUser from '../../../../hooks/useReadLoggedUser';
import useWriteVariants from '../../../../hooks/useWriteVariants';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, COMMON_PADDING } from '../../../../utils/styleUtil';
import FetchLoaderSkeleton from '../../../generic/FetchLoaderSkeleton';
import VariantsListPerPerson from '../perperson/VariantsListPerPerson';
import VariantsListPerSession from '../persession/VariantsListPerSession';
import SessionVariantNotBookable from '../SessionVariantNotBookable';

const SessionVariantsByList = ({ product, availability, nbAvaSelected, defaultVariant, statusLoadVariants, maxAvailablePlaces, canBookMore }) => {
  const loggedUser = useReadLoggedUser();

  const { areVariantsLoaded, variants, onVariantAdd, onVariantDelete, onOptionUpdate, placesAvailable, totalParticipants } = useWriteVariants(
    availability,
    defaultVariant,
    maxAvailablePlaces
  );

  if (!areVariantsLoaded()) {
    return (
      <Box bg="brand.background_secondary" p={COMMON_PADDING} {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
        <FetchLoaderSkeleton status={statusLoadVariants} skeletonsNumber={1} skeletonHeight="100px" />
      </Box>
    );
  }

  if (!canBookMore) return <SessionVariantNotBookable />;

  const listProps = {
    defaultVariant: defaultVariant,
    variants: variants,
    isFree: product.free,
    onVariantAdd: onVariantAdd,
    onVariantDelete: onVariantDelete,
    onOptionUpdate: onOptionUpdate,
    nbAvaSelected: nbAvaSelected,
    loggedUser: loggedUser,
    placesAvailable: placesAvailable,
    placesMax: maxAvailablePlaces,
    totalParticipants: totalParticipants,
  };

  return (
    <>
      {product.price_per_participant && <VariantsListPerPerson {...listProps} />}
      {!product.price_per_participant && <VariantsListPerSession {...listProps} />}
    </>
  );
};

export default SessionVariantsByList;
