import { Divider } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import useFormBuyVoucher from '../../../hooks/useFormBuyVoucher';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import ButtonSubmit from '../../generic/button/ButtonSubmit';
import AlertError from '../../generic/error/AlertError';
import ContainerTotal from '../../generic/layout/ContainerTotal';
import VerticalStack from '../../generic/layout/VerticalStack';
import BuyVoucherDetail from './BuyVoucherDetail';
import BuyVoucherQuantity from './BuyVoucherQuantity';

const BuyVoucherOnlyMode = ({ product }) => {
  const { id: productId } = product;
  const { vouchers: productVouchers, voucher_name: productVoucherName } = product;

  const { onSubmitSaveVoucher, isSubmitting, status, selectedVoucher, handleVoucherSelect, handleVoucherUpdate } = useFormBuyVoucher(
    productId,
    productVouchers
  );

  return (
    <form onSubmit={onSubmitSaveVoucher}>
      <VerticalStack alignItems="center">
        {status === REQUEST_STATUS.ERROR && <AlertError />}

        <BuyVoucherDetail
          productTitle={productVoucherName || <Trans>Voucher</Trans>}
          productVouchers={productVouchers}
          selectedVoucher={selectedVoucher}
          handleVoucherSelect={handleVoucherSelect}
        />

        <Divider m=".5rem 0" />
        {selectedVoucher && <BuyVoucherQuantity selectedVoucher={selectedVoucher} handleVoucherUpdate={handleVoucherUpdate} flexDirection="column" />}

        {selectedVoucher && <ContainerTotal total={selectedVoucher.price * selectedVoucher.quantity} />}

        <ButtonSubmit w="50%" isLoading={isSubmitting || selectedVoucher == null}>
          <Trans>Add to cart</Trans>
        </ButtonSubmit>
      </VerticalStack>
    </form>
  );
};

export default BuyVoucherOnlyMode;
