import { Button } from '@chakra-ui/button';
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import { Trans } from '@lingui/macro';

import { LOCAL_STORAGE_PRECART, LOCAL_STORAGE_PRECART_DEFVAL, ROUTE_P2_AVA_SELECTION } from '../../../../constants';
import useNavigateBookingEngine from '../../../../hooks/useNavigateBookingEngine';
import useWriteStorageBookingEngine from '../../../../hooks/useWriteStorageBookingEngine';
import { getFormattedCurrency } from '../../../../utils/business/prices';
import { COMMON_PADDING, HOVER_UNDERLINE, ITEM_SQUARE_HOVER, ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import ProductMediaManager from '../ProductMediaManager';
import ProductProximityTag from '../ProductProximityTag';

const ProductsSquare = ({ data: products }) => {
  // eslint-disable-next-line no-unused-vars
  const [preCartData, setPreCartData] = useWriteStorageBookingEngine(LOCAL_STORAGE_PRECART, LOCAL_STORAGE_PRECART_DEFVAL);

  const navigate = useNavigateBookingEngine();
  const onProductClick = (e, productId) => {
    e.preventDefault();
    setPreCartData(LOCAL_STORAGE_PRECART_DEFVAL);
    navigate(`${ROUTE_P2_AVA_SELECTION}/${productId}`);
  };

  return (
    <Flex flexDir={{ base: 'column', md: 'row' }} flexWrap="wrap" rowGap={COMMON_PADDING} columnGap={COMMON_PADDING} justifyContent="center">
      {products.map(product => (
        <Box
          key={product.id}
          position="relative"
          {...ITEM_SQUARE_HOVER}
          {...ROUNDED_ELEMENT_PROPS}
          padding="0"
          className="Product"
          background="brand.background_container"
          minWidth={{ base: undefined, md: '48%' }}
          width={{ base: undefined, md: '48%' }}
          height="250px"
          onClick={e => {
            onProductClick(e, product.id);
          }}
        >
          <ProductMediaManager disableVideo product={product} position="absolute" top="0" bottom="0" left="0" right="0" h="inherit" />

          <Box position="absolute" bottom="0" left="0" right="0" px=".5rem" py=".3rem" bg="rgba(0,0,0,.7)" color="white">
            <ProductProximityTag proximity={product.proximity} locality={product.locality} variant="solid" />
            <HStack>
              <VStack gap="0" flexGrow="1" alignItems="flex-start">
                <Text fontSize="md" noOfLines={1} {...HOVER_UNDERLINE}>
                  {product.title}
                </Text>
                <Text fontSize="xs">
                  {product.free && <Trans>Free</Trans>}

                  {!product.free && <Trans>From</Trans>}
                  {!product.free && ' ' + getFormattedCurrency(product.price_from)}
                </Text>
              </VStack>

              <Button {...HOVER_UNDERLINE} size="sm">
                <Trans>Book</Trans>
              </Button>
            </HStack>
          </Box>
        </Box>
      ))}
    </Flex>
  );
};

export default ProductsSquare;
