import { useState } from 'react';

import { Box } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffectOnce } from 'usehooks-ts';

import { LOCAL_STORAGE_CART, ROUTE_P1_PRODUCT_SELECTION } from '../../../constants';
import useNavigateBookingEngine from '../../../hooks/useNavigateBookingEngine';
import useReadStorageBookingEngine from '../../../hooks/useReadStorageBookingEngine';
import useReadTeam from '../../../hooks/useReadTeam';
import { BOTTOM_ROUNDED_CONTAINER_PROPS, COMMON_PADDING, ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import FlexRow from '../../generic/layout/FlexRow';
import HeaderLogoLabel from '../../generic/layout/header/HeaderLogoLabel';
import CheckoutCart from './CheckoutCart';
import CheckoutForm from './CheckoutForm';
import CheckoutPromoTaxContainer from './CheckoutPromoTaxContainer';

const Checkout = () => {
  const { teamId, isTaxIncluded } = useReadTeam();

  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);
  const [unavailableItems, setUnavailableItems] = useState(null);

  const navigate = useNavigateBookingEngine();
  useEffectOnce(() => {
    if (!cartData) {
      navigate(ROUTE_P1_PRODUCT_SELECTION);
    }
  });

  if (!cartData) return <></>;
  return (
    <>
      <HeaderLogoLabel>
        <Trans>Bill and contact</Trans>
      </HeaderLogoLabel>

      <FlexRow p={COMMON_PADDING} {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
        <Box w={{ base: '100%', md: '65%' }} mb={{ base: '1rem', md: undefined }}>
          <CheckoutPromoTaxContainer teamId={teamId} cartOrderId={cartData.cartOrderId} priceData={cartData.totalPrice} />
          <CheckoutCart cartData={cartData} isTaxIncluded={isTaxIncluded} unavailableItems={unavailableItems} />
        </Box>

        <Box bg="brand.background_main" w={{ base: '100%', md: '35%' }} {...ROUNDED_ELEMENT_PROPS}>
          <CheckoutForm
            cartMainClientId={cartData.cartMainClientId}
            totalPrice={cartData.totalPrice}
            unavailableItems={unavailableItems}
            setUnavailableItems={setUnavailableItems}
          />
        </Box>
      </FlexRow>
    </>
  );
};

export default Checkout;
