import { Box, HStack } from '@chakra-ui/react';

const CartElementBox = ({ flexGrow = '0', ...props }) => {
  return (
    <Box
      bg="brand.background_smallbox"
      p={{ base: '.3rem .8rem', md: '.3rem .5rem', lg: '.3rem 1rem' }} // responsive padding for smaller screens P3/P6
      borderRadius="5px"
      fontSize="xs"
      flexGrow={flexGrow}
      shadow="inner"
      style={{ margin: 0 }}
    >
      <HStack>{props.children}</HStack>
    </Box>
  );
};

export default CartElementBox;
