import { Box } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { ADDTOCART_FORM_ACTION, BUYNOW_FORM_ACTION } from '../../../constants';
import { REQUEST_STATUS } from '../../../utils/requestUtil';
import ButtonSubmit from '../../generic/button/ButtonSubmit';

const SessionVariantsActions = ({
  requestedAction,
  statusAddCart,
  statusLoadVariants,
  itemsToDeleteForEdit,
  isMobile,
  buttonsWidth = '100%',
  setRequestedAction,
}) => {
  const getInverseBtn = btnName => (btnName === ADDTOCART_FORM_ACTION ? BUYNOW_FORM_ACTION : ADDTOCART_FORM_ACTION);
  const getBtnProps = btnName => {
    return {
      name: btnName,
      onClick: () => setRequestedAction('before_submit_' + btnName),
      w: buttonsWidth,
      alignSelf: 'center',
      isLoading: requestedAction.current === btnName,
      // We disable when :
      // - we clicked one button so we disable the other one
      // - we didnt load the variant yet (except in edit mode because it was done before)
      isDisabled: requestedAction.current === getInverseBtn(btnName) || (statusLoadVariants !== REQUEST_STATUS.OK && itemsToDeleteForEdit === null),
    };
  };

  return (
    <>
      {statusAddCart === REQUEST_STATUS.LOADING && !itemsToDeleteForEdit && (
        <>
          <ButtonSubmit {...getBtnProps(ADDTOCART_FORM_ACTION)}>
            <Trans>Add to cart</Trans>
          </ButtonSubmit>

          {isMobile && <Box mt="1rem" />}

          <ButtonSubmit {...getBtnProps(BUYNOW_FORM_ACTION)}>
            <Trans>Buy now</Trans>
          </ButtonSubmit>
        </>
      )}

      {statusAddCart === REQUEST_STATUS.LOADING && itemsToDeleteForEdit && (
        <ButtonSubmit {...getBtnProps(ADDTOCART_FORM_ACTION)}>
          <Trans>Save</Trans>
        </ButtonSubmit>
      )}
    </>
  );
};

export default SessionVariantsActions;
