import { useContext } from 'react';

import { Box, Center, Text } from '@chakra-ui/react';
import { Plural } from '@lingui/macro';
import { IoMdAddCircleOutline } from 'react-icons/io';

import { CoreContext } from '../../../context/CoreContext';
import { BOTTOM_ROUNDED_CONTAINER_PROPS } from '../../../utils/styleUtil';
import ButtonAdd from '../../generic/button/ButtonAdd';
import ContainerPlural from '../../generic/layout/ContainerPlural';
import VerticalStack from '../../generic/layout/VerticalStack';

const VariantLinesContainer = ({ canAddVariant, onVariantAdd, placesAvailable, placesMax, ...props }) => {
  const { coreData } = useContext(CoreContext);
  const { show_nb_remaining_tickets: showNbPlaces } = coreData.booking_engine;

  const btnAddProps = {
    onClick: onVariantAdd,
    icon: IoMdAddCircleOutline,
    addText: true,
  };

  const placesTextProps = {
    fontSize: '11px',
    fontStyle: 'italic',
  };

  if (placesAvailable === 0) {
    btnAddProps['isDisabled'] = true;
    btnAddProps['color'] = placesTextProps['color'] = 'red';
  }

  return (
    <Box bg="brand.background_secondary" p="8px" {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
      <VerticalStack>
        {props.children}

        {placesMax !== 1 && (
          <Center>
            {canAddVariant && <ButtonAdd {...btnAddProps} />}

            {placesAvailable !== null && showNbPlaces && (
              <Text {...placesTextProps} as="span">
                {'('}
                <ContainerPlural value={placesAvailable} style={{ display: 'inline' }}>
                  <Plural value={placesAvailable} one="place available" other="places available" />
                </ContainerPlural>
                {')'}
              </Text>
            )}
          </Center>
        )}
      </VerticalStack>
    </Box>
  );
};

export default VariantLinesContainer;
