import { useState } from 'react';

import { Trans } from '@lingui/macro';

import { ROUTE_P3_SESSION_VARIANTS } from '../../../../constants';
import useNavigateBookingEngine from '../../../../hooks/useNavigateBookingEngine';
import useWriteFirstAvailabilityDate from '../../../../hooks/useWriteFirstAvailabilityDate';
import useWritePreCart from '../../../../hooks/useWritePreCart';
import { removeObjectFromArray } from '../../../../utils/arrayUtil';
import ButtonLink from '../../../generic/button/ButtonLink';
import AlertError from '../../../generic/error/AlertError';
import Availability from '../Availability';

const AvailabilitiesListMultiSelect = ({ data: availabilities }) => {
  useWriteFirstAvailabilityDate(availabilities ? availabilities[0].start_date : null);

  const { setPreCartAvailabilities } = useWritePreCart();
  const [selectedAvailabilities, setSelectedAvailabilities] = useState([]);

  const onAvailabilitySelect = (availability, isChecked) => {
    availability.variants = [];

    const arrayCopy = [...selectedAvailabilities];
    removeObjectFromArray(arrayCopy, availability);
    if (isChecked) {
      arrayCopy.push(availability);
    }
    setSelectedAvailabilities(arrayCopy);
  };

  const navigate = useNavigateBookingEngine();
  const onBooking = e => {
    e.preventDefault();

    setPreCartAvailabilities(selectedAvailabilities);
    navigate(ROUTE_P3_SESSION_VARIANTS);
  };

  const hasSubGroupConflict = () => {
    const selectedIds = [];
    for (const availability of selectedAvailabilities) {
      if (hasSubGroupConflictForId(availability.session_group_id, selectedIds)) return true;
      if (availability.main_group_id && hasSubGroupConflictForId(availability.main_group_id, selectedIds)) return true;
    }
    return false;
  };

  const hasSubGroupConflictForId = (elemId, elemList) => {
    if (elemList.includes(elemId)) return true;
    elemList.push(elemId);
    return false;
  };

  const hasConflict = hasSubGroupConflict();

  return (
    <>
      <ButtonLink
        onClick={onBooking}
        isDisabled={selectedAvailabilities.length <= 0 || hasConflict}
        maxW={{ base: '300px', md: '200px' }}
        alignSelf="flex-end"
      >
        <Trans>Book selected sessions ({selectedAvailabilities.length})</Trans>
      </ButtonLink>

      {selectedAvailabilities.length > 0 && hasConflict && (
        <AlertError message={<Trans>Sessions from the same group have been selected. Please check your selection.</Trans>} />
      )}

      {availabilities.map(availability => (
        <Availability key={availability.session_group_id} availability={availability} onAvailabilitySelect={onAvailabilitySelect} />
      ))}
    </>
  );
};

export default AvailabilitiesListMultiSelect;
