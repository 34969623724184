import { Flex } from '@chakra-ui/react';

import { COMMON_GAP } from '../../utils/styleUtil';
import TeamLogo from './TeamLogo';

const FilterLine = ({ filterLineNumber = 0, filters, renderFilter, showFilters, ...props }) => {
  // filter are not shown so we dont display the other filter lines
  if (filterLineNumber > 0 && !showFilters) return <></>;

  // normal render
  return (
    <Flex
      gap={{ base: '.5rem', md: COMMON_GAP }}
      columnGap={{ base: COMMON_GAP, md: undefined }}
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
      justifyContent="flex-start"
    >
      {filterLineNumber === 0 && props.children}

      {/* This is ugly but we want to have the filter lines aligned on lg screens */}
      {filterLineNumber > 0 && props.children && <TeamLogo visibility="hidden" />}

      {filters && filters.map(filterField => renderFilter(filterField))}
    </Flex>
  );
};

export default FilterLine;
