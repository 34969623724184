import { Box } from '@chakra-ui/react';

const BorderLeftBox = ({ children, ...props }) => {
  return (
    <Box borderLeft="2px solid" borderColor="brand.color_main" paddingLeft="8px" {...props}>
      {children}
    </Box>
  );
};

export default BorderLeftBox;
