import { useMemo } from 'react';

import { getVariantSelectedPrice, getOptionsTotalForVariant, getVariantTotalPerParticipant } from '../utils/business/prices';

const useCalcSessionVariantsTotal = (isPricePerPerson, availabilities) => {
  const total = useMemo(() => getAllVariantsTotal(isPricePerPerson, availabilities), [JSON.stringify(availabilities)]);
  return total;
};

export default useCalcSessionVariantsTotal;

export const getAllVariantsTotal = (isPricePerPerson, availabilities) => {
  // We dont care about the availabilities because they're grouped so we take the first one
  const availability = availabilities[0];
  const nbAvaSelected = availabilities.length;

  if (isPricePerPerson) {
    return getAllVariantsTotalPerParticipant(availability, nbAvaSelected);
  }
  return getAllVariantsTotalPerSession(availability, nbAvaSelected);
};

// This calculation is easy, we just add each variant price to the total

const getAllVariantsTotalPerParticipant = (availability, nbAvaSelected) => {
  let price = 0.0;
  for (const variant of availability.variants) {
    price += getVariantTotalPerParticipant(variant, nbAvaSelected);
  }
  return price;
};

// This calculation is complicated compared to the total per participant
// The total per participant depends on each variant separately, this is not really the case here
//
// In this case the session total depends on the sum of ALL participants (= sum of ALL variants of this session)
// Eg: for an availability composed of 2 variants with 1 participant each
//      - the availability has a discount price when we have >=2 participants
// => the 'session total' counts 2 participants and WILL apply a discount price
// => the 'participant total' counts 2 * 1 participant and WILL NOT apply a discount price
//
// We also need to add the options prices that behave in the same way as the total per participant
const getAllVariantsTotalPerSession = (availability, nbAvaSelected) => {
  let price = 0.0;

  // the availabilities might be loaded but the variants not yet !
  const { priceOfAllOptions, participantsOfSession } = calculateEachVariant(availability.variants);
  const sessionTotal = getVariantSelectedPrice(availability.variants[0], participantsOfSession);
  const totalForSession = sessionTotal + priceOfAllOptions;

  price += totalForSession * nbAvaSelected;

  return price;
};

const calculateEachVariant = variants => {
  let priceOfAllOptions = 0.0;
  let participantsOfSession = 0;

  for (const variant of variants) {
    const participantsOfVariant = parseInt(variant.participant);
    const variantOptionsTotal = participantsOfVariant * getOptionsTotalForVariant(variant.custom_options);

    priceOfAllOptions += variantOptionsTotal;
    participantsOfSession += participantsOfVariant;
  }

  return { priceOfAllOptions, participantsOfSession };
};
