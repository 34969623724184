import { Text, VisuallyHidden } from '@chakra-ui/react';
import { defineMessage, Trans } from '@lingui/macro';

import useReadPriceChoices from '../../../../hooks/useReadPriceChoices';
import Select from '../../../generic/form/Select';
import SelectOptionWithPrice from '../../../generic/form/SelectOptionWithPrice';

export const OPTION_PRICE_KEYNAME = 'currentPriceVariationId';

const OptionPrice = ({ index, onOptionUpdate, variant, totalParticipants, displayInline = false }) => {
  const choices = useReadPriceChoices(variant, totalParticipants);
  const hasOnePrice = choices.length === 1;

  // if only one price we still have the select but hidden
  if (hasOnePrice) {
    return (
      <VisuallyHidden>
        <Select
          displayLabel={false}
          fieldLabel={defineMessage({ message: 'Price' })}
          fieldChoices={choices}
          fieldValue={variant.currentPriceVariationId}
          onChange={(fieldName, newValue) => onOptionUpdate(OPTION_PRICE_KEYNAME, newValue, index)}
        />
      </VisuallyHidden>
    );
  }

  return (
    <>
      {displayInline && (
        <Text fontSize="sm" color="brand.color_main">
          <Trans>at a price of :</Trans>
        </Text>
      )}

      <Select
        displayLabel={!displayInline}
        fieldLabel={defineMessage({ message: 'Price' })}
        fieldChoices={choices}
        fieldValue={variant.currentPriceVariationId}
        onChange={(fieldName, newValue) => onOptionUpdate(OPTION_PRICE_KEYNAME, newValue, index)}
        isRequired
        minWidth="240px"
        maxWidth="240px"
        formatOptionLabel={SelectOptionWithPrice}
      />
    </>
  );
};

export default OptionPrice;
