import { useState } from 'react';

import { useUpdateEffect } from '@chakra-ui/react';

import {
  LOCAL_STORAGE_CART,
  LOCAL_STORAGE_CART_DEFVAL,
  LOCAL_STORAGE_CART_EXPIRE,
  LOCAL_STORAGE_CART_EXPIRE_DEFVAL,
  LOCAL_STORAGE_CART_ID,
  LOCAL_STORAGE_CART_ID_DEFVAL,
} from '../constants';
import { devMessage, settings } from '../settings/settings';
import { findArrayItemById } from '../utils/arrayUtil';
import { addDaysToDate } from '../utils/dateUtil';
import { getCartSize } from './useCalcCartSize';
import useWriteStorageBookingEngine from './useWriteStorageBookingEngine';

const useWriteCartData = () => {
  const [cartDataToWrite, setCartDataToWrite] = useState(null);

  const [cartData, setCartData] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART, LOCAL_STORAGE_CART_DEFVAL);

  // eslint-disable-next-line no-unused-vars
  const [cartExpire, setCartExpire] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART_EXPIRE, LOCAL_STORAGE_CART_EXPIRE_DEFVAL);

  const [cartId, setCartId] = useWriteStorageBookingEngine(LOCAL_STORAGE_CART_ID, LOCAL_STORAGE_CART_ID_DEFVAL);

  const [cartWriteSuccess, setCartWriteSuccess] = useState(null);

  useUpdateEffect(() => {
    // React refresh: already handled
    if (!cartDataToWrite || !cartDataToWrite.order) {
      devMessage('Call to write cart but already handled', cartDataToWrite);
      setCartWriteSuccess(true);
      return;
    }

    const cartExpireDate = formatCartExpiration(cartDataToWrite.order.creation_date);
    devMessage('Cart will expire on', cartExpireDate);

    setCartExpire(cartExpireDate.getTime());

    const newCartData = formatCartDataFromBackend(cartDataToWrite);
    resetCartIdOnEmptyData(newCartData);
    setCartData(newCartData);
    setCartWriteSuccess(true);
  }, [cartDataToWrite]);

  const resetCartIdOnEmptyData = cartData => {
    if (cartId && getCartSize(cartData) <= 0) {
      setCartId(LOCAL_STORAGE_CART_ID_DEFVAL);
    }
  };

  return {
    setCartDataToWrite,
    cartData,
    cartWriteSuccess,
    setCartWriteSuccess,
  };
};

export default useWriteCartData;

const formatCartDataFromBackend = cartData => {
  const cartItems = cartData.order.items;
  const formattedData = {
    // order data
    cartOrderId: cartData.order.id,
    cartMainClientId: cartData.order.main_client_id,
    totalPrice: cartData.order.total_price,
    cartSource: cartData.order.source,

    // the order content
    ocSessionsWithResources: getSessionsWithResources(cartItems, cartData.session_groups, cartData.resources),
    ocVouchers: getVouchers(cartItems),
    ocSingleResources: getSingleResources(cartItems),

    // additional data
    clients: getClientsWithSessions(cartItems, cartData.clients),
    products: cartData.products,
    resources: cartData.resources,
    specificPersonData: cartData.specific_person_data,
  };
  devMessage('Formatted cart data:', formattedData);
  return formattedData;
};

const getVouchers = cartItems => {
  return cartItems.filter(item => item.gift_voucher != null);
};

const getSingleResources = cartItems => {
  return cartItems.filter(item => item.resource_target_group_id == null && item.resource_id != null);
};

const getSessionsWithResources = (cartItems, cartSessionsGroups, cartResources) => {
  const variantsGroupedMap = groupSameVariantIds(cartItems, cartSessionsGroups);
  linkResourcesToVariants(variantsGroupedMap, cartItems, cartResources);
  return Array.from(variantsGroupedMap.entries()); // we cannot store a map in the fucking storage
};

const groupSameVariantIds = (cartItems, cartSessionsGroups) => {
  const allSessionsGrouped = new Map();
  for (const item of cartItems) {
    const sessionGroupId = item.session_group_id;
    if (sessionGroupId == null) continue;

    const idForAggregation = getIdForAggregation(item);
    if (!allSessionsGrouped.has(idForAggregation)) {
      const sessionGroup = findArrayItemById(sessionGroupId, cartSessionsGroups);
      allSessionsGrouped.set(idForAggregation, {
        availabilities: [],
        countParticipants: 0,
        countResources: 0,
        detailResources: [],
        totalTaxIncluded: 0.0,
        totalTaxIncludedWithoutPromo: 0.0,
        totalTaxExcluded: 0.0,
        totalTaxExcludedWithoutPromo: 0.0,
        productId: sessionGroup.product_id,
        sessions: sessionGroup.sessions,
        sessionGroupId: sessionGroupId,
      });
    }

    const sameSessions = allSessionsGrouped.get(idForAggregation);
    appendPricesToTotals(sameSessions, item);
    sameSessions.countParticipants += parseInt(item.quantity);

    if (!item.resources) {
      item.resources = [];
    }
    sameSessions.availabilities.push(item);
  }
  return allSessionsGrouped;
};

const linkResourcesToVariants = (variantsGroupedMap, cartItems, cartResources) => {
  for (const item of cartItems) {
    const sessionGroupId = item.resource_target_group_id;
    if (sessionGroupId == null) continue;

    const idForAggregation = getIdForAggregation(item);
    const sameSessions = variantsGroupedMap.get(idForAggregation);
    // We deleted the availability and there's nothing to link with
    if (!sameSessions) continue;

    for (const availability of sameSessions.availabilities) {
      if (availability.client_id !== item.client_id) continue;

      appendPricesToTotals(sameSessions, item);
      sameSessions.countResources += parseInt(item.quantity);
      availability.resources.push(item);

      appendResourcesDetails(sameSessions.detailResources, item, cartResources);
      break;
    }
  }
};

const appendResourcesDetails = (detailResources, resourceToAdd, cartResources) => {
  const foundResource = findArrayItemById(resourceToAdd.resource_id, detailResources);
  if (foundResource) {
    foundResource.quantity += parseInt(resourceToAdd.quantity);
    return;
  }

  const resourceLabel = findArrayItemById(resourceToAdd.resource_id, cartResources).label;
  detailResources.push({ id: resourceToAdd.resource_id, label: resourceLabel, quantity: parseInt(resourceToAdd.quantity) });
};

const appendPricesToTotals = (sameSessions, item) => {
  const priceWithoutPromo = item.price_without_promo ? parseFloat(item.price_without_promo) : null;
  const priceTaxIncluded = parseFloat(item.price);
  const priceTaxExcluded = parseFloat(item.price_tax_excluded);

  sameSessions.totalTaxIncluded += priceTaxIncluded;
  sameSessions.totalTaxIncludedWithoutPromo += priceWithoutPromo ?? priceTaxIncluded;

  sameSessions.totalTaxExcluded += priceTaxExcluded;
  sameSessions.totalTaxExcludedWithoutPromo += priceWithoutPromo ?? priceTaxExcluded;
};

const formatCartExpiration = creationDateStr => {
  const creationDate = new Date(creationDateStr);
  const expirationDate = addDaysToDate(creationDate, settings.CART_DAYS_EXPIRATION);
  return expirationDate;
};

// This will remove the clients associated only with vouchers/dry resources
// It will also remove the main client (used only in P6)
// This way, the P5 will contain only useful participants data
const getClientsWithSessions = (cartItems, clients) => {
  const clientsWithSessions = [];

  for (const item of cartItems) {
    if (!item.session_group_id) continue;

    const client = findArrayItemById(item.client_id, clients);
    clientsWithSessions.push(client);
  }

  return clientsWithSessions;
};

// This is sent by the backend, helps us to regroup our cart elements
const getIdForAggregation = item => item.cart_group_id;
