import { t } from '@lingui/macro';

import { sortObjectArrayByKey } from '../utils/arrayUtil';

const useReadSubcategories = productSubcategories => {
  const choices = [{ label: t`All`, value: '' }];
  if (!productSubcategories) {
    return choices;
  }

  const sortedArray = sortObjectArrayByKey(productSubcategories, 'name');
  for (const tag of sortedArray) {
    choices.push({ label: tag.name, value: tag.id.toString() });
  }
  return choices;
};

export default useReadSubcategories;
