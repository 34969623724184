import { useState } from 'react';

import cloneDeep from 'lodash.clonedeep';
import { useUpdateEffect } from 'usehooks-ts';

import { LOCAL_STORAGE_CART_ID, ROUTE_PAGE_CART } from '../constants';
import { gtmPushAddVoucherToCart } from '../gtm';
import { devMessage } from '../settings/settings';
import { REQUEST_STATUS } from '../utils/requestUtil';
import useNavigateBookingEngine from './useNavigateBookingEngine';
import usePostVoucher from './usePostVoucher';
import useReadStorageBookingEngine from './useReadStorageBookingEngine';
import useReadTeam from './useReadTeam';
import useWriteCartData from './useWriteCartData';

// true = 1 bénéficiaire, same voucher can be used multiple times by the same user
// false = N people => N vouchers in the cart
const DEFAULT_VOUCHER_MULTIUSAGE = true;
const DEFAULT_VOUCHER_QUANTITY = 1;

const useFormBuyVoucher = (productId, productVouchers) => {
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const { teamId, teamCurrency } = useReadTeam();
  const storedCartId = useReadStorageBookingEngine(LOCAL_STORAGE_CART_ID);
  const { data, status, writeVoucher } = usePostVoucher(teamId, storedCartId);

  const { setCartDataToWrite, cartData, cartWriteSuccess } = useWriteCartData();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleVoucherSelect = (fName, fieldValue, selectLabel) => {
    for (const voucher of productVouchers) {
      if (voucher.id === fieldValue) {
        const copyVoucher = cloneDeep(voucher);
        copyVoucher.price = parseFloat(copyVoucher.price);
        copyVoucher.quantity = DEFAULT_VOUCHER_QUANTITY;
        copyVoucher.isMultiUsage = DEFAULT_VOUCHER_MULTIUSAGE;
        setSelectedVoucher(copyVoucher);
        return;
      }
    }
  };

  const handleVoucherUpdate = updateData => {
    const copyVoucher = cloneDeep(selectedVoucher);
    if (updateData.quantity) {
      copyVoucher.quantity = parseInt(updateData.quantity);
    } else if (updateData.toggleMultiUsage) {
      copyVoucher.isMultiUsage = !copyVoucher.isMultiUsage;
    } else return;
    setSelectedVoucher(copyVoucher);
  };

  const onSubmitSaveVoucher = e => {
    e.preventDefault();
    setIsSubmitting(true);

    writeVoucher(productId, selectedVoucher);
  };

  useUpdateEffect(() => {
    if (status === REQUEST_STATUS.OK) {
      setCartDataToWrite(data);
    }
  }, [data, status]);

  const navigate = useNavigateBookingEngine();
  useUpdateEffect(() => {
    setIsSubmitting(false);
    if (cartWriteSuccess) {
      gtmPushAddVoucherToCart(selectedVoucher, teamCurrency);
      const cartUrl = `${ROUTE_PAGE_CART}/${cartData.cartOrderId}`;
      devMessage('Navigating to cart:', cartUrl);
      navigate(cartUrl, { state: { queryBackend: false } });
    }
  }, [cartWriteSuccess]);

  return {
    onSubmitSaveVoucher,
    isSubmitting,
    status,
    selectedVoucher,
    handleVoucherSelect,
    handleVoucherUpdate,
  };
};

export default useFormBuyVoucher;
