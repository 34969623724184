import { useState } from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useUpdateEffect } from 'usehooks-ts';

import { BUSINESS_PROV_SELECTOR_FIELDNAME } from '../components/business/checkout/CheckoutBusinessProvSelect';
import { CLIENT_SELECTOR_FIELDNAME } from '../components/business/checkout/CheckoutClientSelect';
import { DEPOSIT_FIELDNAME } from '../components/business/checkout/CheckoutDeposit';
import { FIRST_NAME_FIELDNAME, LAST_NAME_FIELDNAME } from '../components/business/checkout/CheckoutFieldsCustomer';
import { SEND_BOOKING_MAIL_FIELDNAME } from '../components/business/checkout/CheckoutMailToggle';
import { MERGE_ORDER_FIELDNAME } from '../components/business/checkout/CheckoutMergeOrderCheckbox';
import { PAYMENTLINK_TEMPLATE_FIELDNAME } from '../components/business/checkout/CheckoutPaymentLinkTemplate';
import { PAYMENT_METHOD_FIELDNAME } from '../components/business/checkout/CheckoutPaymentMethod';
import { EMAIL_FIELDNAME } from '../components/generic/form/InputEmail';
import { PHONE_COUNTRYCODE_FIELDNAME, PHONE_FIELDNAME } from '../components/generic/form/InputPhone';
import { RADIO_YES } from '../components/generic/form/InputRadioCard';
import { apiCheckout, LOCAL_STORAGE_CART_ID, ROUTE_PAGE_CHECKOUT_SUCCESS } from '../constants';
import { gtmPushBeginCheckout } from '../gtm';
import { adaptPhoneForBackend } from '../utils/phoneUtil';
import { REQUEST_STATUS } from '../utils/requestUtil';
import { trimObjectKeys } from '../utils/strUtil';
import useCleanStorage from './useCleanStorage';
import useNavigateBookingEngine from './useNavigateBookingEngine';
import usePost from './usePost';
import useReadCartMissingOrder from './useReadCartMissingOrder';
import useReadLoggedUser from './useReadLoggedUser';
import useReadManufacturer from './useReadManufacturer';
import { NO_PAYMENT_METHOD, PAYMENT_LINK_METHOD } from './useReadPaymentMethods';
import useReadStorageBookingEngine from './useReadStorageBookingEngine';

const usePostCheckout = (cartMainClientId, currency, totalPrice) => {
  const storedCartId = useReadStorageBookingEngine(LOCAL_STORAGE_CART_ID);

  const [postData, setPostData] = useState(null);
  const { data, status, executePost } = usePost(apiCheckout(storedCartId), postData);
  useReadCartMissingOrder(data);

  const { cleanOrderGotoCart } = useCleanStorage(false);

  const navigate = useNavigateBookingEngine();

  // This is to determine if we send the order.person_id
  const { isManufacturer } = useReadManufacturer();
  const loggedUser = useReadLoggedUser();

  const writeCheckout = paymentData => {
    const formattedData = formatCheckoutData(cartMainClientId, paymentData);

    // additional formatting methods that are inside this hook
    formatOrderPersonId(formattedData);

    setPostData(formattedData);
  };

  useUpdateEffect(() => {
    // This might be a cart reset, we just clean and redirect
    if (storedCartId == null) {
      cleanOrderGotoCart(t`Your cart has expired, please try again.`);
      return;
    }

    gtmPushBeginCheckout(storedCartId, totalPrice, currency);
    executePost();
  }, [postData]);

  useUpdateEffect(() => {
    if (status === REQUEST_STATUS.OK && data.success) {
      if (data.payment_url !== null) {
        window.top.location.href = data.payment_url; // open in current tab (inside parent of iframe)
        // window.open(data.payment_url, '_blank'); // open in new tab
        // window.location.href = data.payment_url; // open in current tab (inside iframe)
        return;
      }
      navigate(ROUTE_PAGE_CHECKOUT_SUCCESS);
    }
  }, [status]);

  const formatOrderPersonId = formattedData => {
    const selector = formattedData['main_client'][CLIENT_SELECTOR_FIELDNAME];
    const selectorValue = selector ? selector.value : null;
    const isStaffSelector = selector ? selector.areChoicesStaffs : false;
    delete formattedData['main_client'][CLIENT_SELECTOR_FIELDNAME];

    // The order.person_id is only given in manufacturer mode
    if (!isManufacturer) return;

    // Manufacturer && admin => we have a staff selector so we send that value
    // Manufacturer NOT admin => we use the logged user
    formattedData['person_id'] = isStaffSelector ? selectorValue : loggedUser.id;
  };

  return { data, status, writeCheckout };
};

export default usePostCheckout;

const formatCheckoutData = (mainClientId, paymentData) => {
  const formattedData = {
    main_client: { ...paymentData },
  };

  formatPhoneNumber(formattedData['main_client']);
  formatPaymentMethod(paymentData, formattedData);
  formatPaymentLinkTemplate(paymentData, formattedData);
  formatSendBookingEmail(paymentData, formattedData);
  formatBusinessProvider(paymentData, formattedData);
  formatMergeOrder(paymentData, formattedData);
  formatDeposit(paymentData, formattedData);
  trimObjectKeys(formattedData['main_client'], [FIRST_NAME_FIELDNAME, LAST_NAME_FIELDNAME, EMAIL_FIELDNAME]);

  delete formattedData['main_client']['generalConditions'];
  formattedData['main_client']['id'] = mainClientId;

  return formattedData;
};

const formatPhoneNumber = mainClientData => {
  mainClientData[PHONE_FIELDNAME] = adaptPhoneForBackend(mainClientData[PHONE_FIELDNAME], mainClientData[PHONE_COUNTRYCODE_FIELDNAME]);
  delete mainClientData[PHONE_COUNTRYCODE_FIELDNAME];
};

const formatDeposit = (paymentData, formattedData) => {
  const deposit = paymentData[DEPOSIT_FIELDNAME];
  delete formattedData['main_client'][DEPOSIT_FIELDNAME];
  if (!deposit) return;

  formattedData[DEPOSIT_FIELDNAME] = deposit;
};

const formatPaymentMethod = (paymentData, formattedData) => {
  const paymentMethod = paymentData[PAYMENT_METHOD_FIELDNAME];
  delete formattedData['main_client'][PAYMENT_METHOD_FIELDNAME];
  if (!paymentMethod) return;

  // If we selected the NO_PAYMENT_METHOD, we will send null to backend
  // So the backend wont create a payment for this order
  formattedData[PAYMENT_METHOD_FIELDNAME] = paymentMethod === NO_PAYMENT_METHOD ? null : paymentMethod;
};

const formatPaymentLinkTemplate = (paymentData, formattedData) => {
  const paymentLinkTemplate = paymentData[PAYMENTLINK_TEMPLATE_FIELDNAME];
  delete formattedData['main_client'][PAYMENTLINK_TEMPLATE_FIELDNAME];
  if (!paymentLinkTemplate) return;

  const paymentMethod = paymentData[PAYMENT_METHOD_FIELDNAME];
  if (paymentMethod !== PAYMENT_LINK_METHOD) return;

  formattedData[PAYMENTLINK_TEMPLATE_FIELDNAME] = paymentLinkTemplate;
};

const formatSendBookingEmail = (paymentData, formattedData) => {
  const sendBookingEmail = paymentData[SEND_BOOKING_MAIL_FIELDNAME];
  delete formattedData['main_client'][SEND_BOOKING_MAIL_FIELDNAME];
  if (!sendBookingEmail) return;

  formattedData[SEND_BOOKING_MAIL_FIELDNAME] =
    sendBookingEmail === i18n._(RADIO_YES) && paymentData[PAYMENT_METHOD_FIELDNAME] !== PAYMENT_LINK_METHOD;
};

const formatBusinessProvider = (paymentData, formattedData) => {
  const businessProviderId = paymentData[BUSINESS_PROV_SELECTOR_FIELDNAME];
  delete formattedData['main_client'][BUSINESS_PROV_SELECTOR_FIELDNAME];
  if (!businessProviderId) return;

  formattedData[BUSINESS_PROV_SELECTOR_FIELDNAME] = businessProviderId;
};

const formatMergeOrder = (paymentData, formattedData) => {
  const mergeOrder = paymentData[MERGE_ORDER_FIELDNAME];
  delete formattedData['main_client'][MERGE_ORDER_FIELDNAME];
  if (!mergeOrder) return;

  formattedData[MERGE_ORDER_FIELDNAME] = mergeOrder;
};
