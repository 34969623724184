import useReadAvailableFeatures from '../../../../hooks/useReadAvailableFeatures';
import ShopFeature from './ShopFeature';

const ShopFeaturesBloc = ({ data, dataWidthProps }) => {
  const { featureOneOptions, featureTwoOptions, handleFeatureChange, shopResource } = useReadAvailableFeatures(data);

  return (
    <>
      {data.feature_one && featureOneOptions && (
        <ShopFeature
          availableFeatures={featureOneOptions}
          fieldLabel={data.feature_one.label}
          fieldValue={shopResource.feature_one}
          onChange={(fName, fieldValue, selectLabel) => {
            handleFeatureChange(1, fieldValue);
          }}
          {...dataWidthProps}
        />
      )}

      {data.feature_two && featureTwoOptions && (
        <ShopFeature
          availableFeatures={featureTwoOptions}
          fieldLabel={data.feature_two.label}
          fieldValue={shopResource.feature_two}
          onChange={(fName, fieldValue, selectLabel) => {
            handleFeatureChange(2, fieldValue);
          }}
          {...dataWidthProps}
        />
      )}
    </>
  );
};

export default ShopFeaturesBloc;
