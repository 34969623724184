import useWriteParticipantsData from '../../../../hooks/useWriteParticipantsData';
import AllSpecificData from './AllSpecificData';

const CheckoutDetailParticipantData = ({ participant }) => {
  const { additionalDataNeeded, hasAdditionalDataNeeded, onParticipantDataUpdate } = useWriteParticipantsData(participant.id);

  if (!hasAdditionalDataNeeded) {
    return <></>;
  }

  return <AllSpecificData additionalDataNeeded={additionalDataNeeded} participant={participant} onParticipantDataUpdate={onParticipantDataUpdate} />;
};

export default CheckoutDetailParticipantData;
