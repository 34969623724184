import { Text } from '@chakra-ui/react';

const SelectMessage = props => {
  return (
    <Text className="SelectMessage" fontSize="sm">
      {props.children}
    </Text>
  );
};

export default SelectMessage;
