import { Center, Icon, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FilterBarMobileShowHideText = ({ isOpen, onToggle }) => {
  return (
    <Center color="brand.color_main" fontSize="sm" fontWeight="500" onClick={onToggle}>
      {isOpen && (
        <>
          <Text>
            <Trans>Hide filters</Trans>
          </Text>
          <Icon as={FaChevronUp} ml="5px" mt="2px" />
        </>
      )}

      {!isOpen && (
        <>
          <Text>
            <Trans>Filter activities</Trans>
          </Text>
          <Icon as={FaChevronDown} ml="5px" mt="2px" />
        </>
      )}
    </Center>
  );
};

export default FilterBarMobileShowHideText;
