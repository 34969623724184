import { Trans } from '@lingui/macro';
import { IoMdDownload } from 'react-icons/io';

import { settings } from '../../../../settings/settings';
import ButtonIconLabel from '../../../generic/button/ButtonIconLabel';

const CartActionDownloadQuote = ({ storedCartId }) => {
  const handleDownloadQuote = e => {
    e.preventDefault();
    window.open(`${settings.YOPLANNING_ROOT_URL}/get-bill/${storedCartId}?quote=1`, '_blank', 'noopener,noreferrer');
  };

  return (
    <ButtonIconLabel onClick={handleDownloadQuote} icon={<IoMdDownload />}>
      <Trans>Download quote</Trans>
    </ButtonIconLabel>
  );
};

export default CartActionDownloadQuote;
