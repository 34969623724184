import { t } from '@lingui/macro';

import useWriteSeo from '../../hooks/useWriteSeo';
import ShopFilteredList from '../business/shop/ShopFilteredList';

const ShopPage = () => {
  useWriteSeo({ title: t`Shop`, description: t`List of resources bookable online` });

  return <ShopFilteredList />;
};

export default ShopPage;
