import { padToDigits } from './numberUtil';

export const formatDuration = duration => {
  if (duration < 60) {
    return duration + 'mn';
  }

  const minutes = duration % 60;
  const hours = Math.floor(duration / 60);
  if (minutes === 0) {
    return hours + 'h';
  }
  return hours + 'h' + padToDigits(minutes, 2);
};
