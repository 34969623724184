import { useEffect, useRef, useState } from 'react';

import { FormControl } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { Select as ChakraReactSelect } from 'chakra-react-select';

import useIsMobile from '../../../hooks/useIsMobile';
import { findArrayItemByKey } from '../../../utils/arrayUtil';
import InputFormLabel from './InputFormLabel';
import SelectLayout from './SelectLayout';
import SelectMessage from './SelectMessage';

// ==============================================================================================
// This component is used to generate a select either as a filter or inside a form
// When the component value is changed, we trigger an onChange callback provided by a parent
// ==============================================================================================
// NOTE: We switched from standard select to select from "react-select" (with chakra ui wrapper)
// They have a shitty "required" management/bug => https://github.com/JedWatson/react-select/issues/3140
// That's why there's a lot of stuff down there with the required/focus/ref....

const Select = ({
  fieldName,
  fieldLabel,
  fieldLabelLines = 1,
  fieldValue,
  fieldChoices,
  displayLabel = true,
  isRequired,
  onChange,
  minWidth,
  maxWidth,
  height,
  size,
  selectFirstChoice = true,
  triggerChangeOnUpdate = false,
  isSearchable = false,
  formatOptionLabel,
  isOptionInvalid = false,
  isLoading = false,
  onInputChange,
  fieldPlaceholder,
  isClearable = false,
  additionalComponentHRight,
  className = 'ChakraReactSelect',
  classNamePrefix,
  additionalStyles = {},

  // for multi choices
  getSelectedOptionOverride,
  handleOnChangeOverride,
  componentsOverride,
  allowMultipleChoices = false,
  filterOptions,
}) => {
  const selectRef = useRef();
  const isMobile = useIsMobile();

  // DEV: IF YOU WANT TO SEE HOW THE SEARCH IS DONE THEN UNCOMMENT THIS CODE
  // AND UNCOMMENT THE RELATED PROP BELOW
  // IF YOU THINK THERE IS A BUG WITH THE SEARCH THERE'S PROBABLY NO BUG
  // THE ISSUE IS CERTAINLY INSIDE THE OPTION'S LABEL THAT YOU CREATED
  // THIS CODE HELPED ME SEE A DOUBLE SPACE BETWEEN FIRST/LAST NAMES THAT WAS PREVENTING SEARCH TO WORK
  // const filterOption = (candidate, input) => {
  //   console.log(candidate.label, 'vs', input);
  //   console.log('new', candidate.data.__isNew__, 'includes', candidate.label.includes(input));
  //   return candidate.data.__isNew__ || candidate.label.includes(input);
  // };
  // https://react-select.com/advanced#custom-filter-logic

  const getSelectedOption = () => {
    // override used for multi select
    if (getSelectedOptionOverride) return getSelectedOptionOverride();

    if (fieldValue) {
      const existingValue = findArrayItemByKey('value', fieldValue, fieldChoices) ?? findArrayItemByKey('label', fieldValue, fieldChoices);
      if (existingValue) return existingValue;
    }
    return selectFirstChoice && fieldChoices.length > 0 ? fieldChoices[0] : undefined;
  };

  const [selectedOption, setSelectedOption] = useState();
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    const option = getSelectedOption();
    setIsInvalid(isOptionInvalid ? isOptionInvalid(option) : false);
    setSelectedOption(option);

    // This flag is made for some selects that dont use state but only rely on onChange
    if (triggerChangeOnUpdate && option != null) {
      onChange(fieldName, option.value, option.label);
    }
  }, [JSON.stringify(fieldChoices), fieldValue]);

  const handleRequiredFocus = () => {
    selectRef.current.focus();
    setIsInvalid(true);
    selectRef.current.blur();
  };

  const handleOnChange = option => {
    // override used for multi select
    if (handleOnChangeOverride) return handleOnChangeOverride(option, setSelectedOption);

    if (option != null) {
      onChange(fieldName, option.value, option.label);
    } else {
      onChange(fieldName, null, null);
    }
    setSelectedOption(option);
  };

  // When we want to select our first choice by default, we wait for the choices
  // If not the render will be without the default value
  if (selectFirstChoice && (!fieldChoices || !fieldChoices.length)) return <></>;

  return (
    <FormControl isRequired={isRequired} minW={minWidth} maxW={maxWidth} height={height}>
      {displayLabel && fieldLabel && <InputFormLabel fieldName={fieldName} fieldLabel={fieldLabel} noOfLines={fieldLabelLines} />}

      <SelectLayout additionalComponentHRight={additionalComponentHRight}>
        <ChakraReactSelect
          name={fieldName}
          id={fieldName}
          value={selectedOption}
          onChange={handleOnChange}
          size={size}
          //
          // Chakra react select props
          ref={selectRef}
          className={className}
          classNamePrefix={classNamePrefix}
          useBasicStyles
          selectedOptionStyle="check"
          options={fieldChoices}
          isInvalid={isInvalid}
          isSearchable={isSearchable}
          filterOption={filterOptions}
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={() => (
            <SelectMessage>
              <Trans>No result</Trans>
            </SelectMessage>
          )}
          // -- hacky way of setting zIndex: https://stackoverflow.com/a/63898744
          menuPortalTarget={isMobile ? document.body : undefined}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          chakraStyles={{ crossIcon: base => ({ ...base, color: 'red', boxSize: '11px' }), ...additionalStyles }}
          isLoading={isLoading}
          loadingMessage={() => (
            <SelectMessage>
              <Trans>Searching...</Trans>
            </SelectMessage>
          )}
          onInputChange={onInputChange}
          placeholder={fieldPlaceholder}
          isClearable={isClearable}
          // multi choices
          isMulti={allowMultipleChoices}
          closeMenuOnSelect={!allowMultipleChoices}
          components={componentsOverride}
        />
        {isRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: '100%',
              height: 0,
              position: 'absolute',
            }}
            value={selectedOption ? selectedOption.value : ''}
            onChange={() => {}}
            onFocus={handleRequiredFocus}
            required={isRequired}
          />
        )}
      </SelectLayout>
    </FormControl>
  );
};

export default Select;
